import { Action } from "@ngrx/store";

export const SET_ALL_COUPONS = "[COUPONS] Set  Customer All Coupons";
export const SET_FETCH_COUPONS_AGAIN_TIMESTAMP =
  "[COUPONS] Set  Fetch Coupons Again Timestamp";
export const SET_TRIGGER_COUPON_CODE_CHECK =
  "[COUPONS] Set  Trigger Coupon Rule Id Check";

export const SET_SHOW_PROMO_COUPONS_MODAL_TIMESTAMP =
  "[COUPONS] Set  Show Promo Coupons Modal Timestamp";
export const SET_MO_COUPON_CHECK_REQUEST_COMPLETED =
  "[COUPONS] Set  Mo Coupon Check Request Completed";
export const CLEAR_ALL_COUPONS = "[COUPONS] Clear Customer All Coupons";

export class SetAllCoupons implements Action {
  readonly type = SET_ALL_COUPONS;
  constructor(public payload: any) {}
}

export class SetFetchCouponsAgainTimestamp implements Action {
  readonly type = SET_FETCH_COUPONS_AGAIN_TIMESTAMP;
  constructor(public payload: any) {}
}

export class SetShowPromoCouponsModalTimestamp implements Action {
  readonly type = SET_SHOW_PROMO_COUPONS_MODAL_TIMESTAMP;
  constructor(public payload: any) {}
}

export class SetCouponCheckRequestCompleted implements Action {
  readonly type = SET_MO_COUPON_CHECK_REQUEST_COMPLETED;
  constructor(public payload: any) {}
}

export class SetTriggerCouponCodeCheck implements Action {
  readonly type = SET_TRIGGER_COUPON_CODE_CHECK;
  constructor(
    public payload: { timestamp: any; coupon_code: any; type: any }
  ) {}
}

export class ClearAllCoupons implements Action {
  readonly type = CLEAR_ALL_COUPONS;
  constructor() {}
}

export type CustomerCouponsActions =
  | SetAllCoupons
  | SetFetchCouponsAgainTimestamp
  | SetCouponCheckRequestCompleted
  | SetShowPromoCouponsModalTimestamp
  | ClearAllCoupons
  | SetTriggerCouponCodeCheck;
