import { Injectable, Sanitizer } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as SelectedPaymentMethodIDActions from "../../cart/cart-components/payment-methods/store/selectePaymentMethod.actions";
import * as selectors from "../../store/app.selectors";
import * as PaymentGateWaysActions from "../../store/payment-gateways/payment-gateways.actions";
import { BillingInfoComponent } from "src/app/popups/payment/billing-info/billing-info.component";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { AlphaModalComponent } from "src/app/popups/payment/alpha-modal/alpha-modal.component";
import { PaypalModalComponent } from "src/app/popups/payment/paypal-modal/paypal-modal.component";
import { KlarnaModalComponent } from "src/app/popups/payment/klarna-modal/klarna-modal.component";
import { EthnikiModalComponent } from "src/app/popups/payment/ethniki-modal/ethniki-modal.component";
import { BraintreeModalComponent } from "src/app/popups/payment/braintree-modal/braintree-modal.component";
import { MyposModalComponent } from "src/app/popups/payment/mypos-modal/mypos-modal.component";
import { DomSanitizer } from "@angular/platform-browser";
import * as AuthActions from "../../auth/store/auth.actions";
import { DataStorageService } from "../data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { BillingInfoVerifyComponent } from "src/app/popups/payment/billing-info-verify/billing-info-verify.component";
import * as paymentGatewaysActions from "../../store/payment-gateways/payment-gateways.actions";
import { Capacitor } from "@capacitor/core";
import { NavigationToBankModalComponent } from "src/app/popups/navigation-to-bank-modal/navigation-to-bank-modal.component";
import * as OrdersActions from "../../orders/store/orders.actions";
import { Browser } from "@capacitor/browser";
import { Subscription } from "rxjs";
import { NbgSimplifyModalComponent } from "src/app/popups/payment/nbg-simplify-modal/nbg-simplify-modal.component";
import { LocalStorageDataService } from "../local-storage-data/local-storage-data.service";
declare let window: any;

@Injectable({
  providedIn: "root",
})
export class PaymentGatewaysService {
  private askEmailFromUserAlert: any;
  private showPaymentCards: boolean;
  private showPaymentCardsAdminOnly: boolean;
  private showPaymentCardsCustomerOnly: boolean;
  private paymentGateways: any;
  private storesByStoreId: any;
  private store_info: any;
  private stores: any;
  private currentUser: any;
  private selectedPaymentMethodID: string;
  private cart: any;
  private groupApp: any;
  private selectedLanguage: any;
  private mo_token: string;
  private allStoresPaymentGateways: any;
  private paypalModal: any;
  private ethnikiModal: any;
  private nbgSimplifyModal: any;
  private piraeusModal: any;
  private klarnaModal: any;
  private brainTreeModal: any;
  private billingInfoModal: any;
  private myPosModal: any;
  private billing_order_formatted_address: any;
  private ionLoading: any;
  private errorToast: any;
  private navigationToBankModal: any;
  private user_platform: any;
  private billingInfoVerifyModal;
  private loginState: any;
  private checkPaymentGatewaysStoreChange = false;
  private userSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private DomSinitizer: DomSanitizer,
    private dataStorageService: DataStorageService,
    private translate: TranslateService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private localStorageDataService: LocalStorageDataService,
    private platform: Platform,
    private toastController: ToastController
  ) {
    if (Capacitor.isNativePlatform()) {
      if (this.platform.is("android")) {
        this.user_platform = "android";
      } else if (this.platform.is("ios")) {
        this.user_platform = "ios";
      }
    } else {
      this.user_platform = "browser";
    }
  }

  dismissLoading() {
    if (this.ionLoading) {
      this.ionLoading.dismiss();
      this.ionLoading = null;
    }
  }

  async askEmailFromUser(
    gateway,
    type,
    save_card_from_order,
    PaymentProcess,
    selectedCardStore,
    order_id,
    store_id,
    cardNumber,
    card_type,
    formatted_address,
    fixed_address
  ) {
    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    this.askEmailFromUserAlert = await this.alertController.create({
      header: "Email",
      backdropDismiss: false,
      message: this.translate.instant("please-add-email-to-complete-payment"),
      inputs: [
        {
          name: "email",
          type: "text",
          value: "",
          placeholder: this.translate.instant("write-email-here"),
        },
      ],
      buttons: [
        {
          text: this.translate.instant(
            "popups.magic-login-confirmation.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "OK",
          role: "OK",
          handler: (data) => {
            if (data && data.email) {
              if (!data.email.match(emailRegex)) {
                let errorMessage = this.translate.instant(
                  "account-settings.please-enter-a-valid-email"
                );
                if (this.errorToast) {
                  this.errorToast.dismiss();
                  this.errorToast = null;
                }
                this.presentErrorToast(errorMessage, "danger");
                return false;
              } else {
                this.currentUser.email = _.cloneDeep(data.email);

                this.updateCustomerData(
                  { updatedData: this.currentUser },
                  this.mo_token,
                  false,
                  gateway,
                  type,
                  save_card_from_order,
                  PaymentProcess,
                  selectedCardStore,
                  order_id,
                  store_id,
                  cardNumber,
                  card_type,
                  formatted_address,
                  fixed_address
                );
              }
            } else {
              let errorMessage = this.translate.instant(
                "account-settings.please-enter-your-email"
              );
              if (this.errorToast) {
                this.errorToast.dismiss();
                this.errorToast = null;
              }
              this.presentErrorToast(errorMessage, "danger");
              return false;
            }
          },
        },
      ],
    });

    await this.askEmailFromUserAlert.present();
  }

  updateCustomerData(
    obj,
    mo_token,
    show_success_message,
    gateway,
    type,
    save_card_from_order,
    PaymentProcess,
    selectedCardStore,
    order_id,
    store_id,
    cardNumber,
    card_type,
    formatted_address,
    fixed_address
  ) {
    this.dataStorageService.updateCustomerData(obj, mo_token).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          let updatedUser = res.data;
          console.log("writing user to state");
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          if (gateway.id === "ethniki") {
            this.openEthnikiPaymentModal(
              gateway,
              type,
              save_card_from_order,
              PaymentProcess
            );
          } else if (gateway.id === "nbg_simplify") {
            this.openNbgSimplifyModal(
              gateway,
              type,
              save_card_from_order,
              PaymentProcess
            );
          } else if (gateway.id === "eurobank") {
            this.openBillingInfoPopup(
              gateway,
              type,
              selectedCardStore,
              order_id,
              store_id,
              cardNumber,
              card_type,
              formatted_address,
              save_card_from_order,
              PaymentProcess,
              fixed_address
            );
          } else if (gateway.id === "nexi") {
            this.openBillingInfoPopup(
              gateway,
              type,
              selectedCardStore,
              order_id,
              store_id,
              cardNumber,
              card_type,
              formatted_address,
              save_card_from_order,
              PaymentProcess,
              fixed_address
            );
          }

          if (show_success_message && res.comment_id) {
            this.presentErrorToast(
              this.translate.instant("account-settings." + res.comment_id),
              "primary"
            );
          }
        } else {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translate.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }

          this.askEmailFromUser(
            gateway,
            type,
            save_card_from_order,
            PaymentProcess,
            selectedCardStore,
            order_id,
            store_id,
            cardNumber,
            card_type,
            formatted_address,
            fixed_address
          );
        }
      },
      error: (error) => {
        this.askEmailFromUser(
          gateway,
          type,
          save_card_from_order,
          PaymentProcess,
          selectedCardStore,
          order_id,
          store_id,
          cardNumber,
          card_type,
          formatted_address,
          fixed_address
        );
        let errMsg = this.translate.instant(
          "errorMessages.error: There was an error on saving"
        );

        this.presentErrorToast(errMsg, "danger");
        return error;
        console.log("error updating the information");
      },
    });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  fetchPaymentGatewayIdValue(method) {
    if (method.id === "viva") {
      return method.publicKey;
    } else if (method.id === "piraeus") {
      return method.PosId;
    } else if (method.id === "ethniki") {
      return method.mid;
    } else if (method.id === "nbg_simplify") {
      return method.mid;
    } else if (method.id === "mypos") {
      return method.mid;
    } else if (method.id === "alpha") {
      return method.mid;
    } else if (method.id === "eurobank") {
      return method.mid;
    } else if (method.id === "paypal") {
      return "paypal";
    } else {
      return null;
    }
  }

  async closeInAppBrowserRef() {
    if (Capacitor && Capacitor.isNativePlatform()) {
      try {
        await Browser.close();
        console.log("In-app browser closed successfully");
      } catch (error) {
        console.error("Error while closing in-app browser:", error);
      }
    }
  }

  closeEthnikiModal() {
    if (this.ethnikiModal) {
      this.ethnikiModal.dismiss();
      this.ethnikiModal = null;
    }
  }

  closeBillingInfoModal() {
    if (this.billingInfoModal) {
      this.billingInfoModal.dismiss();
      this.billingInfoModal = null;
    }
  }
  closePiraeusModal() {
    if (this.piraeusModal) {
      this.piraeusModal.dismiss();
      this.piraeusModal = null;
    }
  }
  closeBrainTreeModal() {
    if (this.brainTreeModal) {
      this.brainTreeModal.dismiss();
      this.brainTreeModal = null;
    }
  }

  closeMyposModal() {
    if (this.myPosModal) {
      this.myPosModal.dismiss();
      this.myPosModal = null;
    }
  }

  closeNbgSimplifyModal() {
    if (this.nbgSimplifyModal) {
      this.nbgSimplifyModal.dismiss();
      this.nbgSimplifyModal = null;
    }
  }

  closeKlarnaModal() {
    if (this.klarnaModal) {
      this.klarnaModal.dismiss();
      this.klarnaModal = null;
    }
  }

  closePaypalModal() {
    if (this.paypalModal) {
      this.paypalModal.dismiss();
      this.paypalModal = null;
    }
  }

  closeAllModals() {
    if (this.billingInfoModal) {
      this.billingInfoModal.dismiss();
      this.billingInfoModal = null;
    }
    if (this.klarnaModal) {
      this.klarnaModal.dismiss();
      this.klarnaModal = null;
    }
    if (this.piraeusModal) {
      this.piraeusModal.dismiss();
      this.piraeusModal = null;
    }
    if (this.brainTreeModal) {
      this.brainTreeModal.dismiss();
      this.brainTreeModal = null;
    }
    if (this.paypalModal) {
      this.paypalModal.dismiss();
      this.paypalModal = null;
    }
    if (this.myPosModal) {
      this.myPosModal.dismiss();
      this.myPosModal = null;
    }
    if (this.ethnikiModal) {
      this.ethnikiModal.dismiss();
      this.ethnikiModal = null;
    }
  }

  startCheckPaymentGateways() {
    console.log("start check payment gateways subscription");

    this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
        }
      });

    this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
      });

    this.store
      .select("selectedPaymentMethodID")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.selectedPaymentMethodID &&
          !_.isEqual(
            state.selectedPaymentMethodID,
            this.selectedPaymentMethodID
          )
        ) {
          this.selectedPaymentMethodID = _.cloneDeep(
            state.selectedPaymentMethodID
          );
        }
      });

    this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((storeInf) => {
        if (storeInf && !_.isEqual(storeInf, this.store_info)) {
          this.store_info = _.cloneDeep(storeInf);

          if (this.checkPaymentGatewaysStoreChange) {
            console.log("checkPaymentGatewaysStoreChange");
            this.checkingPaymentGateways();
          }

          if (!this.userSubscription) {
            this.checkPaymentGatewaysStoreChange = true;
            this.userSubscription = this.store
              .select("auth")
              .pipe(distinctUntilChanged())

              .subscribe((state) => {
                if (state && !_.isEqual(this.currentUser, state.user)) {
                  console.log(
                    "payment gateways user",
                    state.user,
                    this.currentUser
                  );

                  if (
                    (!this.currentUser && state.user) ||
                    (this.currentUser &&
                      state.user &&
                      !_.isEqual(this.currentUser.cards, state.user.cards))
                  ) {
                    this.currentUser = _.cloneDeep(state.user);
                    if (this.currentUser) {
                      console.log("call check payment from user");
                      this.checkingPaymentGateways();
                      this.findCardStores();
                    }
                  } else {
                    this.currentUser = _.cloneDeep(state.user);
                    if (this.currentUser) {
                      console.log("call check payment from user");
                      this.checkingPaymentGateways();
                    }
                  }
                }

                if (state && !_.isEqual(state.loginState, this.loginState)) {
                  this.loginState = _.cloneDeep(state.loginState);
                  if (this.loginState === "no_login") {
                    this.checkingPaymentGateways();
                  }
                }
                if (state && !_.isEqual(state.mo_token, this.mo_token)) {
                  this.mo_token = _.cloneDeep(state.mo_token);
                }
              });
          }
        }
      });

    this.store
      .select("paymentGateWays")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (
          state &&
          state.allStoresPaymentGateways &&
          !_.isEqual(this.currentUser, state.allStoresPaymentGateways)
        ) {
          this.allStoresPaymentGateways = _.cloneDeep(
            state.allStoresPaymentGateways
          );
        }
      });
  }

  private checkingPaymentGateways() {
    console.log("inside checking payment gateways{}");

    let AllStores = _.cloneDeep(this.stores);

    let AllstoresPaymentGateways: any = {};
    _.each(AllStores, (store, key, list) => {
      //To only have the enabled stores

      var newAdminPaymentGateways = {};
      //console.log("current user inside paymentgateways", this.currentUser);
      if (this.currentUser && this.currentUser.admin) {
        //console.log("user is admin");
        _.each(AllStores[key].paymentGateways, (method, key) => {
          // console.log("methodss", method.active);
          if (method.active) {
            newAdminPaymentGateways[key] = method;
          }
        });
      } else if (this.currentUser && this.currentUser.customerOnly) {
        _.each(AllStores[key].paymentGateways, (method, key) => {
          // console.log("methodss", method.active);
          if (
            method.active &&
            (!_.has(method, "adminOnly") || !method.adminOnly)
          ) {
            newAdminPaymentGateways[key] = method;
          }
        });
      } else {
        //console.log("user is not admin");
        _.each(AllStores[key].paymentGateways, (method, key) => {
          if (
            method.active &&
            (!_.has(method, "adminOnly") || !method.adminOnly) &&
            (!_.has(method, "customerOnly") || !method.customerOnly)
          ) {
            newAdminPaymentGateways[key] = method;
          }
        });
      }
      AllstoresPaymentGateways[store.store_id] = _.cloneDeep(
        newAdminPaymentGateways
      );
      AllStores[key].paymentGateways = _.cloneDeep(newAdminPaymentGateways);
    });
    this.allStoresPaymentGateways = _.cloneDeep(AllstoresPaymentGateways);
    this.store.dispatch(
      new PaymentGateWaysActions.SetAllStoresPaymentGateways(
        _.cloneDeep(AllstoresPaymentGateways)
      )
    );

    this.showPaymentCards = !_.isEmpty(
      _.filter(AllStores, (storeDetails) => {
        return _.find(
          _.map(storeDetails.paymentGateways, (method, key) => {
            return _.extend(method, {
              id: key,
            });
          }),
          (method, key) => {
            return (
              method.active &&
              !method.adminOnly &&
              !method.customerOnly &&
              method.id !== "mobilePOS" &&
              method.id !== "cash" &&
              method.id !== "klarna" &&
              method.id !== "braintree" &&
              method.id !== "paypal" &&
              method.id !== "viva"
            );
          }
        );
      })
    );
    //check if there is a payment gateway for save card else dont show the page
    let paymentGatewaysForSaveCard = [];

    _.each(this.stores, (store, key, list) => {
      _.each(store.paymentGateways, (gateway, k, l) => {
        if (
          this.fetchUniquePaymentGatewayAttr(store.store_id, k).value &&
          gateway.active &&
          k !== "viva" &&
          gateway.tokenization &&
          !_.find(this.paymentGateways, (meth) => {
            return (
              this.fetchPaymentGatewayIdValue(meth) ===
              this.fetchUniquePaymentGatewayAttr(store.store_id, k).value
            );
          })
        ) {
          paymentGatewaysForSaveCard.push(
            _.extend(gateway, {
              store_id: store.store_id,
              id: k,
            })
          );
        }
      });
    });

    console.log("paymentGateways for save card", paymentGatewaysForSaveCard);

    if (paymentGatewaysForSaveCard && paymentGatewaysForSaveCard.length === 0) {
      this.showPaymentCards = false;
    }

    //console.log(
    //   "first time setting payment cards ............................................",
    //    this.showPaymentCards
    //   );

    this.store.dispatch(
      new PaymentGateWaysActions.SetShowPaymentCards(
        _.cloneDeep(this.showPaymentCards)
      )
    );

    this.showPaymentCardsAdminOnly = !_.isEmpty(
      _.filter(AllStores, (storeDetails) => {
        return _.find(
          _.map(storeDetails.paymentGateways, (method, key) => {
            return _.extend(method, {
              id: key,
            });
          }),
          (method) => {
            return (
              method.active &&
              method.adminOnly &&
              method.id !== "mobilePOS" &&
              method.id !== "cash" &&
              method.id !== "viva"
            );
          }
        );
      })
    );
    this.store.dispatch(
      new PaymentGateWaysActions.SetShowPaymentCardsAdminOnly(
        _.cloneDeep(this.showPaymentCardsAdminOnly)
      )
    );

    this.showPaymentCardsCustomerOnly = !_.isEmpty(
      _.filter(AllStores, (storeDetails) => {
        return _.find(
          _.map(storeDetails.paymentGateways, (method, key) => {
            return _.extend(method, {
              id: key,
            });
          }),
          (method) => {
            return (
              method.active &&
              method.customerOnly &&
              this.currentUser &&
              this.currentUser.customerPaymentMethods &&
              this.currentUser.customerPaymentMethods[method.id] &&
              method.id !== "mobilePOS" &&
              method.id !== "cash" &&
              method.id !== "viva"
            );
          }
        );
      })
    );
    this.store.dispatch(
      new PaymentGateWaysActions.SetShowPaymentCardsCustomerOnly(
        _.cloneDeep(this.showPaymentCardsCustomerOnly)
      )
    );

    //Here I am going to filter the same payment gateways between the stores so that I will have single payment gateways
    this.paymentGateways = [];

    _.each(AllStores, (store, key, list) => {
      if (
        !this.cart ||
        this.cart.length === 0 ||
        (this.store_info && store.store_id === this.store_info.store_id)
      ) {
        //This is needed for the cart so that I know if there are payment gateways for the specific store or not!

        _.each(
          this.allStoresPaymentGateways[store.store_id],
          (gateway, k, l) => {
            if (
              this.fetchUniquePaymentGatewayAttr(store.store_id, k).value &&
              gateway.active &&
              !_.find(this.paymentGateways, (meth) => {
                return (
                  this.fetchPaymentGatewayIdValue(meth) ===
                  this.fetchUniquePaymentGatewayAttr(store.store_id, k).value
                );
              })
            ) {
              this.paymentGateways.push(
                _.extend(gateway, {
                  store_id: store.store_id,
                  id: k,
                })
              );
            }
          }
        );
      } else {
        console.log("not pushing in payment gateways");
        // console.log("no paymentGateways in the cart yet!");
      }
    });
    this.store.dispatch(
      new PaymentGateWaysActions.SetPaymentGateWays(
        _.cloneDeep(this.paymentGateways)
      )
    );

    this.storesByStoreId = _.groupBy(AllStores, "store_id");
    _.each(this.storesByStoreId, (store, key, list) => {
      this.storesByStoreId[key] = store[0];
    });

    console.log("get stores - paymentGateways", this.paymentGateways);

    //console.log("BEFORE selectedPaymentMethodID", this.selectedPaymentMethodID);

    if (navigator.cookieEnabled) {
      this.localStorageDataService.loadPaymentMethod();

      this.selectedPaymentMethodID = window.localStorage.getItem(
        "selectedPaymentMethodID"
      )
        ? JSON.parse(window.localStorage.getItem("selectedPaymentMethodID"))
        : null;
    }

    if (
      this.selectedPaymentMethodID &&
      this.store_info.paymentGateways[this.selectedPaymentMethodID] &&
      this.store_info.paymentGateways[this.selectedPaymentMethodID].active &&
      !this.store_info.paymentGateways[this.selectedPaymentMethodID]
        .disable_payment_gateway &&
      (!this.store_info.paymentGateways[this.selectedPaymentMethodID]
        .adminOnly ||
        (this.currentUser && this.currentUser.admin)) &&
      (!this.store_info.paymentGateways[this.selectedPaymentMethodID]
        .customerOnly ||
        (this.currentUser &&
          ((this.currentUser.admin &&
            this.store_info.paymentGateways[this.selectedPaymentMethodID]
              .adminOnly) ||
            (this.currentUser.customerPaymentMethods &&
              this.currentUser.customerPaymentMethods[
                this.selectedPaymentMethodID
              ]))))
    ) {
      this.store.dispatch(
        new SelectedPaymentMethodIDActions.SetSelectedPaymentMethodID(
          _.cloneDeep(this.selectedPaymentMethodID)
        )
      );
    } else {
      if (
        !this.selectedPaymentMethodID &&
        this.store_info.paymentGateways["cash"] &&
        this.store_info.paymentGateways["cash"].active &&
        !this.store_info.paymentGateways["cash"].disable_payment_gateway &&
        (!this.store_info.paymentGateways["cash"].adminOnly ||
          (this.currentUser && this.currentUser.admin)) &&
        (!this.store_info.paymentGateways["cash"].customerOnly ||
          (this.currentUser &&
            ((this.currentUser.admin &&
              this.store_info.paymentGateways["cash"].adminOnly) ||
              (this.currentUser.customerPaymentMethods &&
                this.currentUser.customerPaymentMethods["cash"]))))
      ) {
        this.selectedPaymentMethodID = "cash";
        this.store.dispatch(
          new SelectedPaymentMethodIDActions.SetSelectedPaymentMethodID(
            _.cloneDeep(this.selectedPaymentMethodID)
          )
        );
      } else {
        for (let key in this.store_info.paymentGateways) {
          if (
            this.store_info.paymentGateways &&
            this.store_info.paymentGateways[key] &&
            this.store_info.paymentGateways[key].active &&
            !this.store_info.paymentGateways[key].disable_payment_gateway &&
            (!this.store_info.paymentGateways[key].adminOnly ||
              (this.currentUser && this.currentUser.admin)) &&
            (!this.store_info.paymentGateways[key].customerOnly ||
              (this.currentUser &&
                ((this.currentUser.admin &&
                  this.store_info.paymentGateways[key].adminOnly) ||
                  (this.currentUser.customerPaymentMethods &&
                    this.currentUser.customerPaymentMethods[key]))))
          ) {
            this.selectedPaymentMethodID = key;
            this.store.dispatch(
              new SelectedPaymentMethodIDActions.SetSelectedPaymentMethodID(
                _.cloneDeep(this.selectedPaymentMethodID)
              )
            );
          }
        }
      }
    }

    if (navigator.cookieEnabled) {
      window.localStorage.setItem(
        "selectedPaymentMethodID",
        JSON.stringify(this.selectedPaymentMethodID)
      );
    }

    // console.log("AFTER selectedPaymentMethodID", this.selectedPaymentMethodID);

    this.store.dispatch(
      new PaymentGateWaysActions.SetCartPaymentGateways(
        _.cloneDeep(this.store_info.paymentGateways)
      )
    );
    // console.log("paymentGateways", this.paymentGateways);
  }

  fetchUniquePaymentGatewayAttr(store_id, paymentGateway) {
    // console.log("all payment gateways", this.allStoresPaymentGateways);
    if (
      paymentGateway === "viva" &&
      this.allStoresPaymentGateways &&
      this.allStoresPaymentGateways[store_id] &&
      this.allStoresPaymentGateways[store_id][paymentGateway]
    ) {
      return {
        value:
          this.allStoresPaymentGateways[store_id][paymentGateway].publicKey,
        key: "publicKey",
      };
    } else if (
      paymentGateway === "piraeus" &&
      this.allStoresPaymentGateways &&
      this.allStoresPaymentGateways[store_id] &&
      this.allStoresPaymentGateways[store_id][paymentGateway]
    ) {
      return {
        value: this.allStoresPaymentGateways[store_id][paymentGateway].PosId,
        key: "PosId",
      };
    } else if (
      (paymentGateway === "ethniki" || paymentGateway === "nbg_simplify") &&
      this.allStoresPaymentGateways &&
      this.allStoresPaymentGateways[store_id] &&
      this.allStoresPaymentGateways[store_id][paymentGateway]
    ) {
      return {
        value: this.allStoresPaymentGateways[store_id][paymentGateway].mid,
        key: "mid",
      };
    } else if (
      paymentGateway === "mypos" &&
      this.allStoresPaymentGateways &&
      this.allStoresPaymentGateways[store_id] &&
      this.allStoresPaymentGateways[store_id][paymentGateway]
    ) {
      return {
        value: this.allStoresPaymentGateways[store_id][paymentGateway].mid,
        key: "mid",
      };
    } else if (
      paymentGateway === "alpha" &&
      this.allStoresPaymentGateways &&
      this.allStoresPaymentGateways[store_id] &&
      this.allStoresPaymentGateways[store_id][paymentGateway]
    ) {
      return {
        value: this.allStoresPaymentGateways[store_id][paymentGateway].mid,
        key: "mid",
      };
    } else if (
      paymentGateway === "eurobank" &&
      this.allStoresPaymentGateways &&
      this.allStoresPaymentGateways[store_id] &&
      this.allStoresPaymentGateways[store_id][paymentGateway]
    ) {
      return {
        value: this.allStoresPaymentGateways[store_id][paymentGateway].mid,
        key: "mid",
      };
    } else if (
      paymentGateway === "paypal" &&
      this.allStoresPaymentGateways &&
      this.allStoresPaymentGateways[store_id] &&
      this.allStoresPaymentGateways[store_id][paymentGateway]
    ) {
      return {
        value: "paypal",
        key: "id",
      };
    } else if (
      paymentGateway === "nexi" &&
      this.allStoresPaymentGateways &&
      this.allStoresPaymentGateways[store_id] &&
      this.allStoresPaymentGateways[store_id][paymentGateway]
    ) {
      return {
        value: "nexi",
        key: "mid",
      };
    } else {
      return {};
    }
  }

  findCardStoresDebounce = _.debounce(
    () => {
      this.findCardStores();
    },
    800,
    { maxWait: 800 }
  );

  findCardStores() {
    //based on the customer saved cards I will find currently the stores that can support this card based on their paymentGateways
    console.log("find card in stores", this.paymentGateways);
    let user = _.cloneDeep(this.currentUser);
    if (user && !_.isEmpty(user.cards) && !_.isEmpty(this.paymentGateways)) {
      _.each(user.cards, (card, c, l) => {
        _.each(this.stores, (store, key, list) => {
          if (!card.paymentGateway) {
            card.paymentGateway = "viva";
            card.paymentGatewayLabel = this.paymentGatewayLabelID(
              card.paymentGateway
            );
          }

          console.log(
            store.store_name,
            card.number,
            "publicKey",
            this.fetchUniquePaymentGatewayAttr(
              store.store_id,
              card.paymentGateway
            ).value
          );
          var storeCard = _.find(card.stores, {
            publicKey: this.fetchUniquePaymentGatewayAttr(
              store.store_id,
              card.paymentGateway
            ).value,
          });
          console.log("******", card.number, "storeCard", storeCard);
          var storeIndex = _.findIndex(card.stores, {
            store_id: store.store_id,
            publicKey: this.fetchUniquePaymentGatewayAttr(
              store.store_id,
              card.paymentGateway
            ).value,
          });
          console.log("storeIndex", storeIndex);

          if (storeCard && storeIndex === -1) {
            user.cards[c].invalid = !(
              card.paymentGateway !== "viva" ||
              (card.paymentGateway === "viva" && card.viva3ds)
            );
            console.log("card", user.cards[c].number, user.cards[c].invalid);
            if (!user.cards[c].invalid) {
              user.cards[c].stores.push({
                store_name: store.store_name,
                store_id: store.store_id,
                billingInfo: storeCard.billingInfo,
                publicKey: this.fetchUniquePaymentGatewayAttr(
                  store.store_id,
                  card.paymentGateway
                ).value,
                timestamp: Date.now(),
                token: storeCard.token || storeCard.TransactionId,
              });
            } else {
              console.log(
                "The card is no longer valid for store",
                store.store_name
              );
              user.cards[c].stores = _.filter(user.cards[c].stores, (st) => {
                return st.store_id !== store.store_id;
              });
              console.log("card stores", user.cards[c].stores);
            }
          } else if (storeCard && storeIndex !== -1) {
            user.cards[c].invalid = !(
              card.paymentGateway !== "viva" ||
              (card.paymentGateway === "viva" && card.viva3ds)
            );
            console.log("card", user.cards[c].number, user.cards[c].invalid);
            if (!user.cards[c].invalid) {
              user.cards[c].stores[storeIndex].store_name = store.store_name;
              if (!user.cards[c].stores[storeIndex].token) {
                var storeTransactionId = _.find(user.cards[c].stores, (st) => {
                  return st.token || st.transaction_id;
                });
                user.cards[c].stores[storeIndex].token =
                  storeTransactionId.token
                    ? storeTransactionId.token
                    : storeTransactionId.transaction_id;
              }
            } else {
              console.log(
                "The card is no longer valid for store",
                store.store_name
              );
              user.cards[c].stores = _.filter(user.cards[c].stores, (st) => {
                return st.store_id !== store.store_id;
              });
              console.log("card stores", user.cards[c].stores);
            }
          } else if (!storeCard) {
            console.log("store publicKey is invalid", card.number);
            user.cards[c].invalid = true;
            user.cards[c].stores = _.filter(user.cards[c].stores, (st) => {
              return st.store_id !== store.store_id;
            });
            console.log("card stores", user.cards[c].stores);
          } else {
            console.log("store publicKey is invalid", card.number);
            if (user.cards[c].invalid !== false) {
              user.cards[c].invalid = true;
              user.cards[c].stores = _.filter(user.cards[c].stores, (st) => {
                return st.store_id !== store.store_id;
              });
              console.log("card stores", user.cards[c].stores);
            }
          }
        });
      });

      console.log("cards after inspection", user.cards);

      _.each(user.cards, (card, c, l) => {
        console.log(card.stores);
        card.invalid = _.isEmpty(card.stores);
      });
      console.log("cards stores check inspection", user.cards);

      if (user && user.cards && user.cards.length) {
        _.each(user.cards, (card) => {
          if (card.paymentGateway && card.paymentGateway === "viva") {
            card.invalid = true;
          }
        });
      }
      this.currentUser = _.cloneDeep(user);
      console.log("writing user to state", user);
      this.store.dispatch(new AuthActions.SetUser(_.cloneDeep(user)));
    }
  }

  paymentGatewayLabelID(paymentGateway) {
    if (paymentGateway === "viva") {
      return "viva";
    } else if (paymentGateway === "piraeus") {
      return "piraeus";
    } else if (paymentGateway === "alpha") {
      return "alpha";
    } else if (
      paymentGateway === "ethniki" ||
      paymentGateway === "nbg_simplify"
    ) {
      return "nbg";
    } else if (paymentGateway === "mypos") {
      return "mypos";
    } else if (paymentGateway === "eurobank") {
      return "eurobank";
    } else if (paymentGateway === "paypal") {
      return "paypal";
    } else if (paymentGateway === "nexi") {
      return "nexi";
    }
  }

  selectedPaymentGateway(
    grand_total,
    gateway,
    type,
    selectedCardStore,
    order_id,
    store_id,
    cardNumber,
    card_type,
    formatted_address,
    save_card_from_order,
    PaymentProcess,
    fixed_address
  ) {
    console.log(
      "selectedPaymentGateway",
      grand_total,
      gateway,
      type,
      selectedCardStore,
      formatted_address,
      fixed_address
    );

    if (gateway.id === "viva") {
      this.openVivaPaymentModal(
        gateway,
        type,
        save_card_from_order,
        PaymentProcess
      );
      console.log("open viva modal");
    } else if (gateway.id === "piraeus") {
      this.openBillingInfoPopup(
        gateway,
        type,
        selectedCardStore,
        order_id,
        store_id,
        cardNumber,
        card_type,
        formatted_address,
        save_card_from_order,
        PaymentProcess,
        fixed_address
      );
    } else if (gateway.id === "nexi") {
      if (!this.currentUser.email) {
        this.askEmailFromUser(
          gateway,
          type,
          save_card_from_order,
          PaymentProcess,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
      } else {
        this.openBillingInfoPopup(
          gateway,
          type,
          selectedCardStore,
          order_id,
          store_id,
          cardNumber,
          card_type,
          formatted_address,
          save_card_from_order,
          PaymentProcess,
          fixed_address
        );
      }
    } else if (gateway.id === "paypal") {
      this.openPaypalPaymentModal(grand_total, type, gateway, PaymentProcess);
    } else if (gateway.id === "klarna") {
      console.log("open Klarna modal");
      this.openKlarnaPaymentModal(grand_total, type, gateway, PaymentProcess);
    } else if (gateway.id === "braintree") {
      this.openBraintreePaymentModal(
        grand_total,
        type,
        gateway,
        PaymentProcess
      );
      console.log("open BrainTree modal");
    } else if (gateway.id === "alpha") {
      this.openBillingInfoPopup(
        gateway,
        type,
        selectedCardStore,
        order_id,
        store_id,
        cardNumber,
        card_type,
        formatted_address,
        save_card_from_order,
        PaymentProcess,
        fixed_address
      );
    } else if (gateway.id === "ethniki") {
      console.log("Etniki");
      //ask email from user
      if (!this.currentUser.email) {
        this.askEmailFromUser(
          gateway,
          type,
          save_card_from_order,
          PaymentProcess,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
      } else {
        this.openEthnikiPaymentModal(
          gateway,
          type,
          save_card_from_order,
          PaymentProcess
        );
      }

      console.log("open Ethniki modal");
    } else if (gateway.id === "nbg_simplify") {
      console.log("nbg_simplify");
      //ask email from user
      if (!this.currentUser.email) {
        this.askEmailFromUser(
          gateway,
          type,
          save_card_from_order,
          PaymentProcess,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
      } else {
        this.openNbgSimplifyModal(
          gateway,
          type,
          save_card_from_order,
          PaymentProcess
        );
      }

      console.log("open nbg_simplify modal");
    } else if (gateway.id === "mypos") {
      this.openMyposPaymentModal(
        gateway,
        type,
        save_card_from_order,
        PaymentProcess
      );
      console.log("open MyPOS modal");
    } else if (gateway.id === "eurobank") {
      if (!this.currentUser.email) {
        this.askEmailFromUser(
          gateway,
          type,
          save_card_from_order,
          PaymentProcess,
          selectedCardStore,
          order_id,
          store_id,
          cardNumber,
          card_type,
          formatted_address,
          fixed_address
        );
      } else {
        this.openBillingInfoPopup(
          gateway,
          type,
          selectedCardStore,
          order_id,
          store_id,
          cardNumber,
          card_type,
          formatted_address,
          save_card_from_order,
          PaymentProcess,
          fixed_address
        );
      }
    }
  }

  async openEurobankPaymentModal(
    gateway,
    type,
    billingInfo,
    token,
    save_card_from_order,
    PaymentProcess
  ) {
    let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
    let frame_height =
      window.innerHeight > 700 ? 650 : window.innerHeight - 100;

    let eurobank_frame_params =
      "Authorization=" +
      this.mo_token +
      "&save_card=" +
      (type === "saveCard" ? true : null) +
      "&save_card_from_order=" +
      save_card_from_order +
      "&token=" +
      token +
      "&store_id=" +
      gateway.store_id +
      "&frame_width=" +
      (frame_width - 20) +
      "&frame_height=" +
      (frame_height - 40) +
      "&language_code=" +
      this.selectedLanguage +
      "&billAddress=" +
      billingInfo.billAddress +
      "&billCity=" +
      billingInfo.billCity +
      "&billZip=" +
      billingInfo.billZip +
      "&billState=" +
      billingInfo.billState +
      "&billCountry=" +
      billingInfo.billCountry +
      "&formatted_mobile=" +
      billingInfo.formatted_mobile +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;

    // console.log("$rootScope.eurobank_frame_params", eurobank_frame_params);
    let eurobank_new_window =
      "https://" +
      this.groupApp.domain +
      "/eurobank/frame.html?" +
      encodeURI(eurobank_frame_params);

    await this.openNavigationToBankModal(
      PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null,
      gateway.store_id,
      eurobank_new_window,
      "order"
    );
    console.log("eurobank_new_window", eurobank_new_window);
    await this.openLink(eurobank_new_window);
  }

  async openPiraeusPaymentModal(
    gateway,
    type,
    billingInfo,
    save_card_from_order,
    PaymentProcess
  ) {
    let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
    let frame_height =
      window.innerHeight > 700 ? 650 : window.innerHeight - 100;

    let piraeus_frame_params =
      "Authorization=" +
      this.mo_token +
      "&save_card=" +
      (type === "saveCard" ? true : null) +
      "&save_card_from_order=" +
      save_card_from_order +
      "&store_id=" +
      gateway.store_id +
      "&frame_width=" +
      (frame_width - 20) +
      "&frame_height=" +
      (frame_height - 40) +
      "&CardHolderName=" +
      billingInfo.CardHolderName +
      "&billAddress=" +
      billingInfo.billAddress +
      "&billCity=" +
      billingInfo.billCity +
      "&billZip=" +
      billingInfo.billZip +
      "&billState=" +
      billingInfo.billState +
      "&billCountry=" +
      billingInfo.billCountry +
      "&formatted_mobile=" +
      billingInfo.formatted_mobile +
      "&language_code=" +
      this.selectedLanguage +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;

    if (type === "reservation") {
      piraeus_frame_params += "&type=reservation";
    }

    let piraeus_iframe =
      "https://" +
      this.groupApp.domain +
      "/piraeus/frame.html?" +
      encodeURI(piraeus_frame_params);
    await this.openNavigationToBankModal(
      PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null,
      gateway.store_id,
      piraeus_iframe,
      type === "reservation" ? "reservation" : "order"
    );
    await this.openLink(piraeus_iframe);
  }

  async openAlphaPaymentModal(
    gateway,
    type,
    billingInfo,
    token,
    save_card_from_order,
    PaymentProcess
  ) {
    let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
    let frame_height =
      window.innerHeight > 900 ? 850 : window.innerHeight - 100;

    let alpha_frame_params =
      "Authorization=" +
      this.mo_token +
      "&save_card=" +
      (type === "saveCard" ? true : null) +
      "&save_card_from_order=" +
      save_card_from_order +
      "&token=" +
      token +
      "&store_id=" +
      gateway.store_id +
      "&frame_width=" +
      (frame_width - 20) +
      "&frame_height=" +
      (frame_height - 40) +
      "&language_code=" +
      this.selectedLanguage +
      "&billAddress=" +
      billingInfo.billAddress +
      "&billCity=" +
      billingInfo.billCity +
      "&billZip=" +
      billingInfo.billZip +
      "&billCountry=" +
      billingInfo.billCountry +
      "&formatted_mobile=" +
      billingInfo.formatted_mobile +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;

    console.log("alpha_frame_params", alpha_frame_params);

    let alpha_iframe =
      "https://" +
      this.groupApp.domain +
      "/alpha/frame.html?" +
      encodeURI(alpha_frame_params);

    await this.openNavigationToBankModal(
      PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null,
      gateway.store_id,
      alpha_iframe,
      "order"
    );
    await this.openLink(alpha_iframe);
  }

  async openMyposPaymentModal(
    gateway,
    type,
    save_card_from_order,
    PaymentProcess
  ) {
    let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
    let frame_height =
      window.innerHeight > 700 ? 650 : window.innerHeight - 100;

    let mypos_frame_params =
      "Authorization=" +
      this.mo_token +
      "&save_card=" +
      (type === "saveCard" ? true : null) +
      "&save_card_from_order=" +
      save_card_from_order +
      "&store_id=" +
      gateway.store_id +
      "&frame_width=" +
      (frame_width || 400) +
      "&frame_height=" +
      (frame_height ? frame_height - 25 : 560) +
      "&language_code=" +
      this.selectedLanguage +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;

    let mypos_iframe =
      "https://" +
      (gateway.url || this.groupApp.domain) +
      "/mypos/frame.html?" +
      encodeURI(mypos_frame_params);

    await this.openNavigationToBankModal(
      PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null,
      gateway.store_id,
      mypos_iframe,
      "order"
    );
    await this.openLink(mypos_iframe);

    //this.presentMyPosModal(frame_width, frame_height, mypos_iframe);
  }

  openNbgSimplifyModal(gateway, type, save_card_from_order, PaymentProcess) {
    let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
    let frame_height =
      window.innerHeight > 700 ? 650 : window.innerHeight - 100;

    let nbg_simplify_frame_params =
      "Authorization=" +
      this.mo_token +
      "&save_card=" +
      (type === "saveCard" ? true : null) +
      "&save_card_from_order=" +
      save_card_from_order +
      "&store_id=" +
      gateway.store_id +
      "&frame_width=" +
      (frame_width - 20) +
      "&frame_height=" +
      (frame_height - 40) +
      "&language_code=" +
      this.selectedLanguage +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;
    console.log("nbg_simplify_frame_params", nbg_simplify_frame_params);

    let nbg_simplify_frame = this.trustedUrl(
      "https://" +
        this.groupApp.domain +
        "/nbg_simplify/frame.html?" +
        nbg_simplify_frame_params
    );

    this.presentNbgSimplifyModal(frame_width, frame_height, nbg_simplify_frame);
  }

  openEthnikiPaymentModal(gateway, type, save_card_from_order, PaymentProcess) {
    let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
    let frame_height =
      window.innerHeight > 700 ? 650 : window.innerHeight - 100;

    let ethniki_frame_params =
      "Authorization=" +
      this.mo_token +
      "&save_card=" +
      (type === "saveCard" ? true : null) +
      "&save_card_from_order=" +
      save_card_from_order +
      "&store_id=" +
      gateway.store_id +
      "&frame_width=" +
      (frame_width - 20) +
      "&frame_height=" +
      (frame_height - 40) +
      "&language_code=" +
      this.selectedLanguage +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;
    console.log("$rootScope.ethniki_frame_params", ethniki_frame_params);

    let ethniki_iframe = this.trustedUrl(
      "https://" +
        this.groupApp.domain +
        "/ethniki/frame.html?" +
        ethniki_frame_params
    );

    this.presentEthnikiModal(frame_width, frame_height, ethniki_iframe);
  }

  async openPaypalPaymentModal(grand_total, type, gateway, PaymentProcess) {
    let paypal_frame_params =
      "Authorization=" +
      this.mo_token +
      "&grand_total=" +
      grand_total +
      "&group=" +
      this.groupApp.group +
      "&store_id=" +
      gateway.store_id +
      "&selectedLanguage=" +
      this.selectedLanguage +
      "&branch_link=" +
      this.groupApp.branch_link +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;

    let paypal_iframe = this.trustedUrl(
      "https://" +
        this.groupApp.domain +
        "/paypal/frame.html?" +
        paypal_frame_params
    );
    let paypal_new_window =
      "https://" +
      this.groupApp.domain +
      "/paypal/frame.html?" +
      paypal_frame_params;

    if (Capacitor.isNativePlatform()) {
      await this.openNavigationToBankModal(
        PaymentProcess && PaymentProcess.orderItem
          ? PaymentProcess.orderItem.order_id
          : null,
        gateway.store_id,
        paypal_new_window,
        "order"
      );
      await Browser.open({ url: paypal_new_window }); // I need to open it in an external browser, because paypal triggers an popup to open
    } else {
      this.presentPaypalModal(paypal_iframe);
    }
  }

  openKlarnaPaymentModal(grand_total, type, gateway, PaymentProcess) {
    let klarna_frame_params =
      "Authorization=" +
      this.mo_token +
      "&grand_total=" +
      grand_total +
      "&group=" +
      this.groupApp.group +
      "&store_id=" +
      gateway.store_id +
      "&selectedLanguage=" +
      this.selectedLanguage +
      "&branch_link=" +
      this.groupApp.branch_link +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;

    let klarna_iframe = this.trustedUrl(
      "https://" +
        this.groupApp.domain +
        "/klarna/frame.html?" +
        klarna_frame_params
    );
    this.presentKlarnaModal(klarna_iframe);
  }

  openBraintreePaymentModal(grand_total, type, gateway, PaymentProcess) {
    let braintree_frame_params =
      "Authorization=" +
      this.mo_token +
      "&grand_total=" +
      grand_total +
      "&group=" +
      this.groupApp.group +
      "&store_id=" +
      gateway.store_id +
      "&selectedLanguage=" +
      this.selectedLanguage +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null);
    "&email=" +
      (this.currentUser.email || null) +
      "&user_platform=" +
      this.user_platform;

    console.log("$rootScope.braintree_frame_params", braintree_frame_params);

    let braintree_iframe = this.trustedUrl(
      "https://" +
        this.groupApp.domain +
        "/braintree/frame.html?" +
        braintree_frame_params
    );

    console.log("$rootScope.braintree_iframe", braintree_iframe);

    this.presentBraintreeModal(braintree_iframe);
  }

  async openNexiPaymentModal(
    gateway,
    type,
    billingInfo,
    token,
    save_card_from_order,
    PaymentProcess
  ) {
    let nexi_frame_params =
      "Authorization=" +
      this.mo_token +
      "&save_card=" +
      (type === "saveCard" ? true : null) +
      "&save_card_from_order=" +
      save_card_from_order +
      "&store_id=" +
      gateway.store_id +
      "&language_code=" +
      billingInfo.billAddress +
      "&billCity=" +
      billingInfo.billCity +
      "&CardHolderName=" +
      billingInfo.CardHolderName +
      "&billZip=" +
      billingInfo.billZip +
      "&billCountry=" +
      billingInfo.billCountry +
      "&formatted_mobile=" +
      this.selectedLanguage +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;

    console.log("$rootScope.nexi_frame_params", nexi_frame_params);

    let nexi_iframe =
      "https://" +
      (gateway.url || this.groupApp.domain) +
      "/nexi/frame_new.html?" +
      encodeURI(nexi_frame_params);
    console.log("nexi trusted url", nexi_iframe);
    await this.openNavigationToBankModal(
      PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null,
      gateway.store_id,
      nexi_iframe,
      "order"
    );

    await this.openLink(nexi_iframe);
  }

  async openVivaPaymentModal(
    gateway,
    type,
    save_card_from_order,
    PaymentProcess
  ) {
    let viva_frame_params =
      "Authorization=" +
      this.mo_token +
      "&save_card=" +
      (type === "saveCard" ? true : null) +
      "&save_card_from_order=" +
      save_card_from_order +
      "&store_id=" +
      gateway.store_id +
      "&language_code=" +
      this.selectedLanguage +
      "&order_id=" +
      (PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null) +
      "&user_platform=" +
      this.user_platform;

    console.log("$rootScope.viva_frame_params", viva_frame_params);

    let viva_iframe =
      "https://" +
      this.groupApp.domain +
      "/viva/frame_new.html?" +
      encodeURI(viva_frame_params);
    console.log("viva trusted url", viva_iframe);
    await this.openNavigationToBankModal(
      PaymentProcess && PaymentProcess.orderItem
        ? PaymentProcess.orderItem.order_id
        : null,
      gateway.store_id,
      viva_iframe,
      "order"
    );

    await this.openLink(viva_iframe);
  }

  openBillingInfoPopup(
    gateway,
    type,
    selectedCardStore,
    order_id,
    store_id,
    cardNumber,
    card_type,
    formatted_address,
    save_card_from_order,
    PaymentProcess,
    fixed_address
  ) {
    var token = "null";
    let billingInfo: any;
    if (!_.isEmpty(selectedCardStore)) {
      console.log("selectedCardStore", selectedCardStore);
      billingInfo = selectedCardStore.billingInfo || {
        billCountry: "Greece",
      };
      token = selectedCardStore.token;
    }
    if (_.isEmpty(billingInfo) || !_.isObject(billingInfo)) {
      billingInfo = {
        billCountry: "Greece",
      };
    }

    billingInfo.gateway_id = gateway.id;

    if (
      token !== "null" &&
      token &&
      billingInfo.billZip &&
      billingInfo.billAddress &&
      billingInfo.billCountry &&
      (gateway.id === "piraeus" ||
        gateway.id === "eurobank" ||
        gateway.id === "nexi" ||
        gateway.id === "alpha") &&
      (gateway.id !== "piraeus" || billingInfo.CardHolderName)
    ) {
      console.log(
        "We are in case where we already have saved the billing info!"
      );
      this.presentLoading();
      this.dataStorageService
        .verifyBillingInfo(
          {
            order_formatted_address: billingInfo.order_formatted_address,
            CardHolderName: billingInfo.CardHolderName,
            billAddress: billingInfo.billAddress,
            billAddressNumber: billingInfo.billAddressNumber,
            billZip: billingInfo.billZip,
            billCountry: billingInfo.billCountry,
          },
          this.mo_token
        )
        .subscribe({
          next: async (res: any) => {
            if (res && res.success && res.banks) {
              if (this.ionLoading) {
                this.ionLoading.dismiss();
                this.ionLoading = null;
              }
              if (gateway.id === "eurobank") {
                this.openEurobankPaymentModal(
                  gateway,
                  type,
                  res.banks.eurobank,
                  token,
                  save_card_from_order,
                  PaymentProcess
                );
              } else if (gateway.id === "alpha") {
                this.openAlphaPaymentModal(
                  gateway,
                  type,
                  res.banks.alpha,
                  token,
                  save_card_from_order,
                  PaymentProcess
                );
              } else if (gateway.id === "nexi") {
                this.openNexiPaymentModal(
                  gateway,
                  type,
                  res.banks.nexi,
                  token,
                  save_card_from_order,
                  PaymentProcess
                );
              } else if (gateway.id === "piraeus") {
                this.startPiraeusRecurringPayment3DS(
                  gateway.id,
                  order_id,
                  store_id,
                  token,
                  cardNumber,
                  card_type,
                  res.banks.piraeus
                );
              }
            } else {
              if (this.ionLoading) {
                this.ionLoading.dismiss();
                this.ionLoading = null;
              }
              if (res) {
                if (res.comment_id) {
                  const alert = await this.alertController.create({
                    header: this.translate.instant("alert"),
                    message: this.translate.instant(
                      "errorMessages." + res.comment_id
                    ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                } else {
                  const alert = await this.alertController.create({
                    header: this.translate.instant("alert"),
                    message: this.translate.instant(res.comments),

                    buttons: ["OK"],
                  });
                  await alert.present();
                }
              }

              this.showBillingAddressVerificationPopup(
                gateway,
                type,
                selectedCardStore,
                order_id,
                store_id,
                cardNumber,
                card_type,
                token,
                null,
                billingInfo,
                save_card_from_order,
                PaymentProcess,
                fixed_address
              ); // Null in the formatted_address in case it failed!
            }
          },
          error: async (err) => {
            if (this.ionLoading) {
              this.ionLoading.dismiss();
            }
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message: this.translate.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    } else {
      if (this.ionLoading) {
        this.ionLoading.dismiss();
        this.ionLoading = null;
      }
      this.showBillingAddressVerificationPopup(
        gateway,
        type,
        selectedCardStore,
        order_id,
        store_id,
        cardNumber,
        card_type,
        token,
        formatted_address,
        billingInfo,
        save_card_from_order,
        PaymentProcess,
        fixed_address
      );
    }
  }

  async showBillingAddressVerificationPopup(
    gateway,
    type,
    selectedCardStore,
    order_id,
    store_id,
    cardNumber,
    card_type,
    token,
    formatted_address,

    billingInfo,
    save_card_from_order,
    PaymentProcess,
    fixed_address
  ) {
    this.billing_order_formatted_address = {
      formatted_address,
      same_as_billing_address: !fixed_address ? !!formatted_address : null,
      fixed_address: _.cloneDeep(fixed_address),
    };

    this.billingInfoModal = await this.modalCtrl.create({
      component: BillingInfoComponent,
      cssClass: "billingInfoPopupCss",
      backdropDismiss: false,
      componentProps: {
        billingInfo: billingInfo,
        billing_order_formatted_address: _.cloneDeep(
          this.billing_order_formatted_address
        ),
      },
    });

    await this.billingInfoModal.present();
    this.billingInfoModal.onDidDismiss().then((data) => {
      this.billingInfoModal = null;
      if (
        data &&
        data.data &&
        !data.data.billing_order_formatted_address &&
        data.data.billAddress &&
        data.data.billAddressNumber &&
        data.data.billZip &&
        data.data.billCountry
      ) {
        let billingInfo = data.data;
        this.billingInfoRequestActions(
          gateway,
          type,
          selectedCardStore,
          order_id,
          store_id,
          cardNumber,
          card_type,
          token,
          formatted_address,
          billingInfo,
          save_card_from_order,
          PaymentProcess
        );
      } else if (
        data &&
        data.data &&
        data.data.billing_order_formatted_address
      ) {
        this.billing_order_formatted_address = _.cloneDeep(
          data.data.billing_order_formatted_address
        );
        let cardHolderName;
        if (data.data.billingInfo && data.data.billingInfo.CardHolderName) {
          cardHolderName = data.data.billingInfo.CardHolderName;
        }
        let billingInfo = {
          CardHolderName: cardHolderName,
          order_formatted_address:
            this.billing_order_formatted_address.formatted_address,
          billAddress: null,
          billAddressNumber: null,
          billZip: null,
        };
        this.billingInfoRequestActions(
          gateway,
          type,
          selectedCardStore,
          order_id,
          store_id,
          cardNumber,
          card_type,
          token,
          formatted_address,
          billingInfo,
          save_card_from_order,
          PaymentProcess
        );
      }
    });
  }

  billingInfoRequestActions(
    gateway,
    type,
    selectedCardStore,
    order_id,
    store_id,
    cardNumber,
    card_type,
    token,
    formatted_address,
    billingInfo,
    save_card_from_order,
    PaymentProcess
  ) {
    this.presentLoading();
    this.dataStorageService
      .verifyBillingInfo(
        {
          order_formatted_address: billingInfo.order_formatted_address,
          CardHolderName: billingInfo.CardHolderName,
          billAddress: billingInfo.billAddress,
          billAddressNumber: billingInfo.billAddressNumber,
          billZip: billingInfo.billZip,
          billCountry: billingInfo.billCountry,
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success && res.banks && res.billingInfo) {
            //dispatch billing info
            this.store.dispatch(
              new paymentGatewaysActions.SetNewCardBillingInfo(billingInfo)
            );
            if (this.ionLoading) {
              this.ionLoading.dismiss();
            }
            if (res && res.billingInfo) {
              billingInfo = res.billingInfo;
            }
            this.billingInfoVerifyModal = await this.modalCtrl.create({
              component: BillingInfoVerifyComponent,
              cssClass: "billingInfoVerifyModal",
              backdropDismiss: false,
              componentProps: { verifyAddressRes: res },
            });

            this.billingInfoVerifyModal.present();
            this.billingInfoVerifyModal.onDidDismiss().then((data) => {
              this.billingInfoVerifyModal = null;
              console.log("on alert dismiss", data);
              if (data && data.data) {
                if (data.data === "fix") {
                  this.showBillingAddressVerificationPopup(
                    gateway,
                    type,
                    selectedCardStore,
                    order_id,
                    store_id,
                    cardNumber,
                    card_type,
                    token,
                    null,
                    billingInfo,
                    save_card_from_order,
                    PaymentProcess,
                    null
                  );
                } else if (data.data === "continue") {
                  let banks = res.banks;
                  console.log("continue", gateway, banks);
                  if (gateway.id === "eurobank") {
                    console.log("billingInfo", billingInfo);
                    this.openEurobankPaymentModal(
                      gateway,
                      type,
                      banks.eurobank,
                      token,
                      save_card_from_order,
                      PaymentProcess
                    );
                  } else if (gateway.id === "alpha") {
                    console.log("billingInfo", billingInfo);
                    this.openAlphaPaymentModal(
                      gateway,
                      type,
                      banks.alpha,
                      token,
                      save_card_from_order,
                      PaymentProcess
                    );
                  } else if (gateway.id === "nexi") {
                    console.log("billingInfo", billingInfo);
                    this.openNexiPaymentModal(
                      gateway,
                      type,
                      banks.nexi,
                      token,
                      save_card_from_order,
                      PaymentProcess
                    );
                  } else if (
                    gateway.id === "piraeus" &&
                    billingInfo.CardHolderName
                  ) {
                    if (token !== "null" && token) {
                      this.startPiraeusRecurringPayment3DS(
                        gateway.id,
                        order_id,
                        store_id,
                        token,
                        cardNumber,
                        card_type,
                        banks.piraeus
                      );
                    } else {
                      this.openPiraeusPaymentModal(
                        gateway,
                        type,
                        banks.piraeus,
                        save_card_from_order,
                        PaymentProcess
                      );
                    }
                    console.log("billingInfo", billingInfo);
                  }
                }
              }
            });
          } else {
            if (this.ionLoading) {
              this.ionLoading.dismiss();
            }
            setTimeout(async () => {
              if (res) {
                if (res.comment_id) {
                  const alert = await this.alertController.create({
                    header: this.translate.instant("alert"),
                    message: this.translate.instant(
                      "errorMessages." + res.comment_id
                    ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                } else {
                  const alert = await this.alertController.create({
                    header: this.translate.instant("alert"),
                    message: this.translate.instant(res.comments),

                    buttons: ["OK"],
                  });
                  await alert.present();
                }
              }
            }, 2000);
            this.showBillingAddressVerificationPopup(
              gateway,
              type,
              selectedCardStore,
              order_id,
              store_id,
              cardNumber,
              card_type,
              token,
              null,
              billingInfo,
              save_card_from_order,
              PaymentProcess,
              null
            );
          }
        },
        error: async (err) => {
          if (this.ionLoading) {
            this.ionLoading.dismiss();
          }
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  startPiraeusRecurringPayment3DS(
    selectedPaymentMethodID,
    order_id,
    store_id,
    token,
    card_number,
    card_type,
    billingInfo
  ) {
    if (this.ionLoading) {
      this.ionLoading.dismiss();
      this.ionLoading = null;
    }
    this.presentLoading();
    this.dataStorageService
      .piraeusCheckoutRecurring3ds(
        {
          order_id: order_id,
          languageCode: this.selectedLanguage,
          store_id: store_id,
          billingInfo,
          card_number: card_number,
          card_type: !_.isEmpty(card_type) ? card_type.Name : "",
          token: token,
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            if (
              selectedPaymentMethodID === "piraeus" &&
              res.do_not_show_response_popup &&
              res.last_payment_timestamp
            ) {
              //write to state the necessary data
              this.store.dispatch(
                new OrdersActions.SetCurrentPendingPaymentPiraeusOrder({
                  order_id: order_id,
                  store_id: store_id,
                  last_payment_timestamp: res.last_payment_timestamp,
                })
              );
            }

            if (!res.do_not_show_response_popup) {
              setTimeout(async () => {
                const alert = await this.alertController.create({
                  header: this.translate.instant("alert"),
                  message: res.comment_id
                    ? this.translate.instant(res.comment_id)
                    : this.translate.instant(res.comments),

                  buttons: ["OK"],
                });
                await alert.present();
              }, 1500);
            }
            if (this.ionLoading) {
              this.ionLoading.dismiss();
              this.ionLoading = null;
            }
          } else {
            if (this.ionLoading) {
              this.ionLoading.dismiss();
              this.ionLoading = null;
            }
            if (res) {
              const alert = await this.alertController.create({
                header: this.translate.instant("alert"),
                message: res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant(res.comments),

                buttons: ["OK"],
              });
              await alert.present();
            }
          }
        },
        error: async (err) => {
          if (this.ionLoading) {
            this.ionLoading.dismiss();
            this.ionLoading = null;
          }
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  async presentLoading() {
    console.log("present loading from payment gateways");
    let message = this.translate.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 30000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    this.ionLoading.present();
    this.ionLoading.onDidDismiss().then(() => {
      this.ionLoading = null;
    });

    console.log("Loading dismissed!");
  }

  async presentBillingVerificationAlert(billingInfo) {}

  async presentAlphaModal(frame_width, frame_height, alpha_iframe) {
    this.piraeusModal = await this.modalCtrl.create({
      component: AlphaModalComponent,
      cssClass: "paymentModal",
      backdropDismiss: false,
      componentProps: {
        frame_width: frame_width,
        frame_height: frame_height,
        alpha_iframe: alpha_iframe,
      },
    });

    await this.piraeusModal.present();
    this.piraeusModal.onDidDismiss().then((data) => {
      this.piraeusModal = null;
      if (data && data.data) {
        console.log(data);
      }
    });
  }

  async presentMyPosModal(frame_width, frame_height, mypos_iframe) {
    this.myPosModal = await this.modalCtrl.create({
      component: MyposModalComponent,
      cssClass: "paymentModal",
      backdropDismiss: false,
      componentProps: {
        frame_width: frame_width,
        frame_height: frame_height,
        mypos_iframe: mypos_iframe,
      },
    });

    await this.myPosModal.present();
    this.myPosModal.onDidDismiss().then((data) => {
      if (data && data.data) {
        this.myPosModal = null;
        console.log(data);
      }
    });
  }

  async presentPaypalModal(paypal_iframe) {
    this.paypalModal = await this.modalCtrl.create({
      component: PaypalModalComponent,
      cssClass: "paymentModal",
      backdropDismiss: false,
      componentProps: {
        paypal_iframe: paypal_iframe,
      },
    });

    await this.paypalModal.present();
    this.paypalModal.onDidDismiss().then((data) => {
      if (data && data.data) {
        this.paypalModal = null;
        console.log(data);
      }
    });
  }

  async presentKlarnaModal(klarna_iframe) {
    this.klarnaModal = await this.modalCtrl.create({
      component: KlarnaModalComponent,
      cssClass: "paymentModal",
      backdropDismiss: false,
      componentProps: {
        klarna_iframe: klarna_iframe,
      },
    });

    await this.klarnaModal.present();
    this.klarnaModal.onDidDismiss().then((data) => {
      this.klarnaModal = null;
      if (data && data.data) {
        console.log(data);
      }
    });
  }

  async presentNbgSimplifyModal(frame_width, frame_height, nbg_simplify_frame) {
    this.nbgSimplifyModal = await this.modalCtrl.create({
      component: NbgSimplifyModalComponent,
      cssClass: "paymentModal",
      backdropDismiss: false,
      componentProps: {
        frame_width: frame_width,
        frame_height: frame_height,
        nbg_simplify_frame: nbg_simplify_frame,
      },
    });

    await this.nbgSimplifyModal.present();
    this.nbgSimplifyModal.onDidDismiss().then((data) => {
      if (data && data.data) {
        this.nbgSimplifyModal = null;
        console.log(data);
      }
    });
  }

  async presentEthnikiModal(frame_width, frame_height, ethniki_iframe) {
    this.ethnikiModal = await this.modalCtrl.create({
      component: EthnikiModalComponent,
      cssClass: "paymentModal",
      backdropDismiss: false,
      componentProps: {
        frame_width: frame_width,
        frame_height: frame_height,
        ethniki_iframe: ethniki_iframe,
      },
    });

    await this.ethnikiModal.present();
    this.ethnikiModal.onDidDismiss().then((data) => {
      if (data && data.data) {
        this.ethnikiModal = null;
        console.log(data);
      }
    });
  }

  async presentBraintreeModal(braintree_iframe) {
    this.brainTreeModal = await this.modalCtrl.create({
      component: BraintreeModalComponent,
      cssClass: "paymentModal",
      backdropDismiss: false,
      componentProps: {
        braintree_iframe: braintree_iframe,
      },
    });

    await this.brainTreeModal.present();
    this.brainTreeModal.onDidDismiss().then((data) => {
      if (data && data.data) {
        this.brainTreeModal;
        console.log(data);
      }
    });
  }

  trustedUrl(url) {
    console.log("trusted Url", url);
    return this.DomSinitizer.bypassSecurityTrustResourceUrl(url);
  }

  async openLink(link) {
    console.log("linkkk bank", link);
    // window.open(link, "_blank");
    await Browser.open({ url: link });
  }

  async openNavigationToBankModal(order_id, store_id, bank_link, type) {
    return new Promise(async (res, rej) => {
      if (this.navigationToBankModal) {
        await this.navigationToBankModal.dismiss();
        this.navigationToBankModal = null;
      }
      this.navigationToBankModal = await this.modalCtrl.create({
        component: NavigationToBankModalComponent,
        cssClass: "navigationToBankModal",
        backdropDismiss: false,
        componentProps: {
          bank_link: bank_link,
          order_id: _.cloneDeep(order_id),
          store_id: _.cloneDeep(store_id),
          type: type || "order",
        },
      });

      await this.navigationToBankModal.present();
      res(true);
      this.navigationToBankModal.onDidDismiss().then((data) => {
        this.navigationToBankModal = null;
      });
    });
  }

  async closeNavigationToBankModal() {
    if (this.navigationToBankModal) {
      await this.navigationToBankModal.dismiss();
    }
  }

  billingInfoVerifyModalIsOpen(): boolean {
    if (this.billingInfoVerifyModal) {
      return true;
    }

    return false;
  }

  async closeBillingInfoVerifyModal() {
    if (this.billingInfoVerifyModal) {
      await this.billingInfoVerifyModal.dismiss();
      this.billingInfoVerifyModal = null;
    }
  }
}
