import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { AnimationOptions } from "ngx-lottie";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
declare let window: any;
@Component({
  selector: "app-info-message-modal",
  templateUrl: "./info-message-modal.component.html",
  styleUrls: ["./info-message-modal.component.scss"],
})
export class InfoMessageModalComponent implements OnInit, OnDestroy {
  @Input() browserSize: boolean;
  @Input() infoMessage: any;
  public selectedLangugage: any;
  public groupApp: any;
  public mo_base: any;
  public isBrowserFromAndroid;
  public isBrowserFromIos;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  animationOptionsAndroid: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/download-now-android.json",
  };

  animationOptionsIos: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/download-now-ios.json",
  };

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnInit() {
    if (!Capacitor.isNativePlatform()) {
      if (this.platform.is("android")) {
        this.isBrowserFromAndroid = true;
      } else if (this.platform.is("ios")) {
        this.isBrowserFromIos = true;
      }
    }
    console.log("is", this.isBrowserFromAndroid, this.isBrowserFromIos);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLangugage, state.selectedLangugage)
        ) {
          console.log("inside rootDir");
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }

        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          console.log("inside groupApp");
          this.groupApp = _.cloneDeep(state.groupApp);

          this.mo_base = _.cloneDeep(
            _.find(this.groupApp.languages, {
              mo_base: true,
            })
          );
          if (!this.mo_base) {
            this.mo_base = "en";
          } else {
            this.mo_base = this.mo_base.code;
          }
        }
      });
  }

  async downloadApp() {
    console.log("androidPlatform", this.isBrowserFromAndroid);

    if (this.isBrowserFromAndroid) {
      console.log("open native app android");
      await Browser.open({ url: this.groupApp.androidNativeUrl });
    } else {
      console.log("open native app ios");
      await Browser.open({ url: this.groupApp.iOSNativeUrl });
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  openLink = async (link) => {
    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
