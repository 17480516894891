import * as CustomerCouponsActions from "./coupons.actions";
import * as _ from "lodash";

export interface State {
  allCoupons: any;
  triggerCouponCodeCheckObject: any;
  fetchCouponsAgainTimestamp: any;
  showCouponsPromoModalTimestamp: any;
  couponIdCheckRequestCompleted: any;
}

const initialState: State = {
  allCoupons: null,
  triggerCouponCodeCheckObject: null,
  fetchCouponsAgainTimestamp: null,
  showCouponsPromoModalTimestamp: null,
  couponIdCheckRequestCompleted: false,
};

export function customerCouponsReducer(
  state = initialState,
  action: CustomerCouponsActions.CustomerCouponsActions
) {
  switch (action.type) {
    case CustomerCouponsActions.SET_ALL_COUPONS:
      return {
        ...state,
        allCoupons: action.payload,
      };

    case CustomerCouponsActions.SET_FETCH_COUPONS_AGAIN_TIMESTAMP:
      return {
        ...state,
        fetchCouponsAgainTimestamp: action.payload,
      };

    case CustomerCouponsActions.SET_SHOW_PROMO_COUPONS_MODAL_TIMESTAMP:
      return {
        ...state,
        showCouponsPromoModalTimestamp: action.payload,
      };

    case CustomerCouponsActions.SET_MO_COUPON_CHECK_REQUEST_COMPLETED:
      return {
        ...state,
        couponIdCheckRequestCompleted: action.payload,
      };
    case CustomerCouponsActions.SET_TRIGGER_COUPON_CODE_CHECK:
      return {
        ...state,
        triggerCouponCodeCheckObject: {
          timestamp: action.payload.timestamp,
          coupon_code: action.payload.coupon_code,
          type: action.payload.type,
        },
      };

    case CustomerCouponsActions.CLEAR_ALL_COUPONS:
      return {
        ...state,
        allCoupons: null,
      };

    default:
      return state;
  }
}
