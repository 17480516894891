import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { OptionValidationService } from "../option-validation-service/option-validation.service";
import { ProductDetailsOperationsService } from "../../product-details-operations.service";

@Component({
  selector: "app-radio-option",
  templateUrl: "./radio-option.component.html",
  styleUrls: ["./radio-option.component.scss"],
})
export class RadioOptionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedProductId: any;
  @Input() productName: any;
  @Input() product_id;
  @Input() bundleMode: any;
  @Input() selectedProductParentDiscount: any;
  @Input() option_id: any;
  @Input() must_select_something: any;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() selectedProductFreeParentOption: any;
  @Input() option_stepper_mode: boolean;
  @Input() disable_qty: any;
  @Input() groupStoreData: any;
  @Output() radioOptionChanged = new EventEmitter();

  public allOptionSteps: any;
  public currentOptionStep: any;
  public option: any;
  public optionValidationObject: any;
  private subscriptions: Subscription[] = [];
  private lastValidTimestamp: any;
  private orderPickup: boolean;
  private dineIn: any;
  private optionSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private optionValidationService: OptionValidationService,
    private productDetailsOperationsService: ProductDetailsOperationsService
  ) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          console.log("selecor radtio option");
          this.option = _.cloneDeep(state);
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.selectOption();

    if (this.option_stepper_mode) {
      this.subscriptions.push(
        this.store
          .select("optionStepper")
          .pipe(distinctUntilChanged())
          .pipe(auditTime(20))
          .subscribe((state) => {
            if (
              state &&
              state.selectedProductOptionStepsBySelectedProductId &&
              state.selectedProductOptionStepsBySelectedProductId[
                this.selectedProductId
              ] &&
              state.selectedProductOptionStepsBySelectedProductId[
                this.selectedProductId
              ].optionsSteps &&
              !_.isEqual(
                this.allOptionSteps,
                state.selectedProductOptionStepsBySelectedProductId[
                  this.selectedProductId
                ].optionsSteps
              )
            ) {
              this.allOptionSteps = _.cloneDeep(
                state.selectedProductOptionStepsBySelectedProductId[
                  this.selectedProductId
                ].optionsSteps
              );
            }
            if (
              state &&
              state.selectedProductOptionStepsBySelectedProductId &&
              state.selectedProductOptionStepsBySelectedProductId[
                this.selectedProductId
              ] &&
              state.selectedProductOptionStepsBySelectedProductId[
                this.selectedProductId
              ].selectedStepOptionId &&
              this.allOptionSteps &&
              !_.isEqual(
                this.currentOptionStep,
                state.selectedProductOptionStepsBySelectedProductId[
                  this.selectedProductId
                ].selectedStepOptionId
              )
            ) {
              this.currentOptionStep = _.cloneDeep(
                state.selectedProductOptionStepsBySelectedProductId[
                  this.selectedProductId
                ].selectedStepOptionId
              );
            }
          })
      );
    }

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("productOptionsValidation")

        .pipe(distinctUntilChanged())
        .subscribe((state: any) => {
          console.log("radio validation state opt", this.bundleMode, state);

          if (
            !this.option_stepper_mode &&
            state.lastValidTimestampBySelectedProductId &&
            state.lastValidTimestampBySelectedProductId[
              this.selectedProductId
            ] &&
            !_.isEqual(
              this.lastValidTimestamp,
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            )
          ) {
            console.log("Subscribe to options validation");
            this.lastValidTimestamp = _.cloneDeep(
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            );
            console.log("call validate opt...", this.lastValidTimestamp);
            this.optionValidationObject =
              this.optionValidationService.validateOption(
                this.option,
                null,
                this.lastValidTimestamp,
                this.must_select_something,
                this.orderPickup,
                this.bundleMode,
                this.product_id,
                null,
                this.productName,
                this.selectedProductId,
                this.dineIn
              );
            if (!this.optionValidationObject.valid) {
              console.log("option is not valid", this.option.option_id);
              const el: any = document.getElementById(
                this.option.option_id.toString()
              );
              if (el) {
                el.style.setProperty("--ion-background-color", "#ffd6d6");
              }
            } else {
              console.log("option is valid", this.option.option_id);
              const el: any = document.getElementById(
                this.option.option_id.toString()
              );
              if (el) {
                el.style.setProperty(
                  "--ion-background-color",
                  "--ion-background-color"
                );
              }
            }
          }

          if (
            state &&
            state.lastValidTimestampBySelectedProductId &&
            state.lastValidTimestampBySelectedProductId[
              this.selectedProductId
            ] &&
            !_.isEqual(
              this.lastValidTimestamp,
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            ) &&
            this.option_stepper_mode &&
            this.currentOptionStep &&
            this.option.option_id === this.currentOptionStep
          ) {
            this.lastValidTimestamp = _.cloneDeep(
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            );
            console.log("call validate opt...");
            this.optionValidationObject =
              this.optionValidationService.validateOption(
                this.option,
                null,
                this.lastValidTimestamp,
                this.must_select_something,
                this.orderPickup,
                this.bundleMode,
                this.product_id,
                null,
                this.productName,
                this.selectedProductId,
                this.dineIn
              );
            if (!this.optionValidationObject.valid) {
              console.log("option is not valid", this.option.option_id);
              const el: any = document.getElementById(
                this.option.option_id.toString()
              );
              if (el) {
                el.style.setProperty("--ion-background-color", "#ffd6d6");
              }
            } else {
              console.log("option is valid", this.option.option_id);
              const el: any = document.getElementById(
                this.option.option_id.toString()
              );
              if (el) {
                el.style.setProperty(
                  "--ion-background-color",
                  "--ion-background-color"
                );
              }
            }
          }
        })
    );
  }

  toFloat(num) {
    return parseFloat(num.toString());
  }

  onOptionChanged(newOption) {
    this.option = _.cloneDeep(newOption);
    this.assignFreeChoices();
    this.calculateTotalChoiceQuantity();
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((store_info) => {
        this.store
          .select("deliveryMethodAddress")
          .pipe(first())
          .subscribe((state) => {
            this.productChoicesPriceComment(
              state.orderPickup,
              state.dineIn,
              store_info && store_info.dinein
                ? store_info.alt_dinein_active
                : false,
              state.selectedDineInTable
            );
          })
          .unsubscribe();
      })
      .unsubscribe();

    console.log("call validate opt...");
    this.optionValidationObject = this.optionValidationService.validateOption(
      this.option,
      null,
      this.lastValidTimestamp,
      this.must_select_something,
      this.orderPickup,
      this.bundleMode,
      this.product_id,
      null,
      this.productName,
      this.selectedProductId,
      this.dineIn
    );
    if (!this.optionValidationObject.color) {
      console.log("option is not valid", this.option.option_id);
      const el: any = document.getElementById(this.option.option_id.toString());
      if (el) {
        el.style.setProperty("--ion-background-color", "#ffd6d6");
      }
    } else {
      console.log("option is valid", this.option.option_id);
      const el: any = document.getElementById(this.option.option_id.toString());
      if (el) {
        el.style.setProperty(
          "--ion-background-color",
          "--ion-background-color"
        );
      }
    }
    console.log("radio emit", this.option);
    this.radioOptionChanged.emit(this.option);
  }

  calculateTotalChoiceQuantity() {
    if (!this.option.option_id) {
      return;
    }

    let choicesQuantity = 0;
    if (this.option && this.option.choices && this.option.choices.length) {
      _.each(this.option.choices, (choice) => {
        if (
          choice.is_preselected &&
          choice.activeTotal &&
          choice.availableTotal
        ) {
          choicesQuantity = choicesQuantity + choice.quantity;
        }
      });
      if (!this.option.optionData) {
        this.option.optionData = {};
      }
      this.option.optionData.freeChoices =
        choicesQuantity >= parseInt(this.option.freeChoices)
          ? this.option.freeChoices
          : choicesQuantity;
      this.option.optionData.maxChoices =
        choicesQuantity >= parseInt(this.option.maxChoices)
          ? this.option.maxChoices
          : choicesQuantity;
      this.option.optionData.minChoices =
        choicesQuantity >= parseFloat(this.option.minChoices)
          ? this.option.minChoices
          : choicesQuantity;
    }
  }

  productChoicesPriceComment(
    orderPickup,
    dineIn,
    alt_dinein_active,
    selectedDineInTable
  ) {
    console.log("productChoicesPriceComment");

    if (this.option) {
      console.log("calculatingTotalChoiceQuantity");
      _.each(this.option.choices, (choice, c, q) => {
        if (choice.isFree && choice.quantity === choice.freeQuantity) {
          this.option.choices[c].choicePriceComment = this.option.choices[
            c
          ].choicePriceComment =
            this.productDetailsOperationsService.handle_choice_step_price(
              choice,
              orderPickup,
              dineIn,
              alt_dinein_active,
              selectedDineInTable
            ) +
            ((this.option.alwaysShowChoicesQuantity || choice.quantity !== 1
              ? " x" + choice.quantity + ": "
              : " ") +
              this.translateService.instant("product-details.free"));
        } else {
          let tempPrice;
          if (orderPickup == true && dineIn == false && choice.price_takeaway) {
            tempPrice =
              choice.price_takeaway.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity);
            this.option.choices[c].choicePriceComment =
              this.productDetailsOperationsService.handle_choice_step_price(
                choice,
                orderPickup,
                dineIn,
                alt_dinein_active,
                selectedDineInTable
              ) +
              (choice.price_takeaway
                ? (this.option.alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                    ? " x" + choice.quantity + ": "
                    : " ") +
                  parseFloat(tempPrice.toString()).toFixed(2) +
                  "€"
                : this.option.alwaysShowChoicesQuantity || choice.quantity !== 1
                ? " x" + choice.quantity
                : "");
          } else if (
            orderPickup == false &&
            dineIn == true &&
            (!alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein ||
              !choice.price_alt_dinein) &&
            choice.price_dinein
          ) {
            tempPrice =
              choice.price_dinein.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity);
            this.option.choices[c].choicePriceComment =
              this.productDetailsOperationsService.handle_choice_step_price(
                choice,
                orderPickup,
                dineIn,
                alt_dinein_active,
                selectedDineInTable
              ) +
              (choice.price_dinein
                ? (this.option.alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                    ? " x" + choice.quantity + ": "
                    : " ") +
                  parseFloat(tempPrice.toString()).toFixed(2) +
                  "€"
                : this.option.alwaysShowChoicesQuantity || choice.quantity !== 1
                ? " x" + choice.quantity
                : "");
          } else if (
            orderPickup == false &&
            dineIn == true &&
            alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein &&
            choice.price_alt_dinein
          ) {
            tempPrice =
              choice.price_alt_dinein.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity);
            this.option.choices[c].choicePriceComment =
              this.productDetailsOperationsService.handle_choice_step_price(
                choice,
                orderPickup,
                dineIn,
                alt_dinein_active,
                selectedDineInTable
              ) +
              (choice.price_alt_dinein
                ? (this.option.alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                    ? " x" + choice.quantity + ": "
                    : " ") +
                  parseFloat(tempPrice.toString()).toFixed(2) +
                  "€"
                : this.option.alwaysShowChoicesQuantity || choice.quantity !== 1
                ? " x" + choice.quantity
                : "");
          } else {
            tempPrice =
              choice.price.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity);
            this.option.choices[c].choicePriceComment =
              this.productDetailsOperationsService.handle_choice_step_price(
                choice,
                orderPickup,
                dineIn,
                alt_dinein_active,
                selectedDineInTable
              ) +
              (choice.price
                ? (this.option.alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                    ? " x" + choice.quantity + ": "
                    : " ") +
                  parseFloat(tempPrice.toString()).toFixed(2) +
                  "€"
                : this.option.alwaysShowChoicesQuantity || choice.quantity !== 1
                ? " x" + choice.quantity
                : "");
          }
        }
      });

      if (
        this.option.reverseChoices &&
        !_.isEmpty(this.option.preselectedChoices)
      ) {
        this.store
          .select("deliveryMethodAddress")
          .pipe(first())
          .subscribe((state) => {
            this.option.choicesComment =
              this.productDetailsOperationsService.findChoicesComment(
                this.option,
                state.orderPickup,
                state.dineIn,
                alt_dinein_active,
                state.selectedDineInTable
              );
          })
          .unsubscribe();
      }
    }
  }

  assignFreeChoices() {
    if (!this.option.option_id) {
      //There are some options that do not have option_id
      return;
    }

    let optionsFreeChoices = parseInt(this.option.freeChoices);

    let sortByVariable: any = "price";

    if (
      this.option &&
      this.option.freeChoices &&
      this.option.freeChoicesBySelectionOrder
    ) {
      sortByVariable = "selected_timestamp";
    }

    if (
      this.option &&
      this.option.freeChoices &&
      this.option.freeChoicesByPosition
    ) {
      sortByVariable = (choice, index) => index;
    }

    const sortedChoices = _.sortBy(
      this.option.choices,
      (choice: any, index) => {
        if (sortByVariable === "price") {
          if (this.orderPickup && choice.price_takeaway) {
            sortByVariable = "price_takeway";
            return choice.price_takeaway;
          } else if (this.dineIn && choice.price_dinein) {
            sortByVariable = "price_dinein";
            return choice.price_dinein;
          }
          return choice.price;
        } else if (typeof sortByVariable === "function") {
          return sortByVariable(choice, index);
        } else {
          return choice[sortByVariable];
        }
      }
    );

    _.each(sortedChoices, (choice: any, key, list) => {
      if (
        this.dineIn &&
        (!choice.price || choice.price === 0) &&
        (!choice.price_dinein || choice.price_dinein === 0) &&
        this.option.freeChoicesbyPriceSkipZeros
      ) {
        return;
      }
      if (
        this.orderPickup &&
        (!choice.price_takeaway || choice.price_takeaway === 0) &&
        (!choice.price || choice.price === 0) &&
        this.option.freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        !this.orderPickup &&
        !this.dineIn &&
        (!choice.price || choice.price === 0) &&
        this.option.freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        optionsFreeChoices > 0 &&
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        this.option.choices[
          _.findIndex(this.option.choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = true;
        if (choice.quantity > 1) {
          if (optionsFreeChoices - choice.quantity >= 0) {
            this.option.choices[
              _.findIndex(this.option.choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = choice.quantity;
            optionsFreeChoices -= choice.quantity;
          } else {
            this.option.choices[
              _.findIndex(this.option.choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = optionsFreeChoices;
            optionsFreeChoices -= optionsFreeChoices;
          }
        } else {
          this.option.choices[
            _.findIndex(this.option.choices, {
              choice_id: choice.choice_id,
            })
          ].freeQuantity = 1;
          optionsFreeChoices -= 1;
        }
      } else {
        this.option.choices[
          _.findIndex(this.option.choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = false;
        this.option.choices[
          _.findIndex(this.option.choices, {
            choice_id: choice.choice_id,
          })
        ].freeQuantity = 0;
      }
    });
    optionsFreeChoices = null;
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
