import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Capacitor } from "@capacitor/core";

import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { Loader } from "@googlemaps/js-api-loader";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { DeliveryAreasInfoModalComponent } from "./delivery-areas-info-modal/delivery-areas-info-modal.component";
import mapStyleJson from "../../assets/mapStyle/mapStyle.json";
import mapStyleJsonDark from "../../assets/mapStyle/mapStyleDark.json";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { Router } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { Geolocation } from "@capacitor/geolocation";

declare let window: any;
@Component({
  selector: "app-delivery-areas",
  templateUrl: "./delivery-areas.page.html",
  styleUrls: ["./delivery-areas.page.scss"],
})
export class DeliveryAreasPage implements OnInit, OnDestroy {
  @ViewChild("autoCompleteInput") input: ElementRef;
  public newAddress: any;
  public selectedLanguage: string;
  public groupApp: any;
  public libraryLoaded: boolean = false;
  public clusteringMarkersLibraryLoaded: boolean = false;
  public currentStore: any;
  public deliverAreas: any;
  public loading: boolean = false;
  public checkIfMapsLoadedInterval: any;
  public checkIFClusterLibLoadedInterval: any;
  public store_markers: any;
  public fixed_points_markers: any;
  public stores: any;
  public map: any;
  public infoMarkerInfoToast: any;
  mapOptions = {};
  search;
  autocomplete;
  public infoMarker: any;
  geocoder;
  public allStoreMarkersInfoboxes: any;
  public infoForMoveTheMarker: any;
  public infoForStopMovingTheMarker: any;
  public gps_permissions: any;
  public geoLocationAnimation: boolean = false;
  public geoAnimationInstance: any;
  // public showDownInfoMarkerAnimation;
  // public showUpInfoMarkerAnimation;
  public mobilePlatform: boolean = false;
  public checkMarkerLoading: boolean = false;
  public selectedTheme: any;
  public windowDimensions;
  public showInfoFixedPointsAddresses = true;

  private deliveryAreasInfoModal: any;
  private subs: Subscription[] = [];
  private currentUser: any;
  private unsubscribeBackEvent: Subscription;
  private previousInfoMarkerPosition: any;
  private geoErrMessage: any;
  private ionLoading: any;

  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/location-animation.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef,
    private toastController: ToastController,
    private translateService: TranslateService,
    private dataStorageSevice: DataStorageService,
    private modalController: ModalController,
    private alertController: AlertController,
    private analyticsService: AnalyticsServiceService,
    private router: Router,
    private platform: Platform,
    private loadingController: LoadingController
  ) {}

  animationCreated(ev) {
    this.geoAnimationInstance = ev;
    this.geoAnimationInstance.setSpeed(1.5);
  }

  async presentInfoMarkerToast() {
    this.infoMarkerInfoToast = await this.toastController.create({
      message: this.mobilePlatform
        ? this.translateService.instant(
            "delivery-areas.move-the-map-to-get-info-about-delivery-areas"
          )
        : this.translateService.instant(
            "delivery-areas.move-the-pin-to-get-info-about-delivery-areas"
          ),
      color: "primary",

      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            // console.log("Toast Closed");
          },
        },
        {
          side: "start",
          icon: "assets/flatIcons/info.svg",
          role: "OK",
          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
      duration: 10000,
    });
    await this.infoMarkerInfoToast.present();
    this.map.setOptions({ zoomControl: false });
    await this.infoMarkerInfoToast.onDidDismiss().then(() => {
      this.infoMarkerInfoToast = null;

      if (this.map) {
        this.map.setOptions({ zoomControl: true });
      }
    });
  }
  private navigateToHome() {
    this.router.navigateByUrl("/homepage", {
      replaceUrl: true,
    });
  }

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        this.navigateToHome();
      }
    );
    if (navigator.cookieEnabled) {
      let gpsPermissions: any = localStorage.getItem("gps_permissions");
      if (gpsPermissions) {
        gpsPermissions = JSON.parse(localStorage.getItem("gps_permissions"));
        if (gpsPermissions) {
          this.gps_permissions = gpsPermissions;
        }
      }
    }
    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("deliveryAreas");
    this.analyticsService.tikTokPixelPageView("deliveryAreas");
    this.subs.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme
            ) {
              this.createMapsScript();
            }
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme
            ) {
              this.createMapsScript();
            }
          }

          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            if (this.selectedTheme && this.map) {
              if (this.selectedTheme === "dark") {
                this.map.set("styles", mapStyleJsonDark);
              } else {
                this.map.set("styles", mapStyleJson);
              }
            }
          }
        })
    );

    this.subs.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subs.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme
            ) {
              this.createMapsScript();
            }
          }
        })
    );
    this.subs.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
  }

  ngOnInit() {
    if (Capacitor.isNativePlatform()) {
      this.mobilePlatform = true;
    } else {
      this.mobilePlatform = false;
    }
  }

  createMapsScript() {
    var loader: any;
    // console.log("loader elemnt", document.getElementById("googleMapsLoader"));
    if (!document.getElementById("googleMapsLoader")) {
      loader = new Loader({
        id: "googleMapsLoader",
        apiKey: this.groupApp.googleMapsKey,
        language: this.selectedLanguage,
        libraries: ["geometry", "drawing", "places"],
      });
      loader.load().then(() => {
        console.log("library loaded");
        this.libraryLoaded = true;
        this.changeDetector.detectChanges();
        //TO_DO CHECK THE ABOVE LINES FOR GPS
        // if (!this.gps_permissions || !this.gps_permissions.asked) {
        //   this.presentAlertConfirminLocationAccess();
        // } else if (this.gps_permissions.asked && this.gps_permissions.granted) {
        //   this.checkLocationFromGps();
        // }

        this.initializationSetAddress();
      });
    } else {
      this.libraryLoaded = true;
      this.changeDetector.detectChanges();
      //TO_DO CHECK THE ABOVE LINES FOR GPS
      // if (!this.gps_permissions || !this.gps_permissions.asked) {
      //   this.presentAlertConfirminLocationAccess();
      // } else if (this.gps_permissions.asked && this.gps_permissions.granted) {
      //   this.checkLocationFromGps();
      // }
      this.initializationSetAddress();
      // loader = new Loader({
      //   id: "googleMapsLoader",
      //   apiKey: this.groupApp.googleMapsKey,
      //   libraries: ["geometry", "drawing", "places"],
      // });
    }
  }

  // async presentAlertConfirminLocationAccess() {
  //   const alert = await this.alertController.create({
  //     header: this.translateService.instant("deliveryAreas.location"),
  //     message: this.translateService.instant(
  //       "deliveryAreas.do-you-want-to-access-you-location-in-order-to-see-info"
  //     ),
  //     buttons: [
  //       {
  //         text: this.translateService.instant("no"),
  //         role: "cancel",
  //         cssClass: "secondary",
  //         handler: (blah) => {
  //           if (navigator.cookieEnabled) {
  //             let obj = {
  //               asked: true,
  //               granted: false,
  //             };
  //             localStorage.setItem("gps_permissions", JSON.stringify(obj));
  //           }
  //         },
  //       },
  //       {
  //         text: this.translateService.instant("yes"),
  //         handler: () => {
  //           if (navigator.cookieEnabled) {
  //             let obj = {
  //               asked: true,
  //               granted: true,
  //             };
  //             localStorage.setItem("gps_permissions", JSON.stringify(obj));
  //           }
  //           this.checkLocationFromGps();
  //         },
  //       },
  //     ],
  //   });

  //   await alert.present();
  // }

  hideInfoFixedPointsAddresses() {
    this.showInfoFixedPointsAddresses = false;
  }

  initializationSetAddress() {
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: parseInt(this.groupApp.groupMapZoom),
        gestureHandling: "greedy",
        center: {
          lat: parseFloat(this.groupApp.groupMapCenterLat),
          lng: parseFloat(this.groupApp.groupMapCenterLng),
        },

        zoomControl: true,
        styles:
          this.selectedTheme && this.selectedTheme === "dark"
            ? mapStyleJsonDark
            : mapStyleJson,
        scaleControl: false,

        scrollwheel: true,
        disableDefaultUI: true,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    );

    this.deliveryAreasInitialization(this.map);

    this.checkMapZoomPresentInfo();

    if (this.groupApp && this.groupApp.fixedPointsAddressSystemActive) {
      this.fixed_points_markers = [];

      let deliveryAreas = _.cloneDeep(this.deliverAreas);
      if (!deliveryAreas) {
        deliveryAreas = [];
        _.each(this.stores, (store) => {
          if (store && store.deliveryAreas) {
            deliveryAreas = _.concat(
              _.cloneDeep(deliveryAreas),
              _.cloneDeep(store.deliveryAreas)
            );
          }
        });
      }
      console.log("fixed_points_markers", deliveryAreas);
      _.each(deliveryAreas, (area) => {
        if (area && area.fixed_address_id && area.address) {
          this.fixed_points_markers.push({
            marker: new google.maps.Marker({
              animation: google.maps.Animation.DROP,
              icon: "assets/ionicons/marker-blue.png",
              position: {
                lat: area.address.userLat
                  ? parseFloat(area.address.userLat)
                  : 0,
                lng: area.address.userLng
                  ? parseFloat(area.address.userLng)
                  : 0,
              },
              map: this.map,

              anchorPoint: new google.maps.Point(0, -29),
              draggable: false,
            }),
            store_id: area.store_id,
            userLat: area.address.userLat,
            userLng: area.address.userLng,
            fixed_address_id: area.fixed_address_id,
            infoBox: new google.maps.InfoWindow({
              content: this.createFixedPointAreaInfo(area),
              disableAutoPan: true,
            }),
            infoWindowIsOpen: false,
          });
        }
      });

      console.log("fixed_points_markers", this.fixed_points_markers);
    }

    this.store_markers = [];
    _.each(this.stores, (store) => {
      this.store_markers.push({
        marker: new google.maps.Marker({
          animation: google.maps.Animation.DROP,
          icon: "assets/ionicons/store-location.png",

          position: {
            lat: store.mapCenterStoreLat
              ? parseFloat(store.mapCenterStoreLat)
              : 0,
            lng: store.mapCenterStoreLng
              ? parseFloat(store.mapCenterStoreLng)
              : 0,
          },
          map: this.map,
          anchorPoint: new google.maps.Point(0, -29),
          draggable: false,
        }),
        store_id: store.store_id,
        infoBox: new google.maps.InfoWindow({
          content:
            "<h6 style='padding:0;margin:2px;'>" +
            (store.store_panel_name_langs &&
            store.store_panel_name_langs[this.selectedLanguage]
              ? store.store_panel_name_langs[this.selectedLanguage]
              : store.store_panel_name_langs && store.store_panel_name_langs.el
              ? store.store_panel_name_langs.el
              : store.store_name_panel
              ? store.store_name_panel
              : store.store_name
              ? store.store_name
              : "") +
            "</h6>" +
            "<p style='padding:0;margin:0px'>" +
            store.address +
            "</p>",
          disableAutoPan: true,
        }),
        infoWindowIsOpen: false,
      });
    });
    let markers = [];
    _.each(this.store_markers, (marker) => {
      marker.marker.setVisible(true);
      markers.push(marker.marker);
      marker.marker.addListener("click", () => {
        this.onStoreMarkerClick(marker);
      });
    });

    _.each(this.fixed_points_markers, (marker) => {
      marker.marker.setVisible(true);
      markers.push(marker.marker);
      marker.marker.addListener("click", () => {
        this.onFixedAddressMarkerClick(marker);
      });
    });
    const markerCluster = new MarkerClusterer(this.map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });

    this.map.addListener("zoom_changed", () => {
      this.checkMapZoomPresentInfoDebounced();
    });

    if (Capacitor.isNativePlatform()) {
      this.presentInfoMarkerToast();
      this.map.addListener("dragstart", () => {
        this.deliverAreas = null;
        // this.showDownInfoMarkerAnimation = false;
        // setTimeout(() => {
        //   this.showUpInfoMarkerAnimation = true;
        // }, 100);
      });

      this.map.addListener("dragend", () => {
        // this.showUpInfoMarkerAnimation = false;
        // setTimeout(() => {
        //   this.showDownInfoMarkerAnimation = true;
        // }, 100);
        this.mobileCheckMarker();
      });
    }
    //this.map.setZoom(19);

    this.map.setCenter({
      lat: this.groupApp.groupMapCenterLat
        ? parseFloat(this.groupApp.groupMapCenterLat)
        : 0,
      lng: this.groupApp.groupMapCenterLng
        ? parseFloat(this.groupApp.groupMapCenterLng)
        : 0,
    });
    if (Capacitor.isNativePlatform()) {
      this.mobileCheckMarker();
      this.presentInfoMarkerToast();
    }
    this.changeDetector.detectChanges();
  }

  setInfoMarkerOnCenter() {
    if (Capacitor.isNativePlatform()) {
      this.mobilePlatform = true;
    } else {
      let centerOfMap = this.map.getCenter();
      this.setInfoMarkerAndListeners(centerOfMap, true, false);
      this.mobilePlatform = false;
    }
  }

  setInfoMarkerAndListeners(position, onCenter, setMarkerMapCenter) {
    if (this.infoMarker) {
      this.infoMarker.setVisible(false);
      google.maps.event.clearListeners(this.infoMarker, "dragstart");
      google.maps.event.clearListeners(this.infoMarker, "dragend");
      this.infoMarker = null;
    }
    let positionOfMarker = position;

    if (!onCenter) {
      positionOfMarker = {
        lat: position.lat() - 0.004,
        lng: position.lng(),
      };
    }
    this.previousInfoMarkerPosition = positionOfMarker;

    console.log("position", position);
    var icon = {
      url: "assets/flatIcons/info.png", // url
      scaledSize: new google.maps.Size(70, 70), // scaled size
    };
    this.infoMarker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      icon: icon,
      position: positionOfMarker,
      map: this.map,

      draggable: true,
    });
    this.infoMarker.setVisible(true);
    if (setMarkerMapCenter) {
      this.map.set("center", positionOfMarker);
    }

    this.previousInfoMarkerPosition = positionOfMarker;
    if (this.infoMarkerInfoToast) {
      this.infoMarkerInfoToast.dismiss();
    }

    this.presentInfoMarkerToast();

    google.maps.event.addListener(this.infoMarker, "dragstart", () => {
      _.each(this.store_markers, (storeMarker) => {
        if (this.infoMarkerInfoToast) {
          this.infoMarkerInfoToast.dismiss();
        }
        if (storeMarker.infoWindowIsOpen) {
          storeMarker.infoBox.close(this.map, storeMarker.marker);
          storeMarker.infoWindowIsOpen = false;
        }
        this.deliverAreas = null;
      });

      _.each(this.fixed_points_markers, (marker) => {
        if (this.infoMarkerInfoToast) {
          this.infoMarkerInfoToast.dismiss();
        }
        if (marker.infoWindowIsOpen) {
          marker.infoBox.close(this.map, marker.marker);
          marker.infoWindowIsOpen = false;
        }
        this.deliverAreas = null;
      });
    });

    google.maps.event.addListener(this.infoMarker, "dragend", () => {
      let newPlace = this.infoMarker.getPosition();
      let marker = {
        lat: newPlace.lat(),
        lng: newPlace.lng(),
        group: this.groupApp.group,
        languageCode: this.selectedLanguage,
      };

      // console.log("moving the marker request again", newPlace);
      this.infoMarker.setDraggable(false);
      if (this.infoForMoveTheMarker) {
        this.infoForMoveTheMarker.close();
      }
      this.infoForStopMovingTheMarker = new google.maps.InfoWindow({
        content:
          "<div style='line-height: 1.35; overflow: hidden; white-space: nowrap'>" +
          "<ion-item color='white'  lines='none' style='--min-height: 30px; padding: 0; margin: 0'> <ion-spinner color='primary' slot='start' name='dots'></ion-spinner><ion-label style='padding: 0; margin: 0'>" +
          this.translateService.instant("new-address-modal.loading") +
          "</ion-label></ion-item> </div>",
      });
      this.infoForStopMovingTheMarker.open(this.map, this.infoMarker);
      this.map.setOptions({ draggable: false });
      this.resetAllFixedPointsMarkerIcons();
      //Send the marker object to the request
      this.dataStorageSevice
        .checkMarker(marker)

        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              this.deliverAreas = res.deliveryAreas;
              if (
                this.deliverAreas &&
                this.deliverAreas.length &&
                this.stores &&
                this.stores.length == 1
              ) {
                let areaWithShedule = _.find(this.deliverAreas, (area) => {
                  if (area.showSchedule) {
                    return true;
                  }
                });
                if (!areaWithShedule) {
                  this.deliverAreas = _.cloneDeep([
                    _.minBy(this.deliverAreas, (area) => {
                      return area.minOrder;
                    }),
                  ]);
                }
              } else if (
                this.deliverAreas &&
                this.deliverAreas.length &&
                this.stores &&
                this.stores.length > 1
              ) {
                let areaWithShedule = _.find(this.deliverAreas, (area) => {
                  if (area.showSchedule) {
                    return true;
                  }
                });

                if (!areaWithShedule) {
                  let tempAreas = [];
                  _.each(this.stores, (store) => {
                    tempAreas.push(
                      _.cloneDeep(
                        _.minBy(this.deliverAreas, (area) => {
                          if (store.store_id === area.store_id) {
                            return area.minOrder;
                          }
                        })
                      )
                    );
                  });

                  if (tempAreas && tempAreas.length > 0) {
                    tempAreas = _.cloneDeep(
                      tempAreas.filter((element) => {
                        return element !== undefined;
                      })
                    );
                    this.deliverAreas = tempAreas;
                    //console.log("tempsareas", this.deliverAreas);
                  }
                }
              }

              //   console.log("delivery areas", this.deliverAreas);
            } else {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),

                buttons: ["OK"],
              });
              await alert.present();
            }
            // console.log("res", res);
            if (this.infoForStopMovingTheMarker) {
              this.infoForStopMovingTheMarker.close();
            }
            this.infoMarker.setDraggable(true);
            this.map.setOptions({ draggable: true });
            this.changeDetector.detectChanges();
          },
          error: async (err) => {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    });
    if (this.infoMarker && this.infoMarker.getPosition()) {
      let data = {
        lat: this.infoMarker.getPosition().lat(),
        lng: this.infoMarker.getPosition().lng(),
        group: this.groupApp.group,
        languageCode: this.selectedLanguage,
      };
      this.infoMarker.setDraggable(false);
      this.dataStorageSevice
        .checkMarker(data)

        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              this.deliverAreas = res.deliveryAreas;
              if (
                this.deliverAreas &&
                this.deliverAreas.length &&
                this.stores &&
                this.stores.length == 1
              ) {
                let areaWithShedule = _.find(this.deliverAreas, (area) => {
                  if (area.showSchedule) {
                    return true;
                  }
                });
                if (!areaWithShedule) {
                  this.deliverAreas = _.cloneDeep([
                    _.minBy(this.deliverAreas, (area) => {
                      return area.minOrder;
                    }),
                  ]);
                }
              } else if (
                this.deliverAreas &&
                this.deliverAreas.length &&
                this.stores &&
                this.stores.length > 1
              ) {
                let areaWithShedule = _.find(this.deliverAreas, (area) => {
                  if (area.showSchedule) {
                    return true;
                  }
                });
                if (!areaWithShedule) {
                  let tempAreas = [];
                  _.each(this.stores, (store) => {
                    tempAreas.push(
                      _.cloneDeep(
                        _.minBy(this.deliverAreas, (area) => {
                          if (
                            area &&
                            store &&
                            store.store_id === area.store_id
                          ) {
                            return area.minOrder;
                          }
                        })
                      )
                    );
                  });

                  if (tempAreas && tempAreas.length > 0) {
                    tempAreas = _.cloneDeep(
                      tempAreas.filter((element) => {
                        return element !== undefined;
                      })
                    );
                    this.deliverAreas = tempAreas;
                    //  console.log("tempsareas", this.deliverAreas);
                  }
                }
              }
            } else {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),

                buttons: ["OK"],
              });
              alert.present();
            }
            //console.log("res", res);
            if (this.infoForStopMovingTheMarker) {
              this.infoForStopMovingTheMarker.close();
            }
            this.infoMarker.setDraggable(true);
            this.map.setOptions({ draggable: true });
            this.changeDetector.detectChanges();
          },
          error: async (err) => {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    }

    this.changeDetector.detectChanges();
  }

  async openDeliveryAreaInfoModal(area) {
    this.deliveryAreasInfoModal = await this.modalController.create({
      component: DeliveryAreasInfoModalComponent,
      cssClass: "openDeliveryAreaInfoModal",
      backdropDismiss: false,
      componentProps: {
        currentDeliveryArea: area,
      },
    });
    await this.deliveryAreasInfoModal.present();
    this.deliveryAreasInfoModal.onDidDismiss().then(() => {
      this.deliveryAreasInfoModal = null;
    });
  }

  onFixedAddressMarkerClick(marker) {
    this.checkMarkerFixedAddressPointDebounce(marker);

    this.changeDetector.detectChanges();
  }

  onStoreMarkerClick(marker) {
    if (!marker.infoBox.isOpened) {
      marker.infoBox.open(this.map, marker.marker);
      marker.infoWindowIsOpen = true;
    }
    if (this.mobilePlatform) {
      //TO_DO CHECK ON STORE CLICK MOBILE
    } else {
      this.setInfoMarkerAndListeners(marker.marker.getPosition(), false, false);
    }

    this.changeDetector.detectChanges();
  }

  private checkMarkerFixedAddressPointDebounce = _.debounce(
    (fixedPointMarker) => {
      if (this.infoMarkerInfoToast) {
        this.infoMarkerInfoToast.dismiss();
      }
      this.checkMarkerLoading = true;

      let marker = {
        lat: fixedPointMarker.userLat,
        lng: fixedPointMarker.userLng,
        group: this.groupApp.group,
        languageCode: this.selectedLanguage,
      };

      this.dataStorageSevice
        .checkMarker(marker)

        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              this.deliverAreas = res.deliveryAreas;
              if (
                this.deliverAreas &&
                this.deliverAreas.length &&
                this.stores &&
                this.stores.length == 1
              ) {
                let areaWithShedule = _.find(this.deliverAreas, (area) => {
                  if (area.showSchedule) {
                    return true;
                  }
                });
                if (!areaWithShedule) {
                  this.deliverAreas = _.cloneDeep([
                    _.minBy(this.deliverAreas, (area) => {
                      return area.minOrder;
                    }),
                  ]);
                }
              } else if (
                this.deliverAreas &&
                this.deliverAreas.length &&
                this.stores &&
                this.stores.length > 1
              ) {
                let areaWithShedule = _.find(this.deliverAreas, (area) => {
                  if (area.showSchedule) {
                    return true;
                  }
                });
                // console.log("area with schedule", areaWithShedule);
                if (!areaWithShedule) {
                  let tempAreas = [];
                  _.each(this.stores, (store) => {
                    tempAreas.push(
                      _.cloneDeep(
                        _.minBy(this.deliverAreas, (area) => {
                          if (store.store_id === area.store_id) {
                            return area.minOrder;
                          }
                        })
                      )
                    );
                  });
                  // console.log("area with schedule temp areas", tempAreas);

                  if (tempAreas && tempAreas.length > 0) {
                    tempAreas = _.cloneDeep(
                      tempAreas.filter((element) => {
                        return element !== undefined;
                      })
                    );
                    this.deliverAreas = tempAreas;
                    //  console.log("tempsareas", this.deliverAreas);
                  }
                }
              }
              if (
                this.groupApp &&
                this.groupApp.fixedPointsAddressSystemActive &&
                fixedPointMarker
              ) {
                this.calculateInfoMessagesForFixedPoints(fixedPointMarker);
              }
              this.checkMarkerLoading = false;
            } else {
              this.checkMarkerLoading = false;

              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),

                buttons: ["OK"],
              });
              alert.present();
            }
            console.log("res", res);

            this.changeDetector.detectChanges();
          },
          error: async (err) => {
            this.map.setOptions({ draggable: true });
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
      this.changeDetector.detectChanges();
    },
    500
  );

  private mobileCheckMarkerDebounce = _.debounce(() => {
    if (this.infoMarkerInfoToast) {
      this.infoMarkerInfoToast.dismiss();
    }
    this.checkMarkerLoading = true;
    let centerOfMap = this.map.getCenter();
    let marker = {
      lat: centerOfMap.lat(),
      lng: centerOfMap.lng(),
      group: this.groupApp.group,
      languageCode: this.selectedLanguage,
    };

    this.resetAllFixedPointsMarkerIcons();

    this.dataStorageSevice
      .checkMarker(marker)

      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            this.deliverAreas = res.deliveryAreas;
            if (
              this.deliverAreas &&
              this.deliverAreas.length &&
              this.stores &&
              this.stores.length == 1
            ) {
              let areaWithShedule = _.find(this.deliverAreas, (area) => {
                if (area.showSchedule) {
                  return true;
                }
              });
              if (!areaWithShedule) {
                this.deliverAreas = _.cloneDeep([
                  _.minBy(this.deliverAreas, (area) => {
                    return area.minOrder;
                  }),
                ]);
              }
            } else if (
              this.deliverAreas &&
              this.deliverAreas.length &&
              this.stores &&
              this.stores.length > 1
            ) {
              let areaWithShedule = _.find(this.deliverAreas, (area) => {
                if (area.showSchedule) {
                  return true;
                }
              });
              // console.log("area with schedule", areaWithShedule);
              if (!areaWithShedule) {
                let tempAreas = [];
                _.each(this.stores, (store) => {
                  tempAreas.push(
                    _.cloneDeep(
                      _.minBy(this.deliverAreas, (area) => {
                        if (store.store_id === area.store_id) {
                          return area.minOrder;
                        }
                      })
                    )
                  );
                });
                // console.log("area with schedule temp areas", tempAreas);

                if (tempAreas && tempAreas.length > 0) {
                  tempAreas = _.cloneDeep(
                    tempAreas.filter((element) => {
                      return element !== undefined;
                    })
                  );
                  this.deliverAreas = tempAreas;
                  //  console.log("tempsareas", this.deliverAreas);
                }
              }
            }

            this.checkMarkerLoading = false;
          } else {
            this.checkMarkerLoading = false;
            this.map.setOptions({ draggable: true });
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),

              buttons: ["OK"],
            });
            alert.present();
          }
          console.log("res", res);

          this.changeDetector.detectChanges();
        },
        error: async (err) => {
          this.map.setOptions({ draggable: true });
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
    this.changeDetector.detectChanges();
  }, 1200);

  mobileCheckMarker() {
    this.mobileCheckMarkerDebounce();
  }

  deliveryAreasInitialization(map) {
    _.each(this.stores, (store, key, list) => {
      if (_.isEmpty(store.deliveryAreas) && store.boundaryCoordinates1) {
        console.log("Creating the group's delivery areas...");
        console.log("How many stores the group has: ", this.stores.length);
        //Polygon coordinates
        var boundary = store.boundaryCoordinates1;
        var boundaryData = [];
        var latlngMap = boundary.split(",");
        var lat = [];
        var lng = [];
        for (var i = 0; i < latlngMap.length; i++) {
          if (i % 2 === 0) {
            lat.push(latlngMap[i]);
          } else {
            lng.push(latlngMap[i]);
          }
        }
        for (i = 0; i < latlngMap.length / 2; i++) {
          boundaryData.push(new google.maps.LatLng(lat[i], lng[i]));
        }

        new google.maps.Polygon({
          paths: boundaryData,
          strokeColor: "#0000FF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FFCC00", //YEllOW
          fillOpacity: 0.3,
          zIndex: 10,
          map: map,
        });
      } else {
        console.log("The store has the new deliveryAreas", store.deliveryAreas);
        _.each(store.deliveryAreas, (area, k, l) => {
          if (!area.active) {
            return;
          }

          var boundary = area.boundaries;
          var boundaryData = [];
          var latlngMap = boundary.split(",");
          var lat = [];
          var lng = [];
          for (var i = 0; i < latlngMap.length; i++) {
            if (i % 2 === 0) {
              lat.push(parseFloat(latlngMap[i]));
            } else {
              lng.push(parseFloat(latlngMap[i]));
            }
          }
          for (i = 0; i < latlngMap.length / 2; i++) {
            boundaryData.push(new google.maps.LatLng(lat[i], lng[i]));
          }

          let polygon = new google.maps.Polygon({
            paths: boundaryData,
            strokeColor: area.strokeColor ? area.strokeColor : "#0000FF",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: area.fillColor ? area.fillColor : "#FFCC00",
            fillOpacity: area.fillOpacity ? parseFloat(area.fillOpacity) : 0.3,
            zIndex: 10,
            map: map,
          });

          google.maps.event.addListener(polygon, "click", (event) => {
            //  console.log("polygon clicked", event.latLng);
            if (
              !this.infoMarker &&
              this.map &&
              this.map &&
              this.map.getZoom() >= 12
            ) {
              if (Capacitor.isNativePlatform()) {
                //TO_DO CHECK FRO MOBILE
              } else {
                this.setInfoMarkerAndListeners(event.latLng, true, false);
              }
            }
          });
        });
      }
    });

    this.changeDetector.detectChanges();
  }

  createFixedPointAreaInfo(area) {
    let info = "";

    info =
      "<h6 style='padding:0;margin:2px;'>" +
      this.translateService.instant("delivery-areas.fixed-point-address") +
      "</h6>";

    if (area.store) {
      info = info + "<p style='padding:0;margin:0px'>" + area.store + "</p>";
    }

    if (area.fixedAddressName) {
      info =
        info +
        "<div style='padding:0;margin:0px'>" +
        area.fixedAddressName +
        "</div>";
    }

    //TODO DELETE THIS INFO IF IS NOT USED
    // if (area.minOrder) {
    //   info =
    //     info +
    //     "<p style='padding:0;margin:0px'>" +
    //     this.translateService.instant("delivery-areas.minimum-order") +
    //     ": " +
    //     area.minOrder +
    //     "€" +
    //     "</p>";
    // }

    // if (
    //   area.deliveryCost &&
    //   area.deliveryCost !== "0" &&
    //   !area.delivery_cost_per_kilometer_active
    // ) {
    //   info =
    //     info +
    //     "<p style='padding:0;margin:0px'>" +
    //     this.translateService.instant("delivery-areas.minimum-order") +
    //     ": " +
    //     area.deliveryCost +
    //     "€" +
    //     "</p>";
    // }

    // if (
    //   area.deliveryCost &&
    //   area.deliveryCost !== "0" &&
    //   area.delivery_cost_per_kilometer_active
    // ) {
    //   info =
    //     info +
    //     "<p style='padding:0;margin:0px'>" +
    //     this.translateService.instant("delivery-areas.cost-for-delivery") +
    //     ": " +
    //     area.deliveryCost +
    //     "€ " +
    //     this.translateService.instant("delivery-areas.per") +
    //     " km" +
    //     "</p>";
    // }

    // if (area && area.free_delivery_over_amount) {
    //   info =
    //     info +
    //     "<p style='padding:0;margin:0px'>" +
    //     this.translateService.instant("delivery-areas.free-delivery-over") +
    //     ": " +
    //     parseFloat(area.free_delivery_over_amount.toString()).toFixed(2) +
    //     "€" +
    //     "</p>";
    // }

    // if (area && area.scheduleComment && area.showSchedule) {
    //   info =
    //     info +
    //     "<div style='padding:0;margin:0px'>" +
    //     area.scheduleComment +
    //     "</div>";
    // }

    // if (area && area.comments) {
    //   info =
    //     info + "<div style='padding:0;margin:0px'>" + area.comments + "</div>";
    // }

    return info;
  }

  private checkMapZoomPresentInfoDebounced = _.debounce(() => {
    this.checkMapZoomPresentInfo();
  }, 300);

  checkMapZoomPresentInfo() {
    console.log("zoom", this.map.getZoom());
    if (this.map.getZoom() < 12 && this.infoMarker) {
      this.infoMarker.setVisible(false);
      google.maps.event.clearListeners(this.infoMarker, "dragstart");
      google.maps.event.clearListeners(this.infoMarker, "dragend");
      this.infoMarker = null;
      if (this.infoMarkerInfoToast) {
        this.infoMarkerInfoToast.dismiss();
      }

      this.deliverAreas = null;
    } else if (this.map.getZoom() >= 12 && !this.infoMarker) {
      if (this.previousInfoMarkerPosition) {
        console.log("previous position", this.previousInfoMarkerPosition);
        this.setInfoMarkerAndListeners(
          this.previousInfoMarkerPosition,
          true,
          false
        );
        setTimeout(() => {
          this.changeDetector.detectChanges();
        }, 1000);
      }
    }
    if (this.map.getZoom() >= 14) {
      //disapear info boxes

      _.each(this.store_markers, (storeMarker) => {
        if (!storeMarker.infoWindowIsOpen) {
          storeMarker.infoBox.open(this.map, storeMarker.marker);
          storeMarker.infoWindowIsOpen = true;
        }
      });
    } else if (this.map.getZoom() < 14) {
      _.each(this.store_markers, (storeMarker) => {
        if (storeMarker.infoWindowIsOpen) {
          storeMarker.infoBox.close(this.map, storeMarker.marker);
          storeMarker.infoWindowIsOpen = false;
        }
      });
    }

    this.changeDetector.detectChanges();
  }

  // async presentLoading() {
  //   let message = this.translateService.instant("loading-please-wait");
  //   this.ionLoading = await this.loadingController.create({
  //     duration: 12000,
  //     message: message,
  //     cssClass: "ionicLoadingCss",
  //     keyboardClose: true,
  //   });
  //   this.ionLoading.present();
  //   this.ionLoading.onDidDismiss().then(() => {
  //     this.ionLoading = null;
  //   });

  //   console.log("Loading dismissed!");
  // }

  resetAllFixedPointsMarkerIcons() {
    _.each(this.fixed_points_markers, (marker) => {
      marker.marker.setIcon("assets/ionicons/marker-blue.png");
      marker.infoBox.close(this.map, marker.marker);
      marker.infoWindowIsOpen = true;
    });
  }

  calculateInfoMessagesForFixedPoints(marker) {
    const indexOfMarker = _.findIndex(this.fixed_points_markers, {
      fixed_address_id: marker.fixed_address_id,
    });

    if (indexOfMarker !== -1) {
      const area = _.find(this.deliverAreas, {
        fixed_address_id: marker.fixed_address_id,
      });

      this.resetAllFixedPointsMarkerIcons();

      if (area) {
        if (marker && marker.marker) {
          marker.marker.setIcon("assets/ionicons/marker-red.png");
        }

        marker.infoBox = new google.maps.InfoWindow({
          content: this.createFixedPointAreaInfo(area),
          disableAutoPan: true,
        });

        if (!marker.infoBox.isOpened) {
          marker.infoBox.open(this.map, marker.marker);
          marker.infoWindowIsOpen = true;
          this.map.setCenter({
            lat: marker.userLat ? parseFloat(marker.userLat) + 0.0001 : 0,
            lng: marker.userLng ? parseFloat(marker.userLng) : 0,
          });
          this.map.setZoom(20);
          this.showInfoFixedPointsAddresses = false;
        }
      }
      this.changeDetector.detectChanges();
    }
  }

  async presentGeolocationLoading() {
    this.geoLocationAnimation = true;
    // let message = this.translateService.instant("loading-please-wait");
    // this.ionLoading = await this.loadingController.create({
    //   duration: 12000,
    //   message: message,
    //   cssClass: "ionicLoadingCss",
    //   keyboardClose: true,
    // });
    // this.ionLoading.present();
    // this.ionLoading.onDidDismiss().then(() => {
    //   this.ionLoading = null;
    // });

    //console.log("Loading dismissed!");
  }

  checkLocationFromGps() {
    this.presentGeolocationLoading();
    Geolocation.getCurrentPosition({
      timeout: 12000,
      enableHighAccuracy: true,
    })
      .then((resp) => {
        //"inside then with this data", resp);
        if (resp.coords && resp.coords.latitude && resp.coords.longitude) {
          let position = new google.maps.LatLng({
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          });
          if (Capacitor.isNativePlatform()) {
            this.map.setCenter(position);
            setTimeout(() => {
              this.mobileCheckMarker();
              this.map.setZoom(19);
              if (this.geoLocationAnimation) {
                this.geoLocationAnimation = false;
              }
            }, 1500);
          } else {
            if (!this.infoMarker) {
              this.setInfoMarkerAndListeners(position, true, true);
            }
          }
        } else {
          if (this.geoLocationAnimation) {
            this.geoLocationAnimation = false;
          }
          //cant getting coords
        }
      })
      .catch(async (err) => {
        setTimeout(() => {
          if (this.geoLocationAnimation) {
            this.geoLocationAnimation = false;
          }
        }, 500);
        console.log("errr", err);
        if (
          err &&
          err.message &&
          (err.message === "Timeout expired" ||
            err.message === "User denied Geolocation") &&
          !this.geoErrMessage
        ) {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.please-enable-gps-in-order-to-get-accurate-results"
          );
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: ["OK"],
          });

          await alert.present();
          alert.onDidDismiss().then(() => {
            this.geoErrMessage = null;
          });
        } else if (
          err &&
          err.message &&
          err.message === "Illegal Access" &&
          !this.geoErrMessage
        ) {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.you-can-enable-gps-from-app-setting-in-order-to-find-your-location"
          );
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: ["OK"],
          });

          await alert.present();
          alert.onDidDismiss().then(() => {
            this.geoErrMessage = null;
          });
        } else {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.we-cant-find-you-location-please-enable-gps-and-grant-permissions"
          );
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: ["OK"],
          });

          await alert.present();
          alert.onDidDismiss().then(() => {
            this.geoErrMessage = null;
          });
        }
      });
  }

  trackDeliveryAreas(index, item) {
    return index;
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.infoMarkerInfoToast) {
      this.infoMarkerInfoToast.dismiss();
      this.infoMarkerInfoToast = null;
    }

    if (this.deliveryAreasInfoModal) {
      this.deliveryAreasInfoModal.dismiss();
      this.deliveryAreasInfoModal = null;
    }
    if (this.map) {
      if (Capacitor.isNativePlatform()) {
        google.maps.event.clearListeners(this.map, "dragstart");
        google.maps.event.clearListeners(this.map, "dragend");
      }

      google.maps.event.clearListeners(this.map, "zoom_changed");
      google.maps.event.clearListeners(this.map, "zoom_changed");
      this.map = null;
    }
    if (this.store_markers && this.store_markers.length) {
      _.each(this.store_markers, (marker) => {
        google.maps.event.clearListeners(marker.marker, "click");
      });
    }
    if (this.fixed_points_markers && this.fixed_points_markers.length) {
      _.each(this.fixed_points_markers, (marker) => {
        google.maps.event.clearListeners(marker.marker, "click");
      });
    }
    if (this.infoMarker) {
      google.maps.event.clearListeners(this.infoMarker, "dragstart");
      google.maps.event.clearListeners(this.infoMarker, "dragend");
      this.infoMarker = null;
    }
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
