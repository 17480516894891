<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "popups.billing-info.billingInfo" | translate
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" color="dark" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-item
      lines="none"
      *ngIf="
        billing_order_formatted_address.formatted_address &&
        !billing_order_formatted_address.fixed_address
      "
    >
      <ion-checkbox
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="billing_order_formatted_address.same_as_billing_address"
        (ionChange)="changeSameAsBillingAddress()"
        slot="start"
      ></ion-checkbox>
      <ion-label
        class="ion-text-wrap"
        style="font-size: small; line-height: 1.2"
      >
        {{ "popups.billing-info.same_billing_address" | translate }}
      </ion-label>
    </ion-item>

    <form [formGroup]="billingInfoForm">
      <ion-item
        *ngIf="
          billingInfo &&
          (billingInfo.gateway_id === 'piraeus' ||
            billingInfo.CardHolderName ||
            billingInfo.gateway_id === 'nexi')
        "
      >
        <ion-label class="ion-text-wrap" position="stacked">
          <h6>{{ "popups.billing-info.card-holder-name" | translate }}</h6>
        </ion-label>
        <ion-input
          placeholder="{{ 'write-here' | translate }}"
          type="text"
          formControlName="CardHolderName"
        >
        </ion-input>

        <ion-text
          color="danger"
          style="font-size: x-small"
          *ngIf="
            billingInfoForm &&
            (billingInfoForm.get('CardHolderName').touched ||
              billingInfoForm.get('CardHolderName').dirty) &&
            billingInfoForm
              .get('CardHolderName')
              .hasError('CardHolderNameRequired')
          "
        >
          <p>
            {{
              "popups.billing-info.CardHolderName-field-required" | translate
            }}
          </p>
        </ion-text>
        <ion-text
          color="danger"
          style="font-size: x-small"
          *ngIf="
            billingInfoForm &&
            (billingInfoForm.get('CardHolderName').touched ||
              billingInfoForm.get('CardHolderName').dirty) &&
            billingInfoForm.get('CardHolderName').errors?.pattern
          "
        >
          <p>
            {{ "popups.billing-info.only-latin" | translate }}
          </p>
        </ion-text>
      </ion-item>

      <ion-item
        *ngIf="
          !billing_order_formatted_address ||
          !billing_order_formatted_address.same_as_billing_address
        "
      >
        <ion-label class="ion-text-wrap" position="floating">
          <h6>{{ "popups.billing-info.address" | translate }}</h6>
        </ion-label>
        <ion-input type="text" formControlName="billAddress"> </ion-input>

        <ion-text
          color="danger"
          style="font-size: x-small"
          *ngIf="
            billingInfoForm &&
            (billingInfoForm.get('billAddress').touched ||
              billingInfoForm.get('billAddress').dirty) &&
            billingInfoForm.get('billAddress').hasError('billAddressRequired')
          "
        >
          <p>
            {{ "popups.billing-info.billAddress-field-required" | translate }}
          </p>
        </ion-text>
      </ion-item>
      <ion-item
        *ngIf="
          !billing_order_formatted_address ||
          !billing_order_formatted_address.same_as_billing_address
        "
      >
        <ion-label class="ion-text-wrap" position="floating">
          <h6>{{ "popups.billing-info.address-number" | translate }}</h6>
        </ion-label>
        <ion-input type="text" formControlName="billAddressNumber"> </ion-input>

        <ion-text
          color="danger"
          style="font-size: x-small"
          *ngIf="
            billingInfoForm &&
            (billingInfoForm.get('billAddressNumber').touched ||
              billingInfoForm.get('billAddressNumber').dirty) &&
            billingInfoForm
              .get('billAddressNumber')
              .hasError('billAddressNumberRequired')
          "
        >
          <p>
            {{
              "popups.billing-info.billAddressNumber-field-required" | translate
            }}
          </p>
        </ion-text>
      </ion-item>
      <ion-item
        *ngIf="
          !billing_order_formatted_address ||
          !billing_order_formatted_address.same_as_billing_address
        "
      >
        <ion-label class="ion-text-wrap" position="floating">
          <h6>{{ "popups.billing-info.zip" | translate }}</h6>
        </ion-label>
        <ion-input type="text" formControlName="billZip"> </ion-input>

        <ion-text
          color="danger"
          *ngIf="
            billingInfoForm &&
            (billingInfoForm.get('billZip').touched ||
              billingInfoForm.get('billZip').dirty) &&
            billingInfoForm.get('billZip').hasError('billZipRequired')
          "
          style="font-size: x-small"
        >
          <p>
            {{ "popups.billing-info.zip-field-required" | translate }}
          </p>
        </ion-text>
      </ion-item>

      <ion-item
        *ngIf="
          !billing_order_formatted_address ||
          !billing_order_formatted_address.same_as_billing_address
        "
      >
        <ion-label class="ion-text-wrap" position="floating">
          <h6>{{ "popups.billing-info.country" | translate }}</h6>
        </ion-label>
        <ion-select
          cancelText="{{ 'popups.billing-info.cancel' | translate }}"
          formControlName="billCountry"
        >
          <ion-select-option value="Afghanistan">Afghanistan</ion-select-option>
          <ion-select-option value="Åland Islands"
            >Åland Islands</ion-select-option
          >
          <ion-select-option value="Albania">Albania</ion-select-option>
          <ion-select-option value="Algeria">Algeria</ion-select-option>
          <ion-select-option value="American Samoa"
            >American Samoa</ion-select-option
          >
          <ion-select-option value="Andorra">Andorra</ion-select-option>
          <ion-select-option value="Angola">Angola</ion-select-option>
          <ion-select-option value="Anguilla">Anguilla</ion-select-option>
          <ion-select-option value="Antarctica">Antarctica</ion-select-option>
          <ion-select-option value="Antigua and Barbuda"
            >Antigua and Barbuda</ion-select-option
          >
          <ion-select-option value="Argentina">Argentina</ion-select-option>
          <ion-select-option value="Armenia">Armenia</ion-select-option>
          <ion-select-option value="Aruba">Aruba</ion-select-option>
          <ion-select-option value="Australia">Australia</ion-select-option>
          <ion-select-option value="Austria">Austria</ion-select-option>
          <ion-select-option value="Azerbaijan">Azerbaijan</ion-select-option>
          <ion-select-option value="Bahamas">Bahamas</ion-select-option>
          <ion-select-option value="Bahrain">Bahrain</ion-select-option>
          <ion-select-option value="Bangladesh">Bangladesh</ion-select-option>
          <ion-select-option value="Barbados">Barbados</ion-select-option>
          <ion-select-option value="Belarus">Belarus</ion-select-option>
          <ion-select-option value="Belgium">Belgium</ion-select-option>
          <ion-select-option value="Belize">Belize</ion-select-option>
          <ion-select-option value="Benin">Benin</ion-select-option>
          <ion-select-option value="Bermuda">Bermuda</ion-select-option>
          <ion-select-option value="Bhutan">Bhutan</ion-select-option>
          <ion-select-option value="Bolivia">Bolivia</ion-select-option>
          <ion-select-option value="Bosnia and Herzegovina"
            >Bosnia and Herzegovina</ion-select-option
          >
          <ion-select-option value="Botswana">Botswana</ion-select-option>
          <ion-select-option value="Bouvet Island"
            >Bouvet Island</ion-select-option
          >
          <ion-select-option value="Brazil">Brazil</ion-select-option>
          <ion-select-option value="British Indian Ocean Territory">
            British Indian Ocean Territory
          </ion-select-option>
          <ion-select-option value="Brunei Darussalam"
            >Brunei Darussalam</ion-select-option
          >
          <ion-select-option value="Bulgaria">Bulgaria</ion-select-option>
          <ion-select-option value="Burkina Faso"
            >Burkina Faso</ion-select-option
          >
          <ion-select-option value="Burundi">Burundi</ion-select-option>
          <ion-select-option value="Cambodia">Cambodia</ion-select-option>
          <ion-select-option value="Cameroon">Cameroon</ion-select-option>
          <ion-select-option value="Canada">Canada</ion-select-option>
          <ion-select-option value="Cape Verde">Cape Verde</ion-select-option>
          <ion-select-option value="Cayman Islands"
            >Cayman Islands</ion-select-option
          >
          <ion-select-option value="Central African Republic">
            Central African Republic
          </ion-select-option>
          <ion-select-option value="Chad">Chad</ion-select-option>
          <ion-select-option value="Chile">Chile</ion-select-option>
          <ion-select-option value="China">China</ion-select-option>
          <ion-select-option value="Christmas Island"
            >Christmas Island</ion-select-option
          >
          <ion-select-option value="Cocos (Keeling) Islands">
            Cocos (Keeling) Islands
          </ion-select-option>
          <ion-select-option value="Colombia">Colombia</ion-select-option>
          <ion-select-option value="Comoros">Comoros</ion-select-option>
          <ion-select-option value="Congo">Congo</ion-select-option>
          <ion-select-option value="Congo, The Democratic Republic of The">
            Congo, The Democratic Republic of The
          </ion-select-option>
          <ion-select-option value="Cook Islands"
            >Cook Islands</ion-select-option
          >
          <ion-select-option value="Costa Rica">Costa Rica</ion-select-option>
          <ion-select-option value="Cote D'ivoire"
            >Cote D'ivoire</ion-select-option
          >
          <ion-select-option value="Croatia">Croatia</ion-select-option>
          <ion-select-option value="Cuba">Cuba</ion-select-option>
          <ion-select-option value="Cyprus">Cyprus</ion-select-option>
          <ion-select-option value="Czech Republic"
            >Czech Republic</ion-select-option
          >
          <ion-select-option value="Denmark">Denmark</ion-select-option>
          <ion-select-option value="Djibouti">Djibouti</ion-select-option>
          <ion-select-option value="Dominica">Dominica</ion-select-option>
          <ion-select-option value="Dominican Republic"
            >Dominican Republic</ion-select-option
          >
          <ion-select-option value="Ecuador">Ecuador</ion-select-option>
          <ion-select-option value="Egypt">Egypt</ion-select-option>
          <ion-select-option value="El Salvador">El Salvador</ion-select-option>
          <ion-select-option value="Equatorial Guinea"
            >Equatorial Guinea</ion-select-option
          >
          <ion-select-option value="Eritrea">Eritrea</ion-select-option>
          <ion-select-option value="Estonia">Estonia</ion-select-option>
          <ion-select-option value="Ethiopia">Ethiopia</ion-select-option>
          <ion-select-option value="Falkland Islands (Malvinas)">
            Falkland Islands (Malvinas)
          </ion-select-option>
          <ion-select-option value="Faroe Islands"
            >Faroe Islands</ion-select-option
          >
          <ion-select-option value="Fiji">Fiji</ion-select-option>
          <ion-select-option value="Finland">Finland</ion-select-option>
          <ion-select-option value="France">France</ion-select-option>
          <ion-select-option value="French Guiana"
            >French Guiana</ion-select-option
          >
          <ion-select-option value="French Polynesia"
            >French Polynesia</ion-select-option
          >
          <ion-select-option value="French Southern Territories">
            French Southern Territories
          </ion-select-option>
          <ion-select-option value="Gabon">Gabon</ion-select-option>
          <ion-select-option value="Gambia">Gambia</ion-select-option>
          <ion-select-option value="Georgia">Georgia</ion-select-option>
          <ion-select-option value="Germany">Germany</ion-select-option>
          <ion-select-option value="Ghana">Ghana</ion-select-option>
          <ion-select-option value="Gibraltar">Gibraltar</ion-select-option>
          <ion-select-option value="Greece">Greece</ion-select-option>
          <ion-select-option value="Greenland">Greenland</ion-select-option>
          <ion-select-option value="Grenada">Grenada</ion-select-option>
          <ion-select-option value="Guadeloupe">Guadeloupe</ion-select-option>
          <ion-select-option value="Guam">Guam</ion-select-option>
          <ion-select-option value="Guatemala">Guatemala</ion-select-option>
          <ion-select-option value="Guernsey">Guernsey</ion-select-option>
          <ion-select-option value="Guinea">Guinea</ion-select-option>
          <ion-select-option value="Guinea-bissau"
            >Guinea-bissau</ion-select-option
          >
          <ion-select-option value="Guyana">Guyana</ion-select-option>
          <ion-select-option value="Haiti">Haiti</ion-select-option>
          <ion-select-option value="Heard Island and Mcdonald Islands">
            Heard Island and Mcdonald Islands
          </ion-select-option>
          <ion-select-option value="Holy See (Vatican City State)">
            Holy See (Vatican City State)
          </ion-select-option>
          <ion-select-option value="Honduras">Honduras</ion-select-option>
          <ion-select-option value="Hong Kong">Hong Kong</ion-select-option>
          <ion-select-option value="Hungary">Hungary</ion-select-option>
          <ion-select-option value="Iceland">Iceland</ion-select-option>
          <ion-select-option value="India">India</ion-select-option>
          <ion-select-option value="Indonesia">Indonesia</ion-select-option>
          <ion-select-option value="Iran, Islamic Republic of">
            Iran, Islamic Republic of
          </ion-select-option>
          <ion-select-option value="Iraq">Iraq</ion-select-option>
          <ion-select-option value="Ireland">Ireland</ion-select-option>
          <ion-select-option value="Isle of Man">Isle of Man</ion-select-option>
          <ion-select-option value="Israel">Israel</ion-select-option>
          <ion-select-option value="Italy">Italy</ion-select-option>
          <ion-select-option value="Jamaica">Jamaica</ion-select-option>
          <ion-select-option value="Japan">Japan</ion-select-option>
          <ion-select-option value="Jersey">Jersey</ion-select-option>
          <ion-select-option value="Jordan">Jordan</ion-select-option>
          <ion-select-option value="Kazakhstan">Kazakhstan</ion-select-option>
          <ion-select-option value="Kenya">Kenya</ion-select-option>
          <ion-select-option value="Kiribati">Kiribati</ion-select-option>
          <ion-select-option value="Korea, Democratic People's Republic of">
            Korea, Democratic People's Republic of
          </ion-select-option>
          <ion-select-option value="Korea, Republic of"
            >Korea, Republic of</ion-select-option
          >
          <ion-select-option value="Kuwait">Kuwait</ion-select-option>
          <ion-select-option value="Kyrgyzstan">Kyrgyzstan</ion-select-option>
          <ion-select-option value="Lao People's Democratic Republic">
            Lao People's Democratic Republic
          </ion-select-option>
          <ion-select-option value="Latvia">Latvia</ion-select-option>
          <ion-select-option value="Lebanon">Lebanon</ion-select-option>
          <ion-select-option value="Lesotho">Lesotho</ion-select-option>
          <ion-select-option value="Liberia">Liberia</ion-select-option>
          <ion-select-option value="Libyan Arab Jamahiriya"
            >Libyan Arab Jamahiriya</ion-select-option
          >
          <ion-select-option value="Liechtenstein"
            >Liechtenstein</ion-select-option
          >
          <ion-select-option value="Lithuania">Lithuania</ion-select-option>
          <ion-select-option value="Luxembourg">Luxembourg</ion-select-option>
          <ion-select-option value="Macao">Macao</ion-select-option>
          <ion-select-option value="North Macedonia"
            >North Macedonia</ion-select-option
          >
          <ion-select-option value="Madagascar">Madagascar</ion-select-option>
          <ion-select-option value="Malawi">Malawi</ion-select-option>
          <ion-select-option value="Malaysia">Malaysia</ion-select-option>
          <ion-select-option value="Maldives">Maldives</ion-select-option>
          <ion-select-option value="Mali">Mali</ion-select-option>
          <ion-select-option value="Malta">Malta</ion-select-option>
          <ion-select-option value="Marshall Islands"
            >Marshall Islands</ion-select-option
          >
          <ion-select-option value="Martinique">Martinique</ion-select-option>
          <ion-select-option value="Mauritania">Mauritania</ion-select-option>
          <ion-select-option value="Mauritius">Mauritius</ion-select-option>
          <ion-select-option value="Mayotte">Mayotte</ion-select-option>
          <ion-select-option value="Mexico">Mexico</ion-select-option>
          <ion-select-option value="Micronesia, Federated States of">
            Micronesia, Federated States of
          </ion-select-option>
          <ion-select-option value="Moldova, Republic of"
            >Moldova, Republic of</ion-select-option
          >
          <ion-select-option value="Monaco">Monaco</ion-select-option>
          <ion-select-option value="Mongolia">Mongolia</ion-select-option>
          <ion-select-option value="Montenegro">Montenegro</ion-select-option>
          <ion-select-option value="Montserrat">Montserrat</ion-select-option>
          <ion-select-option value="Morocco">Morocco</ion-select-option>
          <ion-select-option value="Mozambique">Mozambique</ion-select-option>
          <ion-select-option value="Myanmar">Myanmar</ion-select-option>
          <ion-select-option value="Namibia">Namibia</ion-select-option>
          <ion-select-option value="Nauru">Nauru</ion-select-option>
          <ion-select-option value="Nepal">Nepal</ion-select-option>
          <ion-select-option value="Netherlands">Netherlands</ion-select-option>
          <ion-select-option value="Netherlands Antilles"
            >Netherlands Antilles</ion-select-option
          >
          <ion-select-option value="New Caledonia"
            >New Caledonia</ion-select-option
          >
          <ion-select-option value="New Zealand">New Zealand</ion-select-option>
          <ion-select-option value="Nicaragua">Nicaragua</ion-select-option>
          <ion-select-option value="Niger">Niger</ion-select-option>
          <ion-select-option value="Nigeria">Nigeria</ion-select-option>
          <ion-select-option value="Niue">Niue</ion-select-option>
          <ion-select-option value="Norfolk Island"
            >Norfolk Island</ion-select-option
          >
          <ion-select-option value="Northern Mariana Islands">
            Northern Mariana Islands
          </ion-select-option>
          <ion-select-option value="Norway">Norway</ion-select-option>
          <ion-select-option value="Oman">Oman</ion-select-option>
          <ion-select-option value="Pakistan">Pakistan</ion-select-option>
          <ion-select-option value="Palau">Palau</ion-select-option>
          <ion-select-option value="Palestinian Territory, Occupied">
            Palestinian Territory, Occupied
          </ion-select-option>
          <ion-select-option value="Panama">Panama</ion-select-option>
          <ion-select-option value="Papua New Guinea"
            >Papua New Guinea</ion-select-option
          >
          <ion-select-option value="Paraguay">Paraguay</ion-select-option>
          <ion-select-option value="Peru">Peru</ion-select-option>
          <ion-select-option value="Philippines">Philippines</ion-select-option>
          <ion-select-option value="Pitcairn">Pitcairn</ion-select-option>
          <ion-select-option value="Poland">Poland</ion-select-option>
          <ion-select-option value="Portugal">Portugal</ion-select-option>
          <ion-select-option value="Puerto Rico">Puerto Rico</ion-select-option>
          <ion-select-option value="Qatar">Qatar</ion-select-option>
          <ion-select-option value="Reunion">Reunion</ion-select-option>
          <ion-select-option value="Romania">Romania</ion-select-option>
          <ion-select-option value="Russian Federation"
            >Russian Federation</ion-select-option
          >
          <ion-select-option value="Rwanda">Rwanda</ion-select-option>
          <ion-select-option value="Saint Helena"
            >Saint Helena</ion-select-option
          >
          <ion-select-option value="Saint Kitts and Nevis"
            >Saint Kitts and Nevis</ion-select-option
          >
          <ion-select-option value="Saint Lucia">Saint Lucia</ion-select-option>
          <ion-select-option value="Saint Pierre and Miquelon">
            Saint Pierre and Miquelon
          </ion-select-option>
          <ion-select-option value="Saint Vincent and The Grenadines">
            Saint Vincent and The Grenadines
          </ion-select-option>
          <ion-select-option value="Samoa">Samoa</ion-select-option>
          <ion-select-option value="San Marino">San Marino</ion-select-option>
          <ion-select-option value="Sao Tome and Principe"
            >Sao Tome and Principe</ion-select-option
          >
          <ion-select-option value="Saudi Arabia"
            >Saudi Arabia</ion-select-option
          >
          <ion-select-option value="Senegal">Senegal</ion-select-option>
          <ion-select-option value="Serbia">Serbia</ion-select-option>
          <ion-select-option value="Seychelles">Seychelles</ion-select-option>
          <ion-select-option value="Sierra Leone"
            >Sierra Leone</ion-select-option
          >
          <ion-select-option value="Singapore">Singapore</ion-select-option>
          <ion-select-option value="Slovakia">Slovakia</ion-select-option>
          <ion-select-option value="Slovenia">Slovenia</ion-select-option>
          <ion-select-option value="Solomon Islands"
            >Solomon Islands</ion-select-option
          >
          <ion-select-option value="Somalia">Somalia</ion-select-option>
          <ion-select-option value="South Africa"
            >South Africa</ion-select-option
          >
          <ion-select-option
            value="South Georgia and The South Sandwich Islands"
          >
            South Georgia and The South Sandwich Islands
          </ion-select-option>
          <ion-select-option value="Spain">Spain</ion-select-option>
          <ion-select-option value="Sri Lanka">Sri Lanka</ion-select-option>
          <ion-select-option value="Sudan">Sudan</ion-select-option>
          <ion-select-option value="Suriname">Suriname</ion-select-option>
          <ion-select-option value="Svalbard and Jan Mayen"
            >Svalbard and Jan Mayen</ion-select-option
          >
          <ion-select-option value="Swaziland">Swaziland</ion-select-option>
          <ion-select-option value="Sweden">Sweden</ion-select-option>
          <ion-select-option value="Switzerland">Switzerland</ion-select-option>
          <ion-select-option value="Syrian Arab Republic"
            >Syrian Arab Republic</ion-select-option
          >
          <ion-select-option value="Taiwan, Province of China">
            Taiwan, Province of China
          </ion-select-option>
          <ion-select-option value="Tajikistan">Tajikistan</ion-select-option>
          <ion-select-option value="Tanzania, United Republic of">
            Tanzania, United Republic of
          </ion-select-option>
          <ion-select-option value="Thailand">Thailand</ion-select-option>
          <ion-select-option value="Timor-leste">Timor-leste</ion-select-option>
          <ion-select-option value="Togo">Togo</ion-select-option>
          <ion-select-option value="Tokelau">Tokelau</ion-select-option>
          <ion-select-option value="Tonga">Tonga</ion-select-option>
          <ion-select-option value="Trinidad and Tobago"
            >Trinidad and Tobago</ion-select-option
          >
          <ion-select-option value="Tunisia">Tunisia</ion-select-option>
          <ion-select-option value="Turkey">Turkey</ion-select-option>
          <ion-select-option value="Turkmenistan"
            >Turkmenistan</ion-select-option
          >
          <ion-select-option value="Turks and Caicos Islands">
            Turks and Caicos Islands
          </ion-select-option>
          <ion-select-option value="Tuvalu">Tuvalu</ion-select-option>
          <ion-select-option value="Uganda">Uganda</ion-select-option>
          <ion-select-option value="Ukraine">Ukraine</ion-select-option>
          <ion-select-option value="United Arab Emirates"
            >United Arab Emirates</ion-select-option
          >
          <ion-select-option
            value="United Kingdom of Great Britain and Northern Ireland"
          >
            United Kingdom of Great Britain and Northern Ireland
          </ion-select-option>
          <ion-select-option value="United States of America">
            United States of America
          </ion-select-option>
          <ion-select-option value="United States Minor Outlying Islands">
            United States Minor Outlying Islands
          </ion-select-option>
          <ion-select-option value="Uruguay">Uruguay</ion-select-option>
          <ion-select-option value="Uzbekistan">Uzbekistan</ion-select-option>
          <ion-select-option value="Vanuatu">Vanuatu</ion-select-option>
          <ion-select-option value="Venezuela">Venezuela</ion-select-option>
          <ion-select-option value="Viet Nam">Viet Nam</ion-select-option>
          <ion-select-option value="Virgin Islands, British">
            Virgin Islands, British
          </ion-select-option>
          <ion-select-option value="Virgin Islands, U.S."
            >Virgin Islands, U.S.</ion-select-option
          >
          <ion-select-option value="Wallis and Futuna"
            >Wallis and Futuna</ion-select-option
          >
          <ion-select-option value="Western Sahara"
            >Western Sahara</ion-select-option
          >
          <ion-select-option value="Yemen">Yemen</ion-select-option>
          <ion-select-option value="Zambia">Zambia</ion-select-option>
          <ion-select-option value="Zimbabwe">Zimbabwe</ion-select-option>
        </ion-select>
      </ion-item>
    </form>

    <ion-item
      lines="none"
      [hidden]="
        billing_order_formatted_address.formatted_address &&
        billing_order_formatted_address.same_as_billing_address
      "
    >
      <ion-label
        style="font-size: x-small; line-height: 1.2; margin-right: 5px"
        class="ion-text-wrap"
      >
        <strong>{{
          "popups.billing-info.billingInfo-subtitle" | translate
        }}</strong>
      </ion-label>
      <span style="position: absolute; bottom: 0; right: 0px">
        <img
          *ngIf="billingInfo.gateway_id === 'ethniki'"
          alt="ethniki"
          width="50px"
          src="https://data.deliverymanager.gr/bankicons/ethniki.png"
        />
        <img
          *ngIf="billingInfo.gateway_id === 'nbg_simplify'"
          alt="nbg_simplify"
          width="50px"
          src="https://data.deliverymanager.gr/bankicons/ethniki.png"
        />
        <img
          *ngIf="billingInfo.gateway_id === 'mypos'"
          height="20px"
          alt="mypos"
          src="https://data.deliverymanager.gr/bankicons/mypos.png"
        />
        <img
          *ngIf="billingInfo.gateway_id === 'viva'"
          width="50px"
          alt="viva"
          [class]="darkMode ? 'invert' : ''"
          src="https://data.deliverymanager.gr/bankicons/viva.png"
        />
        <img
          *ngIf="billingInfo.gateway_id === 'piraeus'"
          width="20px"
          alt="piraeus"
          src="https://data.deliverymanager.gr/bankicons/piraeus.png"
        />
        <img
          *ngIf="billingInfo.gateway_id === 'eurobank'"
          width="35px"
          alt="eurobank"
          src="https://data.deliverymanager.gr/bankicons/eurobank.png"
        />
        <img
          *ngIf="billingInfo.gateway_id === 'alpha'"
          width="45px"
          alt="alpha"
          src="https://data.deliverymanager.gr/bankicons/alpha.png"
        />
        <img
          *ngIf="billingInfo.gateway_id === 'paypal'"
          width="35px"
          alt="paypal"
          src="https://data.deliverymanager.gr/bankicons/paypal.png"
        />
        <img
          *ngIf="billingInfo.gateway_id === 'nexi'"
          width="45px"
          alt="nexi"
          src="https://data.deliverymanager.gr/bankicons/nexi.png"
        />
      </span>
    </ion-item>

    <span
      *ngIf="
        billing_order_formatted_address.formatted_address &&
        billing_order_formatted_address.same_as_billing_address
      "
      style="position: absolute; bottom: 10px; right: 5px"
    >
      <img
        *ngIf="billingInfo.gateway_id === 'ethniki'"
        alt="ethniki"
        width="50px"
        src="https://data.deliverymanager.gr/bankicons/ethniki.png"
      />
      <img
        *ngIf="billingInfo.gateway_id === 'nbg_simplify'"
        alt="nbg_simplify"
        width="50px"
        src="https://data.deliverymanager.gr/bankicons/ethniki.png"
      />
      <img
        *ngIf="billingInfo.gateway_id === 'mypos'"
        height="20px"
        alt="mypos"
        src="https://data.deliverymanager.gr/bankicons/mypos.png"
      />
      <img
        *ngIf="billingInfo.gateway_id === 'viva'"
        width="50px"
        alt="viva"
        [class]="darkMode ? 'invert' : ''"
        src="https://data.deliverymanager.gr/bankicons/viva.png"
      />
      <img
        *ngIf="billingInfo.gateway_id === 'piraeus'"
        width="20px"
        alt="piraeus"
        src="https://data.deliverymanager.gr/bankicons/piraeus.png"
      />
      <img
        *ngIf="billingInfo.gateway_id === 'eurobank'"
        width="35px"
        alt="eurobank"
        src="https://data.deliverymanager.gr/bankicons/eurobank.png"
      />
      <img
        *ngIf="billingInfo.gateway_id === 'alpha'"
        width="45px"
        alt="alpha"
        src="https://data.deliverymanager.gr/bankicons/alpha.png"
      />
      <img
        *ngIf="billingInfo.gateway_id === 'paypal'"
        width="35px"
        alt="paypal"
        src="https://data.deliverymanager.gr/bankicons/paypal.png"
      />
      <img
        *ngIf="billingInfo.gateway_id === 'nexi'"
        width="45px"
        alt="nexi"
        src="https://data.deliverymanager.gr/bankicons/nexi.png"
      />
    </span>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-row>
    <ion-col size="12" class="ion-text-center">
      <ion-button (click)="onSubmit()" expand="block"
        ><strong>OK</strong></ion-button
      >
    </ion-col>
  </ion-row>
</ion-footer>
