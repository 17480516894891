import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import { PointsCalculationsService } from "src/app/services/pointsCalculations/points-calculations.service";

@Component({
  selector: "app-rules",
  templateUrl: "./rules.component.html",
  styleUrls: ["./rules.component.scss"],
})
export class RulesComponent implements OnInit, OnDestroy {
  public currentUser: any;
  public groupApp: any;
  public stores;
  public pointsFactorsActive = false;
  public pointsFactorsRulesArr = [];
  public pointsDisableRulesArr = [];
  public selectedStoreId;
  public selectedLangugage;
  disablePointsInStores = false;
  sameFactorRulesBetweenStores = true;
  public mo_base;

  public storeInfo;
  @Input() userPoints: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private pointsCalculationsService: PointsCalculationsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.mo_base = _.cloneDeep(
              _.find(this.groupApp.languages, {
                mo_base: true,
              })
            );
            if (!this.mo_base) {
              this.mo_base = "en";
            } else {
              this.mo_base = this.mo_base.code;
            }
            if (this.groupApp && this.storeInfo && this.stores) {
              this.checkIfFactorsHasDifferentRulesStores();
              this.checkStoresPointsSystemFactors(_.cloneDeep(this.storeInfo));
            }
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(state.selectedLangugage, this.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (this.groupApp && this.storeInfo && this.stores) {
              this.checkIfFactorsHasDifferentRulesStores();
              this.checkStoresPointsSystemFactors(_.cloneDeep(this.storeInfo));
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);

            if (this.groupApp && this.storeInfo && this.stores) {
              this.selectedStoreId = _.cloneDeep(this.storeInfo.store_id);
              this.checkIfFactorsHasDifferentRulesStores();
              this.checkStoresPointsSystemFactors(_.cloneDeep(this.storeInfo));
            }
          }
        })
    );
  }

  changeStoreEv(ev) {
    if (this.groupApp && this.storeInfo && this.stores) {
      const selectedStore = _.find(this.stores, {
        store_id: this.selectedStoreId,
      });
      if (selectedStore) {
        this.checkIfFactorsHasDifferentRulesStores();
        this.checkStoresPointsSystemFactors(_.cloneDeep(selectedStore));
      }
    }
  }

  checkIfFactorsHasDifferentRulesStores() {
    this.sameFactorRulesBetweenStores = true;

    _.each(this.stores, (storeOne) => {
      _.each(this.stores, (storeTwo) => {
        if (storeOne.store_id !== storeTwo.store_id) {
          if (
            !_.isEqual(
              storeOne.pointsSystemStoreSettings,
              storeTwo.pointsSystemStoreSettings
            )
          ) {
            this.sameFactorRulesBetweenStores = false;
          }
        }
      });
    });
  }

  checkStoresPointsSystemFactors(storeInfo) {
    this.pointsFactorsActive = false;
    this.disablePointsInStores = false;
    this.pointsFactorsRulesArr = [];
    this.pointsDisableRulesArr = null;

    _.each(this.stores, (store) => {
      if (
        this.groupApp &&
        this.groupApp.pointsSystem &&
        !_.isEmpty(this.groupApp.points) &&
        !_.isEmpty(this.groupApp.points.rules) &&
        store.pointsSystemStoreSettings &&
        store.pointsSystemStoreSettings.active
      ) {
        this.pointsFactorsActive = true;
        const disablePoinntsRules =
          this.pointsCalculationsService.getPointsDisableRulesArray(
            _.cloneDeep(store)
          );

        if (disablePoinntsRules && disablePoinntsRules.length) {
          this.disablePointsInStores = true;
        }
      }
    });

    if (
      this.pointsFactorsActive &&
      storeInfo &&
      storeInfo.pointsSystemStoreSettings &&
      storeInfo.pointsSystemStoreSettings.active
    ) {
      this.pointsFactorsRulesArr =
        this.pointsCalculationsService.getPointsFactorRulesArray(
          _.cloneDeep(storeInfo)
        );
    }

    if (
      this.pointsFactorsActive &&
      storeInfo &&
      storeInfo.pointsSystemStoreSettings &&
      storeInfo.pointsSystemStoreSettings.active
    ) {
      this.pointsDisableRulesArr =
        this.pointsCalculationsService.getPointsDisableRulesArray(
          _.cloneDeep(storeInfo)
        );
    }

    console.log("disableRulesArr", this.pointsDisableRulesArr);
  }

  ngOnDestroy() {
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
