import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

@Component({
  selector: "app-active-coupons-promo-modal",
  templateUrl: "./active-coupons-promo-modal.component.html",
  styleUrls: ["./active-coupons-promo-modal.component.scss"],
})
export class ActiveCouponsPromoModalComponent implements OnInit, OnDestroy {
  @Input() activeCoupons;

  private unsubscribeBackEvent: Subscription;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private router: Router
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  trackFn(index, item) {
    return item.timestamp;
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

  navagateToCart() {
    this.modalController.dismiss();
    this.router.navigateByUrl("/cart", {
      replaceUrl: true,
    });
  }
}
