import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Store } from "@ngrx/store";
import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";
import { distinctUntilChanged, first } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import * as queue from "@aws-sdk/client-sqs";
import { v4 as uuid } from "uuid";
import { Platform } from "@ionic/angular";
import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";

declare let window: any;
declare let fbq: Function;

@Injectable({
  providedIn: "root",
})
export class AnalyticsServiceService {
  private firebaseAnalytics: any;
  private groupApp: any;
  private queue: any;
  private sqenc =
    "eyJhY2Nlc3joy12NLZXlJZCI6IkFLSUEzT1VCVEFLUFAkAk3NWNFJCVUJXIiwic2VjcmV0QWNjZXNzPPP2S2V5IjoidXJsTncvY29rdHRiSHhCNnJ1MWJlUDBuTnpxjoy12QXdKcW9YUXd0ZDZWdSJ9";

  private queueMessagesForResend = [];
  private renderer: Renderer2;

  constructor(
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  groupAppSubscription() {
    this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          this.sinit();
        }
      });
  }

  initializeFirebaseAnalytics() {
    this.firebaseAnalytics = getAnalytics();
  }

  async sinit() {
    if (!this.queue) {
      this.queue = new queue.SQS({
        region: "eu-west-1",
        credentials: this.deK(this.sqenc),
      });
      if (
        this.queue &&
        this.queueMessagesForResend &&
        this.queueMessagesForResend.length
      ) {
        this.sendMissedQueueMessages();
      }
    }
  }

  private async QueueSendMessage(message) {
    return await this.queue.sendMessage({
      QueueUrl:
        "https://sqs.eu-west-1.amazonaws.com/787324535455/mobileorder-footprints",
      MessageBody: JSON.stringify(message),
      DelaySeconds: 0,
    });
  }

  sendMissedQueueMessages() {
    setTimeout(() => {
      if (
        this.queue &&
        this.queueMessagesForResend &&
        this.queueMessagesForResend.length
      ) {
        _.each(this.queueMessagesForResend, (footprint, index) => {
          setTimeout(() => {
            this.dmAnalyticsMessage(
              footprint.action_id,
              footprint.comment_id,
              footprint.product,
              footprint.coupon_description,
              footprint.order_cart_products,
              footprint.order_id,
              footprint.mobile,
              footprint.address,
              footprint.current_bundle_version,
              footprint.newBundleVersion,
              footprint.changedPaymentMethod
            );
          }, 500 * (index + 1));
        });

        this.queueMessagesForResend = [];
      }
    }, 4000);
  }

  dmAnalyticsMessage(
    action_id,
    comment_id,
    product,
    coupon_description,
    order_cart_products,
    order_id,
    mobile,
    address,
    currentBundleVersion,
    newBundleVersion,
    changedPaymentMethod
  ) {
    if (!this.queue) {
      this.queueMessagesForResend.push({
        action_id: action_id,
        comment_id: comment_id,
        product: product,
        coupon_description: coupon_description,
        order_cart_products: order_cart_products,
        order_id: order_id,
        mobile: mobile,
        address: address,
        currentBundleVersion: currentBundleVersion,
        newBundleVersion: newBundleVersion,
        changedPaymentMethod: changedPaymentMethod,
      });
    } else {
      let platform;
      if (Capacitor.isNativePlatform()) {
        if (this.platform.is("android")) {
          platform = "android";
        } else if (this.platform.is("ios")) {
          platform = "ios";
        }
      } else {
        platform = "browser";
      }
      this.store
        .select("auth")
        .pipe(first())
        .subscribe((auth) => {
          this.store
            .select("platformInfo")
            .pipe(first())
            .subscribe((state) => {
              if (state && state.session_id) {
                this.store
                  .select("groupApp")
                  .pipe(first())
                  .subscribe((groupState) => {
                    this.store
                      .select("currentStore")
                      .pipe(first())
                      .subscribe((currentStoreState) => {
                        if (action_id && action_id === "app_loaded") {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              platform: platform,
                              comment_id: comment_id,
                            });
                          }
                        } else if (action_id && action_id === "renew_token") {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "trigger_renew_token"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        } else if (action_id === "added_product" && product) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              product_id: product.product_id,
                              product_name: product.name,
                              comment_id: comment_id,
                            });
                          }
                        } else if (action_id === "dark_mode") {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              dark_mode: comment_id,
                            });
                          }
                        } else if (
                          action_id === "selected_coupon" &&
                          coupon_description
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              coupon_description: coupon_description,
                            });
                          }
                        } else if (action_id === "cart_calculation") {
                          this.store
                            .select("cartPrices")
                            .pipe(first())
                            .subscribe((cartPrices) => {
                              this.store
                                .select("cart")
                                .pipe(first())
                                .subscribe((cartState) => {
                                  if (
                                    cartState &&
                                    cartState.products &&
                                    cartPrices &&
                                    cartPrices.cartPrices &&
                                    groupState &&
                                    groupState.groupApp &&
                                    currentStoreState &&
                                    currentStoreState.currentStoreId
                                  ) {
                                    let cartProductsNumber = 0;
                                    _.each(cartState.products, (product) => {
                                      if (
                                        product.quantity &&
                                        !product.disable_qty &&
                                        (!product.enable_quantity_step ||
                                          !product.quantity_step)
                                      ) {
                                        cartProductsNumber =
                                          cartProductsNumber + product.quantity;
                                      } else {
                                        cartProductsNumber =
                                          cartProductsNumber + 1;
                                      }
                                    });
                                    const footprint_id = uuid();
                                    this.QueueSendMessage({
                                      footprint_id: footprint_id,
                                      group: groupState.groupApp.group,
                                      store_id:
                                        currentStoreState.currentStoreId,
                                      session_id: state.session_id,
                                      device_id: state.device_id,
                                      action_id: action_id,
                                      grand_total:
                                        cartPrices.cartPrices.grand_total,
                                      subtotal: cartPrices.cartPrices.subtotal,
                                      cart_products: cartProductsNumber || 0,
                                    });
                                  }
                                })
                                .unsubscribe();
                            })
                            .unsubscribe();
                        } else if (
                          action_id &&
                          action_id === "add_reorder_cart" &&
                          order_cart_products &&
                          order_id
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            let cartProductsNumber = 0;
                            _.each(order_cart_products, (product) => {
                              if (product.quantity) {
                                cartProductsNumber =
                                  cartProductsNumber +
                                  _.cloneDeep(product.quantity);
                              }
                            });
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              order_cart_products: cartProductsNumber,
                              order_id: order_id,
                            });
                          }
                        } else if (action_id === "submit_mobile_button") {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile: mobile,
                            });
                          }
                        } else if (action_id === "delivery_method") {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              formatted_address:
                                address && address.formatted_address
                                  ? address.formatted_address
                                  : "",
                            });
                          }
                        } else if (
                          action_id === "change_order_payment_method"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId &&
                            changedPaymentMethod
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile: mobile,
                              changedPaymentMethod: changedPaymentMethod,
                            });
                          }
                        } else if (
                          action_id === "complete_order_invalid_data"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                            });
                          }
                        } else if (action_id === "complete_order_failed") {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                            });
                          }
                        } else if (action_id === "complete_order_success") {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              order_id: order_id,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "pending_order_cart_alert_opened"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "pending_order_cart_button_pressed"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "bundle_update_page_loaded"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              current_bundle_version: currentBundleVersion,
                              new_bundle_version: newBundleVersion,
                              platform: platform,
                              comment_id: comment_id,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "update_modal_loaded"
                        ) {
                          const footprint_id = uuid();
                          this.QueueSendMessage({
                            footprint_id: footprint_id,
                            group: groupState.groupApp.group,
                            store_id: currentStoreState.currentStoreId,
                            session_id: state.session_id,
                            device_id: state.device_id,
                            action_id: action_id,
                            version: currentBundleVersion,
                            platform: platform,
                            comment_id: comment_id,
                          });
                        } else if (
                          action_id &&
                          action_id === "preorder_modal_loaded"
                        ) {
                          const footprint_id = uuid();
                          this.QueueSendMessage({
                            footprint_id: footprint_id,
                            group: groupState.groupApp.group,
                            store_id: currentStoreState.currentStoreId,
                            session_id: state.session_id,
                            device_id: state.device_id,
                            action_id: action_id,
                            version: currentBundleVersion,
                            platform: platform,
                            comment_id: comment_id,
                          });
                        } else if (
                          action_id &&
                          action_id === "set_preorder_date_time"
                        ) {
                          setTimeout(() => {
                            this.store
                              .select("deliveryMethodAddress")
                              .pipe(first())
                              .subscribe((deliveryMethodAdressState) => {
                                if (
                                  deliveryMethodAdressState &&
                                  deliveryMethodAdressState.selectedPreorderDate &&
                                  deliveryMethodAdressState.selectedPreorderDate
                                    .date &&
                                  deliveryMethodAdressState.selectedPreorderDate
                                    .hour
                                ) {
                                  const footprint_id = uuid();
                                  this.QueueSendMessage({
                                    footprint_id: footprint_id,
                                    group: groupState.groupApp.group,
                                    store_id: currentStoreState.currentStoreId,
                                    session_id: state.session_id,
                                    device_id: state.device_id,
                                    action_id: action_id,
                                    version: currentBundleVersion,
                                    platform: platform,
                                    comment_id: comment_id,
                                    selected_preorder_day:
                                      deliveryMethodAdressState
                                        .selectedPreorderDate.date,
                                    selected_preorder_hour:
                                      deliveryMethodAdressState
                                        .selectedPreorderDate.hour,
                                  });
                                }
                              })
                              .unsubscribe();
                          }, 500);
                        } else if (
                          action_id &&
                          action_id === "heart_beat_timeout_called"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "reconnect_socket_called"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "disconnect_socket_called"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "after_socket_close"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "on_open_mo_socket"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id === "on_open_user_socket"
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        } else if (
                          action_id &&
                          action_id !== "selected_coupon" &&
                          !product
                        ) {
                          if (
                            groupState &&
                            groupState.groupApp &&
                            currentStoreState &&
                            currentStoreState.currentStoreId
                          ) {
                            const footprint_id = uuid();
                            this.QueueSendMessage({
                              footprint_id: footprint_id,
                              group: groupState.groupApp.group,
                              store_id: currentStoreState.currentStoreId,
                              session_id: state.session_id,
                              device_id: state.device_id,
                              action_id: action_id,
                              comment_id: comment_id || null,
                              platform: platform,
                              mobile:
                                auth && auth.user ? auth.user.mobile : null,
                            });
                          }
                        }
                      })
                      .unsubscribe();
                  })
                  .unsubscribe();
              }
            })
            .unsubscribe();
        })
        .unsubscribe();
    }
  }

  //facebook pixel
  initializeFbq() {
    if (fbq && this.groupApp && this.groupApp.facebookPixel) {
      fbq("init", this.groupApp.facebookPixel);
    }
  }

  fbqTrackPageView() {
    fbq("track", "PageView");
  }

  fbqTrackAddToCart(product, orderPickup, dineIn) {
    if (fbq && this.groupApp && this.groupApp.facebookPixel) {
      fbq("track", "AddToCart", {
        contents: [
          {
            id: product.product_id,
            quantity: product.quantity,
          },
        ],
        content_type: "product",
        currency: "EUR",
        value: parseFloat(
          orderPickup && !dineIn && product.price_takeaway
            ? product.price_takeaway
            : !orderPickup && dineIn && product.price_dinein
            ? product.price_dinein
            : product.price
        ),
      });
    }
  }

  deK(value) {
    try {
      return JSON.parse(atob(this.rem2d(this.sqenc)));
    } catch {
      return null;
    }
  }

  rem2d(val) {
    return val.replace(/joy12|AkAk3|PPP2/g, "");
  }
  fbqTrackInitiateCheckout(cart) {
    if (fbq && this.groupApp && this.groupApp.facebookPixel) {
      fbq("track", "InitiateCheckout", {
        num_items: cart.length,
      });
    }
  }

  fbqTrackPurchase(grand_total, cart) {
    if (fbq && this.groupApp && this.groupApp.facebookPixel) {
      fbq("track", "Purchase", {
        value: parseFloat(grand_total),
        currency: "EUR",
        contents: cart.map((item) => {
          return {
            id: item.product_id,
            quantity: item.quantity,
          };
        }),
        content_type: "product",
      });
    }
  }

  //Tik Tok Pixel

  initializeTikTokPixel() {
    if (this.groupApp && this.groupApp.tikTokPixelId) {
      if (!this.groupApp.tikTokPixelId || typeof window === "undefined") {
        console.error("TikTok Pixel ID is missing or invalid environment.");
        return;
      }

      // Dynamically create the script
      const script = this.renderer.createElement("script");
      script.type = "text/javascript";
      script.text = `
        !(function (w, d, t) {
          w.TiktokAnalyticsObject = t;
          var ttq = (w[t] = w[t] || []);
          (ttq.methods = [
            "page",
            "track",
            "identify",
            "instances",
            "debug",
            "on",
            "off",
            "once",
            "ready",
            "alias",
            "group",
            "enableCookie",
            "disableCookie",
            "holdConsent",
            "revokeConsent",
            "grantConsent"
          ]);
          (ttq.setAndDefer = function (t, e) {
            t[e] = function () {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          });
          for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
          (ttq.instance = function (t) {
            for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
              ttq.setAndDefer(e, ttq.methods[n]);
            return e;
          });
          (ttq.load = function (e, n) {
            var r = "https://analytics.tiktok.com/i18n/pixel/events.js",
              o = n && n.partner;
            (ttq._i = ttq._i || {}),
              (ttq._i[e] = []),
              (ttq._i[e]._u = r),
              (ttq._t = ttq._t || {}),
              (ttq._t[e] = +new Date()),
              (ttq._o = ttq._o || {}),
              (ttq._o[e] = n || {});
            n = document.createElement("script");
            (n.type = "text/javascript"),
              (n.async = !0),
              (n.src = r + "?sdkid=" + e + "&lib=" + t);
            e = document.getElementsByTagName("script")[0];
            e.parentNode.insertBefore(n, e);
          });
          ttq.load("${this.groupApp.tikTokPixelId}");
          ttq.page();
        })(window, document, "ttq");
      `;

      // Append the script to the document head
      this.renderer.appendChild(document.head, script);
    }
  }

  tikTokPixelPageView(page) {
    if (this.groupApp && this.groupApp.tikTokPixelId && window && window.ttq) {
      window.ttq.track("ViewContent", {
        content_id: page,
      });
    }
  }

  tikTokPixelAddToCart(product) {
    if (this.groupApp && this.groupApp.tikTokPixelId && window && window.ttq) {
      window.ttq.track("AddToCart", {
        content_id: product.product_id, // Unique identifier for the product
        content_name: product.name, // Name of the product
        content_category: product.category.name, // Category or type of the product
        quantity: product.quantity, // Number of items added
        value: product.productTotal
          ? parseFloat(product.productTotal.toString())
          : 0, // Total value of the items added
        currency: "EUR", // Currency code
      });
    }
  }

  tikTokPixelInitiateCheckout(cart, grand_total) {
    if (this.groupApp && this.groupApp.tikTokPixelId && window && window.ttq) {
      window.ttq.track("InitiateCheckout", {
        content_ids: cart.map((item) => {
          return item.product_id;
        }), // IDs of products in the cart
        content_name: cart.map((item) => {
          return item.name;
        }), // Names of products
        quantity: cart.length, // Total quantity of items
        value: parseFloat(grand_total.toString()), // Total value of the items in the cart
        currency: "EUR", // Currency used for the cart
      });
    }
  }

  tikTokPixelPurchase(cart, grand_total) {
    if (this.groupApp && this.groupApp.tikTokPixelId && window && window.ttq) {
      window.ttq.track("CompletePayment", {
        value: parseFloat(grand_total), // Total amount for the purchase
        currency: "EUR", // Currency of the purchase
        content_ids: cart.map((item) => item.product_id), // Product IDs
        content_name: cart.map((item) => item.name), // Product names
        content_type: "product", // Type of content being tracked (product)
        quantity: cart.reduce((total, item) => total + item.quantity, 0), // Total quantity
      });
    }
  }

  //firebase analytics

  async logEventPurchase(order_id, grand_total) {
    if (this.groupApp && Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.logEvent({
        name: "purchase",
        params: {
          transaction_id: order_id,
          value: parseFloat(grand_total),
          currency: "EUR",
        },
      }).catch((error) => console.error(error));
    } else if (
      this.groupApp &&
      !Capacitor.isNativePlatform() &&
      this.firebaseAnalytics
    ) {
      logEvent(this.firebaseAnalytics, "purchase", {
        transaction_id: order_id,
        value: parseFloat(grand_total),
        currency: "EUR",
      });
    }
  }

  async logEventAddToCart() {
    if (this.groupApp && Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.logEvent({
        name: "add_to_cart",
      }).catch((error) => console.error(error));
    } else if (this.groupApp && this.firebaseAnalytics) {
      logEvent(this.firebaseAnalytics, "add_to_cart");
    }
  }

  async logEventBeginCheckout() {
    if (this.groupApp && Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.logEvent({
        name: "begin_checkout",
      }).catch((error) => console.error(error));
    } else if (this.groupApp && this.firebaseAnalytics) {
      logEvent(this.firebaseAnalytics, "begin_checkout");
    }
  }

  async setCurrentScreenFirebase(state) {
    if (this.groupApp && Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: state,
      });
    } else if (this.groupApp && this.firebaseAnalytics) {
      setCurrentScreen(this.firebaseAnalytics, state);
    }
  }
}
