<ion-button
  (click)="closeModal()"
  size="small"
  fill="clear"
  color="white"
  style="position: absolute; top: 20px; right: 10px; z-index: 1000"
  ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon
></ion-button>

<ion-grid
  (click)="closeModal()"
  style="height: 100%; width: 100%"
  *ngIf="windowDimensions && windowDimensions.width && storeInfo"
>
  <ion-row
    style="width: 100%; height: 100%"
    class="ion-align-items-center ion-justify-content-center"
  >
    <!-- <ion-col
      class="ion-no-padding"
      size="12"
      *ngIf="currentView !== 'app.cart'"
    >
    </ion-col> -->
    <ion-col
      [class]="
        (showDeliveryButton || infoShowDeliveryButton) &&
        showDineInButton &&
        showPickupButton
          ? 'ion-align-self-center centered-column-max-width-large'
          : 'ion-align-self-center centered-column-max-width-small'
      "
      style="max-width: 650px"
    >
      <ion-row style="width: 100%">
        <ion-col
          [size]="deliveryColSize"
          *ngIf="showDeliveryButton || infoShowDeliveryButton"
        >
          <ion-grid class="ion-no-padding" style="width: 130px">
            <ion-item
              color="primary"
              button="true"
              lines="none"
              detail="false"
              (click)="
                checkAndOpenSettingsModal(
                  infoShowDeliveryButton ? 'delivery-info-modal' : 'delivery'
                );
                $event.stopImmediatePropagation()
              "
            >
              <ion-text
                *ngIf="
                  loginState &&
                  ((loginState === 'login_completed' &&
                    customerOrders &&
                    customerOrders.length > 0) ||
                    (groupApp &&
                      groupApp.mobileorder_buttons_info_messages &&
                      !groupApp.mobileorder_buttons_info_messages
                        .new_customer_delivery_button)) &&
                  groupApp &&
                  groupApp.mobileorder_buttons_info_messages &&
                  groupApp.mobileorder_buttons_info_messages.delivery_button
                "
                style="
                  position: absolute;
                  top: 7px;
                  right: 7px;
                  text-align: right;
                  font-size: medium;
                  font-weight: bolder;
                "
              >
                {{ groupApp.mobileorder_buttons_info_messages.delivery_button }}
              </ion-text>

              <ion-text
                *ngIf="
                  loginState &&
                  (loginState === 'no_login' ||
                    (loginState === 'login_completed' &&
                      (!customerOrders || customerOrders.length === 0))) &&
                  groupApp &&
                  groupApp &&
                  groupApp.mobileorder_buttons_info_messages &&
                  groupApp.mobileorder_buttons_info_messages
                    .new_customer_delivery_button
                "
                style="
                  position: absolute;
                  top: 7px;
                  right: 7px;
                  text-align: right;
                  font-size: medium;
                  font-weight: bolder;
                "
              >
                {{
                  groupApp.mobileorder_buttons_info_messages
                    .new_customer_delivery_button
                }}
              </ion-text>

              <ion-label>
                <ion-grid style="width: 80px">
                  <ng-lottie [options]="deliveryAnimationOptions"> </ng-lottie>
                </ion-grid>
                <div class="ion-text-center" style="font-weight: bold">
                  {{
                    "popups.order-now-popup.new-address-delivery" | translate
                  }}
                </div>
                <ion-text
                  *ngIf="
                    groupApp &&
                    groupApp.mobileorder_buttons_info_messages &&
                    groupApp.mobileorder_buttons_info_messages
                      .bottom_delivery_button &&
                    groupApp.mobileorder_buttons_info_messages
                      .bottom_delivery_button.translations &&
                    groupApp.mobileorder_buttons_info_messages
                      .bottom_delivery_button.translations[mo_base]
                  "
                  style="
                    position: absolute;
                    bottom: 2px;
                    right: 5px;

                    font-size: x-small;
                    font-weight: bolder;
                  "
                >
                  {{
                    groupApp.mobileorder_buttons_info_messages
                      .bottom_delivery_button.translations[selectedLangugage] &&
                    selectedLangugage
                      ? groupApp.mobileorder_buttons_info_messages
                          .bottom_delivery_button.translations[
                          selectedLangugage
                        ]
                      : groupApp.mobileorder_buttons_info_messages
                          .bottom_delivery_button.translations[mo_base]
                  }}
                </ion-text>
              </ion-label>
            </ion-item></ion-grid
          >
        </ion-col>
        <ion-col [size]="takeAwayColSize" *ngIf="showPickupButton">
          <ion-grid class="ion-no-padding" style="width: 130px">
            <ion-item
              color="primary"
              button="true"
              lines="none"
              detail="false"
              (click)="
                checkAndOpenSettingsModal('take-away');
                $event.stopImmediatePropagation()
              "
            >
              <ion-text
                *ngIf="
                  loginState &&
                  ((loginState === 'login_completed' &&
                    customerOrders &&
                    customerOrders.length > 0) ||
                    (groupApp.mobileorder_buttons_info_messages &&
                      !groupApp.mobileorder_buttons_info_messages
                        .new_customer_takeaway_button)) &&
                  groupApp &&
                  groupApp.mobileorder_buttons_info_messages &&
                  groupApp.mobileorder_buttons_info_messages.takeaway_button
                "
                style="
                  position: absolute;
                  top: 7px;
                  right: 7px;
                  text-align: right;
                  font-size: medium;
                  font-weight: bolder;
                "
              >
                {{ groupApp.mobileorder_buttons_info_messages.takeaway_button }}
              </ion-text>

              <ion-text
                *ngIf="
                  loginState &&
                  (loginState === 'no_login' ||
                    (loginState === 'login_completed' &&
                      (!customerOrders || customerOrders.length === 0))) &&
                  groupApp &&
                  groupApp.mobileorder_buttons_info_messages &&
                  groupApp.mobileorder_buttons_info_messages
                    .new_customer_takeaway_button
                "
                style="
                  position: absolute;
                  top: 7px;
                  right: 7px;
                  text-align: right;
                  font-size: medium;
                  font-weight: bolder;
                "
              >
                {{
                  groupApp.mobileorder_buttons_info_messages
                    .new_customer_takeaway_button
                }}
              </ion-text>
              <ion-label>
                <ion-grid style="width: 80px">
                  <ng-lottie [options]="takeawayAnimationOptions"> </ng-lottie>
                </ion-grid>
                <div class="ion-text-center" style="font-weight: bold">
                  {{
                    "popups.order-now-popup.store-pickup-take-away" | translate
                  }}
                </div>
              </ion-label>
            </ion-item>
          </ion-grid>
        </ion-col>
        <ion-col
          *ngIf="showDineInButton"
          size="12"
          [class]="
            dineInColSize === '12'
              ? 'margin-top ion-text-center'
              : 'ion-text-center'
          "
          [size]="dineInColSize"
        >
          <ion-grid style="width: 130px" class="ion-no-padding ion-text-center">
            <ion-item
              color="primary"
              button="true"
              lines="none"
              detail="false"
              (click)="
                storeInfo.dineinGpsCheck && stores && stores.length === 1
                  ? checkDeviceLocation(true)
                  : checkAndOpenSettingsModal('dine-in');
                $event.stopImmediatePropagation()
              "
            >
              <ion-text
                *ngIf="
                  loginState &&
                  ((loginState === 'login_completed' &&
                    customerOrders &&
                    customerOrders.length > 0) ||
                    (groupApp.mobileorder_buttons_info_messages &&
                      !groupApp.mobileorder_buttons_info_messages
                        .new_customer_dinein_button)) &&
                  groupApp &&
                  groupApp.mobileorder_buttons_info_messages &&
                  groupApp.mobileorder_buttons_info_messages.dinein_button
                "
                style="
                  position: absolute;
                  top: 7px;
                  right: 7px;
                  text-align: right;
                  font-size: medium;
                  font-weight: bolder;
                "
              >
                {{ groupApp.mobileorder_buttons_info_messages.dinein_button }}
              </ion-text>

              <ion-text
                *ngIf="
                  loginState &&
                  (loginState === 'no_login' ||
                    (loginState === 'login_completed' &&
                      (!customerOrders || customerOrders.length === 0))) &&
                  groupApp &&
                  groupApp.mobileorder_buttons_info_messages &&
                  groupApp.mobileorder_buttons_info_messages
                    .new_customer_dinein_button
                "
                style="
                  position: absolute;
                  top: 7px;
                  right: 7px;
                  text-align: right;
                  font-size: medium;
                  font-weight: bolder;
                "
              >
                {{
                  groupApp.mobileorder_buttons_info_messages
                    .new_customer_dinein_button
                }}
              </ion-text>
              <ion-label>
                <ion-grid style="width: 80px">
                  <ng-lottie [options]="dineinAnimationOptions"> </ng-lottie>
                </ion-grid>
                <div class="ion-text-center" style="font-weight: bold">
                  {{ "popups.order-now-popup.dine-in" | translate }}
                </div>
              </ion-label>
            </ion-item>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
