import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../../store/app.reducer";
import * as _ from "lodash";
import * as CartCustomOptionsSelectionsActions from "../../store/cart-custom-options-selections/cart-custom-options-selections.actions";
import * as cartCustomerOptionsActions from "../../store/cart-customer-options/cart-customer-options.actions";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-plastic-wrapper-tax-option",
  templateUrl: "./plastic-wrapper-tax-option.component.html",
  styleUrls: ["./plastic-wrapper-tax-option.component.scss"],
})
export class PlasticWrapperTaxOptionComponent implements OnInit, OnDestroy {
  @Input() option_timestamp;
  @Input() selectedLang: any;
  @Input() catalogMode: boolean;
  public option;
  public CustomSelections;
  public cartProducts: any;
  public cartProductsWithWrapperTax: number = 0;
  private subscriptions: Subscription[] = [];
  public finalPriceLoading: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private alertCtrl: AlertController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((cartState) => {
          if (
            cartState &&
            cartState.products &&
            !_.isEqual(this.cartProducts, cartState.products)
          ) {
            this.cartProducts = _.cloneDeep(cartState.products);
            // console.log("products changed", this.cartProducts, this.option);
            this.cartProductsWithWrapperTax = 0;
            _.each(this.cartProducts, (product) => {
              if (product && product.plasticWrapperTax) {
                this.cartProductsWithWrapperTax =
                  this.cartProductsWithWrapperTax +
                  1 *
                    (product.enable_quantity_step && product.quantity_step
                      ? 1
                      : product.quantity);
              }
            });
            if (this.option && this.option.choices) {
              this.option.choices.forEach((ch) => {
                ch.qty = this.calculateChoiceQuantity(this.cartProducts);

                if (ch.qty >= 0) {
                  ch.totalPrice = this.calculateChoicePrice(ch);
                  this.store.dispatch(
                    new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                      _.cloneDeep(this.option)
                    )
                  );
                }
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(state.cartCustomOptionsSelections, this.CustomSelections)
          ) {
            this.CustomSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            // console.log(
            //   "customeSelects option timestamp",
            //   this.CustomSelections,
            //   this.option_timestamp
            // );
            this.option = _.cloneDeep(
              _.find(this.CustomSelections, {
                timestamp: this.option_timestamp,
              })
            );
            if (this.cartProducts && this.option && this.option.choices) {
              _.each(this.option.choices, (ch) => {
                ch.qty = this.calculateChoiceQuantity(this.cartProducts);
                if (ch.qty) {
                }
                // console.log(
                //   "choice qty",
                //   ch,
                //   this.calculateChoiceQuantity(this.cartProducts)
                // );
                if (ch.qty >= 0) {
                  ch.totalPrice = this.calculateChoicePrice(ch);
                  this.store.dispatch(
                    new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                      _.cloneDeep(this.option)
                    )
                  );
                }
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  calculateChoiceQuantityFromProduct(parentProduct, product, wrapperSum) {
    if (product && product.options) {
      _.each(product.options, (option) => {
        if (option.bundle) {
          if (option && option.choices) {
            _.each(option.choices, (choice) => {
              if (
                choice.is_preselected &&
                choice.bundle &&
                !_.isEmpty(choice.bundled_product)
              ) {
                wrapperSum = this.calculateChoiceQuantityFromProduct(
                  product,
                  _.cloneDeep(choice.bundled_product),
                  _.cloneDeep(wrapperSum)
                );
              }
            });
          }
        }
      });
    }

    if (
      product &&
      product.plasticWrapperTax &&
      product.plasticWrapperTaxWeight
    ) {
      if (parentProduct) {
        wrapperSum = Math.floor(
          wrapperSum +
            (product.enable_quantity_step && product.quantity_step
              ? 1
              : product.quantity) *
              product.plasticWrapperTaxWeight *
              (parentProduct.enable_quantity_step && parentProduct.quantity_step
                ? 1
                : parentProduct.quantity)
        );
      } else {
        wrapperSum = Math.floor(
          wrapperSum +
            (product.enable_quantity_step && product.quantity_step
              ? 1
              : product.quantity) *
              product.plasticWrapperTaxWeight
        );
      }
    }

    if (product && product.options && product.options.length) {
      _.each(product.options, (option) => {
        if (option && option.choices && option.choices.length) {
          _.each(option.choices, (choice) => {
            if (
              choice &&
              choice.plasticWrapperTax &&
              choice.plasticWrapperTaxWeight
            ) {
              if (parentProduct) {
                wrapperSum = Math.floor(
                  wrapperSum +
                    (product.enable_quantity_step && product.quantity_step
                      ? 1
                      : product.quantity) *
                      choice.quantity *
                      choice.plasticWrapperTaxWeight *
                      (parentProduct.enable_quantity_step &&
                      parentProduct.quantity_step
                        ? 1
                        : parentProduct.quantity)
                );
              } else {
                wrapperSum = Math.floor(
                  wrapperSum +
                    (product.enable_quantity_step && product.quantity_step
                      ? 1
                      : product.quantity) *
                      choice.quantity *
                      choice.plasticWrapperTaxWeight
                );
              }
            }
          });
        }
      });
    }

    return _.cloneDeep(wrapperSum);
  }

  calculateChoiceQuantity(products) {
    let cartProducts = _.cloneDeep(products);
    if (cartProducts && cartProducts.length) {
      let wrapperSum = 0.0;
      _.each(cartProducts, (product) => {
        wrapperSum = this.calculateChoiceQuantityFromProduct(
          null,
          _.cloneDeep(product),
          _.cloneDeep(wrapperSum)
        );
      });

      return wrapperSum;
    } else {
      return 0;
    }
  }

  calculateChoicePrice(choice) {
    //choice.qty = this.calculateChoiceQuantity();
    //console.log("choice before calc price", choice);
    if (choice && choice.qty > 0 && choice.price > 0) {
      return (choice.qty * choice.price).toFixed(2);
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }
}
