import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import * as selectors from "../../../store/app.selectors";
import { VERSION } from "../../../../environments/version";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { InformationMessagesService } from "src/app/services/informationMessages/information-messages.service";

@Component({
  selector: "app-send-order-and-pay-info-popup",
  templateUrl: "./send-order-and-pay-info-popup.component.html",
  styleUrls: ["./send-order-and-pay-info-popup.component.scss"],
})
export class SendOrderAndPayInfoPopupComponent implements OnInit, OnDestroy {
  @Input() existedOrderItem;
  @Input() existingOrderSelectedPaymentMethodID;

  public dineIn: any;
  public selectedAddress: any;
  public currentUser: any;
  public currentStoreId: any;
  public groupApp: any;
  public cartPrices: any;
  public checkBoxPromotions: any;
  public cartCustomOptions: any;
  public cart: any;
  public customerInvoice: any;
  public coupons: any;
  public orderComments: any;
  public generalComments: any;
  public orderPickup: any;
  public selectedPaymentMethod: any;
  public mo_token: any;
  public loading: boolean;
  public store_info: any;
  public version = VERSION.version;
  public cards: any;
  public selectedPaymentMethodID: any;
  public selectedLanguage: string;
  public selectedCoupons: any;
  public selectedPreorderDate: any;
  public ionLoading: any;
  public checkStoreOnlineInfo: any;
  public pickUpTimeIsNumber: boolean;
  public deliveryTimeIsNumber: boolean;
  public selectedDineInTable: any;
  public currentOrderPoints: any;
  public createOrderModalInfoMessage;
  public mo_base;

  private subscription: Subscription;
  private storeInfoSubscription: Subscription;
  private storesSub: Subscription;

  private unsubscribeBackEvent: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private modalController: ModalController,
    private infoMessagesService: InformationMessagesService
  ) {}

  ngOnInit() {
    console.log("existed order item ", this.existedOrderItem);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.storeInfoSubscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
          this.store_info = _.cloneDeep(storeInfo);
          console.log("store Info from cart options", this.store_info);
        }
      });

    this.storesSub = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (
          state &&
          state.checkStoreOnlineInfo &&
          !_.isEqual(state.checkStoreOnlineInfo, this.checkStoreOnlineInfo)
        ) {
          this.checkStoreOnlineInfo = _.cloneDeep(state.checkStoreOnlineInfo);

          if (
            this.checkStoreOnlineInfo &&
            this.checkStoreOnlineInfo.deliveryTime &&
            this.checkStoreOnlineInfo.deliveryTime
              .toString()
              .match(/^[0-9]+$/) != null
          ) {
            this.deliveryTimeIsNumber = true;
          } else {
            this.deliveryTimeIsNumber = false;
          }
          if (
            this.checkStoreOnlineInfo &&
            this.checkStoreOnlineInfo.pickupDeliveryTime &&
            this.checkStoreOnlineInfo.pickupDeliveryTime
              .toString()
              .match(/^[0-9]+$/) != null
          ) {
            this.pickUpTimeIsNumber = true;
          } else {
            this.pickUpTimeIsNumber = false;
          }
        }
      });

    this.subscription = this.store
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.auth &&
          state.auth.mo_token &&
          !_.isEqual(state.auth.mo_token, this.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.auth.mo_token);
        }
        if (
          state &&
          state.cartCustomerOptions &&
          state.cartCustomerOptions.cartCustomerOptions &&
          !_.isEqual(
            state.cartCustomerOptions.cartCustomerOptions,
            this.cartCustomOptions
          )
        ) {
          this.cartCustomOptions = _.cloneDeep(
            state.cartCustomerOptions.cartCustomerOptions
          );
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(state.deliveryMethodAddress.orderPickup, this.orderPickup)
        ) {
          this.orderPickup = _.cloneDeep(
            state.deliveryMethodAddress.orderPickup
          );
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(state.deliveryMethodAddress.dineIn, this.dineIn)
        ) {
          this.dineIn = _.cloneDeep(state.deliveryMethodAddress.dineIn);
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(
            state.deliveryMethodAddress.selectedDineInTable,
            this.selectedDineInTable
          )
        ) {
          this.selectedDineInTable = _.cloneDeep(
            state.deliveryMethodAddress.selectedDineInTable
          );
        }
        if (
          state &&
          state.currentStore &&
          state.currentStore.currentStoreId &&
          !_.isEqual(state.currentStore.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStore.currentStoreId);
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          state.deliveryMethodAddress.selectedAddress &&
          !_.isEqual(
            state.deliveryMethodAddress.selectedAddress,
            this.selectedAddress
          )
        ) {
          this.selectedAddress = _.cloneDeep(
            state.deliveryMethodAddress.selectedAddress
          );
        }
        if (
          state &&
          state.groupApp &&
          state.groupApp.groupApp &&
          !_.isEqual(state.groupApp.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp.groupApp);

          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              if (store_info) {
                const infoMessagesGroup =
                  this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
                    this.groupApp,
                    store_info,
                    "completeOrderModal"
                  );

                const infoMessagesStore =
                  this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
                    this.groupApp,
                    store_info,
                    "completeOrderModal"
                  );

                if (infoMessagesStore && infoMessagesStore.length) {
                  this.createOrderModalInfoMessage = infoMessagesStore[0];
                } else {
                  if (infoMessagesGroup && infoMessagesGroup.length) {
                    this.createOrderModalInfoMessage = infoMessagesGroup[0];
                  }
                }
              }
            })
            .unsubscribe();

          this.mo_base = _.cloneDeep(
            _.find(this.groupApp.languages, {
              mo_base: true,
            })
          );
          if (!this.mo_base) {
            this.mo_base = "en";
          } else {
            this.mo_base = this.mo_base.code;
          }
        }
        if (
          state &&
          state.groupApp &&
          state.groupApp.selectedLangugage &&
          !_.isEqual(state.groupApp.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.groupApp.selectedLangugage);
        }
        if (
          state &&
          state.checkboxPromotions &&
          state.checkboxPromotions.checkboxPromotions &&
          !_.isEqual(
            state.checkboxPromotions.checkboxPromotions,
            this.checkBoxPromotions
          )
        ) {
          this.checkBoxPromotions = _.cloneDeep(
            state.checkboxPromotions.checkboxPromotions
          );
        }
        if (
          state &&
          state.cartPrices &&
          state.cartPrices.cartPrices &&
          !_.isEqual(state.cartPrices.cartPrices, this.cartPrices)
        ) {
          this.cartPrices = _.cloneDeep(state.cartPrices.cartPrices);
          if (this.cartPrices && this.cartPrices.currentOrderPoints) {
            this.currentOrderPoints = _.cloneDeep(
              this.cartPrices.currentOrderPoints
            );
          }
        }
        if (
          state &&
          state.cart &&
          state.cart.products &&
          !_.isEqual(state.cart.products, this.cart)
        ) {
          this.cart = _.cloneDeep(state.cart.products);
        }
        if (
          state &&
          state.orderComments &&
          state.orderComments.generalComments &&
          !_.isEqual(state.orderComments.generalComments, this.orderComments)
        ) {
          this.orderComments = _.cloneDeep(state.orderComments.generalComments);
        }
        if (
          state &&
          state.selectedInvoice &&
          state.selectedInvoice.selectedInvoice &&
          !_.isEqual(state.selectedInvoice, this.customerInvoice)
        ) {
          this.customerInvoice = _.cloneDeep(
            state.selectedInvoice.selectedInvoice
          );
        }
        if (
          state &&
          state.cartActiveCoupons &&
          state.cartActiveCoupons.activeCoupons &&
          !_.isEqual(state.cartActiveCoupons.activeCoupons, this.coupons)
        ) {
          this.coupons = _.cloneDeep(state.cartActiveCoupons.activeCoupons);
          if (this.coupons && this.coupons.length) {
            this.selectedCoupons = _.filter(this.coupons, {
              couponCheckbox: true,
            });
          }
        }
        if (
          state.selectedPaymentMethodID &&
          state.selectedPaymentMethodID.selectedPaymentMethodID &&
          !this.existedOrderItem &&
          !_.isEqual(
            state.selectedPaymentMethodID.selectedPaymentMethodID,
            this.selectedPaymentMethod
          )
        ) {
          this.selectedPaymentMethod = _.cloneDeep(
            state.selectedPaymentMethodID.selectedPaymentMethodID
          );
        }
        if (
          state.deliveryMethodAddress &&
          state.deliveryMethodAddress.selectedPreorderDate &&
          !_.isEqual(
            state.deliveryMethodAddress.selectedPreorderDate,
            this.selectedPreorderDate
          )
        ) {
          this.selectedPreorderDate = _.cloneDeep(
            state.deliveryMethodAddress.selectedPreorderDate
          );
          console.log("selected preorder date", this.selectedPreorderDate);
        }
      });
  }

  continueToPayment() {
    this.modalController.dismiss("continue");
  }

  closeModal() {
    this.modalController.dismiss();
  }

  toFloat(num) {
    console.log("toFloat", num);
    if (num) {
      const stringNumb = num.toString();
      console.log("stringNum", parseFloat(stringNumb).toFixed(2));
      return parseFloat(stringNumb).toFixed(2);
    } else {
      return 0;
    }
  }

  ngOnDestroy(): void {
    if (this.storesSub) {
      this.storesSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.storeInfoSubscription) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
