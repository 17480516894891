import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import * as selectors from "../../store/app.selectors";
import * as DisableFinalPriceActions from "../../store/disable-final-price/disable-final-price.actions";
import * as checkboxPromotionsActions from "../../cart/cart-components/checkbox-promotions/store/checkbox-promotions.actions";
import * as CartPricesActions from "../../cart/cart-components/cart-prices/store/cart-prices.actions";
import * as CartActions from "../../cart/store/cart.actions";
import * as CartActiveCouponsActions from "../../cart/cart-components/cart-active-coupons/store/cartActiveCoupons.actions";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
  retry,
  timeout,
} from "rxjs/operators";

import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import { AlertController, ModalController } from "@ionic/angular";
import * as ManuallyTriggerFinalPriceCheckAddressActions from "../../store/manuallyTriggerFinalPriceCheckAddress/manuallyTriggerFinalPrice.actions";
import { AnalyticsServiceService } from "../analitycsService/analytics-service.service";
import { TranslateService } from "@ngx-translate/core";
import * as groupAppActions from "../../store/group-app/groupApp.actions";
import * as storesActions from "../../store/stores/stores.actions";
import { getDistance } from "geolib";
import * as RecommendedProductsActions from "../../store/recommended-products/recommended-products.actions";
import { RecommendedProductsServiceService } from "../recommended-products/recommended-products-service.service";
import { Capacitor } from "@capacitor/core";
import { Router } from "@angular/router";
import { ReservationSystemModalsService } from "src/app/reservation-system/reservation-system-modals.service";
import * as customerPromotionsActions from "../../store/customerPromotions/customerPromotions.actions";

@Injectable({
  providedIn: "root",
})
export class CalculateFinalPriceService {
  private developerMode: boolean;
  private stagePath: string;
  private disableFinalPrice: boolean;
  private cartProducts: any;
  private cartCustomerOptions: any;
  private orderPickup: boolean;
  private selectedPaymentMethodID: string;
  private store_info: any;
  private selectedLanguage: string;
  private deliveryCost = "0";
  private selectedAddress: any;
  private dineIn: boolean;
  private selectedDineInTable: any;
  private checkboxPromotions: any;
  private customersPromotions: any;
  private currentStoreId: string;
  private currentUser: any;
  private mo_token: any;
  private dispathcingFromFinalPrice: boolean = false;
  private activeCoupons: any;
  private cartProductsGifts: any;
  private previousCartGifts: any;
  private previousCart: any;
  private triggerFinalPriceTimestamp: any = null;
  private manuallytriggerFinalPriceTimestamp: any = null;
  private productDetailsModal: any;
  private selectedPreorderDate: any;
  private groupApp: any;
  private validStores: any;
  private stores: any;
  private migration_customer_id: any;
  private deliveryCostReplacedWithPrdMaxDeliveryCharge = false;
  private freeDeliveryCostOverAmount = false;
  private recommended_products: any;
  private windowDimensions: any;
  private oldSentValidStores;

  constructor(
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private analyticsService: AnalyticsServiceService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private router: Router,
    private recommendedProductsService: RecommendedProductsServiceService,
    private reservationSystemModalsService: ReservationSystemModalsService
  ) {}

  calculateFinalPriceSubscriptions() {
    this.store
      .select("recommendedProducts")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.recommended_products &&
          !_.isEqual(state.recommended_products, this.recommended_products)
        ) {
          this.recommended_products = _.cloneDeep(state.recommended_products);
        }
      });

    this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.store
      .select("developerMode")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && !_.isEqual(this.developerMode, state.developerMode)) {
          this.developerMode = _.cloneDeep(state.developerMode);
          if (this.developerMode) {
            this.stagePath = "mov5dev";
          } else {
            this.stagePath = "mov5";
          }
        }
      });

    this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });

    this.store
      .select("disableFinalPrice")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.disableFinalPrice, state.disableFinalPrice)
        ) {
          this.disableFinalPrice = _.cloneDeep(state.disableFinalPrice);
        }
        if (
          state &&
          state.triggerFinalPriceTimestamp &&
          !_.isEqual(
            this.triggerFinalPriceTimestamp,
            state.triggerFinalPriceTimestamp
          )
        ) {
          this.triggerFinalPriceTimestamp = _.cloneDeep(
            state.triggerFinalPriceTimestamp
          );
          this.calculateFinalPriceDebounce();
        }
      });

    this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(250))
      .subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(this.currentStoreId, state.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);

          this.store
            .select("deliveryMethodAddress")
            .pipe(first())
            .subscribe((deliveryState) => {
              const oldDeliveryCost = _.cloneDeep(this.deliveryCost);

              this.calculateDeliveryCost(
                _.find(_.cloneDeep(deliveryState.validStores || []), {
                  store_id: this.currentStoreId,
                }),
                _.cloneDeep(deliveryState.selectedAddress),
                null,
                deliveryState.orderPickup,
                deliveryState.dineIn
              );
            })
            .unsubscribe();

          this.calculateFinalPriceDebounce();
        }
      });

    this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.migration_customer_id, state.migration_customer_id)
        ) {
          this.migration_customer_id = _.cloneDeep(state.migration_customer_id);

          this.calculateFinalPriceDebounce();
        }
      });

    this.store
      .select("cart")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && !_.isEqual(this.cartProducts, state.products)) {
          this.cartProducts = _.cloneDeep(state.products);

          this.calculateFinalPriceDebounce();
        }
      });

    this.store
      .select("cartCustomerOptions")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.cartCustomerOptions &&
          !_.isEqual(this.cartCustomerOptions, state.cartCustomerOptions)
        ) {
          let callFinalPrice = false;
          if (
            !this.cartCustomerOptions ||
            (state.cartCustomerOptions &&
              state.cartCustomerOptions.length !==
                this.cartCustomerOptions.length)
          ) {
            let intersection = _.intersectionBy(
              _.cloneDeep(state.cartCustomerOptions),
              _.cloneDeep(this.cartCustomerOptions),

              "timestamp"
            );
            let difference;
            if (_.isArray(this.cartCustomerOptions)) {
              difference = _.differenceBy(
                this.cartCustomerOptions.length >
                  state.cartCustomerOptions.length
                  ? _.cloneDeep(this.cartCustomerOptions)
                  : _.cloneDeep(state.cartCustomerOptions),
                intersection,
                "timestamp"
              );
            } else {
              difference = _.differenceBy(
                _.cloneDeep(state.cartCustomerOptions),

                intersection,
                "timestamp"
              );
            }

            console.log("difference option state", state.cartCustomerOptions);
            console.log("difference option", this.cartCustomerOptions);
            console.log("difference", difference);
            if (difference && difference.length) {
              _.each(difference, (option) => {
                if (option && option.choices) {
                  let findOneChoicePrice = _.find(option.choices, (choice) => {
                    if (choice && choice.price) {
                      return choice;
                    }
                  });
                  if (findOneChoicePrice) {
                    callFinalPrice = true;
                  }
                }
              });
            }
          } else {
            _.each(state.cartCustomerOptions, (option) => {
              let previousOption = _.find(this.cartCustomerOptions, {
                timestamp: option.timestamp,
              });
              console.log("previous option", previousOption);
              if (
                previousOption &&
                option &&
                option.choices &&
                option.choices.length &&
                previousOption.choices &&
                previousOption.choices.length !== option.choices.length
              ) {
                //callFinalPrice = true;
                // return;
              } else if (
                (option &&
                  option.choices &&
                  option.choices.length &&
                  !previousOption) ||
                !previousOption.choices ||
                !previousOption.choices.length
              ) {
                callFinalPrice = true;
                return;
              }

              if (previousOption && previousOption.choices) {
                if (
                  !option.choices ||
                  !option.choices.length ||
                  option.choices.length !== previousOption.choices.length
                ) {
                  let oneChoiceWithPrice = _.find(
                    previousOption.choices,
                    (choice) => {
                      if (choice.price) {
                        return choice;
                      } else {
                        return false;
                      }
                    }
                  );
                  if (oneChoiceWithPrice) {
                    callFinalPrice = true;
                  }
                }
                _.each(option.choices, (choice) => {
                  let previousChoice = _.find(previousOption.choices, {
                    timestamp: choice.timestamp,
                  });
                  // console.log(
                  //   "previous choice qty",
                  //   "choice qty",
                  //   previousChoice.qty,
                  //   choice.qty
                  // );
                  console.log(
                    "inside cart custom choice for",
                    choice,
                    previousChoice
                  );
                  if (
                    choice &&
                    choice.price &&
                    previousChoice &&
                    choice.qty !== previousChoice.qty
                  ) {
                    callFinalPrice = true;
                  } else if (!previousChoice && choice && choice.price) {
                    callFinalPrice = true;
                  } else if (
                    previousChoice &&
                    previousChoice.price &&
                    !choice
                  ) {
                    callFinalPrice = true;
                  }
                });
              } else {
                callFinalPrice = true;
              }
            });
          }

          if (callFinalPrice) {
            this.cartCustomerOptions = _.cloneDeep(state.cartCustomerOptions);
            if (this.cartCustomerOptions) {
              console.log(
                "cartCustomerOptionsFinalPrice",
                this.cartCustomerOptions.length
              );
            }

            this.calculateFinalPriceDebounce();
          } else {
            this.cartCustomerOptions = _.cloneDeep(state.cartCustomerOptions);
          }
        }
      });

    this.store //this is only for complete order button
      .select("manuallyTriggerFinalPriceCheckAddress")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.triggerFinalPriceTimestamp &&
          !_.isEqual(
            this.manuallytriggerFinalPriceTimestamp,
            state.triggerFinalPriceTimestamp
          )
        ) {
          this.manuallytriggerFinalPriceTimestamp = _.cloneDeep(
            state.triggerFinalPriceTimestamp
          );
          console.log("finalll price manuallyy trigger from complete order");
          this.finalPriceManuallyTrigger();
        }
      });

    this.store
      .select("stores")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
          this.stores = _.cloneDeep(state.stores);
        }
      });

    this.store
      .select("checkboxPromotions")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.checkboxPromotions &&
          !_.isEqual(this.checkboxPromotions, state.checkboxPromotions)
        ) {
          this.checkboxPromotions = _.cloneDeep(state.checkboxPromotions);
          this.calculateFinalPriceDebounce();
        }
      });

    this.store
      .select("cartActiveCoupons")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && !_.isEqual(this.activeCoupons, state.activeCoupons)) {
          this.activeCoupons = _.cloneDeep(state.activeCoupons);
          console.log("activeCouponsFinalPrice", this.activeCoupons);
          this.calculateFinalPriceDebounce();
        }
      });

    this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
          if (
            this.validStores &&
            this.validStores.length &&
            !this.orderPickup &&
            !state.dineIn
          ) {
            let currentValidStore = _.find(this.validStores, {
              store_id: this.currentStoreId,
            });
            console.log(
              "calc delivery cost",
              currentValidStore,
              state.selectedAddress
            );
            const oldDeliveryCost = _.cloneDeep(this.deliveryCost);
            this.calculateDeliveryCost(
              _.cloneDeep(currentValidStore),
              _.cloneDeep(state.selectedAddress),
              null,
              state.orderPickup,
              state.dineIn
            );
          } else {
            this.deliveryCost = "0";
          }
          this.calculateFinalPriceDebounce();
        }

        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
          if (
            this.validStores &&
            this.validStores.length &&
            !state.orderPickup &&
            !this.dineIn
          ) {
            let currentValidStore = _.find(this.validStores, {
              store_id: this.currentStoreId,
            });
            if (currentValidStore) {
              console.log(
                "calc delivery cost",
                currentValidStore,
                state.selectedAddress
              );
              const oldDeliveryCost = _.cloneDeep(this.deliveryCost);
              this.calculateDeliveryCost(
                _.cloneDeep(currentValidStore),
                _.cloneDeep(state.selectedAddress),
                null,
                state.orderPickup,
                state.dineIn
              );
            } else {
              this.deliveryCost = "0";
            }
          } else {
            this.deliveryCost = "0";
          }
          this.calculateFinalPriceDebounce();
        }
        if (
          state &&
          !_.isEqual(state.selectedPreorderDate, this.selectedPreorderDate)
        ) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          this.calculateFinalPriceDebounce();
        }

        if (
          state &&
          !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          this.calculateFinalPriceDebounce();
        }

        if (state && !_.isEqual(this.validStores, state.validStores)) {
          this.validStores = _.cloneDeep(state.validStores);
          console.log(
            "valid ssstores",
            this.validStores,
            this.orderPickup,
            this.dineIn
          );
          if (
            this.validStores &&
            this.validStores.length &&
            !this.orderPickup &&
            !this.dineIn
          ) {
            let currentValidStore = _.find(this.validStores, {
              store_id: this.currentStoreId,
            });
            if (currentValidStore) {
              console.log(
                "calc delivery cost",
                currentValidStore,
                state.selectedAddress
              );
              const oldDeliveryCost = _.cloneDeep(this.deliveryCost);
              this.calculateDeliveryCost(
                _.cloneDeep(currentValidStore),
                _.cloneDeep(state.selectedAddress),
                null,
                state.orderPickup,
                state.dineIn
              );
            } else {
              this.deliveryCost = "0";
            }
          } else {
            this.deliveryCost = "0";
          }

          this.calculateFinalPriceDebounce();
        }

        if (state && !_.isEqual(this.selectedAddress, state.selectedAddress)) {
          let calcFinalPrice = false;
          if (!this.selectedAddress && state.selectedAddress) {
            calcFinalPrice = true;
          }
          if (
            (!this.selectedAddress && state.selectedAddress) ||
            (this.selectedAddress &&
              this.selectedAddress.address_id &&
              state.selectedAddress &&
              state.selectedAddress.address_id &&
              (this.selectedAddress.address_id !==
                state.selectedAddress.address_id ||
                !_.isEqual(
                  this.selectedAddress.distance_by_store_id,
                  state.selectedAddress.distance_by_store_id
                )))
          ) {
            let currentValidStore = _.find(this.validStores, {
              store_id: this.currentStoreId,
            });

            const oldDeliveryCost = _.cloneDeep(this.deliveryCost);
            this.calculateDeliveryCost(
              _.cloneDeep(currentValidStore),
              _.cloneDeep(state.selectedAddress),
              null,
              state.orderPickup,
              state.dineIn
            );

            calcFinalPrice = true;
          }
          this.selectedAddress = _.cloneDeep(state.selectedAddress);
          if (calcFinalPrice) {
            this.calculateFinalPriceDebounce();
          }
        }
      });

    this.store
      .select("selectedPaymentMethodID")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.selectedPaymentMethodID &&
          !_.isEqual(
            this.selectedPaymentMethodID,
            state.selectedPaymentMethodID
          )
        ) {
          this.selectedPaymentMethodID = _.cloneDeep(
            state.selectedPaymentMethodID
          );
          this.calculateFinalPriceDebounce();
        }
      });

    this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())

      .subscribe((store_info) => {
        if (store_info && !_.isEqual(this.store_info, store_info)) {
          this.store_info = _.cloneDeep(store_info);
        }
      });

    this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
      });

    this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
          this.calculateFinalPriceDebounce();
        }
        if (state && !_.isEqual(state.mo_token, this.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });
  }

  private finalPriceActions() {
    console.log(
      "all atrr from final price debounce func",
      this.stagePath,
      this.cartProducts,
      this.store_info,
      this.cartCustomerOptions,
      this.activeCoupons,
      this.currentUser
    );
    if (!this.disableFinalPrice) {
      this.store.dispatch(
        new DisableFinalPriceActions.SetDisableFinalPrice(true)
      );
      console.log(
        "all atrr from final price debounce func",
        this.stagePath,
        this.cartProducts,
        this.store_info,
        this.cartCustomerOptions,
        this.activeCoupons,
        this.currentUser
      );
      if (
        this.stagePath &&
        this.store_info &&
        this.store_info.store_id &&
        this.currentStoreId &&
        this.store_info.group
      ) {
        console.log("calling calculateFinalPrice");
        if (this.activeCoupons && this.activeCoupons.length > 0) {
          this.activeCouponsSanityCheck(this.activeCoupons);
        }
        let baseLanguage: string = "";
        const langs = _.cloneDeep(this.groupApp.languages);
        const moBaseLanguage = _.find(langs, { mo_base: true });
        if (moBaseLanguage && moBaseLanguage.code) {
          baseLanguage = moBaseLanguage.code;
        }
        console.log("delivery cost", this.deliveryCost);
        this.calculateFinalPrice(
          {
            store_id: this.store_info.store_id,
            group: this.store_info.group,
            baseLanguage: baseLanguage || "el",
            selectedLanguage: this.selectedLanguage
              ? this.selectedLanguage
              : "el",
            pickup: this.orderPickup,
            dinein: this.dineIn,
            dinein_table_id: this.selectedDineInTable
              ? this.selectedDineInTable.table_id
              : null,
            deliveryCost: this.deliveryCost,
            checkboxPromotions: this.checkboxPromotions || [],
            cartCustomOptions: this.cartCustomerOptions || [],
            coupons: this.currentUser ? this.activeCoupons : [],
            cart: this.cartProducts || [],
            preorder: this.selectedPreorderDate ? true : false,
            preorder_day:
              this.selectedPreorderDate && this.selectedPreorderDate.date
                ? this.selectedPreorderDate.date
                : "",
            preorder_time:
              this.selectedPreorderDate && this.selectedPreorderDate.hour
                ? this.selectedPreorderDate.hour
                : "",
            migration_customer_id:
              this.store_info && this.store_info.vatBasedCustomerProductsActive
                ? this.migration_customer_id
                : null,
            selectedPaymentMethodID: this.selectedPaymentMethodID,
            selectedAddress: this.selectedAddress,
            freeDeliveryCostOverAmount: this.freeDeliveryCostOverAmount,
            recommended_products: this.recommended_products || [],
            platform: Capacitor.isNativePlatform() ? "mobile" : "web",
            catalogZeroPricesMode: this.currentUser
              ? this.currentUser.catalogZeroPricesMode
              : null,
          },
          this.mo_token ? this.mo_token : null
        ).subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              this.checkForNotValidCouponsAndPresentAlerts(res);

              if (
                res.customerPromotions &&
                !_.isEmpty(res.customerPromotions) &&
                _.isArray(res.customerPromotions)
              ) {
                this.store.dispatch(
                  new customerPromotionsActions.SetCustomerPromotions(
                    _.cloneDeep(res.customerPromotions)
                  )
                );
              } else {
                this.store.dispatch(
                  new customerPromotionsActions.ClearCustomerPromotions()
                );
              }

              if (
                res.checkboxPromotions &&
                !_.isEmpty(res.checkboxPromotions)
              ) {
                this.store.dispatch(
                  new checkboxPromotionsActions.SetCheckboxPromotions(
                    _.cloneDeep(res.checkboxPromotions)
                  )
                );
              }

              if (res.subTotalNoPromos) {
                this.store.dispatch(
                  new checkboxPromotionsActions.SetSubtotalNoPromos(
                    _.cloneDeep(res.subTotalNoPromos)
                  )
                );
              }

              if (res.coupons && !_.isEmpty(res.coupons)) {
                this.activeCoupons = res.coupons;
                this.store.dispatch(
                  new CartActiveCouponsActions.SetActiveCoupons(
                    _.cloneDeep(res.coupons)
                  )
                );
              } else {
                this.store.dispatch(
                  new CartActiveCouponsActions.SetActiveCoupons(_.cloneDeep([]))
                );
              }
              this.cartProducts = res.cart;
              if (res.cart) {
                this.store.dispatch(
                  new CartActions.SetCartProducts(
                    _.cloneDeep(this.cartProducts)
                  )
                );
                if (navigator.cookieEnabled) {
                  window.localStorage.setItem("cart", JSON.stringify(res.cart));
                }
              }
              this.cartProductsGifts = [];
              _.each(this.cartProducts, (cartProduct) => {
                if (cartProduct.gift) {
                  this.cartProductsGifts.push(cartProduct);
                }
              });
              console.log("cartProductsGifts", this.cartProductsGifts);
              console.log("cartProductsGiftsPrevious", this.previousCartGifts);
              if (
                (this.previousCartGifts &&
                  this.previousCartGifts.length &&
                  this.cartProductsGifts &&
                  this.cartProductsGifts.length &&
                  this.previousCartGifts.length <
                    this.cartProductsGifts.length) ||
                (this.cartProductsGifts &&
                  this.cartProductsGifts.length &&
                  (!this.previousCartGifts ||
                    (_.isArray(this.previousCartGifts) &&
                      !this.previousCartGifts.length)))
              ) {
                let giftsToShowArray: any = _.differenceBy(
                  this.cartProductsGifts,
                  this.previousCartGifts,
                  "cart_id"
                );
                if (giftsToShowArray && giftsToShowArray.length) {
                  this.calculateGiftToShow(giftsToShowArray[0]);
                }
              }

              this.previousCartGifts = [];
              _.each(this.cartProducts, (cartProduct) => {
                if (cartProduct.gift) {
                  this.previousCartGifts.push(cartProduct);
                }
              });

              this.store
                .select("deliveryMethodAddress")
                .pipe(first())
                .subscribe((state) => {
                  let minOrder;
                  if (state.validStores && state.validStores.length > 0) {
                    let validStores = _.cloneDeep(state.validStores);
                    let currentStore = _.find(validStores, {
                      store_id: this.currentStoreId,
                    });
                    console.log(
                      "valid stores",
                      validStores,
                      this.currentStoreId
                    );
                    // console.log("current store", currentStore);
                    if (currentStore) {
                      minOrder = currentStore.minOrder;
                    }
                    // console.log("deliveryCost", deliveryCost);
                    console.log("minOrder", minOrder);
                    console.log(
                      "subtotalOfOmittedProductsFromMinOrder",
                      res.subtotalOfOmittedProductsFromMinOrder
                    );
                  }

                  let subtotalOfOmittedProductsFromMinOrder =
                    res.subtotalOfOmittedProductsFromMinOrder;
                  let amountToMinimumOrder: any;

                  //calculate delivery cost
                  let retryFinalPrice;
                  console.log(
                    "delivery cost first if",
                    state.orderPickup,
                    state.dineIn
                  );
                  if (
                    !state.orderPickup &&
                    !state.dineIn &&
                    parseFloat(subtotalOfOmittedProductsFromMinOrder) > 0
                  ) {
                    let maxDeliveryCharge = 0;

                    _.each(res.cart, (cartProduct) => {
                      if (cartProduct && cartProduct.extra_delivery_charge) {
                        if (
                          maxDeliveryCharge <
                          parseFloat(cartProduct.extra_delivery_charge)
                        ) {
                          maxDeliveryCharge = _.cloneDeep(
                            parseFloat(cartProduct.extra_delivery_charge)
                          );
                        }
                      }
                    });
                    if (
                      res.subtotal === subtotalOfOmittedProductsFromMinOrder &&
                      res.cart &&
                      res.cart.length > 0 &&
                      maxDeliveryCharge
                    ) {
                      if (
                        +parseFloat(this.deliveryCost).toFixed(2) !==
                        maxDeliveryCharge
                      ) {
                        retryFinalPrice = true;
                        console.log("retry final price");
                      }

                      if (
                        +parseFloat(this.deliveryCost).toFixed(2) !==
                        maxDeliveryCharge
                      ) {
                        this.deliveryCost = maxDeliveryCharge.toString();
                        this.deliveryCostReplacedWithPrdMaxDeliveryCharge =
                          true;
                        if (
                          this.deliveryCost &&
                          parseFloat(this.deliveryCost)
                        ) {
                          this.deliveryCost = parseFloat(
                            parseFloat(this.deliveryCost).toFixed(1)
                          ).toFixed(2);
                        }
                      }

                      console.log("deliveryCost", this.deliveryCost);
                    } else {
                      const oldDeliveryCost = _.cloneDeep(this.deliveryCost);

                      const validStore =
                        this.validStores && this.validStores.length
                          ? _.find(_.cloneDeep(this.validStores), {
                              store_id: this.currentStoreId,
                            })
                          : null;

                      this.calculateDeliveryCost(
                        validStore,
                        _.cloneDeep(this.selectedAddress),
                        res,
                        state.orderPickup,
                        state.dineIn
                      );

                      if (
                        this.oldSentValidStores &&
                        !_.isEqual(this.validStores, this.oldSentValidStores)
                      ) {
                        _.each(this.validStores, (validStore) => {
                          _.each(this.oldSentValidStores, (oldValidStore) => {
                            if (
                              validStore.store_id === oldValidStore.store_id
                            ) {
                            }
                            if (
                              !_.isEqual(
                                validStore.delivery_cost_per_kilometer_active,
                                oldValidStore.delivery_cost_per_kilometer_active
                              ) ||
                              !_.isEqual(
                                validStore.charge_after_distance,
                                oldValidStore.charge_after_distance
                              ) ||
                              !_.isEqual(
                                validStore.step_active,
                                oldValidStore.step_active
                              ) ||
                              !_.isEqual(
                                validStore.fixed_charge,
                                oldValidStore.fixed_charge
                              )
                            ) {
                              retryFinalPrice = true;
                            }
                          });
                        });
                      }

                      this.oldSentValidStores = _.cloneDeep(this.validStores);

                      if (oldDeliveryCost !== this.deliveryCost) {
                        console.log(
                          "not equal delivery cost",
                          this.deliveryCost
                        );
                        retryFinalPrice = true;
                      }

                      console.log("deliveryCost", this.deliveryCost);
                    }
                  } else {
                    const oldDeliveryCost = _.cloneDeep(this.deliveryCost);

                    const validStore =
                      this.validStores && this.validStores.length
                        ? _.find(_.cloneDeep(this.validStores), {
                            store_id: this.currentStoreId,
                          })
                        : null;
                    this.calculateDeliveryCost(
                      validStore || null,
                      _.cloneDeep(this.selectedAddress),
                      res,
                      state.orderPickup,
                      state.dineIn
                    );

                    if (
                      this.oldSentValidStores &&
                      !_.isEqual(this.validStores, this.oldSentValidStores)
                    ) {
                      _.each(this.validStores, (validStore) => {
                        _.each(this.oldSentValidStores, (oldValidStore) => {
                          if (validStore.store_id === oldValidStore.store_id) {
                          }
                          if (
                            !_.isEqual(
                              validStore.delivery_cost_per_kilometer_active,
                              oldValidStore.delivery_cost_per_kilometer_active
                            ) ||
                            !_.isEqual(
                              validStore.charge_after_distance,
                              oldValidStore.charge_after_distance
                            ) ||
                            !_.isEqual(
                              validStore.step_active,
                              oldValidStore.step_active
                            ) ||
                            !_.isEqual(
                              validStore.fixed_charge,
                              oldValidStore.fixed_charge
                            )
                          ) {
                            retryFinalPrice = true;
                          }
                        });
                      });
                    }

                    this.oldSentValidStores = _.cloneDeep(this.validStores);

                    if (oldDeliveryCost !== this.deliveryCost) {
                      console.log("not equal delivery cost", this.deliveryCost);
                      retryFinalPrice = true;
                    }
                  }

                  if (
                    parseFloat(subtotalOfOmittedProductsFromMinOrder) > 0 &&
                    res.subtotal === subtotalOfOmittedProductsFromMinOrder &&
                    this.deliveryCost &&
                    parseFloat(this.deliveryCost) > 0
                  ) {
                    console.log("Discount is null");
                    amountToMinimumOrder = null;
                  } else if (
                    !this.orderPickup &&
                    !res.ignoreMinOrder &&
                    this.store_info.minorderGrandTotal &&
                    parseFloat(res.grand_total) -
                      parseFloat(
                        this.deliveryCost ? this.deliveryCost.toString() : "0"
                      ) -
                      parseFloat(
                        res.subtotalOfOmittedCartCustomOptionsFromMinOrder
                      ) -
                      parseFloat(res.subtotalOfOmittedProductsFromMinOrder) -
                      parseFloat(minOrder) <
                      parseFloat(minOrder)
                  ) {
                    amountToMinimumOrder =
                      (parseFloat(res.grand_total) -
                        parseFloat(
                          this.deliveryCost ? this.deliveryCost.toString() : "0"
                        ) -
                        parseFloat(
                          res.subtotalOfOmittedCartCustomOptionsFromMinOrder
                        ) -
                        parseFloat(res.subtotalOfOmittedProductsFromMinOrder) -
                        parseFloat(minOrder)) *
                      -1;
                    amountToMinimumOrder = amountToMinimumOrder.toFixed(2);
                    console.log(
                      "$rootScope.amountToMinimumOrder 1",
                      amountToMinimumOrder
                    );
                    if (
                      amountToMinimumOrder === "0.00" ||
                      parseFloat(amountToMinimumOrder) <= 0
                    ) {
                      amountToMinimumOrder = null;
                    }
                  } else if (
                    !this.orderPickup &&
                    !res.ignoreMinOrder &&
                    !this.store_info.minorderGrandTotal &&
                    parseFloat(res.subtotal) +
                      parseFloat(res.cartCustomOptionsTotalPrice) -
                      parseFloat(
                        this.deliveryCost ? this.deliveryCost.toString() : "0"
                      ) -
                      parseFloat(
                        res.subtotalOfOmittedCartCustomOptionsFromMinOrder
                      ) -
                      parseFloat(res.subtotalOfOmittedProductsFromMinOrder) <
                      parseFloat(minOrder)
                  ) {
                    amountToMinimumOrder =
                      (parseFloat(res.subtotal) +
                        parseFloat(res.cartCustomOptionsTotalPrice) -
                        parseFloat(
                          res.subtotalOfOmittedCartCustomOptionsFromMinOrder
                        ) -
                        parseFloat(res.subtotalOfOmittedProductsFromMinOrder) -
                        parseFloat(minOrder)) *
                      -1;
                    console.log(
                      "$rootScope.amountToMinimumOrder 2",
                      amountToMinimumOrder
                    );
                    amountToMinimumOrder = amountToMinimumOrder.toFixed(2);
                    if (
                      amountToMinimumOrder === "0.00" ||
                      parseFloat(amountToMinimumOrder) <= 0
                    ) {
                      amountToMinimumOrder = null;
                    }
                  } else {
                    console.log("else discount is to be null");

                    amountToMinimumOrder = null;
                  }
                  // let currentOrderPoints: any;
                  // if (this.groupApp.points && this.groupApp.points.rules) {
                  //   var order_rule = _.find(this.groupApp.points.rules, {
                  //     type: "order",
                  //   });

                  //   console.log("groupAppPoints", this.groupApp.points);

                  //   if (order_rule) {
                  //     var amount_for_points = parseFloat(res.subTotalNoPromos);
                  //     console.log("amount_for_points", amount_for_points);
                  //     var points =
                  //       (amount_for_points * order_rule.points_ratio) /
                  //       order_rule.amount_ratio;
                  //     console.log("points", points);
                  //     if (points > 0) {
                  //       currentOrderPoints = points.toFixed();
                  //     } else {
                  //       currentOrderPoints = 0;
                  //     }
                  //   }
                  // } else {
                  //   currentOrderPoints = 0;
                  // }

                  const recommended_products = res.recommended_products;

                  this.calculateRecommendedAppearedProducts(
                    _.cloneDeep(res.cart),
                    recommended_products
                  );

                  this.recommended_products = _.cloneDeep(recommended_products);

                  this.store.dispatch(
                    new RecommendedProductsActions.SetRecommendedProducts(
                      _.cloneDeep(recommended_products || [])
                    )
                  );

                  if (recommended_products && recommended_products.length) {
                    const hasProductsToRecommend = !!_.find(
                      recommended_products,
                      (prd) => {
                        if (prd && !prd.recommendation_appeared) {
                          return prd;
                        }
                      }
                    );

                    if (hasProductsToRecommend) {
                      this.recommendedProductsService.showRecommendedProductsModal();
                    }
                  }

                  let cartPrices = {
                    subtotal: res.subtotal,
                    deliveryCost:
                      state &&
                      state.orderPickup == false &&
                      state.dineIn == false
                        ? parseFloat(this.deliveryCost)
                        : "",
                    minOrder: minOrder,
                    grand_total_without_delivery_tips:
                      res.grand_total_without_delivery_tips,
                    cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax:
                      res.cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax,
                    deliveryTipsTotal: res.deliveryTipsTotal,
                    wrapperTaxTotal: res.wrapperTaxTotal,
                    cartCustomOptionsTotalPrice:
                      res.cartCustomOptionsTotalPrice,
                    grand_total: res.grand_total,
                    discount_amount: res.discount_amount,
                    subTotalNoPromos: res.subTotalNoPromos,
                    subtotalOfOmittedProductsFromMinOrder:
                      res.subtotalOfOmittedProductsFromMinOrder,
                    subtotalOfOmittedCartCustomOptionsFromMinOrder:
                      res.subtotalOfOmittedCartCustomOptionsFromMinOrder,
                    amountToMinimumOrder: amountToMinimumOrder,
                    currentOrderPoints: res.points,
                    points_deducted_from_coupon:
                      res.points_deducted_from_coupon,
                    vatLevelsTotals: res.vatLevelsTotals,
                    vat_total: res.vat_total,
                    deliveryCostReplacedWithPrdMaxDeliveryCharge:
                      this.deliveryCostReplacedWithPrdMaxDeliveryCharge,
                  };
                  this.store.dispatch(
                    new CartPricesActions.SetCartPrices(_.cloneDeep(cartPrices))
                  );
                  this.dispathcingFromFinalPrice = false;

                  if (
                    res.groupAppUpdatedAttributes &&
                    _.isObject(res.groupAppUpdatedAttributes) &&
                    !_.isEmpty(res.groupAppUpdatedAttributes)
                  ) {
                    this.updateGroupAppAttr(res.groupAppUpdatedAttributes);
                  }

                  if (
                    res.storeUpdatedAttributes &&
                    _.isObject(res.storeUpdatedAttributes) &&
                    !_.isEmpty(res.storeUpdatedAttributes)
                  ) {
                    this.updateStoreInfoAttr(res.storeUpdatedAttributes);
                  }

                  if (retryFinalPrice) {
                    retryFinalPrice = false;
                    this.store.dispatch(
                      new DisableFinalPriceActions.SetTriggerFinalPriceTimestamp(
                        Date.now()
                      )
                    );
                  }
                  this.store.dispatch(
                    new DisableFinalPriceActions.SetDisableFinalPrice(false)
                  );
                })
                .unsubscribe();

              this.analyticsService.dmAnalyticsMessage(
                "cart_calculation",
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              );
            } else {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),
                backdropDismiss: false,
                buttons: ["OK"],
              });
              await alert.present();
              this.store.dispatch(
                new ManuallyTriggerFinalPriceCheckAddressActions.SetFinalPriceSuccess(
                  false
                )
              );
              this.store.dispatch(
                new DisableFinalPriceActions.SetDisableFinalPrice(false)
              );
            }
          },
          error: async (error) => {
            console.log(" catch error", error);

            this.store.dispatch(
              new ManuallyTriggerFinalPriceCheckAddressActions.SetFinalPriceSuccess(
                false
              )
            );
            this.store.dispatch(
              new DisableFinalPriceActions.SetDisableFinalPrice(false)
            );

            if (
              error &&
              error.status !== 401 &&
              error.statusText !== "Unauthorized"
            ) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                ),

                buttons: ["OK"],
              });
              await alert.present();
              return error;
            }
          },
        });
      } else {
        this.store.dispatch(
          new DisableFinalPriceActions.SetDisableFinalPrice(false)
        );
        // this.calculateFinalPriceDebounce();
        //called two times thats why comment out
      }
    } else {
      console.log("finalPrice is disabled");
    }
    console.log("debounced function");
  }

  private calculateFinalPriceDebounce = _.debounce(this.finalPriceActions, 600);

  finalPriceManuallyTrigger() {
    if (!this.disableFinalPrice) {
      this.store.dispatch(
        new DisableFinalPriceActions.SetDisableFinalPrice(true)
      );
      this.store.dispatch(
        new ManuallyTriggerFinalPriceCheckAddressActions.SetFinalPriceSuccess(
          false
        )
      );
      console.log(
        "all atrr from final price debounce func",
        this.stagePath,
        this.cartProducts,
        this.store_info,
        this.cartCustomerOptions,
        this.activeCoupons,
        this.currentUser
      );
      if (
        this.stagePath &&
        this.store_info &&
        this.store_info.store_id &&
        this.currentStoreId &&
        this.store_info.group
      ) {
        console.log("calling calculateFinalPrice");
        if (this.activeCoupons && this.activeCoupons.length > 0) {
          this.activeCouponsSanityCheck(this.activeCoupons);
        }
        let baseLanguage: string = "";
        const langs = _.cloneDeep(this.groupApp.languages);
        const moBaseLanguage = _.find(langs, { mo_base: true });
        if (moBaseLanguage && moBaseLanguage.code) {
          baseLanguage = moBaseLanguage.code;
        }
        console.log("delivery cost", this.deliveryCost);
        this.calculateFinalPrice(
          {
            store_id: this.store_info.store_id,
            group: this.store_info.group,
            baseLanguage: baseLanguage || "el",
            selectedLanguage: this.selectedLanguage
              ? this.selectedLanguage
              : "el",
            pickup: this.orderPickup,
            dinein: this.dineIn,
            dinein_table_id: this.selectedDineInTable
              ? this.selectedDineInTable.table_id
              : null,
            deliveryCost: this.deliveryCost,
            checkboxPromotions: this.checkboxPromotions || [],
            cartCustomOptions: this.cartCustomerOptions || [],
            coupons: this.currentUser ? this.activeCoupons : [],
            cart: this.cartProducts || [],
            preorder: this.selectedPreorderDate ? true : false,
            preorder_day:
              this.selectedPreorderDate && this.selectedPreorderDate.date
                ? this.selectedPreorderDate.date
                : "",
            preorder_time:
              this.selectedPreorderDate && this.selectedPreorderDate.hour
                ? this.selectedPreorderDate.hour
                : "",
            migration_customer_id:
              this.store_info && this.store_info.vatBasedCustomerProductsActive
                ? this.migration_customer_id
                : null,

            selectedPaymentMethodID: this.selectedPaymentMethodID,
            selectedAddress: this.selectedAddress,
            freeDeliveryCostOverAmount: this.freeDeliveryCostOverAmount,
            recommended_products: this.recommended_products || [],
            platform: Capacitor.isNativePlatform() ? "mobile" : "web",
            catalogZeroPricesMode: this.currentUser
              ? this.currentUser.catalogZeroPricesMode
              : null,
          },
          this.mo_token ? this.mo_token : null
        )
          .pipe(
            catchError((err) => {
              console.log("pipe catch error", err);
              this.store.dispatch(
                new ManuallyTriggerFinalPriceCheckAddressActions.SetFinalPriceSuccess(
                  false
                )
              );
              this.store.dispatch(
                new DisableFinalPriceActions.SetDisableFinalPrice(false)
              );
              return err;
            })
          )
          .subscribe({
            next: async (res: any) => {
              if (res && res.success) {
                this.checkForNotValidCouponsAndPresentAlerts(res);

                if (
                  res.customerPromotions &&
                  !_.isEmpty(res.customerPromotions) &&
                  _.isArray(res.customerPromotions)
                ) {
                  this.store.dispatch(
                    new customerPromotionsActions.SetCustomerPromotions(
                      _.cloneDeep(res.customerPromotions)
                    )
                  );
                } else {
                  this.store.dispatch(
                    new customerPromotionsActions.ClearCustomerPromotions()
                  );
                }

                if (
                  res.checkboxPromotions &&
                  !_.isEmpty(res.checkboxPromotions)
                ) {
                  this.store.dispatch(
                    new checkboxPromotionsActions.SetCheckboxPromotions(
                      _.cloneDeep(res.checkboxPromotions)
                    )
                  );
                }

                if (res.subTotalNoPromos) {
                  this.store.dispatch(
                    new checkboxPromotionsActions.SetSubtotalNoPromos(
                      _.cloneDeep(res.subTotalNoPromos)
                    )
                  );
                }

                if (res.coupons && !_.isEmpty(res.coupons)) {
                  this.activeCoupons = res.coupons;
                  this.store.dispatch(
                    new CartActiveCouponsActions.SetActiveCoupons(
                      _.cloneDeep(res.coupons)
                    )
                  );
                } else {
                  this.store.dispatch(
                    new CartActiveCouponsActions.SetActiveCoupons(
                      _.cloneDeep([])
                    )
                  );
                }
                this.cartProducts = res.cart;
                if (res.cart) {
                  this.store.dispatch(
                    new CartActions.SetCartProducts(
                      _.cloneDeep(this.cartProducts)
                    )
                  );
                  if (navigator.cookieEnabled) {
                    window.localStorage.setItem(
                      "cart",
                      JSON.stringify(res.cart)
                    );
                  }
                }
                this.cartProductsGifts = 0;
                _.each(this.cartProducts, (cartProduct) => {
                  if (cartProduct.gift) {
                    this.cartProductsGifts++;
                  }
                });
                //console.log("giiifts", this.cartProductsGifts);
                if (this.previousCartGifts < this.cartProductsGifts) {
                  let gift = _.find(this.cartProducts, (product) => {
                    if (product.gift) {
                      this.calculateGiftToShow(gift);
                    }
                  });
                }

                this.previousCartGifts = 0;
                _.each(this.cartProducts, (cartProduct) => {
                  if (cartProduct.gift) {
                    this.previousCartGifts++;
                  }
                });

                this.store
                  .select("deliveryMethodAddress")
                  .pipe(first())
                  .subscribe((state) => {
                    let minOrder;
                    if (state.validStores && state.validStores.length > 0) {
                      let validStores = _.cloneDeep(state.validStores);
                      let currentStore = _.find(validStores, {
                        store_id: this.currentStoreId,
                      });
                      console.log(
                        "valid stores",
                        validStores,
                        this.currentStoreId
                      );
                      console.log("current store", currentStore);
                      if (currentStore) {
                        minOrder = currentStore.minOrder;
                      }

                      console.log("minOrder", minOrder);
                      console.log(
                        "subtotalOfOmittedProductsFromMinOrder",
                        res.subtotalOfOmittedProductsFromMinOrder
                      );
                    }

                    let subtotalOfOmittedProductsFromMinOrder =
                      res.subtotalOfOmittedProductsFromMinOrder;
                    let amountToMinimumOrder: any;

                    //calculate delivery cost
                    let retryFinalPrice = false;
                    if (
                      !state.orderPickup &&
                      !state.dineIn &&
                      parseFloat(subtotalOfOmittedProductsFromMinOrder) > 0
                    ) {
                      let maxDeliveryCharge = 0;

                      _.each(res.cart, (cartProduct) => {
                        if (cartProduct && cartProduct.extra_delivery_charge) {
                          if (
                            maxDeliveryCharge <
                            parseFloat(cartProduct.extra_delivery_charge)
                          ) {
                            maxDeliveryCharge = _.cloneDeep(
                              parseFloat(cartProduct.extra_delivery_charge)
                            );
                          }
                        }
                      });

                      if (
                        res.subtotal ===
                          subtotalOfOmittedProductsFromMinOrder &&
                        res.cart &&
                        res.cart.length > 0 &&
                        maxDeliveryCharge
                      ) {
                        if (
                          parseFloat(this.deliveryCost) !== maxDeliveryCharge
                        ) {
                          retryFinalPrice = true;
                          console.log("retry final price");
                        }

                        if (
                          +parseFloat(this.deliveryCost).toFixed(2) !==
                          maxDeliveryCharge
                        ) {
                          this.deliveryCost = maxDeliveryCharge.toString();
                          this.deliveryCostReplacedWithPrdMaxDeliveryCharge =
                            true;
                          if (
                            this.deliveryCost &&
                            parseFloat(this.deliveryCost)
                          ) {
                            this.deliveryCost = parseFloat(
                              parseFloat(this.deliveryCost).toFixed(1)
                            ).toFixed(2);
                          }
                          console.log(
                            "assign delivery cost",
                            this.deliveryCost
                          );
                        }
                      } else {
                        const oldDeliveryCost = _.cloneDeep(this.deliveryCost);

                        this.calculateDeliveryCost(
                          this.validStores && this.validStores.length
                            ? _.find(_.cloneDeep(this.validStores), {
                                store_id: this.currentStoreId,
                              })
                            : null,
                          _.cloneDeep(this.selectedAddress),
                          res,
                          state.orderPickup,
                          state.dineIn
                        );

                        if (
                          this.oldSentValidStores &&
                          !_.isEqual(this.validStores, this.oldSentValidStores)
                        ) {
                          _.each(this.validStores, (validStore) => {
                            _.each(this.oldSentValidStores, (oldValidStore) => {
                              if (
                                validStore.store_id === oldValidStore.store_id
                              ) {
                              }
                              if (
                                !_.isEqual(
                                  validStore.delivery_cost_per_kilometer_active,
                                  oldValidStore.delivery_cost_per_kilometer_active
                                ) ||
                                !_.isEqual(
                                  validStore.charge_after_distance,
                                  oldValidStore.charge_after_distance
                                ) ||
                                !_.isEqual(
                                  validStore.step_active,
                                  oldValidStore.step_active
                                ) ||
                                !_.isEqual(
                                  validStore.fixed_charge,
                                  oldValidStore.fixed_charge
                                )
                              ) {
                                retryFinalPrice = true;
                              }
                            });
                          });
                        }

                        this.oldSentValidStores = _.cloneDeep(this.validStores);

                        if (oldDeliveryCost !== this.deliveryCost) {
                          console.log(
                            "not equal delivery cost",
                            this.deliveryCost
                          );
                          retryFinalPrice = true;
                        }
                        console.log("deliveryCost", this.deliveryCost);
                      }
                    } else {
                      const oldDeliveryCost = _.cloneDeep(this.deliveryCost);

                      const validStore =
                        this.validStores && this.validStores.length
                          ? _.find(_.cloneDeep(this.validStores), {
                              store_id: this.currentStoreId,
                            })
                          : null;
                      this.calculateDeliveryCost(
                        validStore || null,
                        _.cloneDeep(this.selectedAddress),
                        res,
                        state.orderPickup,
                        state.dineIn
                      );

                      if (
                        this.oldSentValidStores &&
                        !_.isEqual(this.validStores, this.oldSentValidStores)
                      ) {
                        _.each(this.validStores, (validStore) => {
                          _.each(this.oldSentValidStores, (oldValidStore) => {
                            if (
                              validStore.store_id === oldValidStore.store_id
                            ) {
                            }
                            if (
                              !_.isEqual(
                                validStore.delivery_cost_per_kilometer_active,
                                oldValidStore.delivery_cost_per_kilometer_active
                              ) ||
                              !_.isEqual(
                                validStore.charge_after_distance,
                                oldValidStore.charge_after_distance
                              ) ||
                              !_.isEqual(
                                validStore.step_active,
                                oldValidStore.step_active
                              ) ||
                              !_.isEqual(
                                validStore.fixed_charge,
                                oldValidStore.fixed_charge
                              )
                            ) {
                              retryFinalPrice = true;
                            }
                          });
                        });
                      }

                      this.oldSentValidStores = _.cloneDeep(this.validStores);

                      if (oldDeliveryCost !== this.deliveryCost) {
                        console.log(
                          "not equal delivery cost",
                          this.deliveryCost
                        );
                        retryFinalPrice = true;
                      }
                    }

                    if (
                      parseFloat(subtotalOfOmittedProductsFromMinOrder) > 0 &&
                      res.subtotal === subtotalOfOmittedProductsFromMinOrder &&
                      this.deliveryCost &&
                      parseFloat(this.deliveryCost) > 0
                    ) {
                      console.log("Discount is null");
                      amountToMinimumOrder = null;
                    } else if (
                      !state.orderPickup &&
                      !res.ignoreMinOrder &&
                      this.store_info.minorderGrandTotal &&
                      parseFloat(res.grand_total) -
                        parseFloat(
                          res.subtotalOfOmittedCartCustomOptionsFromMinOrder
                        ) -
                        parseFloat(
                          this.deliveryCost ? this.deliveryCost.toString() : "0"
                        ) -
                        parseFloat(res.subtotalOfOmittedProductsFromMinOrder) -
                        parseFloat(minOrder) <
                        parseFloat(minOrder)
                    ) {
                      amountToMinimumOrder =
                        (parseFloat(res.grand_total) -
                          parseFloat(
                            this.deliveryCost
                              ? this.deliveryCost.toString()
                              : "0"
                          ) -
                          parseFloat(
                            res.subtotalOfOmittedCartCustomOptionsFromMinOrder
                          ) -
                          parseFloat(
                            res.subtotalOfOmittedProductsFromMinOrder
                          ) -
                          parseFloat(minOrder)) *
                        -1;
                      amountToMinimumOrder = amountToMinimumOrder.toFixed(2);
                      console.log(
                        "$rootScope.amountToMinimumOrder 1",
                        amountToMinimumOrder
                      );
                      if (
                        amountToMinimumOrder === "0.00" ||
                        parseFloat(amountToMinimumOrder) <= 0
                      ) {
                        amountToMinimumOrder = null;
                      }
                    } else if (
                      !state.orderPickup &&
                      !res.ignoreMinOrder &&
                      !this.store_info.minorderGrandTotal &&
                      parseFloat(res.subtotal) +
                        parseFloat(res.cartCustomOptionsTotalPrice) -
                        parseFloat(
                          res.subtotalOfOmittedCartCustomOptionsFromMinOrder
                        ) -
                        parseFloat(
                          this.deliveryCost ? this.deliveryCost.toString() : "0"
                        ) -
                        parseFloat(res.subtotalOfOmittedProductsFromMinOrder) <
                        parseFloat(minOrder)
                    ) {
                      amountToMinimumOrder =
                        (parseFloat(res.subtotal) +
                          parseFloat(res.cartCustomOptionsTotalPrice) -
                          parseFloat(
                            res.subtotalOfOmittedCartCustomOptionsFromMinOrder
                          ) -
                          parseFloat(
                            res.subtotalOfOmittedProductsFromMinOrder
                          ) -
                          parseFloat(minOrder)) *
                        -1;
                      console.log(
                        "$rootScope.amountToMinimumOrder 2",
                        amountToMinimumOrder
                      );
                      amountToMinimumOrder = amountToMinimumOrder.toFixed(2);
                      if (
                        amountToMinimumOrder === "0.00" ||
                        parseFloat(amountToMinimumOrder) <= 0
                      ) {
                        amountToMinimumOrder = null;
                      }
                    } else {
                      console.log("else discount is to be null");

                      amountToMinimumOrder = null;
                    }
                    // let currentOrderPoints: any;
                    // if (this.groupApp.points && this.groupApp.points.rules) {
                    //   var order_rule = _.find(this.groupApp.points.rules, {
                    //     type: "order",
                    //   });

                    //   if (order_rule) {
                    //     var amount_for_points = parseFloat(
                    //       res.subTotalNoPromos
                    //     );
                    //     console.log("amount_for_points", amount_for_points);
                    //     var points =
                    //       (amount_for_points * order_rule.points_ratio) /
                    //       order_rule.amount_ratio;
                    //     console.log("points", points);
                    //     if (points > 0) {
                    //       currentOrderPoints = points.toFixed();
                    //     } else {
                    //       currentOrderPoints = 0;
                    //     }
                    //   }
                    // } else {
                    //   currentOrderPoints = 0;
                    // }

                    const recommended_products = res.recommended_products;

                    this.calculateRecommendedAppearedProducts(
                      _.cloneDeep(res.cart),
                      recommended_products
                    );
                    this.recommended_products =
                      _.cloneDeep(recommended_products);

                    this.store.dispatch(
                      new RecommendedProductsActions.SetRecommendedProducts(
                        _.cloneDeep(recommended_products || [])
                      )
                    );

                    if (recommended_products && recommended_products.length) {
                      const hasProductsToRecommend = !!_.find(
                        recommended_products,
                        (prd) => {
                          if (prd && !prd.recommendation_appeared) {
                            return prd;
                          }
                        }
                      );

                      if (hasProductsToRecommend) {
                        this.recommendedProductsService.showRecommendedProductsModal();
                      }
                    }

                    let cartPrices = {
                      subtotal: res.subtotal,
                      deliveryCost:
                        state &&
                        state.orderPickup == false &&
                        state.dineIn == false
                          ? parseFloat(this.deliveryCost)
                          : "",
                      minOrder: minOrder,
                      grand_total_without_delivery_tips:
                        res.grand_total_without_delivery_tips,
                      cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax:
                        res.cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax,
                      deliveryTipsTotal: res.deliveryTipsTotal,
                      wrapperTaxTotal: res.wrapperTaxTotal,
                      cartCustomOptionsTotalPrice:
                        res.cartCustomOptionsTotalPrice,
                      grand_total: res.grand_total,
                      discount_amount: res.discount_amount,
                      subTotalNoPromos: res.subTotalNoPromos,
                      subtotalOfOmittedCartCustomOptionsFromMinOrder:
                        res.subtotalOfOmittedCartCustomOptionsFromMinOrder,
                      subtotalOfOmittedProductsFromMinOrder:
                        res.subtotalOfOmittedProductsFromMinOrder,
                      amountToMinimumOrder: amountToMinimumOrder,
                      currentOrderPoints: res.points,
                      points_deducted_from_coupon:
                        res.points_deducted_from_coupon,
                      vatLevelsTotals: res.vatLevelsTotals,
                      vat_total: res.vat_total,
                      deliveryCostReplacedWithPrdMaxDeliveryCharge:
                        this.deliveryCostReplacedWithPrdMaxDeliveryCharge,
                    };
                    this.store.dispatch(
                      new CartPricesActions.SetCartPrices(
                        _.cloneDeep(cartPrices)
                      )
                    );
                    this.dispathcingFromFinalPrice = false;
                    if (retryFinalPrice) {
                      retryFinalPrice = false;
                      this.store.dispatch(
                        new DisableFinalPriceActions.SetTriggerFinalPriceTimestamp(
                          Date.now()
                        )
                      );
                    }
                    this.store.dispatch(
                      new DisableFinalPriceActions.SetDisableFinalPrice(false)
                    );
                  })
                  .unsubscribe();

                if (
                  res.groupAppUpdatedAttributes &&
                  _.isObject(res.groupAppUpdatedAttributes) &&
                  !_.isEmpty(res.groupAppUpdatedAttributes)
                ) {
                  this.updateGroupAppAttr(res.groupAppUpdatedAttributes);
                }

                if (
                  res.storeUpdatedAttributes &&
                  _.isObject(res.storeUpdatedAttributes) &&
                  !_.isEmpty(res.storeUpdatedAttributes)
                ) {
                  this.updateStoreInfoAttr(res.storeUpdatedAttributes);
                }

                this.store.dispatch(
                  new ManuallyTriggerFinalPriceCheckAddressActions.SetFinalPriceSuccess(
                    true
                  )
                );
                this.analyticsService.dmAnalyticsMessage(
                  "cart_calculation",
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null
                );
              } else {
                //TO_DO_NOW ERROR MESSAGE
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message:
                    res && res.comment_id
                      ? this.translateService.instant(res.comment_id)
                      : this.translateService.instant(
                          "errorMessages.general_error"
                        ),
                  backdropDismiss: false,
                  buttons: ["OK"],
                });
                await alert.present();
                this.store.dispatch(
                  new ManuallyTriggerFinalPriceCheckAddressActions.SetFinalPriceSuccess(
                    false
                  )
                );
                this.store.dispatch(
                  new DisableFinalPriceActions.SetDisableFinalPrice(false)
                );
              }
            },
            error: async (error) => {
              console.log(" catch error", error);

              this.store.dispatch(
                new ManuallyTriggerFinalPriceCheckAddressActions.SetFinalPriceSuccess(
                  false
                )
              );
              this.store.dispatch(
                new DisableFinalPriceActions.SetDisableFinalPrice(false)
              );

              if (
                error &&
                error.status !== 401 &&
                error.statusText !== "Unauthorized"
              ) {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(
                    "errorMessages.problem_reaching_server"
                  ),

                  buttons: ["OK"],
                });
                await alert.present();
              }

              return error;
            },
          });
      } else {
        this.store.dispatch(
          new ManuallyTriggerFinalPriceCheckAddressActions.SetFinalPriceSuccess(
            false
          )
        );
        this.store.dispatch(
          new DisableFinalPriceActions.SetDisableFinalPrice(false)
        );
        // this.calculateFinalPriceDebounce();
      }
    } else {
      console.log("finalPrice is disabled");
    }
    console.log("debounced function");
  }

  updateGroupAppAttr(attributesUpdateObject) {
    if (this.groupApp && attributesUpdateObject) {
      const newGroupApp = _.assign(
        _.cloneDeep(this.groupApp),
        attributesUpdateObject
      );

      this.store.dispatch(new groupAppActions.SetGroupApp(newGroupApp));
    }
  }

  updateStoreInfoAttr(attributesUpdateObject) {
    if (this.store_info && attributesUpdateObject) {
      const newStoreInfo = _.assign(
        _.cloneDeep(this.store_info),
        attributesUpdateObject
      );

      const store_index = _.findIndex(this.stores, {
        store_id: newStoreInfo.store_id,
      });

      if (store_index !== -1) {
        const updatedStores = _.cloneDeep(this.stores);
        updatedStores[store_index] = newStoreInfo;

        this.store.dispatch(new storesActions.SetStores(updatedStores));
      }
    }
  }

  checkForNotValidCouponsAndPresentAlerts(response) {
    if (this.activeCoupons && this.activeCoupons.length) {
      const foundProductWithoutPromo = _.find(this.cartProducts, (product) => {
        if (!product.promo) {
          return product;
        }
      });

      if (
        _.find(this.activeCoupons, { couponCheckbox: true }) &&
        !_.find(response.coupons, { couponCheckbox: true }) &&
        !foundProductWithoutPromo &&
        response.cart &&
        response.cart.length > 0
      ) {
        console.log("with this promo cart you cant use a coupon");
        this.store.dispatch(
          new CartActions.SetPromoCartNoCouponTimestamp(Date.now().toString())
        );
      } else if (
        _.find(this.activeCoupons, {
          couponCheckbox: true,
          preorder_only: true,
          validDate: true,
        }) &&
        !_.find(response.coupons, { couponCheckbox: true }) &&
        (!this.selectedPreorderDate ||
          !this.selectedPreorderDate.date ||
          !this.selectedPreorderDate.hour)
      ) {
        console.log(
          "you dont selected preorder so you cant use preorder only coupons"
        );

        const selectedCoupon = _.find(this.activeCoupons, {
          couponCheckbox: true,
        });

        const responseSelectedCoupon = _.find(response.coupons, {
          timestamp: selectedCoupon.timestamp,
        });

        if (responseSelectedCoupon.validDate) {
          this.store.dispatch(
            new CartActions.setPreorderOnlyNoCouponTimestamp(
              Date.now().toString()
            )
          );
        }
      } else if (
        _.find(this.activeCoupons, {
          couponCheckbox: true,
          online_payment_only: true,
          validDate: true,
        }) &&
        !_.find(response.coupons, { couponCheckbox: true })
      ) {
        console.log(
          "Coupon is only for online payment cant use cash or Mobile POS"
        );

        const selectedCoupon = _.find(this.activeCoupons, {
          couponCheckbox: true,
        });

        const responseSelectedCoupon = _.find(response.coupons, {
          timestamp: selectedCoupon.timestamp,
        });

        if (responseSelectedCoupon.validDate) {
          this.store.dispatch(
            new CartActions.setOnlinePaymentOnlyNoCouponTimestamp(
              Date.now().toString()
            )
          );
        }
      }
    }
  }

  showProductDetailsModal(product, cart_id) {
    let detailsCssClass;
    if (
      product &&
      product.options &&
      product.options.length < 2 &&
      product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      !product.squareImageDesign &&
      product.options &&
      product.options.length > 2 &&
      !product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.options &&
      product.options.length > 2 &&
      !product.noImageProduct &&
      this.windowDimensions &&
      this.windowDimensions.width >= 1200
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }

    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, cart_id, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, cart_id, detailsCssClass) {
    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product.product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  public closeProductDetailsModal() {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
  }
  private calculateFinalPrice(data, mo_token) {
    if (mo_token) {
      return this.http
        .post(
          "https://api.deliverymanager.gr/" +
            this.stagePath +
            "/calculateFinalPriceForCustomer",
          JSON.stringify(data),
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json; charset=utf-8",
              Authorization: mo_token,
            }),
          }
        )
        .pipe(timeout(20000))
        .pipe(retry(2));
    } else {
      return this.http
        .post(
          "https://api.deliverymanager.gr/" +
            this.stagePath +
            "/calculateFinalPrice",
          JSON.stringify(data),
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json; charset=utf-8",
            }),
          }
        )
        .pipe(timeout(20000))
        .pipe(retry(2));
    }
  }

  calculateDeliveryCostByKmCases(
    charge_after_distance,
    step_active,
    step_distance,
    fixed_charge,
    distance_by_store_id,
    currentValidStore
  ) {
    const getDeliveryCost = (multiplier, fixedCharge) => {
      let deliveryCost: any = currentValidStore.deliveryCost
        ? parseFloat(
            currentValidStore.deliveryCost.toString().replace(",", ".")
          )
        : 0;

      deliveryCost = (deliveryCost * multiplier + (fixedCharge || 0)).toFixed(
        2
      );

      if (deliveryCost && parseFloat(deliveryCost.toString())) {
        deliveryCost = parseFloat(
          parseFloat(deliveryCost.toString()).toFixed(1)
        ).toFixed(2);
      }

      return deliveryCost;
    };

    const calculateDistanceToCharge = () => {
      let distanceToCharge = distance_by_store_id * 1000;
      if (charge_after_distance) {
        distanceToCharge -= charge_after_distance;
        if (distanceToCharge < 0) {
          distanceToCharge = 0;
        }
      }
      return distanceToCharge || 0;
    };

    const distanceToCharge = calculateDistanceToCharge();

    if (step_active && step_distance) {
      const numberOfSteps = Math.floor(distanceToCharge / step_distance);
      if (numberOfSteps >= 0) {
        this.deliveryCost = getDeliveryCost(numberOfSteps, fixed_charge);
        console.log("assign delivery cost", this.deliveryCost);
      } else {
        console.log("ERROR: cant find distance by store id attr");
      }
    } else {
      const distanceInKm = charge_after_distance
        ? parseFloat((distanceToCharge / 1000).toFixed(2))
        : distance_by_store_id;
      if (distanceInKm >= 0) {
        this.deliveryCost = getDeliveryCost(distanceInKm, fixed_charge);
        console.log("assign delivery cost", this.deliveryCost);
      } else {
        console.log("ERROR: cant find distance by store id attr");
      }
    }
  }

  private calculateDeliveryCost(
    currentValidStore,
    selectedAddress,
    calculateResponse,
    orderPickup,
    dineIn
  ) {
    this.deliveryCostReplacedWithPrdMaxDeliveryCharge = false;
    if (!(orderPickup === false && dineIn === false)) {
      this.deliveryCost = "0";
    } else {
      if (
        currentValidStore &&
        currentValidStore.delivery_cost_per_kilometer_active
      ) {
        let distance_by_store_id;
        if (
          currentValidStore.calculation_type &&
          currentValidStore.calculation_type === "radius"
        ) {
          distance_by_store_id =
            selectedAddress &&
            selectedAddress.radius_distance_by_store_id &&
            (selectedAddress.radius_distance_by_store_id[
              currentValidStore.store_id
            ] ||
              selectedAddress.radius_distance_by_store_id[
                currentValidStore.store_id
              ] === 0)
              ? selectedAddress.radius_distance_by_store_id[
                  currentValidStore.store_id
                ]
              : null;

          if (!distance_by_store_id) {
            //TODO DELETE THIS AND PUT IT TO CHECK ADDRESS

            distance_by_store_id =
              selectedAddress &&
              selectedAddress.distance_by_store_id &&
              selectedAddress.distance_by_store_id[
                currentValidStore.store_id
              ] &&
              (selectedAddress.distance_by_store_id[currentValidStore.store_id]
                .distance ||
                selectedAddress.distance_by_store_id[currentValidStore.store_id]
                  .distance === 0) &&
              selectedAddress.distance_by_store_id[currentValidStore.store_id]
                .estimated
                ? selectedAddress.distance_by_store_id[
                    currentValidStore.store_id
                  ].distance
                : null;
          }
        } else {
          distance_by_store_id =
            selectedAddress &&
            selectedAddress.distance_by_store_id &&
            selectedAddress.distance_by_store_id[currentValidStore.store_id] &&
            (selectedAddress.distance_by_store_id[currentValidStore.store_id]
              .distance ||
              selectedAddress.distance_by_store_id[currentValidStore.store_id]
                .distance === 0)
              ? selectedAddress.distance_by_store_id[currentValidStore.store_id]
                  .distance
              : null;
        }

        console.log("distance_by_store_id", distance_by_store_id);

        const stepRoundUp = (num, param) => {
          if (param <= 0) {
            throw new Error("Parameter must be a positive number");
          }
          return Math.ceil(num / param) * param;
        };

        if (currentValidStore.distance_charge_type === "levels") {
          if (
            currentValidStore.step_active &&
            currentValidStore.step_distance
          ) {
            distance_by_store_id = stepRoundUp(
              parseFloat(
                parseFloat((distance_by_store_id * 1000).toString()).toFixed(2)
              ),
              currentValidStore.step_distance
            );
          } else {
            distance_by_store_id = stepRoundUp(
              parseFloat(
                parseFloat((distance_by_store_id * 1000).toString()).toFixed(2)
              ),
              1000
            );
          }

          distance_by_store_id = parseFloat(
            parseFloat(
              (parseFloat(distance_by_store_id) / 1000).toString()
            ).toFixed(2)
          );
        }

        this.calculateDeliveryCostByKmCases(
          currentValidStore.charge_after_distance,
          currentValidStore.step_active,
          currentValidStore.step_distance,
          currentValidStore.fixed_charge,
          distance_by_store_id,
          currentValidStore
        );
      } else {
        if (currentValidStore) {
          this.deliveryCost = currentValidStore.deliveryCost
            ? currentValidStore.deliveryCost.toString().replace(",", ".")
            : 0;

          if (this.deliveryCost && parseFloat(this.deliveryCost)) {
            this.deliveryCost = parseFloat(
              parseFloat(this.deliveryCost).toFixed(1)
            ).toFixed(2);
          }
        }
      }
    }
    if (calculateResponse && !_.isEmpty(calculateResponse)) {
      this.checkFreeDeliveryOverAmount(
        currentValidStore,
        calculateResponse.grand_total,
        calculateResponse.subtotal,
        calculateResponse.cartCustomOptionsTotalPrice,
        calculateResponse.subtotalOfOmittedCartCustomOptionsFromMinOrder,
        calculateResponse.subtotalOfOmittedProductsFromMinOrder,
        calculateResponse.freeDeliveryCostOverAmount
      );
    }
  }

  private checkFreeDeliveryOverAmount(
    currentValidStore,
    grand_total,
    subtotal,
    cartCustomOptionsTotalPrice,
    subtotalOfOmittedCartCustomOptionsFromMinOrder,
    subtotalOfOmittedProductsFromMinOrder,
    freeDeliveryCostOverAmount
  ) {
    console.log(
      "checkFreeDeliveryOverAmount",
      currentValidStore,
      grand_total,
      subtotal,
      cartCustomOptionsTotalPrice,
      subtotalOfOmittedCartCustomOptionsFromMinOrder,
      subtotalOfOmittedProductsFromMinOrder,
      this.deliveryCost
    );
    if (
      currentValidStore &&
      currentValidStore.free_delivery_over_amount > 0 &&
      this.deliveryCost &&
      this.deliveryCost !== "0"
    ) {
      if (this.store_info.minorderGrandTotal) {
        if (
          parseFloat(grand_total.toString()) -
            (freeDeliveryCostOverAmount
              ? 0
              : parseFloat(
                  this.deliveryCost ? this.deliveryCost.toString() : "0"
                )) -
            parseFloat(subtotalOfOmittedCartCustomOptionsFromMinOrder) -
            parseFloat(subtotalOfOmittedProductsFromMinOrder) >
          parseFloat(currentValidStore.free_delivery_over_amount.toString())
        ) {
          console.log("ZERO DELIVERY COST");
          this.deliveryCost = "0";

          this.freeDeliveryCostOverAmount = true;
          return true;
        } else {
          this.freeDeliveryCostOverAmount = false;
        }
      } else {
        if (
          parseFloat(subtotal) +
            parseFloat(cartCustomOptionsTotalPrice) -
            parseFloat(subtotalOfOmittedCartCustomOptionsFromMinOrder) -
            parseFloat(subtotalOfOmittedProductsFromMinOrder) >
          parseFloat(currentValidStore.free_delivery_over_amount.toString())
        ) {
          console.log("ZERO DELIVERY COST");
          this.deliveryCost = "0";

          this.freeDeliveryCostOverAmount = true;

          return true;
        } else {
          this.freeDeliveryCostOverAmount = false;
        }
      }
    }
    return false;
  }

  private activeCouponsSanityCheck(activeCoupons) {
    let found;
    _.each(activeCoupons, (coupon) => {
      if (coupon.couponCheckbox && !found) {
        found = true;
        return;
      }
      if (coupon.couponCheckbox && found) {
        coupon.couponCheckbox = false;
      }
    });
    console.log("active coupons sanitycheck", activeCoupons);
  }

  private calculateGiftToShow(gift) {
    if (
      gift &&
      gift.options &&
      gift.options.length &&
      gift.options.length > 1
    ) {
      if (this.groupApp && this.groupApp.active_reservation_app) {
        if (
          this.router &&
          this.router.url &&
          this.router.url.indexOf("catalog") === -1 &&
          this.router.url.indexOf("cart") === -1
        ) {
          this.reservationSystemModalsService.closeReservationCouponsSelectionModal();

          this.router
            .navigateByUrl("reservationSystem/reservation-catalog", {
              replaceUrl: true,
            })
            .then(() => {
              setTimeout(() => {
                this.showProductDetailsModal(gift, gift.cart_id);
              }, 1500);
            });
        } else {
          this.showProductDetailsModal(gift, gift.cart_id);
        }
      } else {
        this.showProductDetailsModal(gift, gift.cart_id);
      }
    } else {
      if (
        this.groupApp &&
        this.groupApp.active_reservation_app &&
        this.router &&
        this.router.url &&
        this.router.url.indexOf("catalog") === -1 &&
        this.router.url.indexOf("cart") === -1
      ) {
        this.reservationSystemModalsService.closeReservationCouponsSelectionModal();
        this.router.navigateByUrl("reservationSystem/reservation-catalog", {
          replaceUrl: true,
        });
      }
    }
  }

  private calculateRecommendedAppearedProduct(
    cartProduct,
    recommended_products
  ) {
    _.each(cartProduct.options, (option) => {
      _.each(option.choices, (choice) => {
        if (choice.bundle === true && !_.isEmpty(choice.bundled_product)) {
          this.calculateRecommendedAppearedProduct(
            choice.bundled_product,
            recommended_products
          );
        }
      });
    });

    _.each(recommended_products, (rec_prd, index) => {
      if (
        rec_prd.product_id === cartProduct.product_id ||
        rec_prd.category_id === cartProduct.category_id
      ) {
        recommended_products[index].recommendation_appeared = true;
      }
    });
  }

  private calculateRecommendedAppearedProducts(
    cartProducts,
    recommended_products
  ) {
    _.each(cartProducts, (cartPrd) => {
      this.calculateRecommendedAppearedProduct(cartPrd, recommended_products);
    });
  }
}
