import { Component, OnDestroy, OnInit } from "@angular/core";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as selectors from "../../store/app.selectors";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { CatalogDisclaimerPopupComponent } from "src/app/popups/catalog-disclaimer-popup/catalog-disclaimer-popup.component";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-catalog-disclaimer-button",
  templateUrl: "./catalog-disclaimer-button.component.html",
  styleUrls: ["./catalog-disclaimer-button.component.scss"],
})
export class CatalogDisclaimerButtonComponent implements OnInit, OnDestroy {
  public catalogDisclaimer: any;
  public storeInfo;
  public mo_base: any;
  public groupApp: any;
  public selectedLanguage;
  public static_pages;

  private subscription: Subscription;
  private staticPagesSub: Subscription;
  private groupSubscription: Subscription;
  private catalogDisclaimerModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.subscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });

    this.groupSubscription = this.store
      .select("groupApp")
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }

        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          this.mo_base = _.cloneDeep(
            _.find(state.groupApp.languages, {
              mo_base: true,
            })
          );
          if (!this.mo_base) {
            this.mo_base = "en";
          } else {
            this.mo_base = this.mo_base.code;
          }
        }
      });

    this.staticPagesSub = this.store
      .select("staticPages")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.static_pages, this.static_pages)) {
          this.static_pages = _.cloneDeep(state.static_pages);

          if (this.static_pages) {
            this.catalogDisclaimer = this.static_pages.catalogDisclaimer;

            console.log("catalogDisclaimer", this.catalogDisclaimer);
          }
        }
      });
  }

  async showCatalogDisclaimerPopup() {
    this.catalogDisclaimerModal = await this.modalController.create({
      component: CatalogDisclaimerPopupComponent,
      cssClass: "allergensModal",
      backdropDismiss: false,
    });

    await this.catalogDisclaimerModal.present();

    this.catalogDisclaimerModal.onDidDismiss().then(() => {
      this.catalogDisclaimerModal = null;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }

    if (this.staticPagesSub) {
      this.staticPagesSub.unsubscribe();
    }

    if (this.catalogDisclaimerModal) {
      this.catalogDisclaimerModal.unsubscribe();
    }
  }
}
