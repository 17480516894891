<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text color="primary">
        {{ "popups.active-coupons-promo-modal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item lines="full">
      <ion-label
        style="font-size: small; font-weight: bold"
        class="ion-text-wrap ion-text-center"
      >
        {{
          "popups.active-coupons-promo-modal.you-have-the-above-coupons-in-you-account"
            | translate
        }}
      </ion-label>
    </ion-item>

    <app-coupons-item
      *ngFor="
        let coupon of activeCoupons | kioskReward : 'mobileorder';
        trackBy: trackFn
      "
      [coupon]="coupon"
    ></app-coupons-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-grid style="margin-bottom: 5px">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="navagateToCart()">
          {{ "popups.active-coupons-promo-modal.show-cart" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
