<ion-card *ngIf="storeInfo && groupApp && !groupApp.active_reservation_app">
  <ion-item-divider mode="ios">
    <ion-label>
      {{
        storeInfo && storeInfo.hidePaymentGateways
          ? ("cart.cart-components.payment-methods.invoice-info" | translate)
          : ("cart.cart-components.payment-methods.payment-methods" | translate)
      }}
    </ion-label>
  </ion-item-divider>

  <app-checkbox-promotions-online-payment
    *ngIf="storeInfo && !storeInfo.hidePaymentGateways"
  ></app-checkbox-promotions-online-payment>

  <span
    *ngIf="
      ((paymentGateways && paymentGateways.length >= 0) ||
        (cartPaymentGateWays &&
          cartPaymentGateWays['mobilePOS'] &&
          cartPaymentGateWays['mobilePOS'].active)) &&
      storeInfo &&
      !storeInfo.hidePaymentGateways
    "
  >
    <ion-radio-group
      [(ngModel)]="selectedPaymentMethodID"
      (ionChange)="selectPaymentMethodRadio($event)"
    >
      <ion-list class="ion-no-padding">
        <span
          #span
          *ngFor="let paymentMethod of cartPaymentGateWays | keyvalue"
        >
          <ion-item
            [disabled]="
              finalPriceLoading ||
              checkForDisablingSomeMethods(
                paymentMethod.key,
                paymentMethod.value,
                null
              )
            "
            lines="full"
            *ngIf="
              paymentMethod &&
              paymentMethod.value &&
              paymentMethod.value.active &&
              (!paymentMethod.value.adminOnly ||
                (currentUser && currentUser.admin)) &&
              (!paymentMethod.value.customerOnly ||
                (currentUser &&
                  ((currentUser.admin && paymentMethod.value.adminOnly) ||
                    (currentUser.customerPaymentMethods &&
                      currentUser.customerPaymentMethods[paymentMethod.key]))))
            "
          >
            <ion-label
              class="ion-text-wrap"
              *ngIf="paymentMethod.key === 'cash'"
              >{{ "cart.cart-components.payment-methods.cash" | translate }}
              <div
                *ngIf="
                  checkForDisablingSomeMethods(
                    paymentMethod.key,
                    paymentMethod.value,
                    null
                  )
                "
              >
                <ion-text color="danger" style="font-size: small">
                  {{
                    "cart.cart-components.payment-methods.temporary-disabled"
                      | translate
                  }}</ion-text
                >
              </div>
            </ion-label>

            <ion-label
              class="ion-text-wrap"
              *ngIf="paymentMethod.key === 'mobilePOS'"
            >
              <ion-text *ngIf="orderPickup == null && dineIn == null">
                {{
                  "cart.cart-components.payment-methods.mobilePOS" | translate
                }}
              </ion-text>
              <ion-text *ngIf="orderPickup == true && dineIn == false">
                {{
                  "cart.cart-components.payment-methods.mobilePOS-takeaway"
                    | translate
                }}
              </ion-text>
              <ion-text *ngIf="orderPickup == false && dineIn == false">
                {{
                  "cart.cart-components.payment-methods.mobilePOS-delivery"
                    | translate
                }}
              </ion-text>
              <ion-text *ngIf="orderPickup == false && dineIn == true">
                {{
                  "cart.cart-components.payment-methods.mobilePOS-dinein"
                    | translate
                }}
              </ion-text>
              <div
                *ngIf="
                  checkForDisablingSomeMethods(
                    paymentMethod.key,
                    paymentMethod.value,
                    null
                  )
                "
              >
                <ion-text color="danger" style="font-size: small">
                  {{
                    "cart.cart-components.payment-methods.temporary-disabled"
                      | translate
                  }}</ion-text
                >
              </div></ion-label
            >
            <ion-radio
              style="margin-right: 13px"
              [value]="paymentMethod.key"
              slot="start"
            ></ion-radio>

            <ion-row
              class="ion-justify-content-center ion-align-items-center"
              style="width: 100%; margin-top: 5px; margin-bottom: 5px"
              *ngIf="
                paymentMethod.key !== 'cash' &&
                paymentMethod.key !== 'mobilePOS'
              "
            >
              <ion-col
                class="ion-no-padding ion-no-margin ion-text-left"
                size="6"
              >
                <ion-label class="ion-text-wrap">
                  {{
                    "cart.cart-components.payment-methods." + paymentMethod.key
                      | translate
                  }}
                  <div
                    *ngIf="
                      checkForDisablingSomeMethods(
                        paymentMethod.key,
                        paymentMethod.value,
                        null
                      )
                    "
                  >
                    <ion-text color="danger" style="font-size: small">
                      {{
                        "cart.cart-components.payment-methods.temporary-disabled"
                          | translate
                      }}</ion-text
                    >
                  </div>
                </ion-label>
              </ion-col>

              <ion-col
                class="ion-no-padding ion-no-margin ion-text-left"
                size="6"
              >
                <span
                  *ngIf="
                    paymentMethod.key !== 'cash' &&
                    paymentMethod.key !== 'mobilePOS' &&
                    storesByStoreId &&
                    storesByStoreId[currentStoreId] &&
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ] &&
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards
                  "
                >
                  <img
                    *ngIf="
                      storesByStoreId[currentStoreId].paymentGateways[
                        paymentMethod.key
                      ].cards.visa
                    "
                    alt="visa"
                    width="35px"
                    style="padding-right: 4px"
                    src="https://data.deliverymanager.gr/bankicons/visa.png"
                  />
                  <img
                    *ngIf="
                      storesByStoreId[currentStoreId].paymentGateways[
                        paymentMethod.key
                      ].cards.mastercard
                    "
                    alt="mastercard"
                    width="35px"
                    style="padding-right: 4px"
                    src="https://data.deliverymanager.gr/bankicons/mastercard.png"
                  />
                  <img
                    *ngIf="
                      storesByStoreId[currentStoreId].paymentGateways[
                        paymentMethod.key
                      ].cards.maestro
                    "
                    alt="maestro"
                    width="55px"
                    style="padding-right: 4px"
                    src="https://data.deliverymanager.gr/bankicons/maestro.png"
                  />
                  <img
                    *ngIf="
                      storesByStoreId[currentStoreId].paymentGateways[
                        paymentMethod.key
                      ].cards.discover
                    "
                    alt="discover"
                    width="55px"
                    style="padding-right: 4px"
                    src="https://data.deliverymanager.gr/bankicons/discover.png"
                  />
                  <img
                    *ngIf="
                      storesByStoreId[currentStoreId].paymentGateways[
                        paymentMethod.key
                      ].cards.diners
                    "
                    alt="diners"
                    width="48px"
                    style="padding-right: 4px"
                    src="https://data.deliverymanager.gr/bankicons/diners.png"
                  />
                  <img
                    *ngIf="
                      storesByStoreId[currentStoreId].paymentGateways[
                        paymentMethod.key
                      ].cards.american
                    "
                    alt="american"
                    width="88px"
                    style="padding-right: 4px"
                    src="https://data.deliverymanager.gr/bankicons/american.png"
                  />
                </span>

                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.googlePay
                  "
                  alt="google-pay"
                  width="42px"
                  style="padding-right: 4px"
                  src="https://data.deliverymanager.gr/bankicons/googlepay.png"
                />

                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.applePay
                  "
                  alt="apple-pay"
                  width="42px"
                  style="padding-right: 4px"
                  src="https://data.deliverymanager.gr/bankicons/applepay.png"
                />

                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.googlePayTransparent
                  "
                  alt="google-pay"
                  [class]="darkMode ? 'invert' : ''"
                  width="42px"
                  style="padding-right: 4px"
                  src="https://data.deliverymanager.gr/bankicons/google-pay.png"
                />

                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.applePayTransparent
                  "
                  alt="apple-pay"
                  width="42px"
                  [class]="darkMode ? 'invert' : ''"
                  style="padding-right: 4px"
                  src="https://data.deliverymanager.gr/bankicons/apple-pay.png"
                />
              </ion-col>
            </ion-row>

            <span
              style="position: absolute; bottom: 0; right: 3px"
              *ngIf="
                paymentMethod.key !== 'cash' &&
                paymentMethod.key !== 'mobilePOS'
              "
            >
              <img
                *ngIf="paymentMethod.key === 'ethniki'"
                alt="ethniki"
                width="50px"
                src="https://data.deliverymanager.gr/bankicons/ethniki.png"
              />

              <img
                *ngIf="paymentMethod.key === 'nbg_simplify'"
                alt="nbg_simplify"
                width="50px"
                src="https://data.deliverymanager.gr/bankicons/ethniki.png"
              />
              <img
                *ngIf="paymentMethod.key === 'mypos'"
                height="20px"
                alt="mypos"
                src="https://data.deliverymanager.gr/bankicons/mypos.png"
              />
              <img
                *ngIf="paymentMethod.key === 'viva'"
                width="50px"
                alt="viva"
                [class]="darkMode ? 'invert' : ''"
                src="https://data.deliverymanager.gr/bankicons/viva.png"
              />
              <img
                *ngIf="paymentMethod.key === 'piraeus'"
                width="20px"
                alt="piraeus"
                src="https://data.deliverymanager.gr/bankicons/piraeus.png"
              />
              <img
                *ngIf="paymentMethod.key === 'eurobank'"
                width="35px"
                alt="eurobank"
                src="https://data.deliverymanager.gr/bankicons/eurobank.png"
              />
              <img
              *ngIf="paymentMethod.key === 'nexi'"
              width="35px"
              alt="nexi"
              src="https://data.deliverymanager.gr/bankicons/nexi.png"
              /><img
                *ngIf="paymentMethod.key === 'alpha'"
                width="45px"
                alt="alpha"
                src="https://data.deliverymanager.gr/bankicons/alpha.png"
              />
              <img
                *ngIf="paymentMethod.key === 'paypal'"
                width="35px"
                alt="paypal"
                src="https://data.deliverymanager.gr/bankicons/paypal.png"
              />
            </span>
          </ion-item>
        </span>
      </ion-list>
    </ion-radio-group>
  </span>

  <app-invoice *ngIf="storeInfo"></app-invoice>
</ion-card>

<ion-grid *ngIf="storeInfo && groupApp && groupApp.active_reservation_app">
  <ion-list>
    <ion-item
      [button]="
        numberOfDisplaydPaymentMethods && numberOfDisplaydPaymentMethods > 1
          ? true
          : false
      "
      style="margin-left: 10px; margin-right: 10px; border-radius: 10px"
      lines="none"
      color="light"
      (click)="
        numberOfDisplaydPaymentMethods && numberOfDisplaydPaymentMethods > 1
          ? openReservationAppSelectPaymentMethodModal()
          : ''
      "
    >
      <ion-icon name="wallet"></ion-icon>
      <ion-label style="margin-left: 10px" *ngIf="selectedPaymentMethodID">{{
        "reservation-app." + selectedPaymentMethodID | translate
      }}</ion-label>
      <ion-label style="margin-left: 10px" *ngIf="!selectedPaymentMethodID">{{
        "reservation-app.please-select" | translate
      }}</ion-label>
    </ion-item>
  </ion-list>
</ion-grid>
