import * as FavoriteProductsActions from "./favoriteProducts.actions";
import * as _ from "lodash";

export interface State {
  favoriteProducts: any;
}

const initialState: State = {
  favoriteProducts: null,
};

export function favoriteProductsActions(
  state = initialState,
  action: FavoriteProductsActions.FavoriteProductsActions
) {
  switch (action.type) {
    case FavoriteProductsActions.SET_FAVORITE_PRODUCTS:
      return {
        ...state,
        favoriteProducts: action.payload,
      };

    case FavoriteProductsActions.ADD_FAVORITE_PRODUCT:
      let newFavoriteProducts = _.cloneDeep(state.favoriteProducts);

      const favProduct = action?.payload?.favoriteProduct;
      let findIndex = -1;
      if (favProduct && !favProduct.migration_customer_id) {
        findIndex = _.findIndex(state.favoriteProducts, {
          store_id: favProduct.store_id,
          customer_id: favProduct.customer_id,
          product_id: favProduct.product_id,
        });
      } else if (favProduct) {
        findIndex = _.findIndex(state.favoriteProducts, {
          store_id: favProduct.store_id,
          migration_customer_id: favProduct.migration_customer_id,
          product_id: favProduct.product_id,
        });
      }

      if (findIndex === -1) {
        if (!newFavoriteProducts) {
          newFavoriteProducts = [];
        }
        newFavoriteProducts.push(favProduct);
      }
      return {
        ...state,
        favoriteProducts: newFavoriteProducts,
      };

    case FavoriteProductsActions.DELETE_FAVORITE_PRODUCT:
      //console.log("action payload delete", action.payload);

      let favoriteProducts = _.cloneDeep(state.favoriteProducts);

      const favoriteProduct = action?.payload?.favoriteProduct;
      let deletedIndex = -1;
      if (favoriteProduct && !favoriteProduct.migration_customer_id) {
        deletedIndex = _.findIndex(state.favoriteProducts, {
          store_id: favoriteProduct.store_id,
          customer_id: favoriteProduct.customer_id,
          product_id: favoriteProduct.product_id,
        });
      } else if (favoriteProduct) {
        deletedIndex = _.findIndex(state.favoriteProducts, {
          store_id: favoriteProduct.store_id,
          migration_customer_id: favoriteProduct.migration_customer_id,
          product_id: favoriteProduct.product_id,
        });
      }

      if (deletedIndex !== -1) {
        favoriteProducts.splice(deletedIndex, 1);
      }
      return {
        ...state,
        favoriteProducts: favoriteProducts,
      };

    case FavoriteProductsActions.CLEAR_CURRENT_STORE_ID:
      return {
        ...state,
        favoriteProducts: null,
      };

    default:
      return state;
  }
}
