import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { environment } from "../environments/environment";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateConfigService } from "./services/translate-config/translate-config.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { CatalogPage } from "./catalog/catalog.page";
import { MobileCatalogComponent } from "./catalog/mobile-catalog/mobile-catalog.component";
import { StoreModule } from "@ngrx/store";
import * as fromApp from "./store/app.reducer";
import { CategoryItemComponent } from "./catalog/categories/category-item/category-item.component";
import { BrowserProductListComponent } from "./catalog/browser-catalog-lg/browser-product-list/browser-product-list.component";
import { ProductItemsBrowserComponent } from "./catalog/products/product-items-browser/product-items-browser.component";
import { ProductItemBrowserNoImageComponent } from "./catalog/products/product-items-browser/product-item-browser-no-image/product-item-browser-no-image.component";
import { VerticalCategoriesComponent } from "./catalog/categories/vertical-categories/vertical-categories.component";
import { CartCatalogComponent } from "./catalog/cart-catalog/cart-catalog.component";
import { CartCatalogProductItemComponent } from "./catalog/cart-catalog/cart-catalog-product-item/cart-catalog-product-item.component";
import { BrowserCatalogLgComponent } from "./catalog/browser-catalog-lg/browser-catalog-lg.component";
import { BrowserCatalogMdComponent } from "./catalog/browser-catalog-md/browser-catalog-md.component";
import { CartPage } from "./cart/cart.page";
import { CartBrowserLgComponent } from "./cart/cart-browser-lg/cart-browser-lg.component";
import { CartBrowserMdComponent } from "./cart/cart-browser-md/cart-browser-md.component";
import { CartMobileComponent } from "./cart/cart-mobile/cart-mobile.component";
import { CartListComponent } from "./cart/cart-components/cart-list/cart-list.component";
import { CartListProductItemComponent } from "./cart/cart-components/cart-list/cart-list-product-item/cart-list-product-item.component";
import { CustomerInfoComponent } from "./cart/cart-components/customer-info/customer-info.component";
import { PaymentMethodsComponent } from "./cart/cart-components/payment-methods/payment-methods.component";
import { CartIconComponent } from "./catalog/cart-icon/cart-icon.component";
import { FormsModule, NgForm, ReactiveFormsModule } from "@angular/forms";
import { CheckboxPromotionsComponent } from "./cart/cart-components/checkbox-promotions/checkbox-promotions.component";
import { CartCustomOptionsComponent } from "./cart/cart-components/cart-custom-options/cart-custom-options.component";
import { InvoiceComponent } from "./cart/cart-components/invoice/invoice.component";
import { InvoiceAddEditModalComponent } from "./cart/cart-components/invoice/invoice-add-edit-modal/invoice-add-edit-modal.component";
import { InvoiceListModalComponent } from "./cart/cart-components/invoice-list-modal/invoice-list-modal.component";
import { ChoicesPreselectedPipe } from "./cart/cart-components/cart-list/cart-list-product-item/choices-preselected.pipe";
import { NoQuantityOptionsComponent } from "./cart/cart-components/cart-custom-options/no-quantity-options/no-quantity-options.component";
import { QuantityOptionsComponent } from "./cart/cart-components/cart-custom-options/quantity-options/quantity-options.component";
import { OrderCommentsComponent } from "./cart/cart-components/order-comments/order-comments.component";
import { CheckboxTypeChoicesComponent } from "./cart/cart-components/cart-custom-options/no-quantity-options/checkbox-type-choices/checkbox-type-choices.component";
import { RadioTypeChoicesComponent } from "./cart/cart-components/cart-custom-options/no-quantity-options/radio-type-choices/radio-type-choices.component";
import { CheckboxTypeChoicesQuantityComponent } from "./cart/cart-components/cart-custom-options/quantity-options/checkbox-type-choices-quantity/checkbox-type-choices-quantity.component";
import { RadioTypeChoicesQuantityComponent } from "./cart/cart-components/cart-custom-options/quantity-options/radio-type-choices-quantity/radio-type-choices-quantity.component";
import { DataStorageService } from "./services/data-storage/data-storage.service";
import { ProductDetailsComponent } from "./product-details/product-details.component";
import { OptionsComponent } from "./product-details/options/options.component";
import { FieldOptionComponent } from "./product-details/options/field-options/field-option.component";
import { RadioOptionComponent } from "./product-details/options/radio-options/radio-option.component";
import { CheckboxOptionComponent } from "./product-details/options/checkbox-options/checkbox-option.component";
import { CheckboxOptionOpenChoicesComponent } from "./product-details/options/checkbox-options/checkbox-option-open-choices/checkbox-option-open-choices.component";
import { CheckboxOptionClosedChoicesComponent } from "./product-details/options/checkbox-options/checkbox-option-closed-choices/checkbox-option-closed-choices.component";
import { NoQuantityCheckboxChoiceComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices-types/no-quantity-checkbox-choice/no-quantity-checkbox-choice.component";
import { QuantityCheckboxChoiceComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices-types/quantity-checkbox-choice/quantity-checkbox-choice.component";
import { CheckboxChoicesComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices.component";
import { QuantityImageCheckboxChoiceComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices-types/quantity-image-checkbox-choice/quantity-image-checkbox-choice.component";
import { NoQuantityImageCheckboxChoiceComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices-types/no-quantity-image-checkbox-choice/no-quantity-image-checkbox-choice.component";
import { RadioOptionOpenChoicesComponent } from "./product-details/options/radio-options/radio-option-open-choices/radio-option-open-choices.component";
import { RadioOptionClosedChoicesComponent } from "./product-details/options/radio-options/radio-option-closed-choices/radio-option-closed-choices.component";
import { RadioChoicesComponent } from "./product-details/options/radio-options/radio-choices/radio-choices.component";
import { NoImageRadioChoiceComponent } from "./product-details/options/radio-options/radio-choices/no-image-radio-choice/no-image-radio-choice.component";
import { ImageRadioChoiceComponent } from "./product-details/options/radio-options/radio-choices/image-radio-choice/image-radio-choice.component";
import { OrderNowPopupComponent } from "./popups/order-now-popup/order-now-popup.component";
import { ChangeQuantityComponent } from "./change-quantity/change-quantity.component";
import { SelectStorePopupComponent } from "./popups/select-store-popup/select-store-popup.component";
import { LanguageSelectionComponent } from "./homepage/language-selection/language-selection.component";
import { LanguageStatusPipe } from "./homepage/language-selection/language-status.pipe";
import { HomepagePage } from "./homepage/homepage.page";
import { PointSystemComponent } from "./homepage/point-system/point-system.component";
import { MagicLoginConfirmationComponent } from "./popups/magic-login-confirmation/magic-login-confirmation.component";
import { AccountSettingsPage } from "./account-settings/account-settings.page";
import { OrdersPage } from "./orders/orders.page";
import { OrderItemComponent } from "./orders/order-item/order-item.component";
import { PaymentCardsPage } from "./payment-cards/payment-cards.page";
import { SelectStoreComponent } from "./payment-cards/modals/select-store/select-store.component";
import { OrderItemDetailsComponent } from "./orders/order-item/order-item-details/order-item-details.component";
import { OrderDetailsProductItemComponent } from "./orders/order-details/order-details-product-item/order-details-product-item.component";
import { OrderDetailsProductItemOptionsComponent } from "./orders/order-details/order-details-product-item/order-details-product-item-options/order-details-product-item-options.component";
import { LocalStorageDataService } from "./services/local-storage-data/local-storage-data.service";
import { PaymentGatewaysService } from "./services/payment-gateways/payment-gateways.service";
import { SelectPaymentGatewayTokenizationComponent } from "./payment-cards/modals/select-payment-gateway-tokenization/select-payment-gateway-tokenization.component";
import { StoreSchedulePage } from "./store-schedule/store-schedule.page";
import { PaymentTermsPage } from "./payment-terms/payment-terms.page";
import { PointsPage } from "./points/points.page";
import { RewardsComponent } from "./points/rewards/rewards.component";
import { HistoryComponent } from "./points/history/history.component";
import { RulesComponent } from "./points/rules/rules.component";
import { ProfileComponent } from "./points/profile/profile.component";
import { RatingsPage } from "./ratings/ratings.page";
import { RatingsItemComponent } from "./ratings/ratings-item/ratings-item.component";
import { RatingDetailsComponent } from "./ratings/modals/rating-details/rating-details.component";
import { StarRatingModule } from "ionic5-star-rating";
import { DiscountCouponsPage } from "./discount-coupons/discount-coupons.page";
import { CouponsItemComponent } from "./discount-coupons/coupons-item/coupons-item.component";
import { InvoiceDataPage } from "./invoice-data/invoice-data.page";
import { MenuFooterComponent } from "./menu-footer/menu-footer.component";
import { CartPricesComponent } from "./cart/cart-components/cart-prices/cart-prices.component";
import { CheckboxPromotionsOnlinePaymentComponent } from "./cart/cart-components/checkbox-promotions/checkbox-promotions-online-payment/checkbox-promotions-online-payment.component";
import { CheckboxPromotionsPickupOnlyComponent } from "./cart/cart-components/checkbox-promotions/checkbox-promotions-pickup-only/checkbox-promotions-pickup-only.component";
import { CheckboxPromotionsOnlinePaymentPipe } from "./cart/cart-components/checkbox-promotions/checkbox-promotions-online-payment/checkbox-promotions-online-payment.pipe";
import { NoOnlinePaymentNoPickupOnlyPipe } from "./cart/cart-components/checkbox-promotions/no-online-payment-no-pickup-only.pipe";
import { BillingInfoComponent } from "./popups/payment/billing-info/billing-info.component";
import { PickupOnlyPromosPipe } from "./cart/cart-components/checkbox-promotions/checkbox-promotions-pickup-only/pickup-only-promos.pipe";
import { BraintreeModalComponent } from "./popups/payment/braintree-modal/braintree-modal.component";
import { AlphaModalComponent } from "./popups/payment/alpha-modal/alpha-modal.component";
import { EthnikiModalComponent } from "./popups/payment/ethniki-modal/ethniki-modal.component";
import { MyposModalComponent } from "./popups/payment/mypos-modal/mypos-modal.component";
import { PaypalModalComponent } from "./popups/payment/paypal-modal/paypal-modal.component";
import { KlarnaModalComponent } from "./popups/payment/klarna-modal/klarna-modal.component";
import { RadioClosedChoicesModalComponent } from "./product-details/options/radio-options/radio-option-closed-choices/radio-closed-choices-selection-modal/radio-closed-choices-selection-modalt.component";
import { CheckboxClosedChoicesSelectionModalComponent } from "./product-details/options/checkbox-options/checkbox-option-closed-choices/checkbox-closed-choices-selection-modal/checkbox-closed-choices-selection-modal.component";
import { DescriptionComponent } from "./catalog/description/description.component";
import { CategoryItemTagsComponent } from "./item-tags/category-item-tags/category-item-tags.component";
import { ProductItemTagsComponent } from "./item-tags/product-item-tags/product-item-tags.component";
import { ProductOptionTagsComponent } from "./item-tags/product-option-tags/product-option-tags.component";
import { ProductChoiceTagsComponent } from "./item-tags/product-choice-tags/product-choice-tags.component";
import { PiraeusThreeDsPopupComponent } from "./popups/payment/piraeus-three-ds-popup/piraeus-three-ds-popup.component";
import { SafePipePipe } from "./popups/payment/safe-pipe.pipe";
import { CartActiveCoupons } from "./cart/cart-components/cart-active-coupons/cart-active-coupons.component";
import { NoInternetConnectionComponent } from "./no-internet-connection/no-internet-connection.component";
import { MobileProductListComponent } from "./catalog/mobile-catalog/mobile-product-list/mobile-product-list.component";
import { HorizontalCategoriesComponent } from "./catalog/categories/horizontal-categories/horizontal-categories.component";
import { ProductItemsMobileComponent } from "./catalog/products/product-items-mobile/product-items-mobile.component";
import { ProductItemMobileImageComponent } from "./catalog/products/product-items-mobile/product-item-mobile-image/product-item-mobile-image.component";
import { ProductItemMobileNoImageComponent } from "./catalog/products/product-items-mobile/product-item-mobile-no-image/product-item-mobile-no-image.component";
import { CategoryItemMobileComponent } from "./catalog/categories/category-item-mobile/category-item-mobile.component";
import { ProductItemBrowserImageComponent } from "./catalog/products/product-items-browser/product-item-browser-image/product-item-browser-image.component";
import { ProductItemsPopularCategoryComponent } from "./catalog/products/product-items-popular-category/product-items-popular-category.component";
import { SubcategoryProductsBrowserComponent } from "./catalog/products/subcategory-products-browser/subcategory-products-browser.component";
import { SubcategoryProductsMobileComponent } from "./catalog/products/subcategory-products-mobile/subcategory-products-mobile.component";
import { ProductItemsPopularCategoryMobileComponent } from "./catalog/products/product-items-popular-category-mobile/product-items-popular-category-mobile.component";
import { OrderDetailsComponent } from "./orders/order-details/order-details.component";
import { ReviewOrderLibModule } from "@deliverymanager/review-order-lib";
import { OrderRatingModalComponent } from "./orders/order-rating-modal/order-rating-modal.component";
import { CustomerPreferencesModalComponent } from "./orders/order-rating-modal/customer-preferences-modal/customer-preferences-modal.component";
import { NewAddressModalComponent } from "./address/new-address-modal/new-address-modal.component";
import { OptionStepperComponent } from "./product-details/options/option-stepper/option-stepper.component";
import { ExtraChoicesComponent } from "./cart/cart-components/extra-choices/extra-choices.component";
import { LastStepComponent } from "./product-details/options/option-stepper/last-step/last-step.component";
import { OrderNowSettingsPopupComponent } from "./popups/order-now-popup/order-now-settings-popup/order-now-settings-popup.component";
import { DeliveryAreasPage } from "./delivery-areas/delivery-areas.page";
import { DeliveryAreasInfoModalComponent } from "./delivery-areas/delivery-areas-info-modal/delivery-areas-info-modal.component";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { FirebaseRecaptchaLoginComponent } from "./popups/firebase-recaptcha-login/firebase-recaptcha-login.component";
import { DeliveryMethodAddressComponent } from "./cart/cart-components/cart-list/delivery-method-address/delivery-method-address.component";
import { NotValidAddressModalComponent } from "./address/not-valid-address-modal/not-valid-address-modal.component";
import { DeliveryInfoComponent } from "./cart/cart-components/delivery-info/delivery-info.component";
import { FirebaseRecaptchaComponent } from "./popups/firebase-recaptcha/firebase-recaptcha.component";
import { ScanLoginModalComponent } from "./popups/scan-login-modal/scan-login-modal.component";
import { ExtraAddressDetailsModalComponent } from "./popups/extra-address-details-modal/extra-address-details-modal.component";
import { DeployService } from "./services/deploy-service/deploy.service";
import { CalculateFinalPriceService } from "./services/calculateFinalPrice/calculate-final-price.service";
import { Deploy } from "cordova-plugin-ionic/dist/ngx";
import { UpdateModalComponent } from "./popups/update-modal/update-modal.component";
import { VerticalHyperCategoriesComponent } from "./catalog/hyper-categories/vertical-hyper-categories/vertical-hyper-categories.component";
import { HorizontalHyperCategoriesComponent } from "./catalog/hyper-categories/horizontal-hyper-categories/horizontal-hyper-categories.component";
import { NextHyperCategoryComponent } from "./catalog/hyper-categories/next-hyper-category/next-hyper-category.component";
import { CatalogSearchComponentComponent } from "./catalog/catalog-search-component/catalog-search-component.component";
import { CatalogSearchModalComponent } from "./catalog/catalog-search-component/catalog-search-modal/catalog-search-modal.component";
import { CartProductOptionRequiredPipe } from "./cart/cart-components/cart-list/cart-list-product-item/cart-product-option-required.pipe";
import { PreviousOrdersSliderComponent } from "./catalog/previous-order/previous-orders-slider/previous-orders-slider.component";
import { PreviousOrderModalComponent } from "./catalog/previous-order/previous-order-modal/previous-order-modal.component";
import { PreviousOrdersSliderMobileComponent } from "./catalog/previous-order/previous-orders-slider-mobile/previous-orders-slider-mobile.component";
import { FavoritesMobileComponent } from "./catalog/favorites/favorites-mobile/favorites-mobile.component";
import { FavoritesBrowserComponent } from "./catalog/favorites/favorites-browser/favorites-browser.component";
import { PreorderPopupComponent } from "./popups/preorder-popup/preorder-popup.component";
import { BundleUpdatePage } from "./bundle-update/bundle-update.page";
import { BillingInfoVerifyComponent } from "./popups/payment/billing-info-verify/billing-info-verify.component";
import { SendOrderPayPopupComponent } from "./popups/send-order-pay-popup/send-order-pay-popup.component";
import { SelectPaymentMethodPopupComponent } from "./popups/select-payment-method-popup/select-payment-method-popup.component";

import { OrderCustomerNotificationsModalComponent } from "./orders/order-customer-notifications-modal/order-customer-notifications-modal.component";
import { CartPointsComponent } from "./cart/cart-components/cart-points/cart-points.component";
import { ParameterHashLocationStrategy } from "./parameter-hash-location-strategy";
import { CompleteOrderButtonComponent } from "./catalog/mobile-catalog/complete-order-button/complete-order-button.component";
import { CartPointsRewardsModalComponent } from "./cart/cart-components/cart-points/cart-points-rewards-modal/cart-points-rewards-modal.component";
import { SideMenuHeaderLogoComponent } from "./side-menu-header-logo/side-menu-header-logo.component";
import { NgOtpInputModule } from "ng-otp-input";
import { FavoriteProductsPage } from "./favorite-products/favorite-products.page";
import { PlasticBugTaxOptionComponent } from "./cart/cart-components/cart-custom-options/quantity-options/plastic-bug-tax-option/plastic-bug-tax-option.component";
import { PlasticWrapperTaxOptionComponent } from "./cart/cart-components/cart-custom-options/quantity-options/plastic-wrapper-tax-option/plastic-wrapper-tax-option.component";
import { AddCouponCodeComponent } from "./cart/cart-components/add-coupon-code/add-coupon-code.component";
import { OrderTimelineModalComponent } from "./orders/order-timeline-modal/order-timeline-modal.component";
import { DineInModalComponent } from "./popups/dine-in-modal/dine-in-modal.component";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { AppRatingModalComponent } from "./orders/order-rating-modal/app-rating-modal/app-rating-modal.component";

import { AlergensPage } from "./alergens/alergens.page";
import { AllergenModalComponent } from "./popups/allergen-modal/allergen-modal.component";
import { InfoMessageModalComponent } from "./popups/info-message-modal/info-message-modal.component";
import { SendOrderPopupComponent } from "./popups/send-order-popup/send-order-popup.component";
import { SwiperModule } from "swiper/angular";
import { NextHyperCategoryMobileComponent } from "./catalog/hyper-categories/next-hyper-category-mobile/next-hyper-category-mobile.component";
import { CouponsScheduleInfoPopupComponent } from "./popups/coupons-schedule-info-popup/coupons-schedule-info-popup.component";
import { SendOrderAndPayInfoPopupComponent } from "./popups/send-order-pay-popup/send-order-and-pay-info-popup/send-order-and-pay-info-popup.component";
import { PointsProductScheduleService } from "./services/pointsProductSchedule/points-product-schedule.service";
import { BundleOptionComponent } from "./product-details/options/bundle-option/bundle-option.component";
import { BundleOptionRadioComponent } from "./product-details/options/bundle-option/bundle-option-radio/bundle-option-radio.component";
import { BundleOptionRadioChoicesComponent } from "./product-details/options/bundle-option/bundle-option-radio/bundle-radio-choices/bundle-option-radio-choices/bundle-option-radio-choices.component";
import { BundleOptionRadioClosedChoicesComponent } from "./product-details/options/bundle-option/bundle-option-radio/bundle-option-radio-closed-choices/bundle-option-radio-closed-choices.component";
import { BundleOptionRadioClosedChoicesModalComponent } from "./product-details/options/bundle-option/bundle-option-radio/bundle-option-radio-closed-choices/bundle-option-radio-closed-choices-modal/bundle-option-radio-closed-choices-modal.component";
import { BundleRadioChoiceComponent } from "./product-details/options/bundle-option/bundle-option-radio/bundle-radio-choices/bundle-option-radio-choices/bundle-radio-choice/bundle-radio-choice.component";
import { BundleProductItemComponent } from "./product-details/options/bundle-option/bundle-product-item/bundle-product-item.component";
import { CartListProductChoiceCommentComponent } from "./cart/cart-components/cart-list/cart-list-product-item/cart-list-product-choice-comment/cart-list-product-choice-comment.component";
import { OrderDetailsBundleProductItemComponent } from "./orders/order-details/order-details-bundle-product-item/order-details-bundle-product-item.component";
import { PreviousOrderBundleProductCommentComponent } from "./catalog/previous-order/previous-order-modal/previous-order-bundle-product-comment/previous-order-bundle-product-comment.component";
import { QuantityCheckboxChoiceWithMeasurementsComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices-types/quantity-checkbox-choice-with-measurements/quantity-checkbox-choice-with-measurements.component";
import { PlasticBugWithLimitOptionComponent } from "./cart/cart-components/cart-custom-options/quantity-options/plastic-bug-with-limit-option/plastic-bug-with-limit-option.component";
import { BlockStoreModalComponent } from "./popups/block-store-modal/block-store-modal.component";
import { LoyaltySystemPage } from "./loyalty-system/loyalty-system.page";
import { LoyaltySwiperComponent } from "./loyalty-system/loyalty-swiper/loyalty-swiper.component";
import { LoyaltyHomePage } from "./loyalty-system/loyalty-home/loyalty-home.page";
import { LoyaltyCouponsSliderComponent } from "./loyalty-system/loyalty-home/loyalty-coupons-slider/loyalty-coupons-slider.component";
import { QrcardModalComponent } from "./loyalty-system/loyalty-home/qrcard-modal/qrcard-modal.component";
import { CampaignItemInformationComponent } from "./loyalty-system/loyalty-home/campaign-item-information/campaign-item-information.component";
import { LoyaltyCouponsPage } from "./loyalty-system/loyalty-coupons/loyalty-coupons.page";
import { LoyaltyCouponItemComponent } from "./loyalty-system/loyalty-coupons/loyalty-coupon-item/loyalty-coupon-item.component";
import { SelectStoreModalComponent } from "./loyalty-system/loyalty-home/select-store-modal/select-store-modal.component";
import { ProductPopoverComponent } from "./loyalty-system/loyalty-home/product-popover/product-popover.component";
import { CreateCouponModalComponent } from "./loyalty-system/loyalty-home/create-coupon-modal/create-coupon-modal.component";
import { LoyaltyMorePage } from "./loyalty-system/loyalty-more/loyalty-more.page";
import { LoyaltyPurchaseHistoryComponent } from "./loyalty-system/loyalty-more/loyalty-purchase-history/loyalty-purchase-history.component";
import { reverseArrayPipe } from "./loyalty-system/loyalty-more/loyalty-purchase-history/reverse-array.pipe";
import { NgxFileDropModule } from "ngx-file-drop";
import { UploadImageFileDropComponent } from "./upload-image-file-drop/upload-image-file-drop.component";
import { PhotoCropperComponent } from "./photo-cropper/photo-cropper.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { EditProfileImageModalComponent } from "./account-settings/edit-profile-image-modal/edit-profile-image-modal.component";
import { AuthService } from "./auth/auth.service";

import { ProductItemsCardsCategoryComponent } from "./catalog/products/product-items-cards-category/product-items-cards-category.component";
import { ProductItemsCardsCategoryMobileComponent } from "./catalog/products/product-items-cards-category-mobile/product-items-cards-category-mobile.component";
import { AffiliatePage } from "./affiliate/affiliate.page";
import { AffiliateInfoModalComponent } from "./affiliate/affiliate-info-modal/affiliate-info-modal.component";
import { FilterAvailableProductsPipe } from "./catalog/filter-available-products.pipe";
import { UnavailableProductsModalComponent } from "./catalog/categories/unavailable-products-modal/unavailable-products-modal.component";
import { ProductItemCardMobileComponent } from "./catalog/products/product-items-cards-category-mobile/product-item-card-mobile/product-item-card-mobile.component";
import { ProductItemCardComponent } from "./catalog/products/product-items-cards-category/product-item-card/product-item-card.component";
import { HallOfFameComponent } from "./loyalty-system/loyalty-more/hall-of-fame/hall-of-fame.component";
import { NoLocationDineInModalComponent } from "./popups/no-location-dine-in-modal/no-location-dine-in-modal.component";
import { CouponInfoPopupComponent } from "./popups/coupon-info-popup/coupon-info-popup.component";
import { DeletedUserInfoModalComponent } from "./popups/deleted-user-info-modal/deleted-user-info-modal.component";
import { CreateOrderFailedPreorderModalComponent } from "./popups/create-order-failed-preorder-modal/create-order-failed-preorder-modal.component";
import { AffiliateReceiversCouponsComponent } from "./affiliate/affiliate-receivers-coupons/affiliate-receivers-coupons.component";
import { CartPreorderItemComponent } from "./cart/cart-components/cart-list/cart-preorder-item/cart-preorder-item.component";

import { FilterPaymentMethodPipe } from "./popups/select-payment-method-popup/filter-payment-method.pipe";
import { QuantityCheckboxChoicesWithInputComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices-types/quantity-checkbox-choices-with-input/quantity-checkbox-choices-with-input.component";
import { ChangeQuantityInputComponent } from "./change-quantity-input/change-quantity-input.component";
import { ChangeQuantityInputPopoverComponent } from "./change-quantity-input/change-quantity-input-popover/change-quantity-input-popover.component";
import { QuantityCheckboxChoiceWithMeasurementsImageComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices-types/quantity-checkbox-choice-with-measurements-image/quantity-checkbox-choice-with-measurements-image.component";
import { QuantityCheckboxChoicesWithInputImageComponent } from "./product-details/options/checkbox-options/checkbox-choices/checkbox-choices-types/quantity-checkbox-choices-with-input-image/quantity-checkbox-choices-with-input-image.component";
import { GeneralInfoModalComponent } from "./popups/general-info-modal/general-info-modal.component";
import { MigratePointsComponent } from "./loyalty-system/loyalty-more/migrate-points/migrate-points.component";
import { LoyaltyContestsPage } from "./loyalty-system/loyalty-contests/loyalty-contests.page";
import { LoyaltyContestItemComponent } from "./loyalty-system/loyalty-contests/loyalty-contest-item/loyalty-contest-item.component";
import { ContestModalComponent } from "./loyalty-system/loyalty-contests/contest-modal/contest-modal.component";
import { PreorderDayPipe } from "./popups/preorder-popup/preorder-day.pipe";
import { ProductItemsPopularCategoryOnlyImageComponent } from "./catalog/products/product-items-popular-category-only-image/product-items-popular-category-only-image.component";
import { ProductItemsPopularCategoryOnlyImageMobileComponent } from "./catalog/products/product-items-popular-category-only-image-mobile/product-items-popular-category-only-image-mobile.component";
import { NavigationToBankModalComponent } from "./popups/navigation-to-bank-modal/navigation-to-bank-modal.component";
import { LoadingSplashscreenComponent } from "./loading-splashscreen/loading-splashscreen.component";
import { ReservationsPage } from "./reservations/reservations.page";
import { CreateReservationPage } from "./reservations/create-reservation/create-reservation.page";
import { ReservationDatePopupComponent } from "./reservations/create-reservation/reservation-date-popup/reservation-date-popup.component";
import { SendReservationPopupComponent } from "./reservations/send-reservation-popup/send-reservation-popup.component";
import { ReservationTablesPipe } from "./reservations/reservation-tables.pipe";
import { SendReservationInfoPopupComponent } from "./reservations/send-reservation-info-popup/send-reservation-info-popup.component";
import { ReservationsItemComponent } from "./reservations/reservations-item/reservations-item.component";
import { ReservationTableInfoModalComponent } from "./reservations/reservation-table-info-modal/reservation-table-info-modal.component";
import { SelectStoreImagePopupComponent } from "./popups/select-store-image-popup/select-store-image-popup.component";
import { FilterStoresSelectionPipe } from "./popups/select-store-image-popup/filter-stores-selection.pipe";
import { FixedAddressInfoPopupComponent } from "./popups/fixed-address-info-popup/fixed-address-info-popup.component";
import { IonicUpdateErrorComponent } from "./ionic-update-error/ionic-update-error.component";
import { FillRequiredNameModalComponent } from "./reservations/fill-required-name-modal/fill-required-name-modal.component";
import { UserWinnerModalComponent } from "./loyalty-system/loyalty-home/user-winner-modal/user-winner-modal.component";
import { ProductValidDeliveryAreasPopupComponent } from "./popups/product-valid-delivery-areas-popup/product-valid-delivery-areas-popup.component";
import { KioskPage } from "./kiosk/kiosk.page";
import { QRCodeModule } from "angularx-qrcode";
import { KioskQrInfoModalComponent } from "./kiosk/kiosk-qr-info-modal/kiosk-qr-info-modal.component";
import { KioskGetPromoModalComponent } from "./kiosk/kiosk-get-promo-modal/kiosk-get-promo-modal.component";
import { KioskCouponsItemComponent } from "./kiosk/kiosk-coupons-item/kiosk-coupons-item.component";
import { KioskCouponQrDisplayModalComponent } from "./kiosk/kiosk-coupon-qr-display-modal/kiosk-coupon-qr-display-modal.component";
import { KioskRewardPipe } from "./points/rewards/kiosk-reward.pipe";
import { KioskRewardsItemComponent } from "./kiosk/kiosk-rewards-item/kiosk-rewards-item.component";
import { FilterSelectedCategoryProductsPipe } from "./catalog/categories/filter-selected-category-products.pipe";
import { HyperCategoriesSelectionComponent } from "./catalog/hyper-categories/hyper-categories-selection/hyper-categories-selection.component";
import { HyperCategoriesSelectionModalComponent } from "./catalog/hyper-categories/hyper-categories-selection/hyper-categories-selection-modal/hyper-categories-selection-modal.component";
import { ProductMarketTypeCardsCategoryMobileComponent } from "./catalog/products/product-market-type-cards-category-mobile/product-market-type-cards-category-mobile.component";
import { ProductMarketTypeCardsCategoryBrowserComponent } from "./catalog/products/product-market-type-cards-category-browser/product-market-type-cards-category-browser.component";
import { ProductItemMarketTypeCardBrowserComponent } from "./catalog/products/product-market-type-cards-category-browser/product-item-market-type-card-browser/product-item-market-type-card-browser.component";
import { ProductItemMarketTypeCardMobileComponent } from "./catalog/products/product-market-type-cards-category-mobile/product-item-market-type-card-mobile/product-item-market-type-card-mobile.component";
import { HomepageLogoComponent } from "./homepage/homepage-logo/homepage-logo.component";
import { HomepageUpperSliderComponent } from "./homepage/homepage-upper-slider/homepage-upper-slider.component";
import { FullscreenImagePopupComponent } from "./popups/fullscreen-image-popup/fullscreen-image-popup.component";
import { ProductShowOptionInDescriptionObjectComponent } from "./catalog/products/product-show-option-in-description/product-show-option-in-description-object.component";
import { LoyaltyContestGiftsComponent } from "./loyalty-system/loyalty-contests/loyalty-contest-gifts/loyalty-contest-gifts.component";
import { DeliveryInfoModalComponent } from "./popups/delivery-info-modal/delivery-info-modal.component";
import { CalculateNoPromosPointsToEarnPipe } from "./cart/cart-components/cart-list/cart-list-product-item/calculate-no-promos-points-to-earn.pipe";
import { QrScanModalComponent } from "./popups/order-now-popup/qr-scan-modal/qr-scan-modal.component";
import { ReservationSystemPage } from "./reservation-system/reservation-system.page";
import { ReservationHomePage } from "./reservation-system/reservation-home/reservation-home.page";
import { ReservationCompletedDateInfoPage } from "./reservation-system/reservation-completed-date-info/reservation-completed-date-info.page";
import { ReservationDateInfoPage } from "./reservation-system/reservation-date-info/reservation-date-info.page";
import { ReservationLocationPreviewPage } from "./reservation-system/reservation-location-preview/reservation-location-preview.page";
import { ReservationMyDatesPage } from "./reservation-system/reservation-my-dates/reservation-my-dates.page";
import { ReservationSelectArtistPage } from "./reservation-system/reservation-select-artist/reservation-select-artist.page";
import { ReservationSelectDatePage } from "./reservation-system/reservation-select-date/reservation-select-date.page";

import { DateDetailsPipe } from "./reservation-system/reservation-my-dates/date-details.pipe";
import { DateFormatPipe } from "./reservation-system/reservation-select-date/date-format.pipe";
import { DayNamePipe } from "./reservation-system/reservation-select-date/day-name.pipe";
import { FirebaseRecaptchaLoginReservationActiveComponent } from "./popups/firebase-recaptcha-login-reservation-active/firebase-recaptcha-login-reservation-active.component";
import { MagicLoginConfirmationReservationActiveComponent } from "./popups/magic-login-confirmation-reservation-active/magic-login-confirmation-reservation-active.component";
import { ExtendModalComponent } from "./reservation-system/extend-modal/extend-modal.component";
import { ReservationPointsPagePage } from "./reservation-system/reservation-points-page/reservation-points-page.page";
import { ReservationMoreInfoPage } from "./reservation-system/reservation-more-info/reservation-more-info.page";
import { UserInfoComponent } from "./reservation-system/user-info/user-info.component";
import { GroupInfoComponent } from "./reservation-system/group-info/group-info.component";
import { ScanningInstructionsComponent } from "./reservation-system/scanning-instructions/scanning-instructions.component";
import { ReservationCatalogPage } from "./reservation-system/reservation-catalog/reservation-catalog.page";
import { TimestampToStringPipe } from "./reservation-system/reservation-select-date/timestamp-to-string.pipe";
import { ReservationProductsCatalogComponent } from "./reservation-system/reservation-catalog/reservation-products-catalog/reservation-products-catalog.component";
import { ReservationProductsListComponent } from "./reservation-system/reservation-catalog/reservation-products-catalog/reservation-products-list/reservation-products-list.component";
import { OrderStoresByDistancePipe } from "./services/order-stores-by-distance.pipe";
import { DeliveryCostInfoModalComponent } from "./popups/delivery-cost-info-modal/delivery-cost-info-modal.component";
import { TwoDecimalsRoundPipe } from "./popups/delivery-cost-info-modal/two-decimals-round.pipe";
import { BrowserProductListProductsComponent } from "./catalog/browser-catalog-lg/browser-product-list/browser-product-list-products/browser-product-list-products.component";
import { MobileProductListProductsComponent } from "./catalog/mobile-catalog/mobile-product-list/mobile-product-list-products/mobile-product-list-products.component";
import { ProductItemOrderTypeTagsComponent } from "./item-tags/product-item-tags/product-item-order-type-tags/product-item-order-type-tags.component";
import { RecommendedProductsPopupComponent } from "./popups/recommended-products-popup/recommended-products-popup.component";
import { ProductItemPopularCategoryComponent } from "./catalog/products/product-items-popular-category/product-item-popular-category/product-item-popular-category.component";
import { ProductItemPopularCategoryMobileComponent } from "./catalog/products/product-items-popular-category-mobile/product-item-popular-category-mobile/product-item-popular-category-mobile.component";
import { FilterShowInDescriptionObjectChoicesPipe } from "./catalog/products/product-show-option-in-description/filter-show-in-description-object-choices.pipe";
import { ProductItemBrowserOverflowImageComponent } from "./catalog/products/product-items-browser/product-item-browser-overflow-image/product-item-browser-overflow-image.component";
import { ProductItemMobileOverflowImageComponent } from "./catalog/products/product-items-mobile/product-item-mobile-overflow-image/product-item-mobile-overflow-image.component";
import { NoScannerAvailableModalComponent } from "./popups/order-now-popup/no-scanner-available-modal/no-scanner-available-modal.component";
import { FilterOnlyDineInDeliveryPickupProductsPipe } from "./catalog/filter-only-dine-in-delivery-pickup-products.pipe";
import { FilterEmptyCategoriesForOnlyDineInDeliveryPickupProductsPipe } from "./catalog/filter-empty-categories-for-only-dine-in-delivery-pickup-products.pipe";
import { FilterInlineCartInfoMessagesPipe } from "./cart/cart-components/cart-list/filter-inline-cart-info-messages.pipe";
import { ReservationProductListProductsComponent } from "./reservation-system/reservation-catalog/reservation-products-catalog/reservation-products-list/reservation-product-list-products/reservation-product-list-products.component";
import { ReservationProductItemComponent } from "./reservation-system/reservation-catalog/reservation-products-catalog/reservation-products-list/reservation-product-item/reservation-product-item.component";
import { ReservationCartComponent } from "./reservation-system/reservation-cart/reservation-cart.component";
import { ReservationSelectPaymentMethodModalComponent } from "./reservation-system/reservation-select-payment-method-modal/reservation-select-payment-method-modal.component";
import { ReservationSendOrderPopupComponent } from "./reservation-system/reservation-send-order-popup/reservation-send-order-popup.component";
import { AccountInfoForContestModalComponent } from "./loyalty-system/account-info-for-contest-modal/account-info-for-contest-modal.component";
import { MenuFooterTabsComponent } from "./menu-footer-tabs/menu-footer-tabs.component";
import { SelectedFixedPointsAddressModalComponent } from "./address/selected-fixed-points-address-modal/selected-fixed-points-address-modal.component";
import { FixedPointsAddressConfirmationPopupComponent } from "./address/selected-fixed-points-address-modal/fixed-points-address-confirmation-popup/fixed-points-address-confirmation-popup.component";
import { FixedPointsAddressSelectionModalComponent } from "./address/selected-fixed-points-address-modal/fixed-points-address-selection-modal/fixed-points-address-selection-modal.component";
import { FilterPointsFactorRulesByOrderTypePipe } from "./points/rules/filter-points-factor-rules-by-order-type.pipe";
import { FactorPointRuleOrderTypePipe } from "./points/rules/factor-point-rule-order-type.pipe";
import { FilterFixedPointsAddressAreasPipe } from "./delivery-areas/filter-fixed-points-address-areas.pipe";
import { ContestWinnersModalComponent } from "./loyalty-system/loyalty-contests/contest-winners-modal/contest-winners-modal.component";
import { ReservationRewardsComponent } from "./reservation-system/reservation-points-page/reservation-rewards/reservation-rewards.component";
import { ReservationCouponsSelectionModalComponent } from "./reservation-system/reservation-coupons-selection-modal/reservation-coupons-selection-modal.component";
import { ReservationCartCouponsComponent } from "./reservation-system/reservation-cart/reservation-cart-coupons/reservation-cart-coupons.component";
import { ReservationCatalogCouponsComponent } from "./reservation-system/reservation-catalog/reservation-catalog-coupons/reservation-catalog-coupons.component";
import { ReservationDiscountCouponsPage } from "./reservation-system/reservation-discount-coupons/reservation-discount-coupons.page";
import { ReservationCouponsItemComponent } from "./reservation-system/reservation-discount-coupons/reservation-coupons-item/reservation-coupons-item.component";
import { ReservationKioskCouponsItemComponent } from "./reservation-system/reservation-discount-coupons/reservation-kiosk-coupons-item/reservation-kiosk-coupons-item.component";
import { ReservationPointsProfileModalComponent } from "./reservation-system/reservation-points-page/reservation-points-profile-modal/reservation-points-profile-modal.component";
import { ReservationPointsHistoryComponent } from "./reservation-system/reservation-points-page/reservation-points-history/reservation-points-history.component";
import { MenuFooterTabsCompleteOrderComponent } from "./menu-footer-tabs/menu-footer-tabs-complete-order/menu-footer-tabs-complete-order.component";
import { SelectedProductPricePipe } from "./product-details/selected-product-price.pipe";
import { KioskExpirationTimestampTimerPipe } from "./kiosk/kiosk-expiration-timestamp-timer.pipe";
import { DeliveryTrackingModalComponent } from "./delivery-drive/delivery-tracking-modal/delivery-tracking-modal.component";
import { NbgSimplifyModalComponent } from "./popups/payment/nbg-simplify-modal/nbg-simplify-modal.component";
import { TextTypeChoicesComponent } from "./cart/cart-components/cart-custom-options/no-quantity-options/text-type-choices/text-type-choices.component";
import { CalculateDeliveryCostDistanceChargeTypePipe } from "./popups/delivery-cost-info-modal/calculate-delivery-cost-distance-charge-type.pipe";
import { SubmitUserDataModalComponent } from "./popups/submit-user-data-modal/submit-user-data-modal.component";
import { CheckGpsCouponRuleModalComponent } from "./popups/check-gps-coupon-rule-modal/check-gps-coupon-rule-modal.component";
import { CatalogDisclaimerComponent } from "./catalog/catalog-disclaimer/catalog-disclaimer.component";
import { CatalogDisclaimerPopupComponent } from "./popups/catalog-disclaimer-popup/catalog-disclaimer-popup.component";
import { CatalogDisclaimerButtonComponent } from "./catalog/catalog-disclaimer-button/catalog-disclaimer-button.component";
import { VatLevelsDetailsModalComponent } from "./popups/vat-levels-details-modal/vat-levels-details-modal.component";
import { FilterAddressesMigrationCustomerIdPipe } from "./popups/order-now-popup/order-now-settings-popup/filter-addresses-migration-customer-id.pipe";
import { ActiveCouponsPromoModalComponent } from "./popups/active-coupons-promo-modal/active-coupons-promo-modal.component";

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    CatalogPage,
    ProductDetailsComponent,
    OptionsComponent,
    OptionStepperComponent,
    LastStepComponent,
    FieldOptionComponent,
    RadioOptionComponent,
    RadioOptionOpenChoicesComponent,
    RadioOptionClosedChoicesComponent,
    RadioClosedChoicesModalComponent,

    //reservation active app components
    FirebaseRecaptchaLoginReservationActiveComponent,
    MagicLoginConfirmationReservationActiveComponent,
    ReservationMyDatesPage,
    ReservationSelectArtistPage,
    ReservationSelectDatePage,
    ReservationCompletedDateInfoPage,
    ReservationLocationPreviewPage,
    ReservationSystemPage,
    ReservationCatalogPage,
    ReservationCatalogCouponsComponent,
    ReservationProductsCatalogComponent,
    ReservationProductsListComponent,
    ReservationProductListProductsComponent,
    ReservationProductItemComponent,
    ReservationCartComponent,
    ReservationHomePage,
    ReservationDateInfoPage,
    TimestampToStringPipe,
    ExtendModalComponent,
    ReservationPointsPagePage,
    ReservationMoreInfoPage,
    UserInfoComponent,
    ScanningInstructionsComponent,
    GroupInfoComponent,
    DateFormatPipe,
    DateDetailsPipe,
    DayNamePipe,
    ReservationSelectPaymentMethodModalComponent,
    ReservationSendOrderPopupComponent,
    ReservationRewardsComponent,
    ReservationCouponsSelectionModalComponent,
    ReservationCartCouponsComponent,
    ReservationDiscountCouponsPage,
    ReservationCouponsItemComponent,
    ReservationKioskCouponsItemComponent,
    ReservationPointsProfileModalComponent,
    ReservationPointsHistoryComponent,
    ActiveCouponsPromoModalComponent,
    //--------------------------------

    RadioChoicesComponent,
    NoImageRadioChoiceComponent,
    ImageRadioChoiceComponent,
    CheckboxOptionComponent,
    CheckboxOptionOpenChoicesComponent,
    CheckboxOptionClosedChoicesComponent,
    CheckboxClosedChoicesSelectionModalComponent,
    CheckboxChoicesComponent,
    QuantityImageCheckboxChoiceComponent,
    NoQuantityImageCheckboxChoiceComponent,
    NoQuantityCheckboxChoiceComponent,
    QuantityCheckboxChoiceComponent,
    QuantityCheckboxChoicesWithInputComponent,
    QuantityCheckboxChoicesWithInputImageComponent,
    BundleOptionComponent,
    BundleOptionRadioComponent,
    BundleOptionRadioClosedChoicesComponent,
    BundleOptionRadioClosedChoicesModalComponent,
    BundleRadioChoiceComponent,
    BundleOptionRadioChoicesComponent,
    QuantityCheckboxChoiceWithMeasurementsComponent,
    QuantityCheckboxChoiceWithMeasurementsImageComponent,
    BrowserCatalogLgComponent,
    BrowserCatalogMdComponent,
    DescriptionComponent,
    CartIconComponent,
    CategoryItemComponent,
    BrowserProductListComponent,
    HomepagePage,
    MobileCatalogComponent,
    HorizontalCategoriesComponent,
    MobileProductListComponent,
    ProductItemsBrowserComponent,
    ProductItemsMobileComponent,
    ProductItemMobileImageComponent,
    ProductItemMobileOverflowImageComponent,
    ProductItemMobileNoImageComponent,
    ProductItemBrowserImageComponent,
    ProductItemBrowserOverflowImageComponent,
    ProductItemBrowserNoImageComponent,
    ProductItemsPopularCategoryComponent,
    ProductItemPopularCategoryComponent,
    ProductItemsPopularCategoryMobileComponent,
    ProductItemPopularCategoryMobileComponent,
    ProductItemsPopularCategoryOnlyImageComponent,
    ProductItemsPopularCategoryOnlyImageMobileComponent,
    ProductItemsCardsCategoryComponent,
    ProductItemCardComponent,
    ProductItemsCardsCategoryMobileComponent,
    ProductItemCardMobileComponent,
    ProductMarketTypeCardsCategoryMobileComponent,
    ProductMarketTypeCardsCategoryBrowserComponent,
    ProductItemMarketTypeCardBrowserComponent,
    ProductItemMarketTypeCardMobileComponent,
    CategoryItemMobileComponent,
    PointSystemComponent,
    CategoryItemComponent,
    VerticalCategoriesComponent,
    CartCatalogProductItemComponent,
    CartCatalogComponent,
    CartPage,
    CartBrowserLgComponent,
    CartBrowserMdComponent,
    CartMobileComponent,
    CartListComponent,
    CartPricesComponent,
    CartPointsComponent,
    CartPointsRewardsModalComponent,
    ChoicesPreselectedPipe,
    CartProductOptionRequiredPipe,
    CheckboxPromotionsComponent,
    CartPreorderItemComponent,
    NoOnlinePaymentNoPickupOnlyPipe,
    CheckboxPromotionsOnlinePaymentComponent,
    CheckboxPromotionsOnlinePaymentPipe,
    CheckboxPromotionsPickupOnlyComponent,
    PickupOnlyPromosPipe,
    CartCustomOptionsComponent,
    NoQuantityOptionsComponent,
    CheckboxTypeChoicesComponent,
    RadioTypeChoicesComponent,
    TextTypeChoicesComponent,
    QuantityOptionsComponent,
    CheckboxTypeChoicesQuantityComponent,
    RadioTypeChoicesQuantityComponent,
    CartActiveCoupons,
    InvoiceComponent,
    InvoiceAddEditModalComponent,
    InvoiceListModalComponent,
    CartListProductItemComponent,
    CartListProductChoiceCommentComponent,
    CustomerInfoComponent,
    DeliveryInfoComponent,
    DeliveryMethodAddressComponent,
    PaymentMethodsComponent,
    ExtraChoicesComponent,
    ChangeQuantityComponent,
    ChangeQuantityInputComponent,
    ChangeQuantityInputPopoverComponent,
    OrderCommentsComponent,
    OrderNowPopupComponent,
    OrderNowSettingsPopupComponent,
    DeliveryAreasPage,
    DeliveryAreasInfoModalComponent,
    LoyaltySystemPage,
    MagicLoginConfirmationComponent,
    SelectStorePopupComponent,
    SelectStoreImagePopupComponent,
    LanguageSelectionComponent,
    LanguageStatusPipe,
    AccountSettingsPage,
    OrdersPage,
    OrderDetailsComponent,
    OrderItemComponent,
    OrderItemDetailsComponent,
    OrderDetailsProductItemComponent,
    OrderDetailsProductItemOptionsComponent,
    PaymentCardsPage,
    SelectStoreComponent,
    SelectPaymentGatewayTokenizationComponent,
    StoreSchedulePage,
    PaymentTermsPage,
    PointsPage,
    RewardsComponent,
    HistoryComponent,
    RulesComponent,
    ProfileComponent,
    RatingsPage,
    RatingsItemComponent,
    RatingDetailsComponent,
    DiscountCouponsPage,
    CouponsItemComponent,
    InvoiceDataPage,
    MenuFooterComponent,
    MenuFooterTabsCompleteOrderComponent,
    BillingInfoComponent,
    PiraeusThreeDsPopupComponent,
    BraintreeModalComponent,
    AlphaModalComponent,
    EthnikiModalComponent,
    NbgSimplifyModalComponent,
    MyposModalComponent,
    PaypalModalComponent,
    KlarnaModalComponent,
    SubmitUserDataModalComponent,
    CheckGpsCouponRuleModalComponent,

    CategoryItemTagsComponent,
    ProductItemTagsComponent,
    ProductItemOrderTypeTagsComponent,
    ProductOptionTagsComponent,
    ProductChoiceTagsComponent,
    SafePipePipe,
    NoInternetConnectionComponent,
    IonicUpdateErrorComponent,
    SubcategoryProductsBrowserComponent,
    SubcategoryProductsMobileComponent,
    OrderRatingModalComponent,
    AppRatingModalComponent,
    CustomerPreferencesModalComponent,
    NewAddressModalComponent,
    NotValidAddressModalComponent,
    FirebaseRecaptchaLoginComponent,
    FirebaseRecaptchaComponent,
    ScanLoginModalComponent,
    ExtraAddressDetailsModalComponent,
    UpdateModalComponent,
    VerticalHyperCategoriesComponent,
    HorizontalHyperCategoriesComponent,
    NextHyperCategoryComponent,
    NextHyperCategoryMobileComponent,
    FavoritesBrowserComponent,
    FavoritesMobileComponent,
    CatalogSearchComponentComponent,
    CatalogSearchModalComponent,
    SendOrderPayPopupComponent,
    SendOrderAndPayInfoPopupComponent,
    SendOrderPopupComponent,
    PreviousOrdersSliderComponent,
    PreviousOrdersSliderMobileComponent,
    PreviousOrderModalComponent,
    PreorderPopupComponent,
    BundleUpdatePage,
    BillingInfoVerifyComponent,
    SelectPaymentMethodPopupComponent,
    OrderCustomerNotificationsModalComponent,
    CompleteOrderButtonComponent,
    SideMenuHeaderLogoComponent,
    FavoriteProductsPage,
    PlasticBugTaxOptionComponent,
    PlasticWrapperTaxOptionComponent,
    PlasticBugWithLimitOptionComponent,
    AddCouponCodeComponent,
    OrderTimelineModalComponent,
    DineInModalComponent,
    AlergensPage,
    AllergenModalComponent,
    InfoMessageModalComponent,
    CouponsScheduleInfoPopupComponent,
    BundleProductItemComponent,
    OrderDetailsBundleProductItemComponent,
    PreviousOrderBundleProductCommentComponent,
    BlockStoreModalComponent,
    LoyaltySwiperComponent,
    LoyaltyHomePage,
    LoyaltyCouponsSliderComponent,
    AccountInfoForContestModalComponent,
    QrcardModalComponent,
    CampaignItemInformationComponent,
    LoyaltyCouponsPage,
    LoyaltyCouponItemComponent,
    SelectStoreModalComponent,
    ProductPopoverComponent,
    CreateCouponModalComponent,
    LoyaltyMorePage,
    LoyaltyPurchaseHistoryComponent,
    reverseArrayPipe,
    UploadImageFileDropComponent,
    PhotoCropperComponent,
    EditProfileImageModalComponent,
    HallOfFameComponent,
    AffiliatePage,
    AffiliateInfoModalComponent,
    UnavailableProductsModalComponent,
    FilterAvailableProductsPipe,
    FilterOnlyDineInDeliveryPickupProductsPipe,
    FilterEmptyCategoriesForOnlyDineInDeliveryPickupProductsPipe,
    NoLocationDineInModalComponent,
    GeneralInfoModalComponent,
    CouponInfoPopupComponent,
    DeletedUserInfoModalComponent,
    CreateOrderFailedPreorderModalComponent,
    AffiliateReceiversCouponsComponent,
    FilterPaymentMethodPipe,
    MigratePointsComponent,
    LoyaltyContestsPage,
    LoyaltyContestItemComponent,
    LoyaltyContestGiftsComponent,
    ContestModalComponent,
    PreorderDayPipe,
    NavigationToBankModalComponent,
    LoadingSplashscreenComponent,
    ReservationsPage,
    CreateReservationPage,
    ReservationDatePopupComponent,
    SendReservationPopupComponent,
    SendReservationInfoPopupComponent,
    ReservationTablesPipe,
    ReservationsItemComponent,
    ReservationTableInfoModalComponent,
    FilterStoresSelectionPipe,
    FixedAddressInfoPopupComponent,
    FillRequiredNameModalComponent,
    UserWinnerModalComponent,
    ProductValidDeliveryAreasPopupComponent,
    KioskPage,
    KioskQrInfoModalComponent,
    KioskGetPromoModalComponent,
    KioskCouponsItemComponent,
    KioskCouponQrDisplayModalComponent,
    KioskRewardsItemComponent,
    KioskRewardPipe,
    KioskExpirationTimestampTimerPipe,
    FilterSelectedCategoryProductsPipe,
    HyperCategoriesSelectionComponent,
    HyperCategoriesSelectionModalComponent,
    HomepageLogoComponent,
    HomepageUpperSliderComponent,
    FullscreenImagePopupComponent,
    ProductShowOptionInDescriptionObjectComponent,
    DeliveryInfoModalComponent,
    CalculateNoPromosPointsToEarnPipe,
    QrScanModalComponent,
    ContestWinnersModalComponent,
    DeliveryTrackingModalComponent,
    CatalogDisclaimerComponent,
    CatalogDisclaimerPopupComponent,
    CatalogDisclaimerButtonComponent,

    NoScannerAvailableModalComponent,
    OrderStoresByDistancePipe,
    DeliveryCostInfoModalComponent,
    TwoDecimalsRoundPipe,
    BrowserProductListProductsComponent,
    MobileProductListProductsComponent,
    RecommendedProductsPopupComponent,
    FilterShowInDescriptionObjectChoicesPipe,
    FilterInlineCartInfoMessagesPipe,
    MenuFooterTabsComponent,
    SelectedFixedPointsAddressModalComponent,
    FixedPointsAddressConfirmationPopupComponent,
    FixedPointsAddressSelectionModalComponent,
    FilterPointsFactorRulesByOrderTypePipe,
    FactorPointRuleOrderTypePipe,
    FilterFixedPointsAddressAreasPipe,
    SelectedProductPricePipe,
    CalculateDeliveryCostDistanceChargeTypePipe,
    VatLevelsDetailsModalComponent,
    FilterAddressesMigrationCustomerIdPipe,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    FormsModule,
    SwiperModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    ReviewOrderLibModule,
    StarRatingModule,
    HttpClientModule,
    QRCodeModule,

    LottieModule.forRoot({ player: playerFactory }),
    StoreModule.forRoot(fromApp.appReducer),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LanguageLoader,
        deps: [HttpClient],
      },
    }),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    NgxFileDropModule,
    ImageCropperModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: ParameterHashLocationStrategy },
    DataStorageService,
    AuthService,
    LocalStorageDataService,
    PaymentGatewaysService,
    DeployService,
    PointsProductScheduleService,
    CalculateFinalPriceService,
    Deploy,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
