import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Subscription } from "rxjs";
import { OrderNowModalPopupsService } from "src/app/services/orderNowModalPopupsService/orderNowModalPopups.service";
import { TranslateService } from "@ngx-translate/core";
import * as selectors from "../../store/app.selectors";
import { getDistance } from "geolib";
import { CheckLocationDistanceService } from "src/app/services/checkLocationDistance/check-location-distance.service";
import { NoLocationDineInModalComponent } from "../no-location-dine-in-modal/no-location-dine-in-modal.component";
import { resolve } from "path";
import { AnimationOptions } from "ngx-lottie";
// import { Barcode, BarcodeScanner } from "@capacitor-mlkit/barcode-scanning";
import { QrScanModalComponent } from "./qr-scan-modal/qr-scan-modal.component";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { App } from "@capacitor/app";
import { Camera, CameraResultType } from "@capacitor/camera";
import { NoScannerAvailableModalComponent } from "./no-scanner-available-modal/no-scanner-available-modal.component";

@Component({
  selector: "app-order-now-popup",
  templateUrl: "./order-now-popup.component.html",
  styleUrls: ["./order-now-popup.component.scss"],
})
export class OrderNowPopupComponent implements OnInit, OnDestroy {
  @Input() currentView: string;
  public currentUser: any;
  public segment = "delivery";
  public showPickupButton: boolean;
  public showDineInButton: boolean;
  public showDeliveryButton: boolean;
  public stores: any;
  public storeInfo: any;
  public deliveryColSize = "6";
  public takeAwayColSize = "6";
  public dineInColSize = "12";
  public windowDimensions: any;
  public groupApp: any;
  public loginState: any;
  public customerOrders: any;
  public infoShowDeliveryButton: any = false;
  public selectedLangugage: any;
  public mo_base: any;

  private ionLoading: any;
  private geoErrMessage;
  private gpsErrorAlert;
  private subscriptions: Subscription[] = [];
  private currentStoreId: string;
  private selectedAddress: any;
  private unsubscribeBackEvent: Subscription;
  private noLocationDineInModal: any;
  private navigationOnDeliveryMethodChange: any;
  private windowInfoSub: Subscription;

  deliveryAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/delivery.json",
  };
  takeawayAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/takeaway.json",
  };
  dineinAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/dinein.json",
  };
  qrScanModal: HTMLIonModalElement;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private platform: Platform,
    private translateService: TranslateService,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private checkLocationDistanceService: CheckLocationDistanceService,
    private loadingController: LoadingController
  ) {}

  windowInfoInit() {
    if (this.windowInfoSub) {
      this.windowInfoSub.unsubscribe();
      this.windowInfoSub = null;
    }

    this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (state && !_.isEqual(state.loginState, this.loginState)) {
          this.loginState = _.cloneDeep(state.loginState);
          if (this.loginState && this.loginState === "login_completed") {
            if (
              state &&
              !_.isEqual(state.customerOrders, this.customerOrders)
            ) {
              this.customerOrders = _.cloneDeep(
                _.filter(_.cloneDeep(state.customerOrders) || [], (order) => {
                  return order.status === "complete";
                })
              );
            }
          }
        }
      });

    this.windowInfoSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
          this.calculateColSizes();
        }
      });
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            this.mo_base = _.cloneDeep(
              _.find(this.groupApp.languages, {
                mo_base: true,
              })
            );
            if (!this.mo_base) {
              this.mo_base = "en";
            } else {
              this.mo_base = this.mo_base.code;
            }
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLangugage, state.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);

            // this.setHightOfModal();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);

            // this.setHightOfModal();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())

        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);

            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (
                  !store.disableOrderDelivery &&
                  store.deliveryAreas &&
                  store.deliveryAreas.length
                ) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );

            console.log("dine in button", this.showDineInButton);

            this.windowInfoInit();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.selectedAddress &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
            console.log("stores", this.stores);
          }
          if (
            state &&
            !_.isEqual(
              state.navigationOnDeliveryMethodChange,
              this.navigationOnDeliveryMethodChange
            )
          ) {
            this.navigationOnDeliveryMethodChange = _.cloneDeep(
              state.navigationOnDeliveryMethodChange
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );
  }

  async openQrScannerSingleScan() {
    this.qrScanModal = await this.modalCtrl.create({
      component: QrScanModalComponent,
      cssClass: "barcode-scanning-modal",
      showBackdrop: false,
    });
    await this.qrScanModal.present();
  }

  async modalWhenQrScannerNotAvailable() {
    const noQrScannerModal = await this.modalCtrl.create({
      component: NoScannerAvailableModalComponent,
      showBackdrop: false,
      cssClass:
        window && window.innerWidth <= 420
          ? "infoMessagesModalMobile"
          : "infoMessagesModalBrowser",
      componentProps: {
        branchLink: this.groupApp.branch_link,
        group: this.groupApp.group,
      },
    });
    await noQrScannerModal.present();
  }

  async checkAndOpenSettingsModal(mode) {
    if (
      mode === "dine-in" &&
      this.storeInfo &&
      this.storeInfo.dine_in_disable_table_selection
    ) {
      // if (Capacitor.isNativePlatform()) {
      //   const appInfo = await App.getInfo();
      //   console.log("app Info", appInfo);
      //   if (parseInt(appInfo.build) >= 702000) {
      //     this.openQrScannerSingleScan();
      //   } else {
      //     this.showToastForScanQr();
      //   }
      // } else {
      //   this.modalWhenQrScannerNotAvailable();
      // }
      this.showToastForScanQr();
    } else {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        mode,
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        this.navigationOnDeliveryMethodChange,
        true
      );
    }
  }

  async showToastForScanQr() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant("open_camera_and_scan_table_qr"),
      backdropDismiss: true,
      buttons: ["OK"],
    });
    await alert.present();
    await alert.onDidDismiss().then(() => {
      this.orderNowModalPopupsService.closeSettingsModal();
      this.modalCtrl.dismiss();
    });
  }

  calculateColSizes() {
    if (this.windowDimensions && this.windowDimensions.width) {
      if (this.windowDimensions.width < 750) {
        if (
          (this.showDeliveryButton || this.infoShowDeliveryButton) &&
          this.showPickupButton &&
          this.showDineInButton
        ) {
          this.deliveryColSize = "6";
          this.takeAwayColSize = "6";
          this.dineInColSize = "12";
        } else if (
          (this.showDeliveryButton || this.infoShowDeliveryButton) &&
          !this.showPickupButton &&
          this.showDineInButton
        ) {
          this.deliveryColSize = "6";
          this.dineInColSize = "6";
        } else if (
          (this.showDeliveryButton || this.infoShowDeliveryButton) &&
          this.showPickupButton &&
          !this.showDineInButton
        ) {
          this.deliveryColSize = "6";
          this.takeAwayColSize = "6";
        } else if (
          !this.showDeliveryButton &&
          !this.infoShowDeliveryButton &&
          this.showPickupButton &&
          this.showDineInButton
        ) {
          this.dineInColSize = "6";
          this.takeAwayColSize = "6";
        }
      } else {
        if (
          (this.showDeliveryButton || this.infoShowDeliveryButton) &&
          this.showPickupButton &&
          this.showDineInButton
        ) {
          this.deliveryColSize = "4";
          this.takeAwayColSize = "4";
          this.dineInColSize = "4";
        } else if (
          (this.showDeliveryButton || this.infoShowDeliveryButton) &&
          !this.showPickupButton &&
          this.showDineInButton
        ) {
          this.deliveryColSize = "6";
          this.dineInColSize = "6";
        } else if (
          (this.showDeliveryButton || this.infoShowDeliveryButton) &&
          this.showPickupButton &&
          !this.showDineInButton
        ) {
          this.deliveryColSize = "6";
          this.takeAwayColSize = "6";
        } else if (
          !this.showDeliveryButton &&
          !this.infoShowDeliveryButton &&
          this.showPickupButton &&
          this.showDineInButton
        ) {
          this.dineInColSize = "6";
          this.takeAwayColSize = "6";
        }
      }
    }
  }

  async presentIonLoading() {
    let message = this.translateService.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 30000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });

    return await this.ionLoading.present();
  }

  dismissIonLoading() {
    console.log("Loading dismissed!", this.ionLoading);
    if (this.ionLoading) {
      this.ionLoading.dismiss();
      this.ionLoading = null;
    }
  }

  async checkDeviceLocation(presentLoading) {
    if (presentLoading) {
      await this.presentIonLoading();
    }

    let distance: any;
    const distance_object: any =
      await this.checkLocationDistanceService.calculateDistanceFromGps(
        {
          lat: this.storeInfo.mapCenterStoreLat,
          lng: this.storeInfo.mapCenterStoreLng,
        },
        false
      );

    this.dismissIonLoading();
    console.log("distance_object", distance_object);
    if (distance_object && distance_object.distance) {
      distance = distance_object.distance;
    }

    if (distance) {
      if (distance <= 100) {
        await this.checkAndOpenSettingsModal("dine-in");
      } else {
        this.openNoLocationDineInModal(
          "popups.no-location-dine-in-modal.you-are-out-of-store"
        );
      }
    } else {
      if (!distance_object || !distance_object.error) {
        this.openNoLocationDineInModal(
          this.translateService.instant(
            "popups.no-location-dine-in-modal.something-went-wrong-try-again"
          )
        );
      } else if (distance_object && distance_object.error) {
        if (distance_object.error === "no-gps-enabled") {
          this.openNoLocationDineInModal(
            this.translateService.instant(
              "popups.no-location-dine-in-modal.no-gps-enabled"
            )
          );
        } else if (distance_object.error === "no-coordinates") {
          this.openNoLocationDineInModal(
            this.translateService.instant(
              "popups.no-location-dine-in-modal.no-coordinates"
            )
          );
        } else if (distance_object.error) {
          this.openNoLocationDineInModal(
            this.translateService.instant(
              this.translateService.instant(distance_object.error)
            )
          );
        } else {
          console.log("GPS no error");
        }
      }
    }
  }

  async openNoLocationDineInModal(message) {
    this.noLocationDineInModal = await this.modalCtrl.create({
      component: NoLocationDineInModalComponent,
      cssClass: "no-location-dine-in-modal",
      animated: true,
      componentProps: {
        orderNowModal: true,
        message: message,
      },
      backdropDismiss: false,
    });

    await this.noLocationDineInModal.present();
    this.noLocationDineInModal.onDidDismiss().then(async (data) => {
      this.noLocationDineInModal = null;
      if (data && data.data && data.data === "check-location-again") {
        await this.presentIonLoading();
        // delay 500 ms for better modal display
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve("");
          }, 500);
        });
        this.checkDeviceLocation(false);
      }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    // if (this.settingsModal) {
    //   this.settingsModal.dismiss();
    //   this.settingsModal = null;
    // }
    // if (this.addNewAddressModal) {
    //   this.addNewAddressModal.dismiss();
    //   this.addNewAddressModal = null;
    // }
    // if (this.firebaseRecaptchaModal) {
    //   this.firebaseRecaptchaModal.dismiss();
    //   this.firebaseRecaptchaModal = null;
    // }

    if (this.noLocationDineInModal) {
      this.noLocationDineInModal.dismiss();
      this.noLocationDineInModal = null;
    }

    if (this.windowInfoSub) {
      this.windowInfoSub.unsubscribe();
    }

    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
