import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { Store } from "@ngrx/store";

import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { AlertController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as CouponsActions from "../../../discount-coupons/store/coupons.actions";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { Capacitor } from "@capacitor/core";
import { InformationMessagesService } from "src/app/services/informationMessages/information-messages.service";
import { Router } from "@angular/router";

declare let window: any;

@Component({
  selector: "app-add-coupon-code",
  templateUrl: "./add-coupon-code.component.html",
  styleUrls: ["./add-coupon-code.component.scss"],
})
export class AddCouponCodeComponent implements OnInit, OnDestroy {
  public couponCode: string;
  public loading: boolean = false;
  public stores: any;
  public storeInfo: any;
  public dirtyField: boolean = false;
  public infoMessage: any;
  public motoCardsProgressValues = {};
  public autoDismissInfoMessageIntervals = {};
  public autoDismissTimeOuts = {};
  public infoMessagesCardsHide = {};
  public languagePath;
  public mo_base;
  public groupApp: any;

  private newCustomer: any;
  private currentUser: any;
  private errorToast: any;

  private cartInfoMessagesStore: any;
  private cartInfoMessagesGroup: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translateService: TranslateService,
    private analyticsService: AnalyticsServiceService,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private infoMessagesService: InformationMessagesService,
    private loginPopupService: LoginPopupsServiceService,
    private router: Router
  ) {}

  addCouponCode() {
    if (this.couponCode) {
      this.loading = true;
      const coupon_code = this.couponCode.toString().toUpperCase();
      this.dataStorageService
        .moCheckCouponCode({
          coupon_code: coupon_code,
          group: this.groupApp.group,
          store_id: this.storeInfo.store_id,
          mobile:
            this.currentUser && this.currentUser.mobile
              ? this.currentUser.mobile
              : null,
        })

        .subscribe({
          next: async (res: any) => {
            this.dirtyField = false;
            if (res && res.success) {
              if (this.currentUser) {
                console.log("moCheckCouponCode res", res);
                const alert = await this.alertController.create({
                  header: this.translateService.instant("information"),
                  subHeader:
                    res.data && res.data.coupon_rule_description
                      ? res.data.coupon_rule_description
                      : res.data && res.data.firstName
                      ? "Έγκυρος κωδικός από: " + res.data.firstName
                      : "",
                  message: res.comment_id
                    ? this.translateService.instant(res.comment_id) +
                      " " +
                      this.translateService.instant(
                        "wait-a-minute-for-your-coupon-to-be-created"
                      )
                    : res.comments +
                      " " +
                      this.translateService.instant(
                        "wait-a-minute-for-your-coupon-to-be-created"
                      ),
                  buttons: ["OK"],
                });
                await alert.present();
              } else {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("information"),
                  message: res.comment_id
                    ? this.translateService.instant(res.comment_id) +
                      " " +
                      this.translateService.instant(
                        "please-connect-to-get-the-promo"
                      )
                    : res.comments +
                      " " +
                      this.translateService.instant(
                        "please-connect-to-get-the-promo"
                      ),
                  buttons: [
                    {
                      text: this.translateService.instant("cancel"),
                      role: "cancel",
                      cssClass: "secondary",
                      handler: (blah) => {
                        console.log("cancel");
                      },
                    },
                    {
                      text: this.translateService.instant(
                        "homepage.magic-login"
                      ),
                      handler: () => {
                        this.presentMagicLoginPopup();
                      },
                    },
                  ],
                });
                await alert.present();
              }

              this.loading = false;
              if (res.data && res.data.affiliate_coupon_code) {
                this.store.dispatch(
                  new CouponsActions.SetTriggerCouponCodeCheck({
                    timestamp: Date.now(),
                    coupon_code: _.cloneDeep(res.data.affiliate_coupon_code),
                    type: "affiliate_coupon_code",
                  })
                );
              } else if (res.data && res.data.coupon_rule_id) {
                this.store.dispatch(
                  new CouponsActions.SetTriggerCouponCodeCheck({
                    timestamp: Date.now(),
                    coupon_code: _.cloneDeep(res.data.coupon_rule_id),
                    type: "coupon_rule_id",
                  })
                );
              } else if (res.data && res.data.partner_coupon_id) {
                this.store.dispatch(
                  new CouponsActions.SetTriggerCouponCodeCheck({
                    timestamp: Date.now(),
                    coupon_code: _.cloneDeep(res.data.partner_coupon_id),
                    type: "partner_coupon_id",
                  })
                );
              }
              if (this.errorToast) {
                this.errorToast.dismiss();
                this.errorToast = null;
              }

              this.couponCode = null;
            } else if (!res.success) {
              this.loading = false;
              if (this.errorToast) {
                this.errorToast.dismiss();
                this.errorToast = null;
              }
              console.log("The coupon rule id is false or something");
              if (res && res.comment_id) {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("information"),
                  message: res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : res.comments,
                  buttons: ["OK"],
                });
                await alert.present();
              }
            } else {
              if (this.errorToast) {
                this.errorToast.dismiss();
                this.errorToast = null;
              }
              this.loading = false;
              this.presentErrorToast(
                this.translateService.instant("errorMessages.classic_error"),
                "danger"
              );
            }
          },
          error: (error) => {
            if (this.errorToast) {
              this.errorToast.dismiss();
              this.errorToast = null;
            }
            this.loading = false;
            this.presentErrorToast(
              this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),
              "danger"
            );
          },
        });
    } else {
      if (this.errorToast) {
        this.errorToast.dismiss();
        this.errorToast = null;
      }
      this.loading = false;
      this.presentErrorToast(
        this.translateService.instant(
          "errorMessages.you-have-to-type-a-coupon-code"
        ),
        "danger"
      );
    }
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.loginPopupService.presentFirebaseModalLogin(null, null);
    } else {
      this.loginPopupService.closeMobileMagicLoginAlert();
      this.loginPopupService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  async presentErrorToast(message, color) {
    this.errorToast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.errorToast.present();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.user);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            this.checkCartInfoMessages();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.languagePath, state.selectedLangugage)
          ) {
            this.languagePath = _.cloneDeep(state.selectedLangugage);
          }

          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            this.mo_base = _.cloneDeep(
              _.find(this.groupApp.languages, {
                mo_base: true,
              })
            );
            if (!this.mo_base) {
              this.mo_base = "en";
            } else {
              this.mo_base = this.mo_base.code;
            }
          }
        })
    );
  }

  checkCartInfoMessages() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.groupApp) {
          const cartInfoMessagesGroup = _.cloneDeep(
            this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
              _.cloneDeep(state.groupApp),
              _.cloneDeep(this.storeInfo),
              "cart"
            )
          );
          const cartInfoMessagesStore = _.cloneDeep(
            this.infoMessagesService.checkForMobileOrderInfoMessagesStore(
              _.cloneDeep(this.storeInfo),
              "cart"
            )
          );

          this.cartInfoMessagesStore = _.cloneDeep(
            _.filter(cartInfoMessagesStore, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "inline") &&
                infoMessage.cartPosition &&
                infoMessage.cartPosition === "add_coupon_code_component"
              ) {
                return infoMessage;
              }
            })
          );

          this.cartInfoMessagesGroup = _.cloneDeep(
            _.filter(cartInfoMessagesGroup, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "inline") &&
                infoMessage.cartPosition &&
                infoMessage.cartPosition === "add_coupon_code_component"
              ) {
                return infoMessage;
              }
            })
          );

          let mo_base = _.cloneDeep(
            _.find(state.groupApp.languages, {
              mo_base: true,
            })
          );

          if (!mo_base) {
            mo_base = "en";
          }

          if (this.cartInfoMessagesGroup && this.cartInfoMessagesGroup.length) {
            this.infoMessage = _.cloneDeep(_.first(this.cartInfoMessagesGroup));
          } else if (
            this.cartInfoMessagesStore &&
            this.cartInfoMessagesStore.length
          ) {
            this.infoMessage = _.cloneDeep(_.first(this.cartInfoMessagesStore));
          }

          // if (this.infoMessage) {
          //   if (
          //     this.infoMessage &&
          //     this.infoMessage.auto_dismiss &&
          //     this.infoMessage.auto_dismiss_seconds
          //   ) {
          //     this.CloseAnimationsFloatingInfoMessagesCard(
          //       this.infoMessage.id,
          //       this.infoMessage.auto_dismiss_seconds * 1000
          //     );
          //   }
          // }
        }
      })
      .unsubscribe();
  }

  CloseAnimationsFloatingInfoMessagesCard(id, dismiss_seconds) {
    const seconds = parseFloat(dismiss_seconds);

    this.motoCardsProgressValues[id] = 0.0;
    this.autoDismissInfoMessageIntervals[id] = setInterval(() => {
      this.motoCardsProgressValues[id] =
        this.motoCardsProgressValues[id] + 1 / (seconds / 150);
    }, 150);

    this.autoDismissTimeOuts[id] = setTimeout(() => {
      this.infoMessagesCardsHide[id] = true;
      clearInterval(this.autoDismissInfoMessageIntervals[id]);
    }, dismiss_seconds);
  }

  change(ev) {
    console.log("on change", this.couponCode);

    if (this.couponCode) {
      this.couponCode = this.couponCode.toUpperCase();
      this.dirtyField = true;
    } else {
      this.dirtyField = false;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }

  navigate(page) {
    this.router.navigateByUrl("/" + page, {
      replaceUrl: true,
    });
  }

  navigateToPage(message) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((groupState) => {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            if (message.navigationPage === "catalog") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "cart") {
              this.navigate(message.navigationPage);
            } else if (
              message.navigationPage === "kiosk" &&
              storeInfo.dm_kiosk_app_active &&
              !(
                groupState &&
                groupState.groupApp &&
                groupState.groupApp.design_settings &&
                groupState.groupApp.design_settings
                  .dm_kiosk_app_disable_mobileorder
              )
            ) {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "points") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "discount-coupons") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "orders") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "favorites") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "loyalty-home") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "reservations") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "contests") {
              this.navigate(message.navigationPage);
            } else if (
              message.navigationPage === "affiliate" &&
              groupState &&
              groupState.groupApp.affiliateActive
            ) {
              this.navigate(message.navigationPage);
            }
          })
          .unsubscribe();
      })
      .unsubscribe();
  }
}
