import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import * as fromApp from "../store/app.reducer";
import * as selectors from "../store/app.selectors";
import * as _ from "lodash";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as SelectedProductActions from "./store/selectedProduct.actions";
import { Subject, Subscription } from "rxjs";
import {
  AlertController,
  GestureController,
  IonContent,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import * as CartActions from "../cart/store/cart.actions";
import { TranslateService } from "@ngx-translate/core";
import * as ProductOptionsValidationActions from "./store/productOptionsValidation/productOptionsValidation.actions";
import { ProductDetailsOperationsService } from "./product-details-operations.service";
import * as productDetailsDimensionsActions from "./store/modal-demensions/product-details-dimensions.actions";
import * as OptionStepperActions from "./options/option-stepper/store/optionStepper.actions";
import * as FavoriteProductActions from "../store/favorite-products/favoriteProducts.actions";
import { LoginPopupsServiceService } from "../services/login-popups-service/login-popups-service.service";
import { Capacitor } from "@capacitor/core";
import * as PreviousOrdersActions from "../catalog/previous-order/store/previousOrders.actions";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { AnimationOptions } from "ngx-lottie";
import { v4 as uuid } from "uuid";
import { PreorderValidationServiceService } from "../services/preorder-validation-service/preorder-validation-service.service";
import { ProductValidDeliveryAreasPopupComponent } from "../popups/product-valid-delivery-areas-popup/product-valid-delivery-areas-popup.component";
import { FullscreenImagePopupComponent } from "../popups/fullscreen-image-popup/fullscreen-image-popup.component";
import * as bluebird from "bluebird";
declare let window: any;

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("optionsList") optionsListEl: any;
  @Input() parentBundleChoice: any;
  @Input() parentProduct: any;
  @Input() parentBundleOption: any;
  @Input() bundleMode: string; //edit or add
  @Input() bundleOptions: any;
  @Input() openingFromBundleChoicesSelection: boolean;
  @Input() edited_bundle_product: any;
  @Input() product_id: string;
  @Input() cart_id: string;
  @Input() previousOrderMode: boolean;
  @Input() order: any;
  @Input() add_bundle_product_to_cart: boolean;
  public selectedProductId = uuid();
  public selectedProduct: any;
  public store_id: string;
  public cartProducts: any;
  public orderPickup: any;
  public dineIn: any;
  public languagePath: string = "el";
  public disableAddButton: boolean = false;
  public disableNextStepButton: boolean = false;
  public image: any;
  public webp: any;
  public store_info: any;
  public animateAddToCartButton: boolean;
  public selectedProductTotalPrice: any;
  public currentOptionStep: any;
  public showAddToCartButtonForStepper: boolean = false;
  public allOptionSteps: any;
  public stepperProgress: number = 0;
  public mo_token: any;
  public currentUser: any;
  public favoriteProducts: any;
  public selectedProductIsFavorite: boolean = false;
  public loadingFavorites: boolean = false;
  public changeQuantityButtonsSize: string;
  public textSizeMedium: boolean;
  public groupApp: any;
  public favoriteAnimation: boolean = false;
  public favoriteAnimationInstance: any;
  public swipeMoveEventSubject = new Subject<any>();
  public swipeInfoAnimationInstance: any;
  public swipeInfoAnimation: any;
  public productDetailsInfoCheckbox: boolean = true;
  public customerPricelist: any;
  public windowDimensions: any;
  public productDetailsDimensions: any;
  public bigProductDetailsDesign: string;
  public migration_customer_id: any;
  public productImageLoaded: boolean;
  public selectedDineInTable: any;
  public selectedPreorderDate: any;
  public selectedProductLoaded: boolean = false;
  public finalPriceLoading;
  public groupStoreData: any = {};
  public selectedAddress;
  public showLoading;
  public productPointsToEarn;
  public disableForDeliveryMethodAddress = false;
  public plasticWrapperTaxProductPrice;
  public atLeastOneFrozenTag: boolean = false;
  public selectedLangugage: string | null;
  public mo_base: string | null;

  private bundleOptionLoadingArray: any;
  private isIos: boolean;
  private gesture: any;
  private newCustomer: any;
  private productOptionsValidation: any;
  private subscriptions: Subscription[] = [];
  private productDetailSubscriptions: Subscription[] = [];
  private migrationCustomerIdSub: Subscription;
  private addProductToCartValidationSub: Subscription;
  private catalogUserPricelistSub: Subscription;
  private nextStepValidationSub: Subscription;
  private addButtonClicked: boolean;
  private animateCartButtonSubscription: Subscription;
  private groupAppSub: Subscription;
  private windowInfoSub: Subscription;
  private productDetailsDimensionsSub: Subscription;
  private unsubscribeBackEvent: Subscription;
  private hardwareBackButtonPriorities: any;
  private errorToast: any;
  private errorWrapperTaxToast: any;
  private mustLoginFavoritesAlert: any;
  private recalculateProductPreorderAvailabilityTimestamp = null;
  private preorderProductAvailabilityAlert: any;
  private productValidDeliveryAreasModal;
  private imageFullscreenModal;
  private orderPickupUpdated;
  private dineInUpdated;

  public productHasBundleOptions: boolean = false;

  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/favorite-animation.json",
  };

  swipeInfoAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/swipe-animation.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private translateService: TranslateService,
    private productDetailsOperationsService: ProductDetailsOperationsService,
    private loginPopupsService: LoginPopupsServiceService,
    private alertController: AlertController,
    private analyticsService: AnalyticsServiceService,
    private platform: Platform,
    private toastController: ToastController,
    private changeDetector: ChangeDetectorRef,
    private gestureController: GestureController,
    private preorderValidationService: PreorderValidationServiceService
  ) {}

  ionViewDidEnter() {
    //console.log("bundle mode", this.bundleMode);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          if (this.loginPopupsService.checkMobileMagicLoginIsOpen()) {
            this.loginPopupsService.closeMobileMagicLoginAlert();
          } else if (this.mustLoginFavoritesAlert) {
            this.mustLoginFavoritesAlert.dismiss();
            this.mustLoginFavoritesAlert = null;
          } else {
            this.closeModal();
          }
        }
      }
    );

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          this.groupStoreData.groupApp = {};
          this.groupStoreData.groupApp.mo_base = _.find(
            _.cloneDeep(this.groupApp.languages),
            {
              mo_base: true,
            }
          );
          this.mo_base = _.cloneDeep(
            this.groupStoreData.groupApp.mo_base &&
              this.groupStoreData.groupApp.mo_base.code
              ? this.groupStoreData.groupApp.mo_base.code
              : null
          );
          this.groupStoreData.groupApp.selectedLanguage = _.cloneDeep(
            state.selectedLangugage
          );

          this.groupStoreData.iconImage = _.cloneDeep(this.groupApp.iconImage);

          this.groupStoreData.group = _.cloneDeep(this.groupApp.group);
        }

        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLangugage, state.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });

    this.productDetailsDimensionsSub = this.store
      .select("productDetailsDimensions")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.productDetailsDimensions &&
          !_.isEqual(
            this.productDetailsDimensions,
            state.productDetailsDimensions
          )
        ) {
          this.productDetailsDimensions = _.cloneDeep(
            state.productDetailsDimensions
          );
          this.checkProductDetailsBigDesign();
        }
      });

    this.windowInfoSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
          this.checkProductDetailsBigDesign();
        }
      });

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("productDetails");
    this.content.getScrollElement().then((el) => {
      // console.log("modal width", el.offsetWidth);
      //console.log("modal height", el.offsetHeight);
      let modalWidth = el.offsetWidth;
      let modalHeight = el.offsetHeight;
      this.setModalButtons(modalWidth);
      if (modalWidth && modalHeight) {
        let dem = {
          height: modalHeight,
          width: modalWidth,
          selectedProductId: _.cloneDeep(this.selectedProductId),
        };
        this.store.dispatch(
          new productDetailsDimensionsActions.SetProductDetailsDimensions(
            _.cloneDeep(dem)
          )
        );
      }
    });

    window.addEventListener("resize", this.debouceResize);
  }

  private debouceResize = _.debounce(
    () => {
      console.log("resize");
      this.content.getScrollElement().then((el) => {
        //console.log("modal width", el.offsetWidth);
        //console.log("modal height", el.offsetHeight);
        let modalWidth = el.offsetWidth;
        let modalHeight = el.offsetHeight;
        this.setModalButtons(modalWidth);
        if (modalWidth && modalHeight) {
          let dem = {
            height: modalHeight,
            width: modalWidth,
            selectedProductId: _.cloneDeep(this.selectedProductId),
          };
          this.store.dispatch(
            new productDetailsDimensionsActions.SetProductDetailsDimensions(
              _.cloneDeep(dem)
            )
          );
        }
      });
    },
    500,
    { maxWait: 500 }
  );

  setModalButtons(modalWidth) {
    if (modalWidth && modalWidth < 350) {
      this.changeQuantityButtonsSize = "small";
    } else {
      this.changeQuantityButtonsSize = "medium";
      this.textSizeMedium = true;
    }
  }

  async addToFavorites() {
    // console.log("add to favorite");

    if (this.currentUser && this.mo_token) {
      let type: string = this.selectedProductIsFavorite ? "delete" : "create";
      this.loadingFavorites = true;
      this.dataStorageService
        .handleFavoriteProduct(
          this.store_id,
          this.selectedProduct.product_id,
          type,
          this.mo_token,
          this.migration_customer_id
        )
        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              this.loadingFavorites = false;
              let favProduct = {
                store_id: this.store_id,
                product_id: this.selectedProduct.product_id,
                customer_id: this.currentUser.customer_id,
                migration_customer_id: this.migration_customer_id
                  ? this.migration_customer_id
                  : null,
              };
              if (type === "delete") {
                this.store.dispatch(
                  new FavoriteProductActions.DeleteFavoriteProduct({
                    favoriteProduct: _.cloneDeep(favProduct),
                    migration_customer_id: this.migration_customer_id,
                  })
                );
              } else if (type === "create") {
                this.favoriteAnimation = true;
                this.store.dispatch(
                  new FavoriteProductActions.AddFavoriteProduct({
                    favoriteProduct: _.cloneDeep(favProduct),
                    migration_customer_id: this.migration_customer_id,
                  })
                );
              }
            } else {
              this.loadingFavorites = false;
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                ),

                buttons: ["OK"],
              });
              await alert.present();
            }
          },
          error: async (err) => {
            this.loadingFavorites = false;
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    } else {
      this.mustLoginFavoritesAlert = await this.alertController.create({
        header: this.translateService.instant("product-details.favorites"),
        message: this.translateService.instant(
          "product-details.in-order-to-add-to-favorites-you-must-login"
        ),
        buttons: [
          {
            text: this.translateService.instant("cancel"),
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              // console.log("Confirm Cancel: blah");
            },
          },
          {
            text: this.translateService.instant("product-details.login"),
            handler: () => {
              this.presentMagicLoginPopup();
            },
          },
        ],
      });

      await this.mustLoginFavoritesAlert.present();
      await this.mustLoginFavoritesAlert.onDidDismiss().then(() => {
        this.mustLoginFavoritesAlert = null;
      });
    }
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.loginPopupsService.presentFirebaseModalLogin(null, null);
    } else {
      this.loginPopupsService.closeMobileMagicLoginAlert();
      this.loginPopupsService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  onMoveEnd(ev) {
    this.swipeMoveEventSubject.next(ev);
  }

  infoAnimationCreated(ev) {
    this.swipeInfoAnimationInstance = ev;
    this.swipeInfoAnimationInstance.setSpeed(1.2);
  }

  checkAndShowSwipeAnimation() {
    if (navigator.cookieEnabled) {
      let pageInfoObject: any;
      if (
        localStorage.getItem("pageInfoAnimations") &&
        localStorage.getItem("pageInfoAnimations") !== ""
      ) {
        pageInfoObject = _.cloneDeep(
          JSON.parse(localStorage.getItem("pageInfoAnimations"))
        );
      }
      if (!pageInfoObject || !pageInfoObject.product_details_showed) {
        this.swipeInfoAnimation = true;
      }
    } else {
      this.swipeInfoAnimation = true;
    }
  }

  productDetailsSwipeInfo() {
    if (navigator.cookieEnabled && this.productDetailsInfoCheckbox) {
      let pageInfoAnimationsObj: any =
        localStorage.getItem("pageInfoAnimations");
      if (pageInfoAnimationsObj && pageInfoAnimationsObj !== "") {
        pageInfoAnimationsObj = JSON.parse(_.cloneDeep(pageInfoAnimationsObj));
        pageInfoAnimationsObj.product_details_showed = true;
        localStorage.setItem(
          "pageInfoAnimations",
          JSON.stringify(pageInfoAnimationsObj)
        );
      } else {
        const pageInfoObject = {
          product_details_showed: true,
        };
        localStorage.setItem(
          "pageInfoAnimations",
          JSON.stringify(pageInfoObject)
        );
      }
    }
    this.swipeInfoAnimation = false;
  }

  ngOnInit() {
    if (
      this.add_bundle_product_to_cart &&
      this.parentBundleOption &&
      this.bundleMode
    ) {
      this.store.dispatch(
        new ProductOptionsValidationActions.AddBundleOptionLoading(
          _.cloneDeep(this.parentBundleOption.product_id_option_id)
        )
      );
    }
    if (this.platform && this.platform.is("ios")) {
      this.isIos = true;
    }
    setTimeout(() => {
      if (this.gesture) {
        this.gesture.destroy();
        this.gesture = null;
      }
      this.gesture = this.gestureController.create(
        {
          el: this.optionsListEl ? this.optionsListEl.nativeElement : "",
          threshold: this.isIos ? 5 : 10,
          maxAngle: 50,
          gestureName: "swipe-catelog",

          onEnd: (ev) => this.onMoveEnd(ev),
        },
        true
      );
      this.gesture.enable(true);
    }, 1000);
    let modal = document.getElementById("product-details-modal");
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.selectedLangugage) {
          this.languagePath = _.cloneDeep(state.selectedLangugage);
          this.onInitActions();
        } else {
          this.languagePath = "el";
          this.onInitActions();
        }
      })
      .unsubscribe();
  }

  onInitActions() {
    console.log("product details on init actions");
    this.productDetailSubscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.mo_token, this.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
            //console.log("mo_token product details", this.mo_token);
          }
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
            // console.log("mo_token product details", this.mo_token);
          }
          if (
            state &&
            state.newCustomer &&
            !_.isEqual(state.newCustomer, this.newCustomer)
          ) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(state.currentStoreId, this.store_id)
          ) {
            this.store_id = _.cloneDeep(state.currentStoreId);
            this.subscriptions.push(
              this.store
                .select("deliveryMethodAddress")
                .pipe(distinctUntilChanged())
                .subscribe((state) => {
                  if (
                    state &&
                    !_.isEqual(state.orderPickup, this.orderPickup)
                  ) {
                    this.orderPickup = state.orderPickup;

                    if (this.product_id && !this.cart_id) {
                      this.store
                        .select("auth")
                        .pipe(first())
                        .subscribe((authState) => {
                          if (
                            authState &&
                            authState.loginState &&
                            (authState.loginState === "login_completed" ||
                              authState.loginState === "login_loading")
                          ) {
                            this.subscribeToCatalogUserPricelists();
                          } else {
                            this.fetchProduct(
                              this.order ? this.order.store_id : this.store_id,
                              this.product_id,
                              this.languagePath,
                              true
                            );
                            this.subscribeToCatalogUserPricelists();
                          }
                        });
                    } else if (this.cart_id && this.product_id) {
                      // console.log("and cart id now");
                      if (this.previousOrderMode) {
                        this.subscriptions.push(
                          this.store
                            .select("previousOrders")
                            .pipe(distinctUntilChanged())
                            .subscribe((state) => {
                              if (
                                state &&
                                state.previousOrderCart &&
                                !_.isEqual(
                                  this.cartProducts,
                                  state.previousOrderCart
                                )
                              ) {
                                this.cartProducts = _.cloneDeep(
                                  state.previousOrderCart
                                );
                                console.log(
                                  "cart products",
                                  this.cartProducts,
                                  this.cart_id
                                );

                                let cartIndex = _.findIndex(this.cartProducts, {
                                  cart_id: this.cart_id,
                                });

                                let product_id =
                                  this.cartProducts[cartIndex].product_id;
                                this.fetchProductWithCartId(
                                  this.order
                                    ? this.order.store_id
                                    : this.store_id,
                                  product_id,
                                  this.languagePath,
                                  cartIndex,
                                  null
                                );
                              }
                            })
                        );
                      } else {
                        this.subscriptions.push(
                          this.store
                            .select("cart")
                            .pipe(distinctUntilChanged())
                            .subscribe((state) => {
                              if (
                                state &&
                                state.products &&
                                !_.isEqual(this.cartProducts, state.products)
                              ) {
                                this.cartProducts = _.cloneDeep(state.products);
                                console.log(
                                  "cart products",
                                  this.cartProducts,
                                  this.cart_id
                                );

                                let cartIndex = _.findIndex(this.cartProducts, {
                                  cart_id: this.cart_id,
                                });

                                let product_id =
                                  this.cartProducts[cartIndex].product_id;

                                this.store
                                  .select("auth")
                                  .pipe(first())
                                  .subscribe((authState) => {
                                    if (
                                      authState &&
                                      authState.loginState &&
                                      (authState.loginState ===
                                        "login_completed" ||
                                        authState.loginState ===
                                          "login_loading")
                                    ) {
                                      this.subscribeToCatalogUserPricelists();
                                    } else {
                                      this.fetchProductWithCartId(
                                        this.order
                                          ? this.order.store_id
                                          : this.store_id,
                                        product_id,
                                        this.languagePath,
                                        cartIndex,
                                        null
                                      );
                                      this.subscribeToCatalogUserPricelists();
                                    }
                                  });
                              }
                            })
                        );
                      }
                    }
                  }

                  if (state && !_.isEqual(state.dineIn, this.dineIn)) {
                    this.dineIn = state.dineIn;
                  }
                })
            );
          }
        })
    );
    this.subscribeToOptionValidationAnimatingButton();
  }

  favoriteProductSubscription() {
    console.log("favorite Product", this.selectedProductIsFavorite);
    this.productDetailSubscriptions.push(
      this.store
        .select("favoriteProducts")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.favoriteProducts &&
            !_.isEqual(state.favoriteProducts, this.favoriteProducts)
          ) {
            this.favoriteProducts = _.cloneDeep(state.favoriteProducts);
            this.selectedProductIsFavorite = !!_.find(this.favoriteProducts, {
              product_id:
                this.selectedProduct && this.selectedProduct.product_id
                  ? this.selectedProduct.product_id
                  : null,
            });
          }
        })
    );
  }

  fetchProduct(store_id, product_id, languagePath, forCustomerPricelists) {
    console.log("fetchProduct");
    if (store_id && product_id && languagePath) {
      this.dataStorageService
        .getProductDetails(store_id, product_id, languagePath)
        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              console.log(res);
              let prd_details = _.cloneDeep(res.productDetails);
              const storeInfo: any = await this.getStoreInfo();
              const userStoreNopricesMode: any =
                await this.productDetailsOperationsService.checkUserCatalogZeroPricesMode();
              if (
                (storeInfo && storeInfo.catalogZeroPricesMode) ||
                userStoreNopricesMode
              ) {
                prd_details =
                  await this.productDetailsOperationsService.deleteAllProductPrices(
                    _.cloneDeep(prd_details)
                  );
              }

              prd_details = await this.checkForCustomerPricelist(
                _.cloneDeep(prd_details)
              );

              prd_details = await this.checkUpdateStockValues(
                _.cloneDeep(prd_details)
              );

              prd_details =
                this.productDetailsOperationsService.calculateProductQuantityWithStep(
                  _.cloneDeep(prd_details)
                );

              prd_details =
                await this.productDetailsOperationsService.checkProductCategoriesAvailability(
                  _.cloneDeep(prd_details)
                );

              this.selectedProduct = _.cloneDeep(prd_details);

              this.selectedProduct =
                await this.checkExclusiveCustomerPromotionsProduct(
                  _.cloneDeep(this.selectedProduct)
                );

              this.store
                .select(selectors.getStoreInfo)
                .pipe(first())
                .subscribe((store_info) => {
                  this.store
                    .select("deliveryMethodAddress")
                    .pipe(distinctUntilChanged())
                    .subscribe(async (DeliveryState) => {
                      if (this.bundleMode) {
                        this.selectedProduct =
                          this.productDetailsOperationsService.calculateBundleProductIgnoreOptionPrices(
                            _.cloneDeep(this.parentProduct),
                            _.cloneDeep(this.parentBundleChoice),
                            _.cloneDeep(this.selectedProduct)
                          );

                        this.selectedProduct =
                          this.productDetailsOperationsService.calculateBundleProductForcedRequiredOptionsPreselectedChoices(
                            _.cloneDeep(this.parentBundleChoice),
                            _.cloneDeep(this.selectedProduct)
                          );
                      }
                      this.selectedProduct =
                        this.productDetailsOperationsService.calculateBundleProductOptionsExceptions(
                          _.cloneDeep(this.selectedProduct),
                          _.cloneDeep(this.parentBundleChoice)
                        );

                      this.selectedProduct =
                        await this.productDetailsOperationsService.calculateBundleChoiceProducts(
                          _.cloneDeep(this.selectedProduct),
                          _.cloneDeep(this.parentBundleChoice)
                        );

                      this.selectedProduct =
                        this.bundleProductPreselectRequiredChoices(
                          _.cloneDeep(this.selectedProduct)
                        );

                      this.selectedProduct =
                        this.productDetailsOperationsService.checkIgnoreImage(
                          _.cloneDeep(this.selectedProduct),
                          _.cloneDeep(this.parentBundleChoice)
                        );

                      if (this.bundleMode && this.bundleMode === "edit") {
                        if (this.edited_bundle_product.options) {
                          await this.initializeOptions(
                            this.edited_bundle_product.options,
                            forCustomerPricelists,
                            DeliveryState,
                            store_info
                          );
                        }

                        this.selectedProduct.quantity = _.cloneDeep(
                          this.edited_bundle_product.quantity
                        );
                      }

                      this.selectedProduct.selectedProductId = _.cloneDeep(
                        this.selectedProductId
                      );

                      this.selectedProduct =
                        this.productDetailsOperationsService.initializeProductOptionsCheckbox(
                          _.cloneDeep(this.selectedProduct)
                        );
                      this.selectedProduct =
                        this.productDetailsOperationsService.checkIsDependentOptions(
                          _.cloneDeep(this.selectedProduct)
                        );
                      this.selectedProduct =
                        this.productDetailsOperationsService.productAllOptionsPriceComments(
                          _.cloneDeep(this.selectedProduct),
                          DeliveryState.orderPickup,
                          DeliveryState.dineIn,
                          store_info && store_info.dinein
                            ? store_info.alt_dinein_active
                            : false,
                          DeliveryState.selectedDineInTable
                        );

                      this.selectedProduct =
                        await this.productDetailsOperationsService.checkProductAvailabilityForPreorder(
                          _.cloneDeep(this.selectedProduct),
                          DeliveryState.selectedPreorderDate,
                          DeliveryState.dineIn
                        );

                      this.selectedProduct =
                        this.productDetailsOperationsService.checkIfProuctHasNotValidDeliveryAreas(
                          _.cloneDeep(this.selectedProduct),
                          DeliveryState.orderPickup,
                          DeliveryState.dineIn,
                          DeliveryState.selectedAddress,
                          DeliveryState.validDeliveryAreas
                        );

                      if (forCustomerPricelists) {
                        this.store
                          .select("deliveryMethodAddress")
                          .pipe(first())
                          .subscribe(async (state) => {
                            //console.log(":call product price");
                            this.selectedProduct =
                              this.productDetailsOperationsService.cartProductPrice(
                                this.selectedProduct,
                                DeliveryState.orderPickup,
                                DeliveryState.dineIn,
                                this.parentBundleChoice && this.bundleMode
                                  ? _.cloneDeep(this.parentBundleChoice)
                                  : null,
                                true,
                                store_info && store_info.dinein
                                  ? store_info.alt_dinein_active
                                  : false,
                                DeliveryState.selectedDineInTable,
                                store_info.decimalPlacesPrices
                              );

                            this.selectedProduct =
                              await this.productDetailsOperationsService.calculateSelectedProductPoints(
                                _.cloneDeep(this.selectedProduct),
                                this.selectedProduct.productTotal,
                                true
                              );
                          })
                          .unsubscribe();
                      }

                      this.subscriptions.push(
                        this.store
                          .select("groupApp")
                          .pipe(distinctUntilChanged())
                          .subscribe((state) => {
                            if (state && !_.isEqual(this.webp, state.webp)) {
                              this.webp = _.cloneDeep(state.webp);
                              this.subscriptions.push(
                                this.store
                                  .select("platformInfo")
                                  .pipe(distinctUntilChanged())
                                  .subscribe((state) => {
                                    let browserPlatform: any;
                                    if (
                                      state &&
                                      !_.isEqual(
                                        browserPlatform,
                                        state.browserPlatform
                                      )
                                    ) {
                                      browserPlatform = _.cloneDeep(
                                        state.browserPlatform
                                      );

                                      if (
                                        this.selectedProduct &&
                                        this.selectedProduct.image &&
                                        this.selectedProduct.image[
                                          browserPlatform
                                        ] &&
                                        this.selectedProduct.image[
                                          browserPlatform
                                        ]["16x9"] &&
                                        this.selectedProduct.image[
                                          browserPlatform
                                        ]["16x9"].path
                                      ) {
                                        if (!this.image) {
                                          this.image = {};
                                        }
                                        this.image.path =
                                          "https://images.deliverymanager.gr/" +
                                          this.selectedProduct.image[
                                            browserPlatform
                                          ]["16x9"].path +
                                          (this.webp
                                            ? ".webp"
                                            : this.selectedProduct.image[
                                                browserPlatform
                                              ]["16x9"].type || ".jpg");
                                        this.checkProductDetailsBigDesign();
                                      } else {
                                        this.checkProductDetailsBigDesign();
                                        if (!this.image) {
                                          this.image = {};
                                        }
                                        this.image.path = null;
                                      }
                                    }
                                  })
                              );
                            }
                          })
                      );
                      console.log("set selectedProduct");
                      const openPreorderProductAvailabilityAlert: any =
                        await this.productDetailsOperationsService.checkShowProductAvailabilityForPreorderAlert(
                          _.cloneDeep(this.selectedProduct),
                          _.cloneDeep(DeliveryState.selectedPreorderDate),
                          DeliveryState.dineIn
                        );

                      console.log(
                        "openPreorderProductAvailabilityAlert",
                        openPreorderProductAvailabilityAlert
                      );

                      const selectedPrd = _.cloneDeep(this.selectedProduct);
                      this.selectedProduct = null;

                      _.each(selectedPrd.options, (option) => {
                        if (option.bundle) {
                          this.productHasBundleOptions = true;
                        }
                      });

                      this.checkAtLeastOneFrozenTag(_.cloneDeep(selectedPrd));

                      this.store.dispatch(
                        new SelectedProductActions.SetSelectedProduct(
                          _.cloneDeep(selectedPrd)
                        )
                      );

                      if (
                        openPreorderProductAvailabilityAlert &&
                        openPreorderProductAvailabilityAlert.show &&
                        openPreorderProductAvailabilityAlert.message
                      ) {
                        this.showPreorderProductAvailabilityAlert(
                          openPreorderProductAvailabilityAlert.message
                        );
                      }

                      this.unsubscribeAfterRequest();
                      this.productDetailsSubscriptions();
                    })
                    .unsubscribe();
                })
                .unsubscribe();
            } else {
              if (res && res.comment_id) {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message:
                    res && res.comment_id
                      ? this.translateService.instant(res.comment_id)
                      : this.translateService.instant(
                          "errorMessages.general_error"
                        ),

                  buttons: ["OK"],
                });
                await alert.present();
              } else if (res && res.comments) {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(res.comments),

                  buttons: ["OK"],
                });
                await alert.present();
              }
            }
          },
          error: async (err) => {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    } else {
      console.log("something missing");
    }
  }

  checkForAddBundleProductInstant() {
    console.log("selectedProduasiduaisd", this.selectedProduct);
    if (this.add_bundle_product_to_cart) {
      setTimeout(() => {
        this.optionsValidationForAddingToCart();
        setTimeout(() => {
          //for safety if something went wrong when trying to add product to cart
          if (this.selectedProduct && this.parentBundleOption) {
            this.store.dispatch(
              new ProductOptionsValidationActions.RemoveBundleOptionLoading(
                _.cloneDeep(this.parentBundleOption.product_id_option_id)
              )
            );
          }
        }, 10000);
      }, 500);
    }
  }

  fetchProductWithCartId(
    store_id,
    product_id,
    languagePath,
    cartIndex,
    forCustomerPricelists
  ) {
    console.log("fetchProductsWithCartId");
    this.dataStorageService
      .getProductDetails(store_id, product_id, languagePath)
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            let productDetails = _.cloneDeep(res.productDetails);
            const storeInfo: any = await this.getStoreInfo();

            if (storeInfo.catalogZeroPricesMode) {
              productDetails =
                await this.productDetailsOperationsService.deleteAllProductPrices(
                  _.cloneDeep(productDetails)
                );
            }

            productDetails = await this.checkForCustomerPricelist(
              _.cloneDeep(productDetails)
            );

            productDetails = await this.checkUpdateStockValues(
              _.cloneDeep(productDetails)
            );

            productDetails =
              await this.productDetailsOperationsService.checkProductCategoriesAvailability(
                _.cloneDeep(productDetails)
              );

            productDetails = await this.checkExclusiveCustomerPromotionsProduct(
              _.cloneDeep(productDetails)
            );

            this.store
              .select(selectors.getStoreInfo)
              .pipe(first())
              .subscribe((store_info) => {
                this.store
                  .select("deliveryMethodAddress")
                  .pipe(first())
                  .subscribe(async (DeliveryState) => {
                    console.log("selected Product", this.selectedProduct);
                    this.selectedProduct = Object.assign(
                      _.cloneDeep(productDetails),
                      {
                        cart_id: this.cart_id,
                      }
                    );
                    // if (this.bundleMode) {
                    //   this.selectedProduct =
                    //     this.productDetailsOperationsService.calculateBundleProductIgnoreOptionPrices(
                    //       _.cloneDeep(this.parentProduct),
                    //       _.cloneDeep(this.selectedProduct)
                    //     );
                    // }

                    this.selectedProduct =
                      await this.productDetailsOperationsService.checkProductAvailabilityForPreorder(
                        _.cloneDeep(this.selectedProduct),
                        DeliveryState.selectedPreorderDate,
                        DeliveryState.dineIn
                      );

                    this.selectedProduct =
                      this.productDetailsOperationsService.calculateBundleProductOptionsExceptions(
                        _.cloneDeep(this.selectedProduct),
                        _.cloneDeep(this.parentBundleChoice)
                      );

                    this.selectedProduct =
                      await this.productDetailsOperationsService.calculateBundleChoiceProducts(
                        _.cloneDeep(this.selectedProduct),
                        _.cloneDeep(this.parentBundleChoice)
                      );

                    this.selectedProduct.quantity =
                      this.cartProducts[cartIndex].quantity;
                    this.selectedProduct.gift =
                      this.cartProducts[cartIndex].gift;
                    this.selectedProduct =
                      this.productDetailsOperationsService.initializeProductOptionsCheckbox(
                        _.cloneDeep(this.selectedProduct)
                      );

                    this.selectedProduct =
                      this.productDetailsOperationsService.checkIfProuctHasNotValidDeliveryAreas(
                        _.cloneDeep(this.selectedProduct),
                        DeliveryState.orderPickup,
                        DeliveryState.dineIn,
                        DeliveryState.selectedAddress,
                        DeliveryState.validDeliveryAreas
                      );

                    this.selectedProduct.selectedProductId = _.cloneDeep(
                      this.selectedProductId
                    );

                    // The following script only runs in here, because this is the only way that the product has options
                    if (this.cartProducts[cartIndex].options) {
                      await this.initializeOptions(
                        this.cartProducts[cartIndex].options,
                        forCustomerPricelists,
                        DeliveryState,
                        store_info
                      );
                    }

                    cartIndex = null;
                    this.selectedProduct =
                      this.productDetailsOperationsService.checkIsDependentOptions(
                        _.cloneDeep(this.selectedProduct)
                      );
                    this.selectedProduct =
                      this.productDetailsOperationsService.productAllOptionsPriceComments(
                        _.cloneDeep(this.selectedProduct),
                        DeliveryState.orderPickup,
                        DeliveryState.dineIn,
                        store_info && store_info.dinein
                          ? store_info.alt_dinein_active
                          : false,
                        DeliveryState.selectedDineInTable
                      );

                    this.selectedProductLoaded = true;

                    this.subscriptions.push(
                      this.store
                        .select("groupApp")
                        .pipe(distinctUntilChanged())
                        .subscribe((state) => {
                          if (state && !_.isEqual(this.webp, state.webp)) {
                            this.webp = _.cloneDeep(state.webp);
                            this.subscriptions.push(
                              this.store
                                .select("platformInfo")
                                .pipe(distinctUntilChanged())
                                .subscribe((state) => {
                                  let browserPlatform: any;
                                  if (
                                    state &&
                                    !_.isEqual(
                                      browserPlatform,
                                      state.browserPlatform
                                    )
                                  ) {
                                    browserPlatform = _.cloneDeep(
                                      state.browserPlatform
                                    );
                                    if (
                                      this.selectedProduct &&
                                      this.selectedProduct.image &&
                                      this.selectedProduct.image[
                                        browserPlatform
                                      ] &&
                                      this.selectedProduct.image[
                                        browserPlatform
                                      ]["16x9"] &&
                                      this.selectedProduct.image[
                                        browserPlatform
                                      ]["16x9"].path
                                    ) {
                                      if (!this.image) {
                                        this.image = {};
                                      }
                                      this.image.path =
                                        "https://images.deliverymanager.gr/" +
                                        this.selectedProduct.image[
                                          browserPlatform
                                        ]["16x9"].path +
                                        (this.webp
                                          ? ".webp"
                                          : this.selectedProduct.image[
                                              browserPlatform
                                            ]["16x9"].type || ".jpg");
                                      this.checkProductDetailsBigDesign();
                                    } else {
                                      this.image = {};
                                      this.image.path = null;
                                      this.checkProductDetailsBigDesign();
                                    }
                                  }
                                })
                            );
                          }
                        })
                    );

                    const openPreorderProductAvailabilityAlert: any =
                      await this.productDetailsOperationsService.checkShowProductAvailabilityForPreorderAlert(
                        _.cloneDeep(this.selectedProduct),
                        _.cloneDeep(DeliveryState.selectedPreorderDate),
                        DeliveryState.dineIn
                      );

                    console.log(
                      "openPreorderProductAvailabilityAlert",
                      openPreorderProductAvailabilityAlert
                    );

                    if (
                      openPreorderProductAvailabilityAlert &&
                      openPreorderProductAvailabilityAlert.show &&
                      openPreorderProductAvailabilityAlert.message
                    ) {
                      this.showPreorderProductAvailabilityAlert(
                        openPreorderProductAvailabilityAlert.message
                      );
                    }

                    const selectedPrd = _.cloneDeep(this.selectedProduct);
                    this.selectedProduct = null;

                    _.each(selectedPrd.options, (option) => {
                      if (option.bundle) {
                        this.productHasBundleOptions = true;
                      }
                    });

                    this.checkAtLeastOneFrozenTag(_.cloneDeep(selectedPrd));

                    this.store.dispatch(
                      new SelectedProductActions.SetSelectedProduct(
                        _.cloneDeep(selectedPrd)
                      )
                    );

                    this.unsubscribeAfterRequest();
                    this.productDetailsSubscriptions();
                  });
              })
              .unsubscribe();
          } else {
            if (res && res.comment_id) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),

                buttons: ["OK"],
              });
              await alert.present();
            } else if (res && res.comments) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(res.comments),

                buttons: ["OK"],
              });
              await alert.present();
            } else {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                ),

                buttons: ["OK"],
              });
              await alert.present();
            }
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  presentPreorderModal() {
    this.preorderValidationService.presentPreorderModalFromProductDetails();
  }

  async showPreorderProductAvailabilityAlert(message) {
    this.preorderProductAvailabilityAlert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant(message),

      buttons: [
        {
          text: this.translateService.instant("no"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            this.presentPreorderModal();
          },
        },
      ],
    });
    await this.preorderProductAvailabilityAlert.present();
    await this.preorderProductAvailabilityAlert.onDidDismiss().then(() => {
      this.preorderProductAvailabilityAlert = null;
    });
  }

  getDeliveryMethodAddress() {
    return new Promise((resolve) => {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe((state) => {
          resolve(_.cloneDeep(state));
        });
    });
  }

  async initializeOptions(
    cartOptions,
    forCustomerPricelists,
    DeliveryState,
    store_info
  ) {
    console.log(
      "cartOptions",
      "selectedProductOptions",
      cartOptions,
      this.selectedProduct.options,
      this.selectedProduct.name
    );
    if (!_.isEmpty(cartOptions)) {
      await bluebird.mapSeries(_.cloneDeep(cartOptions), async (option) => {
        if (option.type === "field") {
          var optionIndex = _.findIndex(this.selectedProduct.options, {
            option_id: option.option_id,
          });
          if (optionIndex !== -1) {
            this.selectedProduct.options[optionIndex].text = option.text;
          }
        } else if (
          (option.type === "checkbox" || option.type === "radio") &&
          _.findIndex(this.selectedProduct.options, {
            option_id: option.option_id,
          }) !== -1
        ) {
          var optionIndex = _.findIndex(this.selectedProduct.options, {
            option_id: option.option_id,
          });
          console.log(
            "this.selectedProduct.options",
            optionIndex,
            this.selectedProduct.options,
            this.selectedProduct.options[optionIndex]
          );

          this.selectedProduct.options[optionIndex].option_parent_total_price =
            option.option_parent_total_price;
          this.selectedProduct.options[optionIndex].parent_free =
            option.parent_free;

          _.each(
            this.selectedProduct.options[optionIndex].choices,
            (choice) => {
              choice.is_preselected = false;
            }
          );
          _.each(option.choices, (choice) => {
            if (choice.is_preselected) {
              // The filter for the active and available had already been placed in the calculateFinalPrice lambda
              if (
                option.bundle &&
                choice.bundle &&
                choice.bundled_with_product_id &&
                choice.bundled_product
              ) {
                var choiceIndex;
                if (
                  option.bundle_options &&
                  option.bundle_options.load_category_choice_products
                ) {
                  choiceIndex = _.findIndex(
                    this.selectedProduct.options[optionIndex].choices,
                    { bundled_with_product_id: choice.bundled_with_product_id }
                  );
                } else {
                  choiceIndex = _.findIndex(
                    this.selectedProduct.options[optionIndex].choices,
                    { choice_id: choice.choice_id }
                  );
                }
                console.log(
                  "preselcted choices of selected product from cart",
                  choiceIndex,
                  this.selectedProduct.options[optionIndex].choices[choiceIndex]
                );
                if (choiceIndex !== -1) {
                  this.selectedProduct.options[optionIndex].choices[
                    choiceIndex
                  ].bundled_product = choice.bundled_product;
                }
              }
              this.clickingOnChoice(option, choice);
              console.log("step", choice.quantityInChoicesStep);
              if (
                choice.quantity > 1 &&
                (!choice.quantityInChoicesStep ||
                  choice.quantityInChoicesStep === 1)
              ) {
                _.each(new Array(choice.quantity - 1), (key) => {
                  this.choiceQuantityIncrease(option, choice);
                });
              } else if (
                choice.quantity > choice.quantityInChoicesStep &&
                choice.quantityInChoicesStep &&
                choice.quantityInChoicesStep !== 1
              ) {
                var clicksToMake =
                  (choice.quantity - choice.quantityInChoicesStep) /
                  choice.quantityInChoicesStep;

                console.log("clicksToMake", clicksToMake);
                _.each(new Array(Math.round(clicksToMake)), (key) => {
                  this.choiceQuantityIncrease(option, choice);
                });
              } else if (
                choice.quantity < choice.quantityInChoicesStep &&
                choice.quantityInChoicesStep &&
                choice.quantityInChoicesStep !== 1
              ) {
                var clicksToMake =
                  (1 - choice.quantity) / choice.quantityInChoicesStep;
                console.log(
                  "clicksToMake",
                  clicksToMake,
                  choice.quantity,
                  choice.quantityInChoicesStep
                );
                _.each(new Array(Math.round(clicksToMake)), (key) => {
                  this.choiceQuantityDecrease(option, choice);
                });
              }
            }
          });
          this.assignFreeChoices(
            option.option_id,
            DeliveryState.orderPickup,
            DeliveryState.dineIn
          );
          this.calculateTotalChoiceQuantity(option.option_id);

          if (forCustomerPricelists) {
            console.log(":call product price");

            const deliveryMethodAddressState: any =
              await this.getDeliveryMethodAddress();

            const orderPickup = deliveryMethodAddressState.orderPickup;
            const dineIn = deliveryMethodAddressState.dineIn;

            this.selectedProduct =
              this.productDetailsOperationsService.cartProductPrice(
                this.selectedProduct,
                orderPickup,
                dineIn,
                this.parentBundleChoice && this.bundleMode
                  ? _.cloneDeep(this.parentBundleChoice)
                  : null,
                true,
                store_info && store_info.dinein
                  ? store_info.alt_dinein_active
                  : false,
                DeliveryState.selectedDineInTable,
                store_info.decimalPlacesPrices
              );

            this.selectedProduct =
              await this.productDetailsOperationsService.calculateSelectedProductPoints(
                _.cloneDeep(this.selectedProduct),
                this.selectedProduct.productTotal,
                true
              );
          }
          this.productDetailsOperationsService.productChoicesPriceComment(
            option.option_id,
            DeliveryState.orderPickup,
            DeliveryState.dineIn,
            this.selectedProduct,
            store_info && store_info.dinein
              ? store_info.alt_dinein_active
              : false,
            DeliveryState.selectedDineInTable
          );
        }

        return Promise.resolve({});
      });
      console.log(
        "product options after initialization",
        this.selectedProduct.options
      );
    }
  }

  unsubscribeAfterRequest() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  optionStepperSubscription() {
    console.log("option stepper subscription");
    this.productDetailSubscriptions.push(
      this.store
        .select("optionStepper")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(20))
        .subscribe((state) => {
          if (
            state &&
            state.selectedProductOptionStepsBySelectedProductId &&
            state.selectedProductOptionStepsBySelectedProductId[
              this.selectedProductId
            ] &&
            state.selectedProductOptionStepsBySelectedProductId[
              this.selectedProductId
            ].optionsSteps &&
            !_.isEqual(
              this.allOptionSteps,
              state.selectedProductOptionStepsBySelectedProductId[
                this.selectedProductId
              ].optionsSteps
            )
          ) {
            this.allOptionSteps = _.cloneDeep(
              state.selectedProductOptionStepsBySelectedProductId[
                this.selectedProductId
              ].optionsSteps
            );
          }
          if (
            state &&
            state.selectedProductOptionStepsBySelectedProductId &&
            state.selectedProductOptionStepsBySelectedProductId[
              this.selectedProductId
            ] &&
            state.selectedProductOptionStepsBySelectedProductId[
              this.selectedProductId
            ].selectedStepOptionId &&
            this.allOptionSteps &&
            !_.isEqual(
              this.currentOptionStep,
              state.selectedProductOptionStepsBySelectedProductId[
                this.selectedProductId
              ].selectedStepOptionId
            )
          ) {
            this.currentOptionStep = _.cloneDeep(
              state.selectedProductOptionStepsBySelectedProductId[
                this.selectedProductId
              ].selectedStepOptionId
            );
            let currentIndex = _.indexOf(
              this.allOptionSteps,
              this.currentOptionStep
            );
            if (currentIndex === this.allOptionSteps.length - 1) {
              this.showAddToCartButtonForStepper = true;
            } else {
              this.showAddToCartButtonForStepper = false;
            }
            if (
              this.currentOptionStep &&
              this.allOptionSteps &&
              this.allOptionSteps.length
            ) {
              this.stepperProgress = 0;
              let step;
              if (this.allOptionSteps.length > 0) {
                step = 1 / this.allOptionSteps.length;
              } else {
                step = 1;
              }
              console.log("step", step);
              _.each(this.allOptionSteps, (option_id) => {
                this.stepperProgress = this.stepperProgress + step;
                if (option_id === this.currentOptionStep) {
                  return false;
                }
              });

              console.log("currentOptionsSteps", this.allOptionSteps);
            }
          }
        })
    );
  }

  productDetailsSubscriptions() {
    // console.log("product details subs");
    this.productDetailSubscriptions.push(
      this.store
        .select(
          selectors.getSelectedProductBySelectedProductId(
            this.selectedProductId
          )
        )
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((selectedProduct) => {
          if (
            selectedProduct &&
            !_.isEqual(this.selectedProduct, selectedProduct)
          ) {
            //console.log("productDetailsChanged", selectedProduct);
            this.selectedProduct = _.cloneDeep(selectedProduct);

            this.calculatePlasticWrapperTaxPrice(this.selectedProduct);
            this.checkForAddBundleProductInstant();
            if (this.selectedProduct) {
              this.selectedProductLoaded = true;
            }

            if (
              (this.platform && this.platform.is("android")) ||
              this.platform.is("ios")
            ) {
              this.checkAndShowSwipeAnimation();
            }

            if (this.selectedProduct && this.selectedProduct.option_stepper) {
              this.optionStepperSubscription();
            }
            this.favoriteProductSubscription();
            //this.selectedProduct.option_stepper = true;
          }
          // if (
          //   state &&
          //   state.selectedProductTotalPrice &&
          //   !_.isEqual(
          //     state.selectedProductTotalPrice,
          //     this.selectedProductTotalPrice
          //   )
          // ) {
          //   this.selectedProductTotalPrice = _.cloneDeep(
          //     state.selectedProductTotalPrice
          //   );
          // }
        })
    );

    this.productDetailSubscriptions.push(
      this.store
        .select(
          selectors.getSelectedProductTotalPriceBySelectedProductId(
            this.selectedProductId
          )
        )
        .pipe(distinctUntilChanged())
        .subscribe(async (state) => {
          if (
            state &&
            state.productTotalPrice &&
            !_.isEqual(this.selectedProductTotalPrice, state.productTotalPrice)
          ) {
            this.selectedProductTotalPrice = _.cloneDeep(
              state.productTotalPrice
            );
            if (this.selectedProduct) {
              this.selectedProduct =
                await this.productDetailsOperationsService.calculateSelectedProductPoints(
                  _.cloneDeep(this.selectedProduct),
                  this.selectedProductTotalPrice,
                  false
                );
            }
          }
        })
    );

    this.productDetailSubscriptions.push(
      this.store
        .select(
          selectors.getSeletedProductPointsToEarnBySelectedProductId(
            this.selectedProductId
          )
        )
        .pipe(distinctUntilChanged())
        .subscribe(async (state) => {
          if (
            state &&
            state.productPointsToEarn &&
            !_.isEqual(this.productPointsToEarn, state.productPointsToEarn)
          ) {
            this.productPointsToEarn = _.cloneDeep(state.productPointsToEarn);
          }
        })
    );

    this.productDetailSubscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
          }
        })
    );

    this.productDetailSubscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    this.productDetailSubscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.selectedDineInTable, this.selectedDineInTable)
          ) {
            this.selectedDineInTable = state.selectedDineInTable;
          }

          if (
            state &&
            !_.isEqual(state.selectedPreorderDate, this.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = state.selectedPreorderDate;
          }

          if (
            state &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = state.selectedAddress;
          }

          if (state && !_.isEqual(state.orderPickup, this.orderPickupUpdated)) {
            this.orderPickupUpdated = _.cloneDeep(state.orderPickup);
            this.checkDisableForDeliveryMethodAddress(
              state.orderPickup,
              state.dineIn
            );
          }

          if (state && !_.isEqual(state.dineIn, this.dineInUpdated)) {
            this.dineInUpdated = _.cloneDeep(state.dineIn);
            this.checkDisableForDeliveryMethodAddress(
              state.orderPickup,
              state.dineIn
            );
          }
        })
    );

    this.productDetailSubscriptions.push(
      this.store
        .select("selectedProduct")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(300))
        .subscribe((state) => {
          if (
            state &&
            state.recalculateProductPreorderAvailabilityTimestamp &&
            !_.isEqual(
              this.recalculateProductPreorderAvailabilityTimestamp,
              state.recalculateProductPreorderAvailabilityTimestamp
            )
          ) {
            this.recalculateProductPreorderAvailabilityTimestamp = _.cloneDeep(
              state.recalculateProductPreorderAvailabilityTimestamp
            );
            if (
              this.recalculateProductPreorderAvailabilityTimestamp &&
              this.selectedProduct
            ) {
              this.store
                .select("deliveryMethodAddress")
                .pipe(first())
                .subscribe(async (DeliveryState) => {
                  const newProduct =
                    await this.productDetailsOperationsService.checkProductAvailabilityForPreorder(
                      _.cloneDeep(this.selectedProduct),
                      DeliveryState.selectedPreorderDate,
                      DeliveryState.dineIn
                    );

                  if (
                    newProduct &&
                    !_.isEqual(newProduct, this.selectedProduct)
                  ) {
                    this.store.dispatch(
                      new SelectedProductActions.SetSelectedProduct(
                        _.cloneDeep(newProduct)
                      )
                    );
                  }
                })
                .unsubscribe();
            }
          }
        })
    );

    this.productDetailSubscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);
            const catalogCustomTagsById = {};
            _.each(_.cloneDeep(this.store_info.catalogCustomTags), (tag) => {
              catalogCustomTagsById[tag.id] = tag;
            });

            this.groupStoreData.store_info = {
              store_id: store_info.store_id,
              overridePromoItemsForPointsSystem: _.cloneDeep(
                this.store_info.overridePromoItemsForPointsSystem
              ),
              alt_dinein_active: this.store_info.dinein
                ? _.cloneDeep(this.store_info.alt_dinein_active)
                : null,
              catalogCustomTagsById: catalogCustomTagsById,

              design_settings: store_info.design_settings,
            };
            if (
              this.store_info &&
              this.store_info.vatBasedCustomerProductsActive &&
              !this.migrationCustomerIdSub
            ) {
              this.migrationCustomerIdSub = this.store
                .select("auth")
                .pipe(distinctUntilChanged())
                .subscribe((authState) => {
                  if (
                    authState &&
                    !_.isEqual(
                      this.migration_customer_id,
                      authState.migration_customer_id
                    )
                  ) {
                    this.migration_customer_id = _.cloneDeep(
                      authState.migration_customer_id
                    );
                  }
                });
            }
          }
        })
    );

    this.productDetailSubscriptions.push(
      this.store
        .select("productOptionsValidation")
        .pipe(auditTime(100))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.bundleOptionLoadingArray &&
            !_.isEqual(
              this.bundleOptionLoadingArray,
              state.bundleOptionLoadingArray
            )
          ) {
            this.bundleOptionLoadingArray = _.cloneDeep(
              state.bundleOptionLoadingArray
            );

            if (
              this.bundleOptionLoadingArray &&
              this.bundleOptionLoadingArray.length &&
              !this.bundleMode
            ) {
              this.showLoading = true;
            } else {
              this.showLoading = false;
            }
            this.changeDetector.detectChanges();
          }
        })
    );
  }
  async productQuantityDecrease() {
    let tempProduct = _.cloneDeep(this.selectedProduct);
    if (
      this.selectedProduct &&
      this.selectedProduct.quantity > 0 &&
      this.selectedProduct.enable_quantity_step &&
      this.selectedProduct.quantity_step
    ) {
      if (tempProduct.min_quantity) {
        if (
          parseFloat(
            parseFloat(
              _.cloneDeep(
                tempProduct.quantity - tempProduct.quantity_step
              ).toString()
            ).toFixed(2)
          ) >= tempProduct.min_quantity
        ) {
          tempProduct.quantity = parseFloat(
            parseFloat(
              _.cloneDeep(
                tempProduct.quantity - tempProduct.quantity_step
              ).toString()
            ).toFixed(2)
          );

          this.store.dispatch(
            new SelectedProductActions.SetSelectedProduct(
              _.cloneDeep(tempProduct)
            )
          );
        }
      } else {
        if (tempProduct.quantity - tempProduct.quantity_step > 0) {
          tempProduct.quantity = parseFloat(
            parseFloat(
              _.cloneDeep(
                tempProduct.quantity - tempProduct.quantity_step
              ).toString()
            ).toFixed(2)
          );

          this.store.dispatch(
            new SelectedProductActions.SetSelectedProduct(
              _.cloneDeep(tempProduct)
            )
          );
        }
      }

      //console.log(tempProduct.quantity);
      //console.log("set selectedProduct");
    } else if (
      this.selectedProduct &&
      this.selectedProduct.quantity > 1 &&
      (!this.selectedProduct.enable_quantity_step ||
        !this.selectedProduct.quantity_step)
    ) {
      tempProduct.quantity--;

      this.store.dispatch(
        new SelectedProductActions.SetSelectedProduct(_.cloneDeep(tempProduct))
      );
    } else if (
      this.selectedProduct &&
      this.selectedProduct.quantity === 1 &&
      (!this.selectedProduct.enable_quantity_step ||
        !this.selectedProduct.quantity_step) &&
      this.cart_id
    ) {
      const alert = await this.alertController.create({
        header: this.translateService.instant("alert"),
        message: this.translateService.instant(
          "product-details.do-you-want-to-remove-product-from-cart"
        ),

        buttons: [
          {
            text: this.translateService.instant("no"),
            role: "cancel",
            handler: () => {
              console.log("canceled");
            },
          },
          {
            text: this.translateService.instant("yes"),
            handler: () => {
              const product_index_cart = _.findIndex(this.cartProducts, {
                product_id: this.selectedProduct.product_id,
                cart_id: this.cart_id,
              });

              if (product_index_cart !== -1) {
                this.store
                  .select("cart")
                  .pipe(first())
                  .subscribe((cartState) => {
                    if (
                      cartState &&
                      cartState.products &&
                      cartState.products.length
                    ) {
                      let cart = _.cloneDeep(cartState.products);
                      cart.splice(product_index_cart, 1);
                      //console.log("newnew cart", cart);
                      this.store.dispatch(
                        new CartActions.SetCartProducts(_.cloneDeep(cart))
                      );
                      this.modalCtrl.dismiss();
                    }
                  })
                  .unsubscribe();
              }
            },
          },
        ],
      });
      await alert.present();
    }
  }

  productQuantityIncrease() {
    if (this.selectedProduct && this.selectedProduct.quantity < 50) {
      let tempProduct = _.cloneDeep(this.selectedProduct);
      if (
        this.selectedProduct.enable_quantity_step &&
        this.selectedProduct.quantity_step &&
        !this.selectedProduct.max_quantity &&
        parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        ) < 50
      ) {
        tempProduct.quantity = parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        );
      } else if (
        this.selectedProduct.enable_quantity_step &&
        this.selectedProduct.quantity_step &&
        this.selectedProduct.max_quantity &&
        parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        ) < this.selectedProduct.max_quantity
      ) {
        tempProduct.quantity = parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        );
      } else if (
        !this.selectedProduct.enable_quantity_step ||
        !this.selectedProduct.quantity_step
      ) {
        tempProduct.quantity++;
      }
      //console.log(tempProduct.quantity);
      //console.log("set selectedProduct");
      this.store.dispatch(
        new SelectedProductActions.SetSelectedProduct(_.cloneDeep(tempProduct))
      );
    }
  }

  startValidationWritingState() {
    this.store.dispatch(
      new ProductOptionsValidationActions.ClearProductOptionsValidation(
        this.selectedProductId
      )
    );
    this.store.dispatch(
      new ProductOptionsValidationActions.SetProductOptionValidation({
        optionValidations: "",
        selectedProductId: this.selectedProductId,
        lastValidTimestamp: Date.now(),
      })
    );
  }

  optionsValidationForAddingToCart() {
    console.log("start validation for adding to carts");
    // if (this.selectedProduct && this.selectedProduct.option_stepper) {
    //   this.showAddToCartButtonForStepper = false;
    // }
    console.log(
      "must select somethin attr",
      this.selectedProduct.must_select_something
    );
    console.log(
      "must select somethin func",
      this.check_for_must_select_something()
    );
    if (
      this.selectedProduct &&
      this.selectedProduct.must_select_something &&
      !this.check_for_must_select_something()
    ) {
      this.presentErrorToast(
        this.translateService.instant(
          "product-details.you-have-to-fill-the-choice"
        )
      );

      if (this.add_bundle_product_to_cart && this.bundleMode) {
        this.modalCtrl.dismiss("option_required_error");
        return false;
      }
    } else if (
      this.selectedProduct &&
      this.selectedProduct.maxQuantityInCart &&
      !this.validatedMaxQuantityInCart()
    ) {
      this.presentErrorToast(
        this.translateService.instant(
          "product-details.you-have-already-the-max-quantity"
        ) +
          " " +
          this.selectedProduct.maxQuantityInCart
      );

      if (this.add_bundle_product_to_cart && this.bundleMode) {
        this.modalCtrl.dismiss("option_required_error");
        return false;
      }
    } else {
      this.disableAddButton = true;

      this.startValidationWritingState();

      this.addProductToCart();
    }
  }

  validatedMaxQuantityInCart() {
    let valid = true;
    let productQuantityInCart = 0;
    if (this.cartProducts && this.cartProducts.length) {
      _.each(this.cartProducts, (cartProduct) => {
        if (cartProduct.product_id === this.selectedProduct.product_id) {
          productQuantityInCart = cartProduct.quantity + productQuantityInCart;
        }
      });
    }

    if (this.selectedProduct.cart_id) {
      const cartProduct = _.find(this.cartProducts, {
        cart_id: this.selectedProduct.cart_id,
      });

      if (!_.isEmpty(cartProduct)) {
        productQuantityInCart = productQuantityInCart - cartProduct.quantity;
      }
    }

    if (
      productQuantityInCart + this.selectedProduct.quantity >
      this.selectedProduct.maxQuantityInCart
    ) {
      valid = false;
    }

    return valid;
  }

  addProductToCart() {
    this.addButtonClicked = true;
    this.addProductToCartValidationSub = this.store
      .select("productOptionsValidation")
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(400))
      .subscribe((state) => {
        if (state && !_.isEqual(this.productOptionsValidation, state)) {
          this.productOptionsValidation = _.cloneDeep(state);

          if (
            this.productOptionsValidation &&
            this.productOptionsValidation
              .optionValidationsBySelectedProductId &&
            this.productOptionsValidation.optionValidationsBySelectedProductId[
              this.selectedProductId
            ]
          ) {
            let notvalidOption;
            let optionTOOpen;

            notvalidOption = _.find(
              this.productOptionsValidation
                .optionValidationsBySelectedProductId[this.selectedProductId],
              {
                valid: false,
                product_id: this.product_id,
              }
            );
            optionTOOpen = _.find(
              this.productOptionsValidation
                .optionValidationsBySelectedProductId[this.selectedProductId],
              {
                optionToOpen: true,
                product_id: this.product_id,
              }
            );
            console.log(
              "notValidOption before checking bundle products",
              notvalidOption
            );

            //this is for child products of bundle
            if (!notvalidOption) {
              notvalidOption = _.find(
                this.productOptionsValidation
                  .optionValidationsBySelectedProductId[this.selectedProductId],
                {
                  valid: false,
                }
              );
            }

            //this must be delete we dont want to find options to open inside child products
            // if (!optionTOOpen) {
            //   optionTOOpen = _.find(
            //     this.productOptionsValidation
            //       .optionValidationsBySelectedProductId[this.selectedProductId],
            //     {
            //       optionToOpen: true,
            //     }
            //   );
            // }

            if (!notvalidOption && !optionTOOpen && this.addButtonClicked) {
              let prd = _.cloneDeep(this.selectedProduct);
              if (!this.selectedProduct.cart_id) {
                if (!this.bundleMode) {
                  //we dont need to add a cart_id and analytics if is a bundle product of a bundle choice cause we are not gona adding to cart
                  prd.cart_id = Date.now().toString();
                  this.omit_from_product(prd);
                  this.analyticsService.logEventAddToCart();

                  this.store
                    .select("deliveryMethodAddress")
                    .pipe(first())
                    .subscribe((state) => {
                      this.analyticsService.fbqTrackAddToCart(
                        _.cloneDeep(prd),
                        state.orderPickup,
                        state.dineIn
                      );
                    })
                    .unsubscribe();

                  this.analyticsService.dmAnalyticsMessage(
                    "added_product",
                    null,
                    prd,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  );
                }
                console.log("previousOrderMode", this.previousOrderMode);
                if (this.previousOrderMode) {
                  if (
                    !this.selectedProduct.image ||
                    (this.selectedProduct.image &&
                      !this.selectedProduct.image.true) ||
                    (this.selectedProduct.image &&
                      !this.selectedProduct.image.true["16x9"]) ||
                    (this.selectedProduct.image &&
                      this.selectedProduct.image.true["16x9"] &&
                      !this.selectedProduct.image.true["16x9"].path)
                  ) {
                    prd.noImageProduct = true;
                  } else {
                    prd.noImageProduct = false;
                  }
                  this.store.dispatch(
                    new PreviousOrdersActions.AddUpdatePreviousOrderCartItem(
                      _.cloneDeep(prd)
                    )
                  );
                } else {
                  if (
                    !this.selectedProduct.image ||
                    (this.selectedProduct.image &&
                      !this.selectedProduct.image.true) ||
                    (this.selectedProduct.image &&
                      !this.selectedProduct.image.true["16x9"]) ||
                    (this.selectedProduct.image &&
                      this.selectedProduct.image.true["16x9"] &&
                      !this.selectedProduct.image.true["16x9"].path)
                  ) {
                    prd.noImageProduct = true;
                  } else {
                    prd.noImageProduct = false;
                  }

                  if (this.bundleMode) {
                    //the case that the product added to bundle choice not to cart

                    this.modalCtrl.dismiss({
                      product: prd,
                      price: this.selectedProductTotalPrice,
                    });
                    console.log("dismiss", prd);
                  } else {
                    console.log("product added to cart", prd);
                    if (
                      !this.cart_id &&
                      !this.bundleMode &&
                      _.find(prd.options, { bundle: true })
                    ) {
                      this.calculateBundleProductsCategories(prd);
                    }
                    this.store.dispatch(
                      new CartActions.AddUpdateCartProductItem(_.cloneDeep(prd))
                    );
                  }
                }

                console.log("close modal");
                if (!this.bundleMode) {
                  this.closeModal();
                }

                this.disableAddButton = false;
                this.addButtonClicked = false;
              } else {
                if (
                  this.selectedProduct &&
                  typeof this.selectedProduct === "object"
                ) {
                  let prd = _.cloneDeep(this.selectedProduct);
                  console.log("selected product with no cart id");
                  this.omit_from_product(prd);
                  if (this.previousOrderMode) {
                    if (
                      !this.selectedProduct.image ||
                      (this.selectedProduct.image &&
                        !this.selectedProduct.image.true) ||
                      (this.selectedProduct.image &&
                        !this.selectedProduct.image.true["16x9"]) ||
                      (this.selectedProduct.image &&
                        this.selectedProduct.image.true["16x9"] &&
                        !this.selectedProduct.image.true["16x9"].path)
                    ) {
                      prd.noImageProduct = true;
                    } else {
                      prd.noImageProduct = false;
                    }
                    this.store.dispatch(
                      new PreviousOrdersActions.AddUpdatePreviousOrderCartItem(
                        _.cloneDeep(prd)
                      )
                    );
                  } else {
                    if (
                      !this.selectedProduct.image ||
                      (this.selectedProduct.image &&
                        !this.selectedProduct.image.true) ||
                      (this.selectedProduct.image &&
                        !this.selectedProduct.image.true["16x9"]) ||
                      (this.selectedProduct.image &&
                        this.selectedProduct.image.true["16x9"] &&
                        !this.selectedProduct.image.true["16x9"].path)
                    ) {
                      prd.noImageProduct = true;
                    } else {
                      prd.noImageProduct = false;
                    }

                    if (this.bundleMode) {
                      //the case that the product added to bundle choice not to cart
                      this.modalCtrl.dismiss({
                        product: prd,
                        price: this.selectedProductTotalPrice,
                      });
                      console.log("dismiss", prd);
                    } else {
                      console.log("product added to cart", prd);
                      this.store.dispatch(
                        new CartActions.AddUpdateCartProductItem(
                          _.cloneDeep(prd)
                        )
                      );
                    }
                  }

                  this.closeModal();
                }
              }
            } else if (
              (notvalidOption || optionTOOpen) &&
              this.addButtonClicked
            ) {
              if (this.add_bundle_product_to_cart && this.bundleMode) {
                this.modalCtrl.dismiss("option_required_error");
                return false;
              }

              let optionElem = document.getElementById(
                notvalidOption
                  ? notvalidOption.option_id
                  : optionTOOpen.option_id
              );
              if (optionElem && !this.selectedProduct.option_stepper) {
                this.content.scrollToPoint(0, optionElem.offsetTop, 500);
              } else if (optionElem && this.selectedProduct.option_stepper) {
                this.store.dispatch(
                  new OptionStepperActions.SetSelectedStepOptionId({
                    selectedProductId: this.selectedProductId,
                    selectedStepOptionId: notvalidOption.option_id,
                  })
                );
              }

              if (
                notvalidOption &&
                notvalidOption.product_id !== this.product_id &&
                notvalidOption.productName &&
                notvalidOption.choice_id
              ) {
                this.presentErrorToast(
                  this.translateService.instant("product-details.needs-edit") +
                    ": " +
                    notvalidOption.productName
                );
                this.presentProductDetailsModalForBundle(
                  notvalidOption.choice_id,
                  notvalidOption.bundle_option_id,
                  notvalidOption.option_id,
                  notvalidOption.product_id
                );
              } else if (notvalidOption && notvalidOption.errorMessages) {
                this.presentErrorToast(notvalidOption.errorMessages[0]);
              }

              this.addButtonClicked = false;
              this.disableAddButton = false;
              this.addProductToCartValidationSub.unsubscribe();
            }
          } else {
            console.log("bug");
          }
        }
      });
  }

  showProductValidDeliveryAreasInfoModal() {
    if (!this.productValidDeliveryAreasModal) {
      this.presentProductValidDeliveryAreasModal();
    }
  }

  async presentProductValidDeliveryAreasModal() {
    this.productValidDeliveryAreasModal = await this.modalCtrl.create({
      component: ProductValidDeliveryAreasPopupComponent,
      cssClass: "productValidDeliveryAreasPopup",
      animated: true,
      componentProps: {
        notValidAddress: this.selectedAddress,
        cartProduct: this.selectedProduct,
      },
      backdropDismiss: false,
    });

    await this.productValidDeliveryAreasModal.present();
    this.productValidDeliveryAreasModal.onDidDismiss().then(() => {
      this.productValidDeliveryAreasModal = null;
    });
  }

  presentProductDetailsModalForBundle(
    choice_id,
    bundle_option_id,
    option_id,
    product_id
  ) {
    const optionIndex = _.findIndex(this.selectedProduct.options, {
      option_id: bundle_option_id,
    });
    console.log("presentPrdetailsforbunnde", optionIndex);
    if (optionIndex !== -1) {
      const choice = _.find(this.selectedProduct.options[optionIndex].choices, {
        choice_id: choice_id,
      });
      console.log("presentPrdetailsforbunnde", optionIndex, choice);
      if (choice && choice.bundle && choice.bundled_product) {
        this.store.dispatch(
          new ProductOptionsValidationActions.SetOpenProductModalBundleChoice({
            product: choice.bundled_product,
            choice_id: choice.choice_id,
          })
        );
      }
    }
  }

  calculateBundleProductsCategories(prd) {
    this.store
      .select("hyperCategories")
      .pipe(first())
      .subscribe((hyperCatState) => {
        if (
          hyperCatState &&
          hyperCatState.hyperCategories &&
          hyperCatState.hyperCategories.length
        ) {
          this.store
            .select("catalog")
            .pipe(first())
            .subscribe((catalogState) => {
              if (catalogState.allCategories) {
                let categories = _.cloneDeep(catalogState.allCategories);

                _.each(prd.options, (option) => {
                  if (option.bundle) {
                    _.each(option.choices, (choice) => {
                      if (choice.bundle && choice.bundle_product) {
                        choice.bundle_product_category = _.find(categories, {
                          category_id: choice.bundle_product.category_id,
                        });
                      }
                    });
                  }
                });
              }
            });
        } else if (
          hyperCatState &&
          hyperCatState.hyperCategories &&
          !hyperCatState.hyperCategories.length
        ) {
          this.store
            .select("catalog")
            .pipe(first())
            .subscribe((catalogState) => {
              if (catalogState.catalog) {
                let categories = _.cloneDeep(catalogState.catalog);
                _.each(prd.options, (option) => {
                  if (option.bundle) {
                    _.each(option.choices, (choice) => {
                      if (choice.bundle && choice.bundle_product) {
                        choice.bundle_product_category = _.find(categories, {
                          category_id: choice.bundle_product.category_id,
                        });
                      }
                    });
                  }
                });
              }
            });
        }
      });
  }

  nextStepInOptionsStepper() {
    this.disableNextStepButton = true;
    if (this.nextStepValidationSub) {
      this.nextStepValidationSub.unsubscribe();
    }
    console.log("current option", this.currentOptionStep);

    this.startValidationWritingState();

    this.nextStepValidationSub = this.store
      .select("productOptionsValidation")
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(400))
      .subscribe((state) => {
        if (state && !_.isEqual(this.productOptionsValidation, state)) {
          this.productOptionsValidation = _.cloneDeep(state);

          if (
            this.productOptionsValidation &&
            this.productOptionsValidation
              .optionValidationsBySelectedProductId &&
            this.productOptionsValidation.optionValidationsBySelectedProductId[
              this.selectedProductId
            ]
          ) {
            let notvalidOption;
            let optionTOOpen;

            notvalidOption = _.find(
              this.productOptionsValidation
                .optionValidationsBySelectedProductId[this.selectedProductId],
              {
                valid: false,
                product_id: this.product_id,
              }
            );
            optionTOOpen = _.find(
              this.productOptionsValidation
                .optionValidationsBySelectedProductId[this.selectedProductId],
              {
                optionToOpen: true,
                product_id: this.product_id,
              }
            );
            //this is for child bundle products
            if (!notvalidOption) {
              notvalidOption = _.find(
                this.productOptionsValidation
                  .optionValidationsBySelectedProductId[this.selectedProductId],
                {
                  valid: false,
                }
              );
            }

            //this must be delete we dont want to find options to open inside child products
            // if (!optionTOOpen) {
            //   optionTOOpen = _.find(
            //     this.productOptionsValidation
            //       .optionValidationsBySelectedProductId[this.selectedProductId],
            //     {
            //       optionToOpen: true,
            //     }
            //   );
            // }
            console.log("notValidOption checking", notvalidOption);
            let currentOption = _.find(this.selectedProduct.options, {
              option_id: this.currentOptionStep,
            });
            console.log(
              "current option",
              this.currentOptionStep,
              currentOption,
              this.selectedProduct
            );
            let stayInStep = false;
            if (
              currentOption &&
              currentOption.autoOpenOption &&
              !currentOption.optionOpened
            ) {
              stayInStep = true;
              currentOption.optionOpened = true;
            }

            if (
              !optionTOOpen &&
              !stayInStep &&
              (!notvalidOption ||
                notvalidOption.option_id !== this.currentOptionStep)
            ) {
              if (
                this.currentOptionStep &&
                this.allOptionSteps &&
                this.allOptionSteps.length
              ) {
                let currentIndex = _.indexOf(
                  this.allOptionSteps,
                  this.currentOptionStep
                );

                if (
                  currentIndex !== -1 &&
                  currentIndex < this.allOptionSteps.length - 1
                ) {
                  // if (currentIndex === this.allOptionSteps.length - 2) {
                  //   this.showAddToCartButtonForStepper = true;
                  // } else {
                  //   this.showAddToCartButtonForStepper = false;
                  // }

                  this.store.dispatch(
                    new OptionStepperActions.SetSelectedStepOptionId({
                      selectedProductId: this.selectedProductId,
                      selectedStepOptionId:
                        this.allOptionSteps[currentIndex + 1],
                    })
                  );

                  if (this.nextStepValidationSub) {
                    this.nextStepValidationSub.unsubscribe();
                  }
                } else if (
                  currentIndex !== -1 &&
                  currentIndex == this.allOptionSteps.length - 1
                ) {
                  this.store.dispatch(
                    new OptionStepperActions.SetSelectedStepOptionId({
                      selectedProductId: this.selectedProductId,
                      selectedStepOptionId: this.allOptionSteps[0],
                    })
                  );

                  if (this.nextStepValidationSub) {
                    this.nextStepValidationSub.unsubscribe();
                  }
                }
              }
              this.disableNextStepButton = false;
            } else {
              if (
                notvalidOption &&
                notvalidOption.errorMessages &&
                notvalidOption.errorMessages.length
              ) {
                this.presentErrorToast(notvalidOption.errorMessages[0]);
              }

              if (this.nextStepValidationSub) {
                this.nextStepValidationSub.unsubscribe();
              }
            }
            this.disableNextStepButton = false;
          }
          if (!this.bundleMode) {
            this.store.dispatch(
              new ProductOptionsValidationActions.ClearProductOptionsValidation(
                "all"
              )
            );
          }
        }
      });
  }

  check_for_must_select_something() {
    var foundOptionWithSelectedChoice = false;

    _.each(
      _.filter(this.selectedProduct.options, (opt) => {
        return (
          opt &&
          typeof opt === "object" &&
          opt.availableTotal &&
          opt.activeTotal &&
          (_.isEmpty(opt.dependenciesVariable) || opt.showDependent)
        );
      }),
      (option) => {
        if (
          _.find(
            _.reject(option.choices, (ch) => {
              return !ch.availableTotal || !ch.activeTotal;
            }),
            {
              is_preselected: true,
            }
          )
        ) {
          foundOptionWithSelectedChoice = true;
        }
      }
    );

    console.log(
      "must_select_something foundOptionWithSelectedChoice",
      foundOptionWithSelectedChoice
    );
    return foundOptionWithSelectedChoice;
  }

  closeModal() {
    console.log("close modal from function");

    if (!this.bundleMode) {
      this.store.dispatch(
        new SelectedProductActions.ClearSelectedProduct("all")
      );
    }
    console.log(
      "closing product details modal",
      this.bundleMode,
      this.openingFromBundleChoicesSelection,
      this.parentBundleChoice,
      this.parentProduct
    );
    if (
      this.bundleMode &&
      this.bundleMode === "add" &&
      this.openingFromBundleChoicesSelection &&
      this.parentBundleChoice &&
      this.parentProduct
    ) {
      console.log("closing product details modal", this.parentProduct);
      let product_id_option_id;
      _.each(this.parentProduct.options, (option) => {
        _.each(option.choices, (choice, choiceIndex) => {
          if (
            this.parentBundleChoice.option_id_choice_id ===
            choice.option_id_choice_id
          ) {
            product_id_option_id = option.product_id_option_id;
          }
        });
      });
      console.log("closing product details modal", this.parentProduct);

      console.log("set selectedProduct");

      this.store.dispatch(
        new SelectedProductActions.clearSelectedBundleChoiceById({
          product_id_option_id: product_id_option_id,
          option_id_choice_id: this.parentBundleChoice.option_id_choice_id,
          selectedProductId: this.parentProduct.selectedProductId,
        })
      );
    }
    this.modalCtrl.dismiss();
  }

  changeSelectedProductQuantity(ev) {
    if (ev === "increase") {
      this.productQuantityIncrease();
    } else if (ev === "decrease") {
      this.productQuantityDecrease();
    }
  }

  async presentErrorToast(errorMessage) {
    this.errorToast = await this.toastCtrl.create({
      message: errorMessage,
      position: "middle",
      duration: 3000,

      color: "danger",
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.errorToast.present();

    this.errorToast.onDidDismiss().then(() => {
      this.errorToast = null;
    });
  }

  assignFreeChoices(option_id, orderPickup, dineIn) {
    if (!option_id) {
      // There are some options that do not have option_id
      return;
    }
    var optionIndex = _.findIndex(this.selectedProduct.options, {
      option_id: option_id,
    });

    var optionsFreeChoices = parseInt(
      this.selectedProduct.options[optionIndex].freeChoices
    );

    let sortByVariable: any = "price";

    if (
      this.selectedProduct &&
      this.selectedProduct.options &&
      this.selectedProduct.options[optionIndex] &&
      this.selectedProduct.options[optionIndex].freeChoices &&
      this.selectedProduct.options[optionIndex].freeChoicesBySelectionOrder
    ) {
      sortByVariable = "selected_timestamp";
    }

    if (
      this.selectedProduct &&
      this.selectedProduct.options &&
      this.selectedProduct.options[optionIndex] &&
      this.selectedProduct.options[optionIndex].freeChoices &&
      this.selectedProduct.options[optionIndex].freeChoicesByPosition
    ) {
      sortByVariable = (choice, index) => index;
    }

    const sortedChoices = _.sortBy(
      this.selectedProduct.options[optionIndex].choices,
      (choice, index) => {
        if (sortByVariable === "price") {
          if (this.orderPickup && choice.price_takeaway) {
            sortByVariable = "price_takeway";
            return choice.price_takeaway;
          } else if (this.dineIn && choice.price_dinein) {
            sortByVariable = "price_dinein";
            return choice.price_dinein;
          }
          return choice.price;
        } else if (typeof sortByVariable === "function") {
          return sortByVariable(choice, index);
        } else {
          return choice[sortByVariable];
        }
      }
    );

    _.each(sortedChoices, (choice) => {
      if (
        dineIn &&
        (!choice.price_dinein || choice.price_dinein === 0) &&
        (!choice.price || choice.price === 0) &&
        this.selectedProduct.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }
      if (
        orderPickup &&
        (!choice.price_takeaway || choice.price_takeaway === 0) &&
        (!choice.price || choice.price === 0) &&
        this.selectedProduct.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        !orderPickup &&
        !dineIn &&
        (!choice.price || choice.price === 0) &&
        this.selectedProduct.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        optionsFreeChoices > 0 &&
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        this.selectedProduct.options[optionIndex].choices[
          _.findIndex(this.selectedProduct.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = true;
        if (choice.quantity > 1) {
          if (optionsFreeChoices - choice.quantity >= 0) {
            this.selectedProduct.options[optionIndex].choices[
              _.findIndex(this.selectedProduct.options[optionIndex].choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = choice.quantity;
            optionsFreeChoices -= choice.quantity;
          } else {
            this.selectedProduct.options[optionIndex].choices[
              _.findIndex(this.selectedProduct.options[optionIndex].choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = optionsFreeChoices;
            optionsFreeChoices -= optionsFreeChoices;
          }
        } else {
          this.selectedProduct.options[optionIndex].choices[
            _.findIndex(this.selectedProduct.options[optionIndex].choices, {
              choice_id: choice.choice_id,
            })
          ].freeQuantity = 1;
          optionsFreeChoices -= 1;
        }
      } else {
        this.selectedProduct.options[optionIndex].choices[
          _.findIndex(this.selectedProduct.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = false;
        this.selectedProduct.options[optionIndex].choices[
          _.findIndex(this.selectedProduct.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].freeQuantity = 0;
      }
    });

    optionsFreeChoices = null;
  }

  choiceQuantityDecrease(option, choice) {
    var optionIndex = _.findIndex(this.selectedProduct.options, {
      option_id: option.option_id,
    });
    var choiceIndex = _.findIndex(
      this.selectedProduct.options[optionIndex].choices,
      {
        choice_id: choice.choice_id,
      }
    );

    var qtyStep =
      this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .quantityInChoicesStep || 1;
    //console.log("qtyStep", qtyStep);
    if (
      this.selectedProduct.options[optionIndex].choices[choiceIndex].quantity >
        qtyStep &&
      this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .is_preselected &&
      this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .activeTotal &&
      this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .availableTotal
    ) {
      this.selectedProduct.options[optionIndex].choices[choiceIndex].quantity =
        this.selectedProduct.options[optionIndex].choices[choiceIndex]
          .quantity - qtyStep;
    }
  }

  calculateTotalChoiceQuantity(option_id) {
    if (!option_id) {
      return;
    }
    var optionIndex = _.findIndex(this.selectedProduct.options, {
      option_id: option_id,
    });
    var choicesQuantity = 0;
    _.each(this.selectedProduct.options[optionIndex].choices, (choice) => {
      if (
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        choicesQuantity = choicesQuantity + choice.quantity;
      }
    });

    if (!this.selectedProduct.options[optionIndex].optionData) {
      this.selectedProduct.options[optionIndex].optionData = {};
    }

    this.selectedProduct.options[optionIndex].optionData.freeChoices =
      choicesQuantity >=
      parseInt(this.selectedProduct.options[optionIndex].freeChoices)
        ? this.selectedProduct.options[optionIndex].freeChoices
        : choicesQuantity;
    this.selectedProduct.options[optionIndex].optionData.maxChoices =
      choicesQuantity >=
      parseInt(this.selectedProduct.options[optionIndex].maxChoices)
        ? this.selectedProduct.options[optionIndex].maxChoices
        : choicesQuantity;
    this.selectedProduct.options[optionIndex].optionData.minChoices =
      choicesQuantity >=
      parseFloat(this.selectedProduct.options[optionIndex].minChoices)
        ? this.selectedProduct.options[optionIndex].minChoices
        : choicesQuantity;
  }

  clickingOnChoice(option, choice) {
    var optionIndex;
    var choiceIndex;

    if (
      option &&
      option.bundle &&
      option.bundle_options &&
      option.bundle_options.load_category_choice_products &&
      choice &&
      choice.bundle &&
      choice.bundled_with_product_id
    ) {
      optionIndex = _.findIndex(this.selectedProduct.options, {
        option_id: option.option_id,
      });
      choiceIndex = _.findIndex(
        this.selectedProduct.options[optionIndex].choices,
        {
          bundled_with_product_id: choice.bundled_with_product_id,
        }
      );
    } else {
      optionIndex = _.findIndex(this.selectedProduct.options, {
        option_id: option.option_id,
      });
      choiceIndex = _.findIndex(
        this.selectedProduct.options[optionIndex].choices,
        {
          choice_id: choice.choice_id,
        }
      );
    }

    console.log(
      "clickingOnChoice optionIndex choiceIndex",
      optionIndex,
      choiceIndex
    );

    if (
      optionIndex !== -1 &&
      choiceIndex !== -1 &&
      (!this.selectedProduct.options[optionIndex].availableTotal ||
        !this.selectedProduct.options[optionIndex].choices[choiceIndex]
          .availableTotal ||
        (this.selectedProduct.options[optionIndex].dependenciesVariable &&
          this.selectedProduct.options[optionIndex].dependenciesVariable
            .length > 0 &&
          !this.selectedProduct.options[optionIndex].dependentHidden &&
          !this.selectedProduct.options[optionIndex].showDependent))
    ) {
      console.log("option/choice is not available");
      return;
    }

    if (
      optionIndex !== -1 &&
      choiceIndex !== -1 &&
      !this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .is_preselected &&
      !this.checkMaximumChoices(option.option_id)
    ) {
      this.selectedProduct.options[optionIndex].choices[
        choiceIndex
      ].is_preselected = false;

      console.log("toast Show");

      return;
    }
    if (optionIndex !== -1 && choiceIndex !== -1) {
      this.selectedProduct.options[optionIndex].choices[
        choiceIndex
      ].is_preselected =
        !this.selectedProduct.options[optionIndex].choices[choiceIndex]
          .is_preselected;

      if (
        this.selectedProduct.options[optionIndex].choices[choiceIndex]
          .is_preselected &&
        choice.selected_timestamp
      ) {
        this.selectedProduct.options[optionIndex].choices[
          choiceIndex
        ].selected_timestamp = _.cloneDeep(choice.selected_timestamp);
      }
    }

    // this.selectedProduct.options[optionIndex].choices[choiceIndex].quantity = 1;
  }

  choiceQuantityIncrease(option, choice) {
    var optionIndex;
    var choiceIndex;
    if (
      option &&
      option.bundle &&
      option.bundle_options &&
      option.bundle_options.load_category_choice_products &&
      choice &&
      choice.bundle &&
      choice.bundled_with_product_id
    ) {
      optionIndex = _.findIndex(this.selectedProduct.options, {
        option_id: option.option_id,
      });
      choiceIndex = _.findIndex(
        this.selectedProduct.options[optionIndex].choices,
        {
          bundled_with_product_id: choice.bundled_with_product_id,
        }
      );
    } else {
      optionIndex = _.findIndex(this.selectedProduct.options, {
        option_id: option.option_id,
      });
      choiceIndex = _.findIndex(
        this.selectedProduct.options[optionIndex].choices,
        {
          choice_id: choice.choice_id,
        }
      );
    }

    var qtyStep =
      this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .quantityInChoicesStep || 1;
    console.log("qtyStep", qtyStep);
    if (
      this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .is_preselected &&
      this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .activeTotal &&
      this.selectedProduct.options[optionIndex].choices[choiceIndex]
        .availableTotal
    ) {
      if (this.checkMaximumChoices(option.option_id)) {
        this.selectedProduct.options[optionIndex].choices[
          choiceIndex
        ].quantity =
          this.selectedProduct.options[optionIndex].choices[choiceIndex]
            .quantity + qtyStep;
      } else {
        // Toast.show(
        //   $rootScope.languages["MAXIMUM CHOICES ALLOWED"][
        //     $rootScope.selectedLanguage
        //   ] +
        //     ": " +
        //     $rootScope.currentProduct.options[optionIndex].maxChoices
        // );
        console.log("show Toast");
      }
    }
  }

  checkMaximumChoices(option_id) {
    //I need to calculate here the choice quantity!

    let option = _.find(this.selectedProduct.options, {
      option_id: option_id,
    });

    var optionsMaxChoices = parseInt(option.maxChoices);
    var counter = 0;

    if (!_.isNaN(optionsMaxChoices) && optionsMaxChoices > 0) {
      _.each(_.sortBy(option.choices, "price"), (choice, key, list) => {
        if (
          choice.is_preselected &&
          choice.activeTotal &&
          choice.availableTotal
        ) {
          counter = counter + choice.quantity;
        }
      });
      console.log(counter, optionsMaxChoices);
      if (counter >= optionsMaxChoices) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  omit_from_product(product) {
    // This function also exists in the backend calculate final price lambda
    const omit_from_product = (product) => {
      return _.omit(product, [
        "initial_price",
        "num_of_options",
        "product_link",
        "description",
        "original_description",
        "image",
        "showOptionInDescription",
        "productIncludedInCart",
      ]);
    };

    const omit_from_option = (option) => {
      return _.omit(option, [
        "num_of_choices",
        "always_open",
        "always_split_columns",
        "new_template_from_cloning",
        "image",
        "optionData",
        "template_id_option_id",
      ]);
    };

    const omit_from_choice = (choice) => {
      return _.omit(choice, [
        "template",
        "image",
        "type",
        "dependenciesWhenNotPreselected",
        "dependentOptions",
        "option_template_id_choice_id",
      ]);
    };
    product = omit_from_product(product);
    product.options = _.filter(
      product.options,
      (option) =>
        !option.dependenciesVariable ||
        option.dependenciesVariable.length === 0 ||
        (option.dependenciesVariable.length > 0 && option.showDependent)
    );

    _.each(product.options, (option, o) => {
      product.options[o] = omit_from_option(option);
      if (_.isEmpty(option.preselectedChoices)) {
        product.options[o].choices = _.filter(
          product.options[o].choices,
          (choice) =>
            choice.is_preselected && choice.activeTotal && choice.availableTotal
        );
      }
      _.each(product.options[o].choices, (choice, c) => {
        product.options[o].choices[c] = omit_from_choice(choice);
      });
    });
  }

  checkProductDetailsBigDesign() {
    // console.log(
    //   "checkProductDetailsBigDesign",
    //   this.windowDimensions,
    //   this.productDetailsDimensions,
    //   this.selectedProduct,
    //   this.image
    // );
    let modalDemensions: any;
    if (this.selectedProductId && this.productDetailsDimensions) {
      modalDemensions = _.find(this.productDetailsDimensions, {
        selectedProductId: this.selectedProductId,
      });
    }

    if (
      this.windowDimensions &&
      this.windowDimensions.width >= 1200 &&
      ((modalDemensions && modalDemensions.width >= 1100) ||
        (this.selectedProduct && this.selectedProduct.squareImageDesign)) &&
      this.selectedProduct &&
      this.selectedProduct.options &&
      this.selectedProduct.options.length &&
      this.selectedProduct.options.length > 2 &&
      !this.bundleMode &&
      this.image &&
      this.image.path
    ) {
      this.bigProductDetailsDesign = "yes";
    } else {
      this.bigProductDetailsDesign = "no";
    }
  }

  subscribeToOptionValidationAnimatingButton() {
    this.animateCartButtonSubscription = this.store
      .select("productOptionsValidation")
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(200))
      .subscribe((state) => {
        if (
          state &&
          state.lastValidTimestampBySelectedProductId &&
          state.lastValidTimestampBySelectedProductId[this.selectedProductId] &&
          !_.isEmpty(
            state.optionValidationsBySelectedProductId[this.selectedProductId]
          )
        ) {
          let optionValidations = _.cloneDeep(
            state.optionValidationsBySelectedProductId[this.selectedProductId]
          );
          let notValid = _.find(optionValidations, {
            valid: false,
          });
          if (!notValid) {
            this.animateAddToCartButton = true;
          } else {
            this.animateAddToCartButton = false;
          }
        }
      });
  }

  async checkUpdateStockValues(selectedProduct) {
    return new Promise((resolve) => {
      if (
        selectedProduct &&
        (selectedProduct.availability_stock_enabled ||
          selectedProduct.preorder_availability_stock_enabled)
      ) {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            this.store
              .select("deliveryMethodAddress")
              .pipe(first())
              .subscribe((deliveryState) => {
                this.store
                  .select("groupApp")
                  .subscribe((groupAppState) => {
                    if (groupAppState && deliveryState) {
                      this.dataStorageService
                        .fetchProductDetailsUpdatedData(
                          storeInfo.store_id,
                          groupAppState.groupApp.group,
                          selectedProduct.product_id,
                          groupAppState.selectedLangugage,
                          deliveryState.orderPickup,
                          deliveryState.dineIn,
                          deliveryState.selectedPreorderDate
                            ? {
                                preorder:
                                  deliveryState.selectedPreorderDate &&
                                  deliveryState.selectedPreorderDate.date &&
                                  deliveryState.selectedPreorderDate.hour
                                    ? true
                                    : false,
                                preorder_day:
                                  deliveryState.selectedPreorderDate.date,
                                preorder_time:
                                  deliveryState.selectedPreorderDate.hour,
                              }
                            : null
                        )
                        .subscribe({
                          next: async (res: any) => {
                            if (!res || !res.success) {
                              const alert = await this.alertController.create({
                                header: this.translateService.instant("alert"),
                                message:
                                  res && res.comment_id
                                    ? this.translateService.instant(
                                        res.comment_id
                                      )
                                    : this.translateService.instant(
                                        "errorMessages.general_error"
                                      ),
                                backdropDismiss: false,
                                buttons: ["OK"],
                              });

                              await alert.present();
                            } else {
                              if (res && res.product) {
                                selectedProduct = _.assign(
                                  _.cloneDeep(selectedProduct),
                                  res.product
                                );
                              }
                            }

                            resolve(_.cloneDeep(selectedProduct));
                          },
                          error: async (err) => {
                            const alert = await this.alertController.create({
                              header: this.translateService.instant("alert"),
                              message: this.translateService.instant(
                                "errorMessages.problem_reaching_server"
                              ),

                              buttons: ["OK"],
                            });
                            await alert.present();

                            resolve(_.cloneDeep(selectedProduct));
                          },
                        });
                    } else {
                      resolve(_.cloneDeep(selectedProduct));
                    }
                  })
                  .unsubscribe();
              })
              .unsubscribe();
          })
          .unsubscribe();
      } else {
        resolve(_.cloneDeep(selectedProduct));
      }
    });
  }

  getStoreInfo() {
    return new Promise((resolve, reject) => {
      this.store
        .select(selectors.getStoreInfo)
        .pipe(first())
        .subscribe((storeInfo) => {
          resolve(storeInfo);
        });
    });
  }

  checkForCustomerPricelist(selectedProduct) {
    return new Promise((resolve) => {
      if (selectedProduct && selectedProduct.price) {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            if (
              storeInfo &&
              storeInfo.enable_customer_pricelists_mobileorder &&
              storeInfo.enable_customer_pricelists
            ) {
              this.store
                .select("catalog")
                .pipe(first())
                .subscribe((catalogState) => {
                  if (
                    catalogState &&
                    catalogState.customerPricelist &&
                    catalogState.customerPricelist.length > 0
                  ) {
                    let customerProducts = _.cloneDeep(
                      catalogState.customerPricelist
                    );
                    _.each(customerProducts, (customerProduct) => {
                      if (
                        customerProduct.product_id ===
                        selectedProduct.product_id
                      ) {
                        if (customerProduct.price) {
                          selectedProduct.price = _.cloneDeep(
                            parseFloat(
                              customerProduct.price.toString()
                            ).toFixed(
                              storeInfo && storeInfo.decimalPlacesPrices
                                ? storeInfo.decimalPlacesPrices
                                : 2
                            )
                          );
                        }

                        if (customerProduct.price_takeaway) {
                          selectedProduct.price_takeaway = _.cloneDeep(
                            parseFloat(
                              customerProduct.price_takeaway.toString()
                            ).toFixed(
                              storeInfo && storeInfo.decimalPlacesPrices
                                ? storeInfo.decimalPlacesPrices
                                : 2
                            )
                          );
                        }
                      }
                    });

                    resolve(selectedProduct);
                  } else {
                    resolve(selectedProduct);
                  }
                });
            } else {
              resolve(selectedProduct);
            }
          });
      } else {
        resolve(selectedProduct);
      }
    });
  }

  calculatePlasticWrapperTaxPrice(product) {
    this.plasticWrapperTaxProductPrice = 0;
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          state.groupApp.design_settings &&
          state.groupApp.design_settings.plasticWrapperTaxWithPriceInProducts
        ) {
          console.log("plasticWrapperTaxProductPrice");
          this.store
            .select("cartCustomOptionsSelections")
            .pipe(first())
            .subscribe(async (cartOptionsState) => {
              const cartCustomOptions = _.cloneDeep(
                cartOptionsState.cartCustomOptionsSelections
              );
              console.log("plasticWrapperTaxProductPrice", cartCustomOptions);
              if (cartCustomOptions) {
                const plasticWrapperTaxOption = _.find(cartCustomOptions, {
                  preNameId: "plastic_wrapper_tax",
                  active: true,
                });
                let wrapperSum = 0.0;

                wrapperSum = await this.calculateChoiceQuantityFromProduct(
                  null,
                  product,
                  wrapperSum
                );

                if (
                  wrapperSum &&
                  plasticWrapperTaxOption &&
                  plasticWrapperTaxOption.choices &&
                  plasticWrapperTaxOption.choices[0] &&
                  plasticWrapperTaxOption.choices[0].price
                ) {
                  this.plasticWrapperTaxProductPrice = parseFloat(
                    (
                      plasticWrapperTaxOption.choices[0].price * wrapperSum
                    ).toFixed(2)
                  );
                }
              }
            })
            .unsubscribe();
        }
      })
      .unsubscribe();
  }

  bundleProductPreselectRequiredChoices(product) {
    if (this.bundleMode && product.options) {
      _.each(product.options, (option) => {
        if (
          option.is_require &&
          option.choices &&
          option.choices.length === 1
        ) {
          if (!option.choices[0].is_preselected) {
            option.choices[0].is_preselected = true;
          }
        }
      });
    }

    return product;
  }

  calculateChoiceQuantityFromProduct(parentProduct, product, wrapperSum) {
    if (product && product.options) {
      _.each(product.options, (option) => {
        if (option.bundle) {
          if (option && option.choices) {
            _.each(option.choices, (choice) => {
              if (
                choice.is_preselected &&
                choice.bundle &&
                !_.isEmpty(choice.bundled_product)
              ) {
                wrapperSum = this.calculateChoiceQuantityFromProduct(
                  product,
                  _.cloneDeep(choice.bundled_product),
                  _.cloneDeep(wrapperSum)
                );
              }
            });
          }
        }
      });
    }

    if (
      product &&
      product.plasticWrapperTax &&
      product.plasticWrapperTaxWeight
    ) {
      if (parentProduct) {
        wrapperSum = Math.floor(
          wrapperSum +
            (product.enable_quantity_step && product.quantity_step
              ? 1
              : product.quantity) *
              product.plasticWrapperTaxWeight *
              (parentProduct.enable_quantity_step && parentProduct.quantity_step
                ? 1
                : parentProduct.quantity)
        );
      } else {
        wrapperSum = Math.floor(
          wrapperSum +
            (product.enable_quantity_step && product.quantity_step
              ? 1
              : product.quantity) *
              product.plasticWrapperTaxWeight
        );
      }
    }

    if (product && product.options && product.options.length) {
      _.each(product.options, (option) => {
        if (option && option.choices && option.choices.length) {
          _.each(option.choices, (choice) => {
            if (
              choice &&
              choice.plasticWrapperTax &&
              choice.plasticWrapperTaxWeight
            ) {
              if (parentProduct) {
                wrapperSum = Math.floor(
                  wrapperSum +
                    (product.enable_quantity_step && product.quantity_step
                      ? 1
                      : product.quantity) *
                      choice.quantity *
                      choice.plasticWrapperTaxWeight *
                      (parentProduct.enable_quantity_step &&
                      parentProduct.quantity_step
                        ? 1
                        : parentProduct.quantity)
                );
              } else {
                wrapperSum = Math.floor(
                  wrapperSum +
                    (product.enable_quantity_step && product.quantity_step
                      ? 1
                      : product.quantity) *
                      choice.quantity *
                      choice.plasticWrapperTaxWeight
                );
              }
            }
          });
        }
      });
    }

    return _.cloneDeep(wrapperSum);
  }

  // addPlasticWrapperTax(selectedProductPrice) {
  //   return new Promise((res) => {
  //     this.store
  //       .select("cartCustomOptionsSelections")
  //       .pipe(first())
  //       .subscribe((cartOptionsState) => {
  //         if (
  //           cartOptionsState &&
  //           cartOptionsState.cartCustomOptionsSelections
  //         ) {
  //           const plastic_wrapper_tax_option = _.find(
  //             _.cloneDeep(cartOptionsState.cartCustomOptionsSelections),
  //             { preNameId: "plastic_wrapper_tax", active: true }
  //           );

  //           console.log("addPlasticWrapperTax", plastic_wrapper_tax_option);

  //           if (plastic_wrapper_tax_option) {
  //             let wrapper_sum = 0;

  //             wrapper_sum = this.calculateChoiceQuantityFromProduct(
  //               _.cloneDeep(this.selectedProduct),
  //               wrapper_sum
  //             );

  //             console.log(
  //               "addPlasticWrapperTax",
  //               plastic_wrapper_tax_option,
  //               wrapper_sum
  //             );

  //             if (wrapper_sum) {
  //               if (
  //                 plastic_wrapper_tax_option &&
  //                 plastic_wrapper_tax_option.choices &&
  //                 plastic_wrapper_tax_option.choices[0] &&
  //                 plastic_wrapper_tax_option.choices[0].price
  //               ) {
  //                 selectedProductPrice = (
  //                   parseFloat(_.cloneDeep(selectedProductPrice).toString()) +
  //                   parseFloat(
  //                     (
  //                       parseFloat(wrapper_sum.toString()) *
  //                       parseFloat(
  //                         plastic_wrapper_tax_option.choices[0].price.toString()
  //                       )
  //                     ).toString()
  //                   )
  //                 ).toFixed(2);

  //                 res(_.cloneDeep(selectedProductPrice));
  //               }
  //             } else {
  //               res(_.cloneDeep(selectedProductPrice));
  //             }
  //           } else {
  //             res(_.cloneDeep(selectedProductPrice));
  //           }
  //         } else {
  //           res(_.cloneDeep(selectedProductPrice));
  //         }
  //       })
  //       .unsubscribe();
  //   });
  // }

  subscribeToCatalogUserPricelists() {
    this.catalogUserPricelistSub = this.store
      .select("catalog")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.customerPricelist, state.customerPricelist)
        ) {
          this.customerPricelist = _.cloneDeep(state.customerPricelist);
          console.log("customerPricelists", this.customerPricelist);
          if (this.customerPricelist) {
            if (!this.cart_id && this.product_id) {
              this.fetchProduct(
                this.order ? this.order.store_id : this.store_id,
                this.product_id,
                this.languagePath,
                true
              );

              console.log("selectedProductsta", this.selectedProduct);
            } else if (this.product_id && this.cart_id) {
              this.store
                .select("cart")
                .pipe(first())
                .subscribe((state) => {
                  if (state && state.products) {
                    this.cartProducts = _.cloneDeep(state.products);
                    console.log(
                      "cart products",
                      this.cartProducts,
                      this.cart_id
                    );

                    let cartIndex = _.findIndex(this.cartProducts, {
                      cart_id: this.cart_id,
                    });

                    let product_id = this.cartProducts[cartIndex].product_id;

                    this.fetchProductWithCartId(
                      this.store_id,
                      product_id,
                      this.languagePath,
                      cartIndex,
                      true
                    );
                  }
                })
                .unsubscribe();
            }
          }
        }
      });
  }

  async showWrapperTaxToast() {
    this.errorWrapperTaxToast = await this.toastController.create({
      message: this.translateService.instant(
        this.selectedProduct.plasticWrapperTaxWeight == 2
          ? "product-details.tax-for-two-plastic-wrappers"
          : "product-details.tax-for-one-plastic-wrappers"
      ),
      duration: 5000,
      position: "middle",

      color: "primary",
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.errorWrapperTaxToast.present();

    this.errorWrapperTaxToast.onDidDismiss().then(() => {
      this.errorWrapperTaxToast = null;
    });
  }

  animationCreated(ev) {
    this.favoriteAnimationInstance = ev;
    this.favoriteAnimationInstance.setSpeed(1);
  }

  animationComplete(ev) {
    this.favoriteAnimationInstance.stop();
    this.favoriteAnimation = false;
    this.changeDetector.detectChanges();
  }

  async imageFullscreen() {
    this.imageFullscreenModal = await this.modalCtrl.create({
      component: FullscreenImagePopupComponent,
      cssClass: "fullscreen-image-popup",
      animated: true,
      componentProps: {
        image_path: this.image.path,
      },
      backdropDismiss: true,
    });

    await this.imageFullscreenModal.present();
    this.imageFullscreenModal.onDidDismiss().then(() => {
      this.imageFullscreenModal = null;
    });
  }

  checkDisableForDeliveryMethodAddress(orderPickup, dineIn) {
    this.disableForDeliveryMethodAddress = false;
    if (orderPickup === false && dineIn === false) {
      if (
        this.selectedProduct &&
        !this.selectedProduct.delivery &&
        (this.selectedProduct.pickup || this.selectedProduct.dinein)
      ) {
        this.disableForDeliveryMethodAddress = true;
      }
    } else if (orderPickup && dineIn === false) {
      if (
        this.selectedProduct &&
        !this.selectedProduct.pickup &&
        (this.selectedProduct.dinein || this.selectedProduct.delivery)
      ) {
        this.disableForDeliveryMethodAddress = true;
      }
    } else if (orderPickup === false && dineIn) {
      if (
        this.selectedProduct &&
        !this.selectedProduct.dinein &&
        (this.selectedProduct.delivery || this.selectedProduct.pickup)
      ) {
        this.disableForDeliveryMethodAddress = true;
      }
    }
  }

  checkAtLeastOneFrozenTag(product) {
    if (product) {
      this.atLeastOneFrozenTag = false;
      if (product.tags && product.tags.frozen) {
        this.atLeastOneFrozenTag = true;
      } else {
        _.each(product.options, (opt) => {
          _.each(opt.choices, (ch) => {
            if (ch && ch.tags && ch.tags.frozen) {
              this.atLeastOneFrozenTag = true;

              if (
                ch &&
                ch.bundle &&
                !_.isEmpty(ch.bundled_product) &&
                ch.bundled_product &&
                ch.bundled_product.tags &&
                ch.bundled_product.tags.frozen
              ) {
                this.atLeastOneFrozenTag = true;
              }
            }
          });
        });
      }
    }
  }

  checkExclusiveCustomerPromotionsProduct(product) {
    console.log("dklasjdklas", product.promotions);
    return new Promise((resolve) => {
      this.store
        .select("customerPromotions")
        .pipe(first())
        .subscribe((customerPromosState) => {
          if (product) {
            const customerPromotions =
              customerPromosState.customerPromotions || [];

            if (product && !customerPromotions.length) {
              if (product && product.promotions && product.promotions.length) {
                product.promotions = _.map(
                  _.cloneDeep(product.promotions),
                  (promotion) => {
                    return !promotion.exclusiveMobilesActive;
                  }
                );
              }
            } else if (product && customerPromotions.length) {
              _.each(customerPromotions, (customerPromo) => {
                if (customerPromo.exclusiveMobilesActive) {
                  if (product && !product.promotions) {
                    product.promotions = [];
                  }

                  if (
                    customerPromo.appliesToCategoriesOrProducts &&
                    (_.find(customerPromo.appliesToCategoriesOrProducts, {
                      id: product.category_id,
                    }) ||
                      _.find(customerPromo.appliesToCategoriesOrProducts, {
                        id: product.product_id,
                      })) &&
                    !_.find(product.promotions, {
                      id: product.category_id,
                    }) &&
                    !_.find(product.promotions, {
                      id: product.product_id,
                    })
                  ) {
                    if (!product.promotions) {
                      product.promotions = [];
                    }

                    if (
                      !_.find(product.promotions, {
                        id: product.category_id,
                        description: customerPromo.description,
                        exclusiveMobilesActive: true,
                      }) &&
                      !_.find(product.promotions, {
                        description: customerPromo.description,
                      })
                    ) {
                      //TODO CHECK HERE THE SECOND FIND NEED TO BE DELETED IF PRODUCT CDN UPDATED
                      product.promotions.push({
                        id: product.category_id,
                        description: customerPromo.description,
                        exclusiveMobilesActive: true,
                      });
                    }
                  }
                }
              });
            }

            resolve(_.cloneDeep(product));
          }
        });
    });
  }

  ionViewWillLeave() {
    this.store.dispatch(
      new OptionStepperActions.SetOptionsSteps({
        selectedProductId: this.selectedProductId,
        optionsSteps: null,
      })
    );

    this.store.dispatch(
      new OptionStepperActions.SetSelectedStepOptionId({
        selectedProductId: this.selectedProductId,
        selectedStepOptionId: "not_set",
      })
    );
  }

  ngOnDestroy() {
    window.removeEventListener("resize", this.debouceResize);

    if (this.imageFullscreenModal) {
      this.imageFullscreenModal.dismiss();
      this.imageFullscreenModal = null;
    }

    if (
      this.add_bundle_product_to_cart &&
      this.parentBundleOption &&
      this.bundleMode
    ) {
      this.store.dispatch(
        new ProductOptionsValidationActions.RemoveBundleOptionLoading(
          _.cloneDeep(this.parentBundleOption.product_id_option_id)
        )
      );
    }

    this.store
      .select("selectedProduct")
      .pipe(first())
      .subscribe((state) => {
        if (
          state &&
          state.selectedProductsArray &&
          (!state.selectedProductsArray.length ||
            (state.selectedProductsArray.length &&
              state.selectedProductsArray.length === 1)) &&
          state.recalculateProductPreorderAvailabilityTimestamp
        ) {
          this.store.dispatch(
            new SelectedProductActions.SetRecalculateProductPreorderAvailability(
              null
            )
          );
        }
      })
      .unsubscribe();

    if (this.catalogUserPricelistSub) {
      this.catalogUserPricelistSub.unsubscribe();
    }

    if (this.gesture) {
      this.gesture.destroy();
      this.gesture = null;
    }
    if (this.errorToast) {
      this.errorToast.dismiss();
      this.errorToast = null;
    }

    if (this.errorWrapperTaxToast) {
      this.errorWrapperTaxToast.dismiss();
      this.errorWrapperTaxToast = null;
    }

    if (this.migrationCustomerIdSub) {
      this.migrationCustomerIdSub.unsubscribe();
      this.migrationCustomerIdSub = null;
    }

    if (this.preorderProductAvailabilityAlert) {
      this.preorderProductAvailabilityAlert.dismiss();
      this.preorderProductAvailabilityAlert = null;
    }

    this.store.dispatch(
      new SelectedProductActions.ClearSelectedProduct(this.selectedProductId)
    );
    this.store.dispatch(
      new SelectedProductActions.ClearSelectedProductTotalPrice(
        this.selectedProductId
      )
    );
    this.store.dispatch(
      new SelectedProductActions.ClearSelectedProductPointsToEarn(
        this.selectedProductId
      )
    );

    this.store.dispatch(
      new productDetailsDimensionsActions.ClearProductDetailDimensions(
        _.cloneDeep(this.selectedProductId)
      )
    );

    // this.store.dispatch(
    //   new hardwareButtonPrioritiesActions.DeletePriority({
    //     id: "productDetailsModal",
    //     priority: 20,
    //   })
    // );
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    this.loginPopupsService.closeFirebaseModalLogin();
    this.loginPopupsService.closeConfirmationLoginModal();
    this.loginPopupsService.closeMobileMagicLoginAlert();
    console.log("on Destroy");
    if (!this.bundleMode) {
      this.store.dispatch(
        new ProductOptionsValidationActions.ClearProductOptionsValidation("all")
      );
      this.store.dispatch(new OptionStepperActions.ClearState());
    }

    if (this.addProductToCartValidationSub) {
      this.addProductToCartValidationSub.unsubscribe();
    }

    if (this.animateCartButtonSubscription) {
      this.animateCartButtonSubscription.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (
      this.productDetailSubscriptions &&
      this.productDetailSubscriptions.length > 0
    ) {
      this.productDetailSubscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }

    if (this.windowInfoSub) {
      this.windowInfoSub.unsubscribe();
    }

    if (this.productValidDeliveryAreasModal) {
      this.productValidDeliveryAreasModal.dismiss();
      this.productValidDeliveryAreasModal = null;
    }
  }
}
