<div style="position: absolute; top: 20px; left: 10px; z-index: 1000">
  <ion-title size="small" color="white">
    {{
      "payment-cards.modals.select-payment-gateway-tokenization.please-select"
        | translate
    }}
  </ion-title>
  <p
    *ngIf="order"
    style="margin-inline-start: 22px; margin-top: 2px; font-size: small"
  >
    <ion-text color="white"
      ><strong>#{{ order.order_id }} | </strong></ion-text
    >
    <ion-text color="white" *ngIf="order.timestamp"
      ><strong>
        {{ order.timestamp | date : "dd/MM/yy HH:mm" }}
      </strong>
    </ion-text>

    <ion-text color="white" *ngIf="order.grand_total"
      ><strong>| {{ order.grand_total }}€ </strong></ion-text
    >
  </p>
</div>
<ion-button
  style="position: absolute; top: 20px; right: 10px; z-index: 1000"
  (click)="closeModal()"
  size="small"
  fill="clear"
  color="white"
>
  <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
</ion-button>

<ion-grid
  *ngIf="
    windowDimensions &&
    ((paymentGateways && paymentGateways.length >= 0) ||
      (cartPaymentGateWays &&
        cartPaymentGateWays['mobilePOS'] &&
        cartPaymentGateWays['mobilePOS'].active))
  "
  (click)="closeModal()"
  style="height: 100%; width: 100%"
>
  <ion-row
    *ngIf="
      (paymentGateways && paymentGateways.length >= 0) ||
      (cartPaymentGateWays &&
        cartPaymentGateWays['mobilePOS'] &&
        cartPaymentGateWays['mobilePOS'].active)
    "
    style="width: 100%; height: 100%"
    class="ion-align-items-center ion-justify-content-center"
  >
    <!-- <ion-col
      class="ion-no-padding"
      size="12"
      *ngIf="currentView !== 'app.cart'"
    >
    </ion-col> -->
    <ion-col style="max-width: 650px">
      <ion-row style="width: 100%">
        <ion-col
          class="ion-text-center"
          style="padding-bottom: 18px"
          [size]="
            windowDimensions &&
            windowDimensions.width &&
            windowDimensions.width < 650
              ? '6'
              : colSizeBrowser
          "
          *ngFor="
            let paymentMethod of cartPaymentGateWays
              | filterPaymentMethod : currentUser
              | keyvalue
          "
        >
          <ion-grid class="ion-no-padding" style="width: 130px">
            <ion-item
              color="selectPaymentMethod"
              button="true"
              lines="none"
              detail="false"
              (click)="selectPaymentMethod(paymentMethod)"
            >
              <ion-label
                style="font-size: small; font-weight: bold; margin: 0"
                class="ion-text-wrap ion-text-center"
              >
                <ion-grid
                  style="width: 80px; min-height: 80px; padding-bottom: 0"
                >
                  <ng-lottie
                    *ngIf="paymentMethod.key === 'cash'"
                    [options]="cashAnimationOptions"
                  >
                  </ng-lottie>
                  <ng-lottie
                    *ngIf="paymentMethod.key === 'mobilePOS'"
                    [options]="mobilePosAnimationOptions"
                  >
                  </ng-lottie>
                  <ng-lottie
                    *ngIf="
                      paymentMethod.key !== 'cash' &&
                      paymentMethod.key !== 'mobilePOS' &&
                      paymentMethod.key !== 'paypal' &&
                      paymentMethod.key !== 'klarna'
                    "
                    [options]="cardAnimationOptions"
                  >
                  </ng-lottie>
                </ion-grid>
                <ion-text *ngIf="paymentMethod.key === 'cash'">{{
                  "popups.select-payment-method.cash" | translate
                }}</ion-text>

                <ion-text *ngIf="paymentMethod.key === 'mobilePOS'">
                  {{
                    "popups.select-payment-method.mobilePOS" | translate
                  }}</ion-text
                ><ion-text
                  *ngIf="
                    paymentMethod.key !== 'cash' &&
                    paymentMethod.key !== 'mobilePOS'
                  "
                >
                  {{
                    "popups.select-payment-method." + paymentMethod.key
                      | translate
                  }}
                </ion-text>
              </ion-label>

              <div
                class="ion-text-center"
                style="position: absolute; top: 5px; left: 0; width: 100%"
                *ngIf="
                  paymentMethod.key !== 'cash' &&
                  paymentMethod.key !== 'mobilePOS' &&
                  storesByStoreId &&
                  storesByStoreId[currentStoreId] &&
                  storesByStoreId[currentStoreId].paymentGateways[
                    paymentMethod.key
                  ] &&
                  storesByStoreId[currentStoreId].paymentGateways[
                    paymentMethod.key
                  ].cards
                "
              >
                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.visa
                  "
                  alt="visa"
                  width="20px"
                  style="padding-right: 2px"
                  src="https://data.deliverymanager.gr/bankicons/visa.png"
                />
                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.mastercard
                  "
                  alt="mastercard"
                  width="20px"
                  style="padding-right: 2px"
                  src="https://data.deliverymanager.gr/bankicons/mastercard.png"
                />
                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.maestro
                  "
                  alt="maestro"
                  width="20px"
                  style="padding-right: 2px"
                  src="https://data.deliverymanager.gr/bankicons/maestro.png"
                />
                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.discover
                  "
                  alt="discover"
                  width="20px"
                  style="padding-right: 2px"
                  src="https://data.deliverymanager.gr/bankicons/discover.png"
                />
                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.diners
                  "
                  alt="diners"
                  width="20px"
                  style="padding-right: 2px"
                  src="https://data.deliverymanager.gr/bankicons/diners.png"
                />
                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.american
                  "
                  alt="american"
                  width="20px"
                  style="padding-right: 2px"
                  src="https://data.deliverymanager.gr/bankicons/american.png"
                />

                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.googlePay
                  "
                  alt="google-pay"
                  width="42px"
                  style="padding-right: 4px"
                  src="https://data.deliverymanager.gr/bankicons/googlepay.png"
                />

                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.applePay
                  "
                  alt="apple-pay"
                  width="42px"
                  style="padding-right: 4px"
                  src="https://data.deliverymanager.gr/bankicons/applepay.png"
                />

                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.googlePayTransparent
                  "
                  alt="google-pay"
                  width="42px"
                  style="padding-right: 4px"
                  src="https://data.deliverymanager.gr/bankicons/google-pay.png"
                />

                <img
                  *ngIf="
                    storesByStoreId[currentStoreId].paymentGateways[
                      paymentMethod.key
                    ].cards.applePayTransparent
                  "
                  alt="apple-pay"
                  width="42px"
                  style="padding-right: 4px"
                  src="https://data.deliverymanager.gr/bankicons/apple-pay.png"
                />
              </div>

              <span
                style="position: absolute; bottom: 5px; right: 5px"
                *ngIf="
                  paymentMethod.key !== 'cash' &&
                  paymentMethod.key !== 'mobilePOS'
                "
              >
                <img
                  *ngIf="paymentMethod.key === 'ethniki'"
                  alt="ethniki"
                  width="30px"
                  src="https://data.deliverymanager.gr/bankicons/ethniki.png"
                />

                <img
                  *ngIf="paymentMethod.key === 'nbg_simplify'"
                  alt="nbg_simplify"
                  width="30px"
                  src="https://data.deliverymanager.gr/bankicons/ethniki.png"
                />
                <img
                  *ngIf="paymentMethod.key === 'mypos'"
                  height="20px"
                  alt="mypos"
                  src="https://data.deliverymanager.gr/bankicons/mypos.png"
                />
                <img
                  *ngIf="paymentMethod.key === 'viva'"
                  width="50px"
                  alt="viva"
                  src="https://data.deliverymanager.gr/bankicons/viva.png"
                />
                <img
                  *ngIf="paymentMethod.key === 'piraeus'"
                  width="18px"
                  alt="piraeus"
                  src="https://data.deliverymanager.gr/bankicons/piraeus.png"
                />
                <img
                  *ngIf="paymentMethod.key === 'eurobank'"
                  width="35px"
                  alt="eurobank"
                  src="https://data.deliverymanager.gr/bankicons/eurobank.png"
                />
                <img
                  *ngIf="paymentMethod.key === 'alpha'"
                  width="50px"
                  alt="alpha"
                  src="https://data.deliverymanager.gr/bankicons/alpha.png"
                />
                <img
                  *ngIf="paymentMethod.key === 'paypal'"
                  width="35px"
                  alt="paypal"
                  src="https://data.deliverymanager.gr/bankicons/paypal.png"
                />
                <img
                  *ngIf="paymentMethod.key === 'nexi'"
                  width="35px"
                  alt="nexi"
                  src="https://data.deliverymanager.gr/bankicons/nexi.png"
                />
              </span>
            </ion-item>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- <ion-content>
  <ion-list>
    <span>
      <span *ngFor="let paymentMethod of cartPaymentGateWays | keyvalue">
        <ion-item button detail="false" lines="full"> </ion-item>
      </span>
    </span>
  </ion-list>
</ion-content> -->
